export default {
  // ETG
  ARRAY: [
    'CARNE',
    'PRODUCTOS CÁRNICOS',
    'PRODUCTOS DE LA PESCA',
    'PRODUCTOS DE LA PESCA TRANSFORMADOS',
    'PRODUCTOS LÁCTEOS',
    'HUEVOS Y OVOPRODUCTOS',
    'PRODUCTOS COMPUESTOS',
    'OTROS PRODUCTOS DE ORIGEN ANIMAL DESTINADOS AL CONSUMO HUMANO',
  ],
}
