<template>
  <v-dialog v-model="dialog" max-width="700px" persistent>
    <v-form ref="form" v-model="valid">
      <v-card v-if="dialog">
        <v-card-title>
          Datos Instalación
        </v-card-title>

        <v-card-text style="overflow: auto; max-height: 73vh;">
          <v-container>
            <v-row>

              <v-col cols="12" v-if="!item.is_active && !isPortal">
                <v-alert
                  outlined
                  :icon="false"
                  type="warning"
                  border="left">
                  Una instalación desactivada no se podrá utilizar en algunos lugares de la aplicación
                </v-alert>
              </v-col>

              <v-col cols="4" class="pl-5" style="display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;">
                <v-switch
                  v-model="item.is_active"
                  color="success"
                  inset
                  :label="item.is_active ? 'Activa' : 'Desactivada'"
                  :disabled="isPortal"
                  class="pa-0 ma-0"
                ></v-switch>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Razón social"
                  v-model="item.razon_social"
                  :rules="rules"
                  required
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="NIF"
                  v-model="item.nif"
                  maxlength="11"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="N° RGSEAA"
                  v-model="item.rgseaa"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="cod. Instalacion"
                  v-model.number="item.codigo"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Fecha validez"
                      :value="formatDate(item.fecha_validez)"
                      clearable
                      dense
                      readonly
                      v-on="on"
                      @click:clear="item.fecha_validez = ''"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.fecha_validez"
                    locale="es"
                    color="primary"
                    :first-day-of-week="1"
                    no-title
                    scrollable
                    @change="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="11" class="mx-auto mt-0 mb-3">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <SelectTipoInstalacion
                  dense
                  v-model="item.tipo_instalacions"
                  :rules="rules"
                  autoSelectField="codigo"
                  autoSelectValue="propia"
                  required
                />
                <!-- :autoSelectFirst="isNew" -->
              </v-col>

              <v-col cols="12" class="pt-0" v-if="isCerticalidad">
                <v-checkbox class='pa-0 my-2' dense v-model="item.empresa_grupo" color="primary"
                hide-details="auto"
                label="Esta instalación pertenece a la empresa del grupo"/>
              </v-col>

              <component
                v-if="item && customFields"
                ref="customFields"
                :is="customFields"
                :item="item" />

              <v-col cols="6">
                <SelectPais
                  v-model="item.pais.uuid"
                  required
                  dense
                />
              </v-col>

              <v-col cols="6">
                <SelectComunidad
                  v-if="item.comunidad"
                  v-model="item.comunidad.uuid"
                  :pais_uuid="item.pais.uuid"
                  :disabled="!item.pais.uuid"
                  dense
                />
              </v-col>

              <v-col cols="6">
                <SelectProvincia
                  v-if="item.provincia"
                  v-model="item.provincia.uuid"
                  :pais_uuid="item.pais.uuid"
                  :disabled="!item.pais.uuid"
                  dense
                />
              </v-col>

              <v-col cols="6">
                <SelectMunicipio
                  v-if="item.municipio"
                  v-model="item.municipio.uuid"
                  :provincia_uuid="item.provincia.uuid"
                  :disabled="!item.provincia.uuid"
                  dense
                />
              </v-col>

              <v-col cols="8">
                <SelectLocalidad
                  v-model="item.localidad.uuid"
                  dense
                  required
                />
                <div style="display: none;">

                <v-text-field
                  v-model="item.localidad.uuid"
                  required
                  :rules="rules">
                </v-text-field>
                </div>
              </v-col>

              <v-col cols="4">
                <v-text-field
                    label="Código Postal"
                    v-model="item.cp"
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Dirección"
                  v-model="item.direccion"
                  dense
                ></v-text-field>
              </v-col>

              <v-col>
                <select-actividades
                  v-model="item.tramites"
                  :label="isCerticalidad ? 'Actividades Norma' : 'Actividades'"
                  dense
                  multiple
                  returnObject
                ></select-actividades>
              </v-col>

              <v-col v-if="isCerticalidad">
                <v-select
                  v-model="item.actividades_rgseaa"
                  :items="[
                    'Matadero de porcino',
                    'Sala de despiece de carne de ungulados',
                    'Fabricación, elaboración o transformación de productos cárnicos',
                    'Salado y secado de jamones',
                    'Salado y secado de carne y derivados cárnicos',
                    'Envasado de productos cárnicos',
                    'Reenvasador de productos de origen animal',
                    'Almacenamiento frigorífico y en congelación',
                    'Almacenamiento sin refrigeración',
                    'Almacenamiento polivalente',
                    'Distribución de productos cárnicos',
                    'Distribución de carne de ungulados',
                  ]"
                  label="Actividades RGSEAA"
                  hide-details="auto"
                  dense
                  multiple
                ></v-select>
              </v-col>

              <v-col cols="12"></v-col>
              <v-col cols="11" class="mx-auto mt-0 mb-3">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="4">
                <v-checkbox class='pa-0' dense v-model="item.certificada" color="primary" label="Certificada"/>
              </v-col>

              <v-col cols="8">
                <!-- <v-select
                  v-model="item.contacto.uuid"
                  label="Contacto asociadox"
                  hide-details="auto"
                  items="db.contactos"
                  dense
                >

                </v-select> -->
                <SelectContactos
                  label="Contacto asociado"
                  v-model="item.contacto.uuid"
                  :cliente='cliente'
                  :create="true"
                  dense
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="Entidad Certificadora"
                  v-model="item.entidad_certificadora"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6" >
                <v-text-field
                  label="Numero de certificado"
                  v-model="item.numero_certificado"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  label="Observaciones"
                  v-model="item.observaciones"
                  outlined
                  auto-grow
                ></v-textarea>
              </v-col>

              <!-- Campos Dinamicos -->
              <v-col class="row pa-3 mx-3" cols="12" v-if="isQuesoManchego" style="border: 1px solid #ccc; border-radius: 3px;">
                <h3 class="pb-2 col col-12">Campos personalizados</h3>
                <v-btn v-if="!item.instalacion_data || item.instalacion_data.length == 0"
                  @click="addCamposPersonalizados"
                  outlined>
                  Añadir campos
                </v-btn>

                <!-- <CamposDinamicosManchengo v-model="item.instalacion_data" /> -->
                <v-col v-else cols="6"
                  v-for="(c, i) in item.instalacion_data"
                  :key="i">
                  <template v-if="c.keyname == 'situacion'">
                    <v-select
                      v-model="c.value"
                      :items="[
                        'Casco urbano',
                        'Polígono industrial' ,
                        'Terreno rústico'
                      ]"
                      :label="c.keyname"
                      hide-details="auto"
                      dense outlined
                    ></v-select>
                  </template>

                  <v-text-field
                    v-else
                    :label="c.keyname"
                    v-model="c.value"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-col>

              <!-- Campos Dinamicos -->
              <v-col cols="12" v-if="!isQuesoManchego">
                <CamposDinamicos v-model="item.instalacion_data" />
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()">Cancelar</v-btn>
          <v-btn depressed color="primary" :disabled="!valid" @click="submit()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\clientes\instalaciones\GestionInstalacionesDefaultDialog.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon Jul 17 2023
 **/

import CamposDinamicos from '~/components/clientes/instalaciones/araporcei/CamposDinamicos'
import CamposDinamicosManchengo from '~/components/clientes/instalaciones/manchengo/CamposDinamicos'
import SelectComunidad from '~/components/forms/SelectComunidad'
import SelectActividades from '~/components/forms/SelectActividades'
import SelectProvincia from '~/components/forms/SelectProvincia'
import SelectMunicipio from '~/components/forms/SelectMunicipio'
import SelectPais from '~/components/forms/SelectPais'
import SelectLocalidad from '../../forms/SelectLocalidades'
import SelectTipoInstalacion from '../../forms/SelectTipoInstalacion'
import SelectContactos from '../../forms/SelectContactos'

import fieldsCerticalidad from '~/components/instalaciones/customFields/certicalidad'

export default {
  name: 'GestionInstalacionesDefaultDialog',
  props: {
    value: Boolean,
    cliente: { type: Object, default: null },
    instalacion: { type: Object, default: null },
    isPortal: { type: Boolean, default: false },
    location: { type: Object, default: null },
  },

  components: {
    SelectTipoInstalacion,
    SelectLocalidad,
    CamposDinamicos,
    SelectPais,
    SelectMunicipio,
    SelectProvincia,
    SelectComunidad,
    SelectActividades,
    SelectContactos,
    CamposDinamicosManchengo,
    fieldsCerticalidad,
  },
  data: () => ({
    valid: false,
    dialog: false,
    menu: false,
    item: {
      nif: '',
      tramites: [],
      pais: {},
      comunidad: {},
      provincia: {},
      municipio: {},
      localidad: {},
      instalacion_data: [],
      codigo: '',
      contacto: {},
    },
    rules: [(v) => !!v || 'Obligatorio'],

    datos: [],
    fields: [],
  }),

  watch: {
    value(val) {
      if (val) this.open()
      if (!val) this.resetForm()
    },

    instalacion(val) {
      if (val) this.getOne()
      else this.resetForm()
    },
  },

  computed: {
    isCerticalidad() {
      return this.$auth.user.empresa.nombre == 'certicalidad'
    },
    isQuesoManchego() {
      return this.$auth.user.empresa.nombre == 'Queso Manchego'
    },
    isNew() {
      return this.instalacion === null
    },
    isNewCliente() {
      return this.$route.params.uuid === '_' && !this.cliente
    },

    customFields() {
      let cod = this.$auth.user.empresa.codigo
      if (cod !== 'certicalidad') return false

      let empresa = cod.charAt(0).toUpperCase() + cod.slice(1)
      return 'fields' + empresa
    },
  },

  methods: {
    open() {
      this.dialog = true
      this.setInitialValues()
    },

    nifResponsableErrors() {
      const errors = []
      if (!this.$v.item.nif.$dirty) return errors
      !this.$v.item.nif.minLength && errors.push('Documento inválido')
      !this.nifResponsableValid && errors.push('Documento inválido')
      return errors
    },

    checkResponsableDocument() {
      let document = 'OTRO'
      const passport = new RegExp('^[P](?:-)*[0-9]+$')
      const dni = new RegExp('^[A-Z](?:-)*[0-9]{8}$')
      const nie = new RegExp('^[A-Z][0-9]{7}(?:-)*[A-Z]$')
      const nif = new RegExp('^[0-9]{8}(?:-)*[A-Z]$')
      const other1 = new RegExp('^[0-9]{7}$')
      const other2 = new RegExp('^[0-9]{8}$')
      const other3 = new RegExp('^[0-9]{9}$')
      const other4 = new RegExp('^[0-9]{11}$')
      if (passport.test(this.cliente.responsable_nif)) document = 'PASAPORTE'
      if (dni.test(this.cliente.responsable_nif)) document = 'DNI'
      if (nie.test(this.cliente.responsable_nif)) document = 'NIE'
      if (nif.test(this.cliente.responsable_nif)) document = 'NIF'
      return document
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    formatDateBack(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    formatDateToApi(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD') : ''
    },

    addCamposPersonalizados(data) {
      if(this.isQuesoManchego){
        this.item.instalacion_data = [
          { keyname: 'superficie_total', value: data.superficie_total || '' },
          { keyname: 'edificado', value: data.edificado || '' },
          { keyname: 'cinicial', value: data.cinicial || '' },
          { keyname: 'ampliaciones', value: data.ampliaciones || '' },
          { keyname: 'situacion', value: data.situacion || '' },
        ]
      }
      else{
      
        this.item.instalacion_data = [
          { keyname: 'superficie_total', value: data.superficie_total || '' },
          { keyname: 'edificado', value: data.edificado || '' },
          { keyname: 'cinicial', value: data.cinicial || '' },
          { keyname: 'ampliaciones', value: data.ampliaciones || '' },
          { keyname: 'situacion', value: data.situacion || '' },
          { keyname: 'curbano', value: data.curbano || '' },
          { keyname: 'pindustrial', value: data.pindustrial || '' },
          { keyname: 'trustico', value: data.trustico || '' },
        ]
      }
      
    },

    resetForm() {
      this.item = {
        nif: '',
        tipo_instalacions: [],
        tramites: [],
        pais: {},
        comunidad: {},
        provincia: {},
        municipio: {},
        localidad: {},
        instalacion_data: [],
        contacto: {},
      }
      if (this.$refs.form) this.$refs.form.resetValidation()
    },

    //+-------------------------------------------------
    // updateLocation()
    // -----
    // Created on Fri Sep 24 2021
    // Updated on Mon Oct 25 2021
    //+-------------------------------------------------
    updateLocation(localidadUUID, object) {
      console.info('object', object)
      if (object.pais?.uuid) this.item.pais.uuid = object.pais?.uuid
      if (object.comunidad?.uuid) this.item.comunidad.uuid = object.comunidad?.uuid
      if (object.provincia?.uuid) this.item.provincia.uuid = object.provincia?.uuid
      if (object.municipio?.uuid) this.item.municipio.uuid = object.municipio?.uuid
      // if (object.codigo) this.item.cp = object.codigo

      this.$forceUpdate()
    },

    close() {
      this.dialog = false

      this.resetForm()
      this.$emit('close')
    },
    async getOne() {
      const item = Object.assign({}, this.instalacion)
      if (!item.pais) item.pais = { uuid: '' }
      if (!item.contacto) item.contacto = { uuid: '' }
      if (!item.comunidad) item.comunidad = { uuid: '' }
      if (!item.provincia) item.provincia = { uuid: '' }
      if (!item.municipio) item.municipio = { uuid: '' }
      if (!item.localidad) item.localidad = { uuid: '' }

      // if (typeof item.instalacion_data == 'object' && item.instalacion_data.length == undefined)
      //   item.instalacion_data = [item.instalacion_data]

      this.item = item

      if (this.isQuesoManchego && Object.keys(item.instalacion_data).length)
        this.addCamposPersonalizados(item.instalacion_data)

      // console.info('this.item', this.item);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        // if (this.isQuesoManchego) {
        //   this.item.instalacion_data_original = this.item.instalacion_data
        //   this.item.instalacion_data.forEach((item) => {
        //     let data = [
        //       {
        //         keyname: 'superficie_total',
        //         value: item.superficie_total,
        //       },
        //       {
        //         keyname: 'edificado',
        //         value: item.edificado,
        //       },
        //       {
        //         keyname: 'cinicial',
        //         value: item.cinicial,
        //       },
        //       {
        //         keyname: 'ampliaciones',
        //         value: item.ampliaciones,
        //       },
        //       {
        //         keyname: 'situacion',
        //         value: item.situacion,
        //       },
        //       {
        //         keyname: 'curbano',
        //         value: item.curbano,
        //       },
        //       {
        //         keyname: 'pindustrial',
        //         value: item.pindustrial,
        //       },
        //       {
        //         keyname: 'trustico',
        //         value: item.trustico,
        //       },
        //     ]
        //     this.datos = this.datos.concat(data)
        //   })
        //   this.item.instalacion_data = this.datos
        // }

        const body = {
          cliente_uuid: this.cliente == null ? this.$route.params.uuid : this.cliente.uuid,
          instalacion: {
            ...this.item,
          },
        }

        body.instalacion.tramites = this.item.tramites.map((element) => element.uuid)
        body.instalacion.fecha_validez = this.formatDateToApi(this.item.fecha_validez)

        if (this.item.pais) body.instalacion.pais = this.item.pais.uuid
        if (this.item.comunidad) body.instalacion.comunidad = this.item.comunidad.uuid
        if (this.item.provincia) body.instalacion.provincia = this.item.provincia.uuid
        if (this.item.municipio) body.instalacion.municipio = this.item.municipio.uuid
        if (this.item.localidad) body.instalacion.localidad = this.item.localidad.uuid
        if (this.item.contacto) body.instalacion.contacto = this.item.contacto.uuid
        if (!this.item.fecha_validez) delete body.instalacion.fecha_validez
        // console.info('body', body);

        if (body.instalacion.tipo_instalacions?.length > 0) {
          if (body.instalacion.tipo_instalacions[0].uuid) {
            let reduction = body.instalacion.tipo_instalacions.map(function (a) {
              return a.uuid
            })

            body.instalacion.tipo_instalacions = reduction
          }
        }

        if (this.isNew) {
          this.isNewCliente
            ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
            : await this.$store.dispatch('clientes_instalaciones/create', body)

          if (!this.isNewCliente) {
            this.$emit('created', this.item)
          }
        } else {
          this.isNewCliente
            ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
            : await this.$store.dispatch('clientes_instalaciones/update', body)

          if (!this.isNewCliente) {
            this.$emit('updated', this.item)
          }
        }
        if (!this.isNewCliente) {
          this.$emit('init')
        }
        this.close()
      }
    },

    setInitialValues() {
      if (this.isNew) {
        this.item.is_active = true

        if (this.location?.cp) this.item.cp = this.location.cp
        if (this.location?.pais) this.item.pais.uuid = this.location.pais
        if (this.location?.comunidad) this.item.comunidad.uuid = this.location.comunidad
        if (this.location?.provincia) this.item.provincia.uuid = this.location.provincia
        if (this.location?.municipio) this.item.municipio.uuid = this.location.municipio
        if (this.location?.localidad) this.item.localidad.uuid = this.location.localidad

        if (this.isQuesoManchego) {
          this.item.nif = this.cliente.nif
          this.item.razon_social = this.cliente.nombre
          if (this.isPortal) this.item.is_active = false
        }
      }
    },

    async init() {
      this.$nuxt.$on('localidades:selected', (payload) => {
        this.updateLocation(payload.uuid, payload.localidad)
      })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('localidades:selected')
  },
}
</script>
