<template>
  <v-app>
    <notification/>
    <v-main>
      <Menu v-if="!isPdf" />
      <v-container class="main-container" :class="padding">
        <v-fade-transition>
          <Nuxt/>
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from '~/components/Menu'
import Notification from '~/components/Notification'
// import role from '~/models/role'
import store from '~/store'

export default {
  components: {
    Notification,
    Menu,
  },
  computed: {
    isPdf() {
      return this.$route.name === 'formatos'
    },
    isPlanificador() {
      return this.$route.name === 'planificador'
    },
    padding() {
      return this.isPlanificador ? 'pa-0' : this.isPdf ? 'white pa-0' : 'pa-8'
    },
  },
}
</script>

<style>
.main-container {
  max-width: 100% !important;
}
.ag-theme-material .ag-icon-checkbox-checked {
  color: #2e3444 !important;
}

/* .v-navigation-drawer__content {
  overflow-y: hidden !important;
} */

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #00000069;
  border-radius: 15px;
  border: 1px solid #a5a5a5;
}
</style>
