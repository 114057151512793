/**
 * @project: certiapp-nuxt
 * @file:    eventos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th March 2021
 * Last Modified: Wed Oct 20 2021
 **/

import {uuid} from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  async list({rootState, commit}, params) {
 
    rootState.loading = true
    const body = {
      ...params,
    }

    const res = await this.$axios.put('eventos', body)
    let eventos = []
    if (res) {
      const data = res.data
      data.forEach((event) => {
        eventos.push(event)
      })
      commit('SET_LIST', eventos)
    }
    rootState.loading = false
    return eventos
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`eventos/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, evento) {
    rootState.loading = true

    const body = {
      ...evento,
    }

    body.uuid = body.uuid === undefined ? uuid.v4() : body.uuid
    body.descripcion = body.descripcion == null ? '' : body.descripcion

    delete body.user

    await this.$axios.post('eventos', body)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  //
  //+-------------------------------------------------
  async update({rootState}, evento) {
    rootState.loading = true
    const body = {
      ...evento,
    }

    body.user = body.user?.uuid
    body.alcance = body.alcance?.uuid
    body.cliente = body.cliente?.uuid
    body.desde = this.$moment(body.desde).format('YYYY-MM-DD')
    body.hasta = this.$moment(body.hasta).format('YYYY-MM-DD')

    delete body.owner
    delete body.users
    delete body.empresa
    delete body.auditoria
    delete body.expediente

    await this.$axios.patch(`eventos/${evento.uuid}`, body)
    rootState.loading = false
  },

  async delete({rootState}, evento) {
    rootState.loading = true
    await this.$axios.delete(`eventos/${evento.uuid}`)
    rootState.loading = false
  },

  async config({rootState}, data) {
    rootState.loading = true
    const body = {
      config: {
        filters: {
          ...data,
        },
      },
    }
    await this.$axios.post(`eventos/config`, body)
    rootState.loading = false
  },
}
