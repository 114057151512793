<template>
  <div>
    <div v-if="item.tipo == 'html'" :key="'wysiwyg-'+ui.key">
      <template v-if="$nuxt.isOffline">
        Los bloques de tipo HTML no se pueden mostrar en offline
      </template>
      <RichTextEditor v-else
        :inital-value="item.content_html"
        v-model="item.content_html"></RichTextEditor>
    </div>

    <div v-if="item.tipo == 'table'" :key="'table-'+ui.key">
        <table-block-editor
          ref="tableEditor"
          :_table="item.content_table"
        />
    </div>

    <div v-if="item.tipo == 'simpletable'" :key="'table-'+ui.key">
        <simpletable-block-editor
          ref="simpletableEditor"
          :_table="item.content_table"
        />
    </div>

    <!-- Confirmación de eliminar -->
    <Confirmation v-model="ui.confirm"
      text="¿Estás seguro de que quieres reiniciar este bloque?"
      textButton="Eliminar"
      @confirm="reset"/>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    blockEmbed.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 5th July 2021
 * Last Modified: Tue Apr 04 2023
 **/

import { uuid } from 'vue-uuid'
import _arr from '~/util/arrayHelper'
import format from '~/util/formatHelper'
import * as UTIL from '~/util'

import Confirmation from '~/components/Confirmation'
import RichTextEditor from '~/components/RichTextEditor'
import tableBlockEditor from '../edit/tableBlockEditor'
import simpletableBlockEditor from '../edit/simpletableBlockEditor'

export default {
  name: 'bloques-embed',
  props: ['block', 'submodulo_ref', 'disabled'],

  components: {
    Confirmation,
    RichTextEditor,
    tableBlockEditor,
    simpletableBlockEditor,
  },

  data() {
    return {
      item: {
        alcance: null,
        tipo: 'html',
        nombre: '',
        tipo: '',
        orden: 1,
        isNew: false,
      },

      ui: {
        key: 0,
        loading: false,
        confirm: false,
        confirmRemove: false,
      },
    }
  },

  methods: {
    //+-------------------------------------------------
    // doUpdate()
    // Calls for creation or update based on this uuid
    // -----
    // Updated on Fri Oct 21 2022
    //+-------------------------------------------------
    async doUpdate(block) {
      let method = this.item.plantilla ? 'update' : 'create'
      // let method = this.item.uuid ? 'update' : 'create'
      await this.store(method)
      this.ui.key++
    },

    async doClone() {
      await this.store('create')
      $nuxt.$emit('bloques:refresh', {})
    },

    async doReset() {
      this.ui.confirm = true
      this.ui.confirmRemove = this.item
    },

    async doExport(){
      //export to excel
      let data = this.item.content_table.rows
      //data is a array of arrays and need convert data to array object
      /* data = data.map((row) => {
        let obj = {}
        for (let i in row) {
          obj[this.item.content_table.headers[i].label ?? 'dato'] = row[i]
        }
        return obj
      }) */
    
      let headers = this.item.content_table.headers
      let formatos = this.item.content_table.formatos
      let filename = this.item.titulo
      let ws_name = 'Bloque-'+this.item.titulo
      UTIL.exportFile(this, filename, ws_name, headers, data,true)
    },

    //+-------------------------------------------------
    // toFormatos()
    // Creates a new object with key data value
    // -----
    // Created on Fri Aug 26 2022
    //+-------------------------------------------------
    toFormatos(data) {
      let formatos = []

      for (let i in data.rows) {
        let row = {}
        let empty = true

        for (const o in data.rows[i]) {
          let el = data.rows[i][o]
          let head = data.headers[o]?.label
          if (el) empty = false
          row[format.stringToslug(head)] = el
        }

        if (!empty) {
          formatos.push(row)
        }
      }

      return formatos
    },

    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async store(method = 'update') {
      this.ui.loading = true

      const payload = { ...this.item }

      if (method == 'create') {
        payload.plantilla = payload.plantilla?.uuid ? payload.plantilla?.uuid : payload.uuid
        payload.uuid = uuid.v4()
        payload.modulo_ref = this.$route.params.uuid
      }

      if (payload.tipo == 'table') {
        payload.content_table = { ...this.$refs.tableEditor.table }
        payload.content_table.formatos = this.toFormatos(payload.content_table)
      }

      try {
        let xhr = await this.$store.dispatch('bloques/' + method, payload)

        // if (xhr && xhr.uuid) {
        //   // this.item = xhr

        this.item.uuid = payload.uuid
        this.item.isNew = false
        this.item.plantilla = { uuid: payload.plantilla }
        // }
      } catch (e) {
        console.info(e)
        this.$store.commit('notification/show', {
          text: 'Ha ocurrido un error guardando los cambios',
          color: 'error',
          timeout: 3000,
        })
      } finally {
        this.ui.loading = false
      }
    },

    async reset() {
      this.ui.loading = true
      this.ui.confirm = false

      const xhr = await this.$store.dispatch('bloques/delete', this.ui.confirmRemove)
      $nuxt.$emit('bloques:refresh', {})

      // await this.getTemplate()
      // this.ui.refresh = Date.now()
      // this.ui.loading = false
    },

    async getTemplate() {
      const xhr = await this.$store.dispatch('plantillas-bloques/get', this.item.plantilla.uuid)
      this.item = { ...xhr }
      this.item.isNew = true
      this.item.modulo_ref = this.ui.confirmRemove.modulo_ref
      this.ui.key = Date.now()
    },

    //+-------------------------------------------------
    // Async API requests
    //+-------------------------------------------------

    async init() {
      this.item = { ...this.block }
      if (!this.item.tipo && this.item.plantilla?.tipo) this.item.tipo = this.item.plantilla.tipo
      if (this.submodulo_ref) this.item.submodulo_ref = this.submodulo_ref
    },
  },

  mounted() {
    this.init()
  },
}
</script>
