import ObjectTools from '../plugins/ObjectTools'
import moment from 'moment'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }, uuid) {
    rootState.loading = true
    const body = {}
    if (uuid) body.expediente = uuid
    const res = await this.$axios.put('dictamenes', body)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },
  async customList({ rootState, commit }, payload) {
    rootState.loading = true
    const body = {}
    //serverside pagination
    if (payload && payload.limit) body.limit = payload.limit
    if (payload && payload.page) body.page = payload.page
    if (payload && payload.sortBy && payload.sortDesc)
      body.sort = { field: payload.sortBy[0], order: payload.sortDesc[0] ? 'desc' : 'asc' }
    const res = await this.$axios.put('dictamenes', body)
    rootState.loading = false
    if (res) {
      const data = res.data
      commit('SET_LIST', data)
      return data
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`dictamenes/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, dictamen) {
    rootState.loading = true
    await this.$axios.post('dictamenes', dictamen)
    rootState.loading = false
  },

  async update({ rootState, dispatch }, dictamen) {
    console.log('update -> dictamen', dictamen)
    let body = {
      estado: dictamen.estado,
      proposicion: dictamen.proposicion,
      certificado_emitido: dictamen.certificado_emitido,
      fecha_proposicion: moment(dictamen.fecha_proposicion).format('YYYY-MM-DD'),
      decision: dictamen.decision,
      fecha_decision: moment(dictamen.fecha_decision).format('YYYY-MM-DD'),
      accion: dictamen.accion,
      motivo: dictamen.motivo,
      dictamen: dictamen.uuid,
      auditor_1: dictamen.auditor1 ? dictamen.auditor1.uuid : null,
      auditor_2: dictamen.auditor2 ? dictamen.auditor2.uuid : null,
      en_calidad_de_auditor_1: dictamen.auditor1.en_calidad_de,
      en_calidad_de_auditor_2: dictamen.auditor2.en_calidad_de,
      revision: dictamen.revision.uuid,
      medida_aplicacion_catin_eco: dictamen.medida_aplicacion_catin_eco,
    }

    if (dictamen.desde) body.suspendido_desde = moment(dictamen.desde).format('YYYY-MM-DD')
    if (dictamen.hasta) body.suspendido_hasta = moment(dictamen.hasta).format('YYYY-MM-DD')
    if (dictamen.no_conformidades)
      body.no_conformidades = dictamen.no_conformidades.map((element) => element.uuid)

    console.info('body', body)
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}`, body)
    rootState.loading = false
  },

  async delete({ rootState }, dictamen) {
    rootState.loading = true
    await this.$axios.delete(`dictamenes/${dictamen.uuid}`)
    rootState.loading = false
  },

  async noemitir({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/no-emitir`, body)
    rootState.loading = false
  },

  async suspender({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(`dictamenes/${dictamen.uuid}/suspender`, {
      dictamen: dictamen.uuid,
      desde: moment(dictamen.desde).format('YYYY-MM-DD'),
      hasta: moment(dictamen.hasta).format('YYYY-MM-DD'),
      motivo: dictamen.comentario,
    })

    rootState.loading = false
  },

  async no_emitir({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(`dictamenes/${dictamen.uuid}/no-emitir`, {
      dictamen: dictamen.uuid,
      motivo: dictamen.comentario,
    })

    rootState.loading = false
  },

  async retirar({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(`dictamenes/${dictamen.uuid}/retirar`, {
      dictamen: dictamen.uuid,
      desde: moment(dictamen.desde).format('YYYY-MM-DD'),
      hasta: moment(dictamen.hasta).format('YYYY-MM-DD'),
      motivo: dictamen.comentario,
    })

    rootState.loading = false
  },

  async baja_temporal({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(`dictamenes/${dictamen.uuid}/baja-temporal`, {
      dictamen: dictamen.uuid,
      desde: moment(dictamen.desde).format('YYYY-MM-DD'),
      hasta: moment(dictamen.hasta).format('YYYY-MM-DD'),
      motivo: dictamen.comentario,
    })

    rootState.loading = false
  },

  async baja_voluntaria({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(`dictamenes/${dictamen.uuid}/baja-voluntaria`, {
      dictamen: dictamen.uuid,
      desde: moment(dictamen.desde).format('YYYY-MM-DD'),
      hasta: moment(dictamen.hasta).format('YYYY-MM-DD'),
      motivo: dictamen.comentario,
    })

    rootState.loading = false
  },

  async levantar({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/levantar`, body)
    rootState.loading = false
  },

  async ampliacion({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/ampliacion`, body)
    rootState.loading = false
  },

  async reduccion({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/reduccion`, body)
    rootState.loading = false
  },

  async mantener({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/mantener`, body)
    rootState.loading = false
  },

  async apercibimento({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/apercibir`, body)
    rootState.loading = false
  },

  async emitir({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/emitir`, body)
    rootState.loading = false
  },

  async renovar({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/renovar`, body)
    rootState.loading = false
  },
}
