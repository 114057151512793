/**
 * @project: certiapp-nuxt
 * @file:    \store\user.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 4th July 2022
 * Last Modified: Thu Feb 02 2023
 **/

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // can()
  // Returns true or false for the current user to access a module
  // based on roles
  // -----
  // Created on Mon Jul 04 2022
  //+-------------------------------------------------
  async can({ rootState }, params) {
    let can = false
    let auth = $nuxt.$auth.user.permisos
    //console.log('🗝️', 'auth', auth)
    let modul = auth.find((item) => {
      if (params.submodule)
        return item.modulo == params.module && item.submodulo == params.submodule
      else return item.modulo == params.module
    })
    //console.log('🗝️', 'modul', modul)
    // let modules = auth.filter((item) => {
    //   if (params.submodule)
    //     return item.modulo == params.module && item.submodulo == params.submodule
    //   else return item.modulo == params.module
    // })

    if (!modul) return { can: false, details: params }
    if (params.return == 'all' || !params.action) return modul.can_config

    if (modul.can_config[params.action] && modul.can_config[params.action] == true) {
      can = true
    }

    if (can == false) {
      console.warn(
        '🗝️',
        'No se permite el acceso al usuario para el modulo:',
        params.module + ' - ' + params.action,
        modul.can_config,
        $nuxt.$auth.user
      )
    }
    return can
  },
}
