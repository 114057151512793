<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-form ref="form" lazy-validation v-model="valid">
        <v-card>
          <v-card-title>
            {{ isNew ? 'Nuevo' : 'Editar' }} Cultivo
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <SelectLocalidad
                    ref="localidad"
                    outlined
                    v-model="itemObjects.localidad"
                    :codigo="item.localidad_codigo"
                    :returnObject="true"
                    @change="updateLocalidad(itemObjects.localidad)"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Paraje"
                    v-model="item.paraje"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="POLG"
                    v-model="item.polg"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Parcel"
                    v-model="item.parcel"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="RC"
                    v-model="item.rc"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Sup. Parcela"
                    v-model.number="item.superficie_parcela"
                    type="number"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Sup. Plantada"
                    v-model.number="item.superficie_plantada"
                    type="number"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-menu
                    close-on-content-click
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="formatDate(item.fecha_alta)"
                        label="Fecha Alta"
                        prepend-icon="event"
                        clearable
                        readonly
                        dense
                        v-on="on"
                        @click:clear="item.fecha_alta = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.fecha_alta"
                      color="primary"
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="CALF"
                    v-model="item.calf"
                    :items="TIPOS_CALF"
                    :rules="rules"
                    required
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Propiedad"
                    v-model="item.propiedad"
                    :items="TIPOS_PROPIEDAD"
                    :rules="rules"
                    required
                    dense
                  ></v-select>
                </v-col>


                <v-col cols="6">
                  <v-text-field
                      label="Agregado"
                      v-model.number="item.agregado"
                      :rules="rulesWithZero"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <select-metadata
                      label="Cultivo"
                      dense
                      v-model="itemObjects.cultivo"
                      :codigo="item.cultivo_codigo"
                      tipo="cultivos"
                      familia='produccion_vegetal'
                      @change="updateItemObject('cultivo')"
                  ></select-metadata>
<!--                  <SelectEstaticos-->
<!--                    v-if="alcance"-->
<!--                    v-model="item.cultivo"-->
<!--                    :alcance="alcance.uuid"-->
<!--                    codigo="cultivos"-->
<!--                    required-->
<!--                    dense-->
<!--                  />-->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" :disabled="!valid || loading" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    ProduccionVegetalDialog.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Fri Nov 04 2022
 **/

import format from '~/util/formatHelper'

import MODELS from '~/models/ccl/solicitudes'
import SelectEstaticos from '~/components/forms/SelectEstaticos'
import SelectMetadata from '../../../../forms/selectMetadata'
import SelectLocalidad from '../../../../../components/forms/SelectLocalidades'

export default {
  name: 'ProduccionVegetalDialog',
  props: {
    value: Boolean,
    itemSelected: { type: Object, default: null },
    alcance: { type: Object, default: () => {} },
  },
  components: {
    SelectMetadata,
    // SelectProvincia,
    SelectEstaticos,
    SelectLocalidad,
  },
  data: () => ({
    itemObjects: {},
    valid: false,
    dialog: false,
    item: {},
    rules: [(v) => !!v || 'El campo es obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'El campo es obligatorio'],
    rulesWithZero: [
      (v) => {
        if (v == undefined || v.length == 0) {
          return 'El campo es obligatorio.'
        }
        if (v < 0) {
          return 'Valor incorrecto'
        }
        return true
      },
    ],
    TIPOS_CALF: MODELS.CALF_TIPOS,
    TIPOS_PROPIEDAD: MODELS.PROPIEDAD_TIPOS,
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    dialog(val) {
      if (!val) this.close()
    },
    itemSelected(val) {
      if (val) this.setItem()
    },
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      },
    },
    isNew() {
      return this.itemSelected === null
    },
    // isNewCliente() {
    //   return this.$route.params.uuid === '_'
    // },
  },
  methods: {
    updateItemObject(object) {
      this.item[object + '_codigo'] = this.itemObjects[object].codigo
      this.item[object + '_nombre'] = this.itemObjects[object].nombre
    },
    updateLocalidad(localidad) {
      this.item['localidad_codigo'] = localidad.codigo
      this.item['provincia_codigo'] = localidad.provincia.codigo
      this.item['provincia_nombre'] = localidad.provincia.nombre
      this.item['municipio_codigo'] = localidad.municipio.codigo
      this.item['municipio_nombre'] = localidad.municipio.nombre
    },
    formatDate(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD') : ''
    },
    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },

    // async getOne() {
    //   const itemSelected = this.itemSelected
    //   if (itemSelected.fecha_alta)
    //     itemSelected.fecha_alta = this.$moment(itemSelected.fecha_alta).format('YYYY-MM-DD')
    //   this.item = Object.assign({}, itemSelected)
    // },

    setItem() {
      if (this.itemSelected && this.itemSelected.data) this.item = { ...this.itemSelected.data }
      this.item.fecha_alta = format.date(this.item.fecha_alta, 'YYYY-MM-DD')
      console.log('setItem', this.item)
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.item.alcance = this.alcance.uuid
        const body = {
          ...this.item,
        }

        const action = this.isNew ? 'add' : 'edit'
        this.$emit(action, this.item)
        this.$emit('submit', this.item)
        this.close()
      }
    },
    // async submit() {
    //   if (this.$refs.form.validate()) {
    //     const body = {
    //       cliente_uuid: this.cliente ? this.cliente : this.$route.params.uuid,
    //       instalacion: {
    //         ...this.item
    //       }
    //     }
    //     body.instalacion.tramites = this.item.tramites.map(element => element.uuid)
    //     if (this.isNew) {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/create', body)
    //     } else {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/update', body)
    //     }
    //     if (!this.isNewCliente) this.$emit('init')
    //     this.close()
    //   }
    // },
    resetForm() {
      this.item = {}
      this.itemObjects = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
  },
}
</script>
