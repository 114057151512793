<template>
  <div>
    <v-row>
      <v-col cols="12">
        <ag-datatable
            :key="grid.key"
            :title="grid.title"
            :headers="grid.columnDefs"
            :items="grid.rowData"
            :height="'65'"
            rowSelection="multiple"
            hideOverlay
            disableClickRow
            @gridApi="onGridReady($event)"
        >
          <!-- Añadir Fila -->
          <template slot="title">
            <v-tooltip right v-if="editable">
              <template v-slot:activator="{ on }">
                <v-btn x-small fab depressed color="success" class="white--text mx-2" v-on="on" @click="dialog = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Añadir</span>
            </v-tooltip>

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn x-small fab depressed color="error" class="white--text mx-2" v-on="on" @click="removeRow()">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Eliminar filas seleccionadas</span>
            </v-tooltip> -->
          </template>
        </ag-datatable>
      </v-col>
    </v-row>

    <!-- Dialog Alcance del Producto -->
    <component
      :is="dialogComponent"
      v-model="dialog"
      :db="db"
      :cliente="cliente"
      :isOperadorResposable="isOperadorResposable"
      :isProductosIbericos="isProductosIbericos"
      :itemSelected="itemSelected"
      @close="dialog = false; itemSelected = null"
      @submit="addOrUpdateRow($event)"
    />

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    AlcanceProductoList.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue Jun 13 2023
 **/

import AgDatatable from '~/components/AgDatatableOld'
import AlcanceProductoDialog from './AlcanceProductoDialog'
import AlcanceProductoDialogSAE from './AlcanceProductoDialogSAE'
import AlcanceProductoDialogETG from './AlcanceProductoDialogETG'
import MODELS from '~/models/certicalidad/solicitudes'

export default {
  name: 'AlcanceProductoList',
  props: {
    cliente: { type: Object, default: null },
    isOperadorResposable: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    // isETG: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    solicitud: { type: Object, default: null },
    db: { type: Object, default: {} },
  },
  components: {
    AgDatatable,
    AlcanceProductoDialog,
    AlcanceProductoDialogSAE,
    AlcanceProductoDialogETG,
  },
  data: () => ({
    itemSelected: null,

    dialog: false,
    tipoAlimentacion: MODELS.ALIMENTACION_TIPO,
    tipoProducto: MODELS.PRODUCTO_TIPO,
    tipoProductoETG: MODELS.PRODUCTO_TIPO_ETG,
    tipoRacial: MODELS.RACIAL_TIPO,
    tipoEstado: MODELS.ESTADOS_TIPO,
    tipoPresentacion: MODELS.PRESENTACION_TIPO,
    tipoPresentacionETG: MODELS.PRESENTACION_TIPO_ETG,
    grid: {
      key: 1,
      title: 'Alcance del Producto',
      api: null,
      rowData: [],
      columnDefs: [],
    },
  }),
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      },
    },

    isProductosIbericos() {
      return this.solicitud.solicitudes[0].alcance.codigo == 'certicalidad_productos_ibericos'
    },

    isSAE() {
      return this.solicitud.solicitudes[0].alcance.codigo == 'certicalidad_sae'
    },

    isETG() {
      return this.solicitud.solicitudes[0].alcance.codigo == 'certicalidad-etg'
    },

    dialogComponent() {
      if (this.isSAE) return 'AlcanceProductoDialogSAE'
      if (this.isETG) return 'AlcanceProductoDialogETG'

      return 'AlcanceProductoDialog'
    },
  },
  methods: {
    arrayCellRenderer(params) {
      let arrayData = []

      if (params.data[params.field] == undefined) return ''

      arrayData = Array.isArray(params.data[params.field])
        ? params.data[params.field]
        : params.data[params.field].split(',')

      if (arrayData.length === 0) {
        return '--'
        return params.data[params.field]
      }

      let output = '<ul>'
      arrayData.forEach((elem) => (output = output + '<li>' + elem + '</li>'))
      output = output + '</ul>'
      return output
    },

    onChange() {
      this.$emit('change', this.grid.rowData)
    },
    // Grid
    onGridReady(api, tipo) {
      this.grid.api = api
    },

    //+-------------------------------------------------
    // addOrUpdateRow()
    // Adds a row to products, or updates if this item has uuid
    // -----
    // Created on Mon Dec 27 2021
    //+-------------------------------------------------
    addOrUpdateRow(item) {
      if (this.itemSelected === null) {
        this.addRow(item)
      } else {
        this.updateRow(item)
      }

      this.grid.key++
    },

    addRow(item) {
      this.loading = true

      this.grid.rowData.push(item)
      this.grid.api.resetRowHeights()
      this.loading = false
      this.onChange()
    },

    updateRow(item, tipo) {
      let index = this.grid.rowData.indexOf(this.itemSelected)
      this.grid.rowData[index] = item

      this.grid.key++
      this.dialog = false
      this.itemSelected = null
      this.onChange()
    },

    removeRow(type) {
      const selectedRows = this.grid.api.getSelectedRows()
      if (!selectedRows || !selectedRows.length) {
        return
      }

      selectedRows.forEach((row) => {
        const index = this.grid.rowData.indexOf(row)
        this.grid.rowData.splice(index, 1)
      })

      this.onChange()
    },
    init() {
      const vm = this
      const columnDefs = []

      if (this.isProductosIbericos) {
        columnDefs.push(
          {
            headerName: 'Producto',
            field: 'producto',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'producto' },
          },
          {
            headerName: 'Alimentación',
            field: 'alimentacion',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'alimentacion' },
          },
          {
            headerName: 'Racial',
            field: 'racial',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'racial' },
          },
          {
            headerName: 'Estado',
            field: 'estado',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'estado' },
          }
        )
      }

      // Columns
      columnDefs.push(
        {
          headerName: 'Codigo RGSEAA',
          field: 'instalacion_codigo',
          sortable: true,
          filter: true,
          resizable: true,
          enableRowGroup: true,
        },
        {
          headerName: 'Nombre Instalacion',
          field: 'instalacion_nombre',
          sortable: true,
          filter: true,
          resizable: true,
          enableRowGroup: true,
        }
      )

      // Productos Ibericos
      if (this.isProductosIbericos) {
        columnDefs.push(
          {
            headerName: 'Presentación',
            field: 'presentacion',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'presentacion' },
          },
          {
            headerName: 'Actividad Realizada',
            field: 'actividades_realizadas',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'actividades_realizadas' },
          },
          {
            headerName: 'Actividad Subcontratada',
            field: 'actividades_subcontratadas',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'actividades_subcontratadas' },
          },
          {
            headerName: 'Certificación',
            field: 'certificacion',
            sortable: false,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          },
          {
            headerName: 'Marcas',
            field: 'marcas',
            sortable: false,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          }
        )
      }

      if (this.isSAE) {
        columnDefs.push(
          {
            headerName: 'Categoría de productos',
            field: 'categoria_productos',
            sortable: false,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          },
          {
            headerName: 'Líneas de producción',
            field: 'lineas_produccion',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'lineas_produccion' },
          },
          {
            headerName: 'Nivel SAE',
            field: 'nivel_sae',
            sortable: false,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          },
          {
            headerName: 'Certificados emitidos',
            field: 'certificados_emitidos',
            sortable: false,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          },
          {
            headerName: 'Partidas suministradas',
            field: 'partidas_suministradas',
            sortable: false,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          }
        )
      }

      if (this.isETG) {
        columnDefs.push(
          {
            headerName: 'Producto',
            field: 'producto',
            sortable: false,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          },
          {
            headerName: 'Presentación',
            field: 'presentacion',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'presentacion' },
          },
          {
            headerName: 'Actividad Realizada',
            field: 'actividades_realizadas',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'actividades_realizadas' },
          },
          {
            headerName: 'Actividad Subcontratada',
            field: 'actividades_subcontratadas',
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            autoHeight: true,
            cellRenderer: this.arrayCellRenderer,
            cellRendererParams: { field: 'actividades_subcontratadas' },
          },
          {
            headerName: 'Certificación',
            field: 'certificacion',
            sortable: false,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          },
          {
            headerName: 'Marcas',
            field: 'marcas',
            sortable: false,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          }
        )
      }

      // Editable
      // if (this.editable)
      // this.grid.columnDefs.push({
      //   headerName: 'Acciones',
      //   sortable: false,
      //   resizable: true,
      //   cellRendererFramework: 'DeleteRenderer',
      //   cellRendererParams: {
      //     text: '¿Eliminar el alcance del producto seleccionado?',
      //     init: this.onChange.bind(this),
      //     confirm: true,
      //   },
      // })

      // columnDefs.unshift({
      //   headerName: '#',
      //   sortable: true,
      //   filter: true,
      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
      //   valueGetter: (params) => params.node.rowIndex + 1,
      // })

      if (this.editable) {
        columnDefs.unshift({
          headerName: 'DELETE',
          width: 50,

          cellRendererFramework: 'DeleteRenderer',
          cellRendererParams: {
            text: '¿Eliminar este registro?',
            init: this.onChange.bind(this),
            confirm: true,
          },
        })

        columnDefs.unshift({
          headerName: 'EDIT',
          width: 50,

          cellRendererFramework: 'OpenModalRenderer',
          cellRendererParams: {
            init: this.onChange.bind(this),
            onClick: (params) => {
              this.itemSelected = params.data
              this.dialog = true
            },
          },
        })
      }

      this.grid.columnDefs = columnDefs
      this.grid.rowData = this.items
    },
  },
  created() {
    this.init()
  },
}
</script>
