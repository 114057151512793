export const state = () => ({
});

//commit mutations
export const mutations = {
};


//dispatch actions
export const actions = {

  async list({ rootState }, params) {
    rootState.loading = true
    const body = Object.assign({}, params || {})
    delete body.offlineMode
    const random = Math.random()
    const res = await this.$axios.put('estaticos?'+body.codigos, body)
    rootState.loading = false
    if (res) return res.data
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`estaticos/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, item) {
    rootState.loading = true
    const res = await this.$axios.post(`estaticos`, item)
    rootState.loading = false
    if (res) return res.data
  },

  async update({rootState}, item) {
    rootState.loading = true
    const res = await this.$axios.patch(`estaticos/${item.estatico}`, item)
    rootState.loading = false
  },

  async delete({rootState}, item) {
    rootState.loading = true
    await this.$axios.delete(`estaticos/${item.uuid}`)
    rootState.loading = false
  },

};
