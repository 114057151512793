import { render, staticRenderFns } from "./SelectGruposAlcancesObject.vue?vue&type=template&id=3943e4c5&scoped=true&"
import script from "./SelectGruposAlcancesObject.vue?vue&type=script&lang=js&"
export * from "./SelectGruposAlcancesObject.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3943e4c5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
