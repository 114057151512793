<template>
  <div>
    <v-row>
      <v-col cols="6" class="text-right" v-if="editable">
        <!-- Importar Excel -->
        <input type="file" ref="inputFile" style="display: none;" @change="importFile($event)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="warning" class="white--text mr-2" v-on="on" @click="$refs.inputFile.click()">
              <v-icon>mdi-file-import</v-icon>
            </v-btn>
          </template>
          <span>Importar excel</span>
        </v-tooltip>

        <!-- Añadir Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="success" class="white--text mr-2" v-on="on" @click="dialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Añadir fila</span>
        </v-tooltip>

        <!-- Eliminar Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="error" class="white--text" v-on="on" @click="removeRow()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar fila</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <ag-datatable
            :title="grid.title"
            :headers="grid.columnDefs"
            :items="grid.rowData"
            :height="'65'"
            hide-overlay
            emitClickRow
            :disableClickRow="!editable"
            @click-row="itemSelected = $event, dialog = true"
            @gridApi="onGridReady($event)"
          ></ag-datatable>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog -->
    <ProductosEcologicosDialog
      v-model="dialog"
      :itemSelected="itemSelected"
      @close="dialog = false"
      @add="addRow($event)"
      @edit="editRow($event)"/>

  </div>
</template>

<script>
import MODELS from "~/models/ccl/solicitudes"
import AgDatatable from "~/components/AgDatatable"
import ProductosEcologicosDialog from './ProductosEcologicosDialog'
import * as UTIL from '~/util'

export default {
  name: "ProductosEcologicosList",
  props: {
    editable: { type: Boolean, default: false },
    items: { type: Array, default: () => [] }
  },
  components:{
    AgDatatable,
    ProductosEcologicosDialog
  },
  data: () => ({
    dialog: false,
    itemSelected: null,
    grid: {
      title: '',
      api: null,
      rowData: [],
      columnDefs: []
    },
    UTIL: UTIL
  }),
  computed: {
    loading: {
      get () {
        return this.$store.state.loading
      },
      set (val) {
        this.$store.commit('LOADING', val)
      }
    }
  },
  methods: {
    onChange() {
      this.itemSelected = null
      this.$emit('change', this.grid.rowData)
    },
    // Grid
    onGridReady(api, tipo) {
      this.grid.api = api
    },
    addRow(item) {
      this.grid.rowData.push(item)
      this.grid.api.updateRowData({ add: [item] })
      this.onChange()
    },
    editRow(item) {
      const index = this.grid.rowData.indexOf(this.itemSelected)
      this.grid.rowData.splice(index, 1, item)
      this.onChange()
    },
    removeRow() {
      const selectedRows = this.grid.api.getSelectedRows()
      if (selectedRows && selectedRows.length) {
        selectedRows.forEach(element => {
          const index = this.grid.rowData.indexOf(element)
          this.grid.rowData.splice(index, 1)
        })
      }
      this.grid.api.updateRowData({remove: this.grid.api.getSelectedRows()})
      this.onChange()
    },
    init () {
      const vm = this
      this.grid.rowData = this.items
      this.grid.columnDefs = [
        {
          headerName: "#", sortable: true, filter: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true, valueGetter: params => params.node.rowIndex + 1
        },
        {
          headerName: "Código de explotación REGA", field: "codigo_explotacion_rega", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "Nombre productor", field: "nombre_productor", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "CIF", field: "cif", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "Dirección Localidad Provincia Teléfono Fax", field: "direccion_localidad_provincia_telefono_fax", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "Coordenadas Latitud Longitud", field: "coordenadas_latitud_longitud", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "Módulo tipo de producto", field: "modulo_tipo_producto", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "Plazas de cebo", field: "plazas_cebo", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "Producción Toneladas/año Peso vivo", field: "produccion_toneladas", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "Existe Producción Paralela SI/NO", field: "existe_produccion_paralela", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "GGN de los fabricante del pienso compuesto O Esquema de certificación homologado", field: "ggn_fabricante", sortable: true, filter: true, resizable: true,
        },
        {
          headerName: "Comunicación de GGN A RELLENAR POR ARAPORCEI", field: "comunicacion_ggn", sortable: true, filter: true, resizable: true,
        },
      ]
    },
    async importFile (event) {
      UTIL.importFile(this, event, this.grid, this.grid.columnDefs, this.onChange.bind(this))
      this.$refs.inputFile.value = ''
    }
  },
  created () {
    this.init()
  }
}
</script>
