<template>
  <v-autocomplete
    ref="alcance"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    v-model="selected"
    @change="$emit('input', selected)"
    :item-text="nombre"
    item-value="uuid"
    label="Alcance"
    :items="items"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'SelectAlcances',
  props: {
    readonly: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    value: String,
  },
  mounted() {
    if (this.required) {
      this.rules = [(v) => !!v || 'Obligatorio']
    }
    this.getItems()
  },
  data(ctx) {
    return {
      rules: [],
      selected: ctx.value,
      items: [],
    }
  },
  watch: {
    value: function (val) {
      if (!val || val === '') this.$refs.alcance.reset()
      this.selected = val
    },
  },
  methods: {
    nombre: function (item) {
      // ? `${item.codigo} - ${item.nombre}`
      return item.codigo ? `${item.nombre}` : item.nombre
    },
    async getItems() {
      const res = await this.$axios.get('alcances')

      if (res) this.items = res.data
    },
  },
}
</script>

<style scoped>
</style>
