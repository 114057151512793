var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"id":"documents-downloader","max-width":1200},model:{value:(_vm.ui.dialog),callback:function ($$v) {_vm.$set(_vm.ui, "dialog", $$v)},expression:"ui.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"grey lighten-4","size":"36"}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-cloud")])],1),_vm._v("\n      Archivos multimedia\n\n      "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"px-7 pb-0"},[_c('p',[_vm._v("\n        Se han encontrado los siguientes documentos asociados."),_c('br'),_vm._v("\n        Descarga los documentos que necesites\n      ")]),_vm._v(" "),_c('v-divider')],1),_vm._v(" "),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"px-9 pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Buscar","placeholder":"","append-icon":"mdi-magnify","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{ref:"datatable",class:{'disabled': _vm.ui.loading},attrs:{"headers":_vm.table.headers,"items":_vm.items,"items-per-page":10,"search":_vm.table.search,"sort-by":"name","loading-text":"Cargando archivos, por favor espera","loading":_vm.ui.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.fileIcon(item)))]),_vm._v("\n            "+_vm._s(item.name)+"\n          ")]}},{key:"item.dates",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"background":"transparent","transform":"translateX(-10px)","color":"#929292"},attrs:{"small":"","label":""}},[_vm._v("\n              "+_vm._s(_vm.$moment(item.date).format("DD [de] MMMM, YYYY"))+"\n            ")])]}},{key:"item.filetype",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.type)+"\n          ")]}},{key:"item.filesize",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.size)+"\n          ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"min-widht":"40px"},attrs:{"small":"","dense":"","text":"","color":"primary darken-3"},on:{"click":function($event){return _vm.download(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"font-size":"20px"}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Descargar archivo")])])]}}])})],1)],1),_vm._v(" "),(!_vm.ui.loading && !_vm.ui.saving)?_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"small":"","text":"","outlined":"","color":"success"},on:{"click":_vm.close}},[_vm._v("\n        Cerrar\n      ")])],1):_c('v-card-actions',[_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }