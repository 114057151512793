<template>
  <div>
    <!-- Confirm -->
    <confirmation
      v-model="confirm"
      :text="`¿Desea crear una muestra?`"
      textButton="Crear"
      :valid="valid"
      @confirm="createMuestra()"
      @close="close()">
      <v-form ref="form" lazy-validation v-model="valid">
        <v-container>
          <v-row align="center" justify="space-around">
            <v-col cols="8">
              <v-select
                label="Tipo"
                v-model="newMuestra.tipo"
                :rules="rules"
                :muestras="tipos"
                required
                dense></v-select>
            </v-col>
            <v-col cols="8">
              <SelectEstaticos
                v-if="auditoria.alcance"
                v-model="newMuestra.laboratorio"
                :alcance="auditoria.alcance.uuid"
                codigo="laboratorios"
                required
                dense />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </confirmation>

    <!-- Muestras -->
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline text-capitalize font-weight-bold">
          Muestra: {{ tab.replace('_', ' ').toLowerCase() }}
        </span>
        <v-spacer></v-spacer>
        <v-tooltip bottom v-for="item in muestras" :key="item.id">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :color="tab === item.tipo ? 'info' : 'grey lighten-4'"
              :class="tab === item.tipo ? 'white--text' : ' grey--text text--darken-1'"
              class="mr-4"
              depressed
              @click="tab = item.tipo">
              <span class="text-capitalize">{{ item.tipo }}</span>
            </v-btn>
          </template>
          <span class="text-capitalize">{{ item.tipo.replace('_', ' ') }}</span>
        </v-tooltip>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small fab depressed color="secondary2" class="white--text" v-on="on" @click="confirm = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Crear</span>
        </v-tooltip> -->
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-window v-model="tab">
          <v-window-item v-for="(item, key) in muestras" :key="item.id" :value="item.tipo">
            <v-row justify="space-between" v-show="tab === item.tipo">
              <!-- LOTE -->
              <v-col cols="5">
                <v-card flat>
                  <v-card-title> Lote </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row no-gutters justify="space-between">
                        <v-col cols="6" class="pb-1">
                          <v-text-field
                            label="Lote"
                            v-model="item.lote"
                            :disabled="isDisabled"
                            dense
                            @change="updateMuestra($event, item)"></v-text-field>
                        </v-col>

                        <v-col cols="6" class="pb-1">
                          <v-text-field
                            label="Precinto"
                            v-model="item.precinto"
                            :disabled="isDisabled"
                            dense
                            @change="updateMuestra($event, item)"></v-text-field>
                        </v-col>

                        <v-col cols="6" class="pb-1">
                          <!-- <SelectEstaticos
                            label="Seleccionar laboratorio"
                              v-if="auditoria.alcance && !isQuesoManchego"
                              v-model="item.laboratorios"
                              :disabled="isDisabled"
                              :alcance="auditoria.alcance.uuid"
                              :estaticos="estaticos"
                              codigo="laboratorios"
                              required
                              dense
                              :multiple="true"
                              @change="updateMuestra($event, item)"
                          /> -->
                          <SelectEstaticos
                            label="Seleccionar laboratorio"
                            v-if="auditoria.alcance && !isQuesoManchego"
                            v-model="item.laboratorio"
                            :disabled="isDisabled"
                            :alcance="auditoria.alcance.uuid"
                            :estaticos="estaticos"
                            codigo="laboratorios"
                            required
                            dense
                            @change="updateMuestra($event, item)" />
                        </v-col>

                        <v-col cols="6" class="pb-1">
                          <!-- <SelectEstaticos
                            label="Seleccionar tipo de análisis"
                              v-if="auditoria.alcance && !isQuesoManchego"
                              v-model="item.tipos"
                              :disabled="isDisabled"
                              :alcance="auditoria.alcance.uuid"
                              :estaticos="estaticos"
                              codigo="tipos_analisis"
                              required
                              dense
                              :multiple="isQuesoManchego ? true : false"
                              @change="updateMuestra($event, item)"
                          /> -->
                          <SelectEstaticos
                            label="Seleccionar tipo de análisis"
                            v-if="auditoria.alcance && !isQuesoManchego"
                            v-model="item.tipo_muestra"
                            :disabled="isDisabled"
                            :alcance="auditoria.alcance.uuid"
                            :estaticos="estaticos"
                            codigo="tipos_analisis"
                            required
                            dense
                            :multiple="isQuesoManchego ? true : false"
                            @change="updateMuestra($event, item)" />
                        </v-col>

                        <v-col cols="12" class="pb-1">
                          <v-text-field
                            label="Sustancias detectadas"
                            v-model="item.sustancias_detectadas"
                            :disabled="isDisabled"
                            dense
                            @change="updateMuestra($event, item)"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Fechas -->
              <v-col cols="4" xl="3">
                <v-card flat>
                  <v-card-title>Fechas</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12" class="py-1" v-if="!isOceGlobal">
                          <v-menu
                            close-on-content-click
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :value="formatDate(item.fecha_envio)"
                                :disabled="isDisabled"
                                label="Fecha envio"
                                prepend-icon="event"
                                clearable
                                readonly
                                dense
                                v-on="on"
                                @click:clear="item.fecha_envio = ''"></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.fecha_envio"
                              color="primary"
                              :first-day-of-week="1"
                              @change="updateMuestra($event, item)"></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" class="pb-1">
                          <v-menu
                            close-on-content-click
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :value="formatDate(item.fecha_recepcion)"
                                :disabled="isDisabled"
                                label="Fecha recepción"
                                prepend-icon="event"
                                clearable
                                readonly
                                dense
                                v-on="on"
                                @click:clear="item.fecha_recepcion = ''"></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.fecha_recepcion"
                              color="primary"
                              :first-day-of-week="1"
                              @change="updateMuestra($event, item)"></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>

              <!--  -->
              <v-col cols="3" :key="component">
                <v-row no-gutters>
                  <v-col cols="12" xl="6">
                    <v-switch
                      color="success"
                      v-model="item.estado"
                      true-value="CUMPLE"
                      false-value="NO_CUMPLE"
                      :disabled="isDisabled"
                      @change="updateMuestra($event, item)">
                      <template slot="label">
                        <span
                          class="font-weight-bold"
                          :class="item.estado === 'CUMPLE' ? 'success--text' : 'error--text'">
                          {{ item.estado === 'CUMPLE' ? 'CUMPLE' : 'NO CUMPLE' }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" xl="6">
                    <v-checkbox
                      label="Facturable"
                      v-model="item.facturable"
                      :disabled="isDisabled"
                      @change="updateMuestra($event, item)"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex align-center">
                    <v-btn
                      text
                      block
                      outlined
                      color="primary lighten-2"
                      :disabled="isDisabled"
                      @click="ui.showFirmasRow = !ui.showFirmasRow">
                      Gestión de firmas
                      <v-icon right dark> mdi-chevron-down </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="ui.showFirmasRow">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-card flat>
                      <v-card-title>Gestión de firmas </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="3">
                            <v-menu
                              close-on-content-click
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px">
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  :value="formatDate(itemFirma.fecha)"
                                  :disabled="isDisabled"
                                  label="Fecha de la firma"
                                  prepend-icon="event"
                                  clearable
                                  readonly
                                  dense
                                  v-on="on"
                                  @click:clear="itemFirma.fecha = ''"></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="itemFirma.fecha"
                                color="primary"
                                :first-day-of-week="1"></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-card
                              flat
                              style="border: 2px solid #ccc; cursor: pointer"
                              @click.stop="$refs['firmaMuestraAUD' + key][0].sign()"
                              :disabled="isDisabled">
                              <v-card-text>
                                <Signature
                                  :ref="'firmaMuestraAUD' + key"
                                  :signed="itemFirma.firmas"
                                  :options="{
                                    modulo: 'muestra_datas',
                                    modulo_ref: item.uuid,
                                    can_replace: true,
                                    typeOfUser: 'auditor',
                                    created_by: auditoria.responsable.uuid,
                                    created_name: auditoria.responsable.nombre_completo,
                                  }" />
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="6" @click.stop="$refs['firmaMuestraCLI' + key][0].sign()">
                            <v-card flat style="border: 2px solid #ccc; cursor: pointer">
                              <v-card-text>
                                <Signature
                                  :ref="'firmaMuestraCLI' + key"
                                  :signed="itemFirma.firmas"
                                  :options="{
                                    modulo: 'muestra_datas',
                                    can_replace: true,
                                    modulo_ref: item.uuid,
                                    typeOfUser: 'cliente',
                                    created_by: auditoria.cliente.uuid,
                                    created_name: auditoria.cliente.nombre,
                                  }" />
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="green darken-1"
                          @click="addFirma(itemFirma, item.tipo, key)"
                          v-if="!firmaEditMode">
                          Añadir
                        </v-btn>
                        <v-btn
                          text
                          color="green darken-1"
                          @click="updateFirma(itemFirma, key)"
                          v-if="firmaEditMode">
                          Actualizar
                        </v-btn>
                        <v-btn
                          text
                          color="indigo darken-4"
                          @click="clearFirmas(key)"
                          v-if="firmaEditMode">
                          Limpiar
                        </v-btn>
                      </v-card-actions>
                      <v-dialog v-model="ui.dialogFirmaReplace" min-width="290">
                        <v-card>
                          <v-card-title>Reemplazar firma</v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <strong>¡Atención!</strong> Ya existe una firma con esta fecha.
                                ¿Desea reemplazarla?
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text color="error" @click="ui.dialogFirmaReplace = false">
                              Cancelar
                            </v-btn>
                            <v-btn
                              text
                              color="success"
                              @click="addFirma(itemFirma, item.tipo, key, true)">
                              Reemplazar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-data-table
                        ref="table"
                        :headers="headers"
                        :items="item.firmas.map((firma, index) => ({ ...firma, parentKey: key }))"
                        :items-per-page="5"
                        class="elevation-1"
                        hide-default-footer
                        :loading="loading"
                        :no-data-text="loading ? 'Cargando...' : 'No hay datos'"
                        :no-results-text="loading ? 'Cargando...' : 'No hay resultados'">
                        <template v-slot:item="{ item }">
                          <tr @click="editFirma(item, item.parentKey)">
                            <td>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    :color="
                                      item.cliente && item.auditor
                                        ? 'success'
                                        : item.cliente || item.auditor
                                        ? 'warning'
                                        : 'error'
                                    "
                                    v-on="on">
                                    mdi-circle
                                  </v-icon>
                                </template>
                                <span>
                                  {{ !item.cliente ? 'Falta firma cliente' : '' }}
                                  {{ !item.auditor ? 'Falta firma auditor' : '' }}
                                  {{
                                    item.cliente && item.auditor
                                      ? 'Este paquete de firmas es válido'
                                      : ''
                                  }}
                                </span>
                              </v-tooltip>
                            </td>
                            <td>{{ formatDate(item.fecha) }}</td>
                            <td>{{ item.cliente ? '✓' : '' }}</td>
                            <td>{{ item.auditor ? '✓' : '' }}</td>
                            <td>
                              <v-btn icon x-small @click.stop="deleteFirma(item, item.parentKey)">
                                <v-icon color="error">mdi-delete</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <!-- Codigo Muestra -->
              <v-col cols="12">
                <DatosMuestra
                  v-model="item.params"
                  :auditoria="auditoria"
                  :codigo="toma_muestra.codigo"
                  :isDisabled="isDisabled"
                  :lote="get_lote"
                  :tipos="isQuesoManchego ? item.tipos : item.tipo_muestra"
                  :laboratorios="isQuesoManchego ? item.laboratorios : item.laboratorio"
                  @change="updateMuestra($event, item)" />
              </v-col>

              <!-- ANALISIS -->
              <!-- <v-col cols="12">
                <Analisis
                  :muestra="item"
                  :toma_muestra="toma_muestra"
                  :auditoria="auditoria"
                />
              </v-col> -->
            </v-row>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DatosMuestra from '~/components/auditorias/muestras/DatosMuestra'
import Analisis from '~/components/auditorias/muestras/MuestrasTabAnalisis'
import SelectEstaticos from '~/components/forms/SelectEstaticos'
import Confirmation from '~/components/Confirmation'
import Signature from '~/components/signatures/Show'
import moment from 'moment'

export default {
  props: {
    auditoria: {
      type: Object,
      default: () => {},
    },
    toma_muestra: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatosMuestra,
    Analisis,
    SelectEstaticos,
    Confirmation,
    Signature,
  },
  data: () => ({
    estaticos: null,
    component: 0,
    search: '',
    tab: 'INICIAL',
    valid: true,
    confirm: false,
    firmaEditMode: false,
    analisisIdx: null,
    newMuestra: {},
    itemFirma: {
      fecha: '',
      firmas: [],
    },
    headers: [
      { text: 'Estado', value: 'estado' },
      { text: 'Fecha', value: 'fecha' },
      { text: 'Cliente', value: 'cliente' },
      { text: 'Auditor', value: 'auditor' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    tipos: ['NUEVA'],
    rules: [(v) => !!v || 'Obligatorio'],
    muestras: [],
    ui: {
      showFirmasRow: false,
      dialogFirmaReplace: false,
    },
  }),
  watch: {
    toma_muestra() {
      this.listMuestra()
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading
    },
    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },
    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },
    get_lote() {
      if (this.item) {
        let muestra = this.toma_muestra.muestras.filter((m) => m.tipo === this.tab)[0]
        if (muestra) {
          return muestra.lote
        } else {
          return ''
        }
      }
    },
  },
  mounted() {
    this.getEstaticos()
  },
  methods: {
    addFirma(itemFirma, tipo, index, replace = false) {
      // Get the firmas pads
      let firmaAUD = this.$refs['firmaMuestraAUD' + index][0].signature
      let firmaCLI = this.$refs['firmaMuestraCLI' + index][0].signature

      //find the muestra by tipo
      let muestra = this.muestras.find((m) => m.tipo === tipo)
      // Check if firmas exist in muestra
      if (!muestra.firmas) {
        this.$set(muestra, 'firmas', [])
      }

      // Check if firma exists
      let firma = muestra.firmas.find((f) => f.fecha === itemFirma.fecha)
      let idx = muestra.firmas.findIndex((f) => f.fecha === itemFirma.fecha)

      // If firma exists, up a dialog
      if (firma && !replace) {
        this.ui.dialogFirmaReplace = true
        return
      }

      if (replace) {
        muestra.firmas.splice(idx, 1)
        this.ui.dialogFirmaReplace = false
      }
      // Add firmas to muestra
      muestra.firmas.push({
        fecha: itemFirma.fecha,
        cliente: firmaCLI,
        auditor: firmaAUD,
      })

      // Reset firmas pads
      this.$refs['firmaMuestraAUD' + index][0].reset()
      this.$refs['firmaMuestraCLI' + index][0].reset()

      // Reset itemFirma
      this.itemFirma = {
        fecha: '',
        firmas: [],
      }

      //Update muestra
      this.updateMuestra(true, muestra)

      this.component++
    },
    deleteFirma(firma, index) {
      let muestra = this.muestras.find((m) => m.tipo === this.tab)
      let idx = muestra.firmas.findIndex((f) => f.fecha === firma.fecha)
      muestra.firmas.splice(idx, 1)

      //Update firmas pads
      this.$refs['firmaMuestraAUD' + index][0].reset()
      this.$refs['firmaMuestraCLI' + index][0].reset()

      //Reset itemFirma
      this.itemFirma = {
        fecha: '',
        firmas: [],
      }
      //Update muestra
      this.updateMuestra(true, muestra)
      this.firmaEditMode = false
      this.component++
    },
    editFirma(firma, index) {
      this.firmaEditMode = true
      this.itemFirma = firma
      //update firmas pads
      if (firma.auditor) this.$refs['firmaMuestraAUD' + index][0].display(firma.auditor)
      if (firma.cliente) this.$refs['firmaMuestraCLI' + index][0].display(firma.cliente)
    },
    updateFirma(itemFirma, index) {
      let firmaAUD = this.$refs['firmaMuestraAUD' + index][0].signature
      let firmaCLI = this.$refs['firmaMuestraCLI' + index][0].signature
      itemFirma.cliente = firmaCLI
      itemFirma.auditor = firmaAUD
      let muestra = this.muestras.find((m) => m.tipo === this.tab)

      let idx = muestra.firmas.findIndex((f) => f.created_at === itemFirma.created_at)
      muestra.firmas[idx] = itemFirma

      //Update muestra
      this.updateMuestra(true, muestra)

      // Reset firmas pads
      this.$refs['firmaMuestraAUD' + index][0].reset()
      this.$refs['firmaMuestraCLI' + index][0].reset()

      this.firmaEditMode = false
      this.itemFirma = {
        fecha: '',
        firmas: [],
      }
      this.component++
    },
    clearFirmas(index) {
      this.$refs['firmaMuestraAUD' + index][0].reset()
      this.$refs['firmaMuestraCLI' + index][0].reset()
      this.firmaEditMode = false
      this.itemFirma = {
        fecha: '',
        firmas: [],
      }
    },
    async getEstaticos() {
      const body = {
        alcance: this.auditoria.alcance.uuid,
        codigos: ['datos_muestra', 'laboratorios', 'tipos_analisis'],
      }
      this.estaticos = await this.$store.dispatch('estaticos/list', body)
    },
    formatDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },
    close() {
      this.confirm = false
      this.newMuestra = {}
      this.$refs.form.reset()
    },
    // Muestras
    async listMuestra() {
      if (this.toma_muestra.uuid) {
        const res = await this.$store.dispatch('tomas_muestra/listMuestra', this.toma_muestra.uuid)
        if (res) {
          let data = res
          let dirimente = ''
          let contradictorio = ''
          for (let i = 0; i < data.length; i++) {
            const element = data[i]
            if (element.fecha_envio)
              element.fecha_envio = moment(element.fecha_envio).format('YYYY-MM-DD')
            if (element.fecha_recepcion)
              element.fecha_recepcion = moment(element.fecha_recepcion).format('YYYY-MM-DD')
            if (element.tipo == 'DIRIMENTE') dirimente = element
            if (element.tipo == 'CONTRADICTORIO') contradictorio = element
          }
          this.muestras = data
          this.muestras[1] = contradictorio
          this.muestras[2] = dirimente
          //add an array firmas to each muestra if not exist
          this.muestras.forEach((item) => {
            if (!item.firmas) this.$set(item, 'firmas', [])
            //format the fechas of every firma
            item.firmas.forEach((firma) => {
              firma.fecha = moment(firma.fecha).format('YYYY-MM-DD')
            })
          })
          this.$emit('updateMuestras', this.muestras)
        }
      }
    },
    async createMuestra(idx) {
      if (this.$refs.form.validate()) {
        const body = {
          ...this.newMuestra,
          toma_muestra: this.toma_muestra.uuid,
        }
        await this.$store.dispatch('tomas_muestra/createMuestra', body)
        this.close()
        this.listMuestra()
      }
    },
    async updateMuestra(val, item) {
      if (val || val === false) {
        let data = {}
        if (this.isQuesoManchego) {
          item.tipos = item.tipos
          item.laboratorios = item.laboratorios

          data = {
            ...item,
            toma_muestra: this.toma_muestra.uuid,
          }
        } else {
          data = {
            ...item,
            toma_muestra: this.toma_muestra.uuid,
          }
        }
        const body = data
        console.log('updateMuestra', body)
        await this.$store.dispatch('tomas_muestra/updateMuestra', body)
        this.listMuestra()
      }
    },
  },
  created() {
    this.listMuestra()
  },
}
</script>
