<template>
  <div>

    <v-speed-dial
      v-model="fab"
      fixed
      bottom
      right
      :open-on-hover="!isMobile"
      direction="top"
      :transition="transition">
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <!-- Delete -->
      <v-tooltip left v-if="showDelete">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab dark color="red" @click="dialogConfirmDelete = true" >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <span>Eliminar</span>
      </v-tooltip>

      <!-- Cancel -->
      <v-tooltip left v-if="cancel" >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab dark color="red" @click="controlCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Cancelar</span>
      </v-tooltip>

      <!-- Save -->
      <v-tooltip left v-if="save">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab dark color="orange" @click="controlConfirmation()">
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
        </template>
        <span>Guardar</span>
      </v-tooltip>

      <!-- PDF -->
      <v-tooltip left v-if="pdf">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab dark color="red" @click="controlPDF()">
            <v-icon>mdi-adobe-acrobat</v-icon>
          </v-btn>
        </template>
        <span>PDF</span>
      </v-tooltip>

      <!-- -->
      <v-btn v-if="to && !dialog" :to="to" fab dark color="light-green">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn v-if="dialog" fab dark color="light-green" @click="emitDialog()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <!-- CONFIRMACIÓN DE GUARDADO -->
    <confirmation v-model="dialogConfirmSave"
      :text="text || '¿Desea guardar los datos?'"
      :textButton="textButton || 'Guardar'"
      @confirm="submit()"/>

    <!-- CONFIRMACIÓN DE BORRADO -->
    <confirmation v-model="dialogConfirmDelete"
      text="¿Desea eliminar el objeto?"
      colorButton="red"
      dark
      textButton="Eliminar"
      @confirm="deleteItem()"/>

  </div>
</template>

<script>
import Confirmation from '~/components/Confirmation'

export default {
  name: 'FloatingButtonMenu',
  components: {
    Confirmation
  },
  props: {
    valid: { type: Boolean, default: false },
    dialog: { type: Boolean, default: false },
    cancel: { type: Boolean, default: false },
    pdf: { type: Boolean, default: false },
    showDelete: { type: Boolean, default: false },
    text: String,
    textButton: String
  },
  data: () => ({
    dialogConfirmSave: false,
    dialogConfirmDelete: false,
    fab: false,
    transition: 'slide-y-reverse-transition',
    to: null,
    save: false
  }),
  watch: {
    $route(to, from) {
      //console.log('[floating button]', to, from)
    },
    valid: function (e) {
      this.valid = e
    }
  },
  computed: {
    isMobile() {
      return this.$device.isTablet || this.$device.isMobile
    }
  },
  mounted() {
    //console.log('[floating button]', this.$router.currentRoute)
    if (
      (this.$router.currentRoute.name.indexOf('uuid') !== -1 ||
        this.$router.currentRoute.name.indexOf('nueva') !== -1 ||
        this.$router.currentRoute.name.indexOf('create') !== -1) &&
      this.$router.currentRoute.params
    ) {
      this.save = true
      this.to = null
    } else {
      this.save = false
      this.to = this.getTo()
    }
  },
  methods: {
    controlConfirmation() {
      if (this.valid) this.dialogConfirmSave = true
      else this.submit()
    },
    submit() {
      this.dialogConfirmSave = false
      this.$emit('submit')
    },
    controlCancel() {
      this.$emit('cancel')
    },
    controlPDF() {
      this.$emit('pdf')
    },
    deleteItem() {
      this.dialogConfirmDelete = false
      this.$emit('deleteItem')
    },
    getTo() {
      if (this.$router.currentRoute.name === 'solicitudes')
        return this.$router.currentRoute.path + '/nueva'
      else return this.$router.currentRoute.path + '/_'
    },
    emitDialog() {
      this.$emit('dialog')
    }
  }
}
</script>

<style scoped>
.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}

.fondo-app {
  background-color: rgb(246, 248, 249) !important;
}
.toolbar-no-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09) !important;
  color: #727891 !important;
  z-index: 1;
  right: 0;
  left: 0;
  position: fixed;
}
</style>
