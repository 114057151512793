<template>
<div class="d-flex align-center">
  <v-autocomplete
    ref="select"
    label="Localidad"
    v-model="selected"
    :items="items"
    :loading="ui.loading"
    item-text="nombre"
    item-value="uuid"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :dense="dense"
    :outlined="outlined"
    :filled="filled"
    :hide-details="hideDetails"
    :return-object="returnObject"
    no-data-text="Listado vacío, seleccione la localidad."
    :error="error"
    :error-messages="errorMessages"
    @change="emitChange()">

    <template v-slot:item="data">
      <template>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
          <span style="font-size: 14px;">
            {{ data.item.codigo }}, {{ data.item.municipio.nombre }}, {{ data.item.provincia.nombre }}
          </span>
        </v-list-item-content>
      </template>
    </template>

    <template v-slot:append-outer class="ma-1"
      v-if="this.allowCreate">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="success" text small
            @click="$refs.crud.newItem()" style="transform: translateY(-2px)">
            <v-icon small>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        Crear una nueva localidad
      </v-tooltip>
    </template>
  </v-autocomplete>

  <crud-dialog
    ref="crud"
    @stored="eventStored">
  </crud-dialog>
</div>

</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    components/forms/SelectLocalidades.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 9th June 2021
 * Last Modified: Fri Feb 17 2023
 **/

import notifications from '~/util/notificationHelper'
import crudDialog from '~/pages/localidades/widgets/crudDialog'

export default {
  name: 'SelectLocalidad',
  components: {
    crudDialog,
  },

  props: {
    value: [String, Object],
    label: { type: String, default: 'Locaidad' },
    readonly: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
    returnObject: { type: Boolean, default: false },
    allowCreate: { type: Boolean, default: false },
    codigo: { type: String, default: '' },
    error: { type: Boolean, default: false },
    errorMessages: { type: String, default: '' },
  },

  data() {
    return {
      selected: null,

      db: {
        localidades: [],
      },

      ui: {
        loading: true,
      },
    }
  },

  computed: {
    rules() {
      return this.required ? [(v) => !!v || 'Obligatorio'] : []
    },

    items() {
      return this.db.localidades
    },

    selectedObject() {
      return this.$refs.select.selectedItems[0]
    },
  },

  watch: {
    value(val) {
      if (!val && this.$refs.select) this.$refs.select.resetValidation()
      this.selected = val
    },

    codigo(val) {
      if (this.codigo) this.setValueByCodigo(this.codigo)
    },
  },

  methods: {
    //+-------------------------------------------------
    // setValueByCodigo()
    // Useful method to be called by parent component
    // update value in this
    // -----
    // Created on Mon Mar 21 2022
    //+-------------------------------------------------
    setValueByCodigo(codigo) {
      const selected = this.items.find((e) => e.codigo == codigo)
      this.selected = { ...selected }
      this.$emit('input', selected)
    },

    emitChange() {
      setTimeout(() => {
        this.$emit('input', this.selected)
        this.$emit('change', this.selected, this.selectedObject)

        $nuxt.$emit('localidades:selected', {
          uuid: this.selected,
          localidad: this.selectedObject,
        })
      }, 500)
    },

    eventStored(payload) {
      this.db.localidades.push(payload.item)
      notifications.show({ text: 'La localidad se ha guardado correctamente' })
      this.selected = payload.item.uuid

      this.emitChange()
    },

    async getLocalidades() {
      const xhr = await this.$store.dispatch('localidades/customlist')
      if (xhr) this.db.localidades = xhr
    },

    async init() {
      this.selected = this.value
      await this.getLocalidades()
      if (this.codigo) this.setValueByCodigo(this.codigo)

      this.ui.loading = false
    },
  },

  created() {
    this.init()
  },
}
</script>
