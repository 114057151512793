<template>
  <v-dialog v-model="dialog" max-width="700px" persistent>
    <v-form ref="form" lazy-validation v-model="valid">
      <v-card v-if="dialog">
        <v-card-title>
          Datos Instalación
        </v-card-title>

        <v-card-text style="overflow: auto; max-height: 73vh;">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="N° RGSEAA"
                  v-model="item.rgseaa"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Fecha validez"
                      :value="formatDate(item.fecha_validez)"
                      clearable
                      dense
                      readonly
                      v-on="on"
                      @click:clear="item.fecha_validez = ''"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.fecha_validez"
                    locale="es"
                    color="primary"
                    :first-day-of-week="1"
                    no-title
                    scrollable
                    @change="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="8">
                <v-text-field
                  label="Razón social"
                  v-model="item.razon_social"
                  :rules="rules"
                  required
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <SelectTipoInstalacion
                  v-model="item.tipo_instalacion"
                />
              </v-col>


              <v-col cols="12">
                <v-text-field
                  label="NIF"
                  v-model="item.nif"
                  maxlength="11"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <SelectPais
                  v-model="item.pais.uuid"
                  required
                  dense
                />
              </v-col>

              <v-col cols="6">
                <SelectComunidad
                  v-if="item.comunidad"
                  v-model="item.comunidad.uuid"
                  :pais_uuid="item.pais.uuid"
                  :disabled="!item.pais.uuid"
                  dense
                />
              </v-col>

              <v-col cols="6">
                <SelectProvincia
                  v-if="item.provincia"
                  v-model="item.provincia.uuid"
                  :pais_uuid="item.pais.uuid"
                  :disabled="!item.pais.uuid"
                  dense
                />
              </v-col>

              <v-col cols="6">
                <SelectMunicipio
                  v-if="item.municipio"
                  v-model="item.municipio.uuid"
                  :provincia_uuid="item.provincia.uuid"
                  :disabled="!item.provincia.uuid"
                  dense
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Dirección"
                  v-model="item.direccion"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <select-actividades
                  v-model="item.tramites"
                  dense
                  multiple
                  smallChips
                  returnObject
                ></select-actividades>
              </v-col>

              <v-col cols="6">
                <v-checkbox class='pa-0' dense v-model="item.certificada" color="primary" label="Certificada"/>
              </v-col>

              <v-col cols="6">
                <v-textarea
                  label="Observaciones"
                  v-model="item.observaciones"
                  outlined
                  auto-grow
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  label="Observaciones"
                  v-model="item.observaciones"
                  outlined
                  auto-grow
                ></v-textarea>
              </v-col>

              <!-- Campos Dinamicos -->
              <v-col cols="12">
                <CamposDinamicos v-model="item.instalacion_data" />
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()">Cancelar</v-btn>
          <v-btn depressed color="primary" :disabled="!valid" @click="submit()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import CamposDinamicos from '~/components/clientes/instalaciones/araporcei/CamposDinamicos'
import SelectComunidad from '~/components/forms/SelectComunidad'
import SelectActividades from '~/components/forms/SelectActividades'
import SelectProvincia from '~/components/forms/SelectProvincia'
import SelectMunicipio from '~/components/forms/SelectMunicipio'
import SelectPais from '~/components/forms/SelectPais'
import SelectTipoInstalacion from "~/components/forms/SelectTipoInstalacion";

export default {
  name: 'GestionInstalacionesDialog',
  props: {
    value: Boolean,
    cliente: { type: Object, default: null },
    instalacion: { type: Object, default: null },
  },
  components: {
    CamposDinamicos,
    SelectPais,
    SelectMunicipio,
    SelectProvincia,
    SelectComunidad,
    SelectActividades,
    SelectTipoInstalacion
  },
  data: () => ({
    valid: false,
    dialog: false,
    menu: false,
    item: {
      tipo_instalacion: [],
      nif: '',
      tramites: [],
      pais: {},
      comunidad: {},
      provincia: {},
      municipio: {},
      instalacion_data: [],
    },
    rules: [(v) => !!v || 'Obligatorio'],
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    instalacion(val) {
      if (val) this.getOne()
      else this.resetForm()
    },
  },
  computed: {
    isNew() {
      return this.instalacion === null
    },
    isNewCliente() {
      return this.$route.params.uuid === '_' && !this.cliente
    },
  },
  methods: {
    nifResponsableErrors() {
      const errors = []
      if (!this.$v.item.nif.$dirty) return errors
      !this.$v.item.nif.minLength && errors.push('Documento inválido')
      !this.nifResponsableValid && errors.push('Documento inválido')
      return errors
    },
    checkResponsableDocument() {
      let document = 'OTRO'
      const passport = new RegExp('^[P](?:-)*[0-9]+$')
      const dni = new RegExp('^[A-Z](?:-)*[0-9]{8}$')
      const nie = new RegExp('^[A-Z][0-9]{7}(?:-)*[A-Z]$')
      const nif = new RegExp('^[0-9]{8}(?:-)*[A-Z]$')
      const other1 = new RegExp('^[0-9]{7}$')
      const other2 = new RegExp('^[0-9]{8}$')
      const other3 = new RegExp('^[0-9]{9}$')
      const other4 = new RegExp('^[0-9]{11}$')
      if (passport.test(this.cliente.responsable_nif)) document = 'PASAPORTE'
      if (dni.test(this.cliente.responsable_nif)) document = 'DNI'
      if (nie.test(this.cliente.responsable_nif)) document = 'NIE'
      if (nif.test(this.cliente.responsable_nif)) document = 'NIF'
      return document
    },
    formatDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },
    formatDateBack(date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    formatDateToApi(date) {
      return date ? moment(date).format('YYYY-MM-DD') : ''
    },
    resetForm() {
      this.item = {
        nif: '',
        tramites: [],
        pais: {},
        comunidad: {},
        provincia: {},
        municipio: {},
        instalacion_data: [],
      }
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },
    async getOne() {
      const item = Object.assign({}, this.instalacion)
      if (!item.pais) item.pais = { uuid: '' }
      if (!item.comunidad) item.comunidad = { uuid: '' }
      if (!item.provincia) item.provincia = { uuid: '' }
      if (!item.municipio) item.municipio = { uuid: '' }
      this.item = item
      // console.info('this.item', this.item);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const body = {
          cliente_uuid: this.cliente == null ? this.$route.params.uuid : this.cliente.uuid,
          instalacion: {
            ...this.item,
          },
        }
        body.instalacion.tramites = this.item.tramites.map((element) => element.uuid)
        body.instalacion.fecha_validez = this.formatDateToApi(this.item.fecha_validez)
        if (this.item.pais) body.instalacion.pais = this.item.pais.uuid
        if (this.item.comunidad) body.instalacion.comunidad = this.item.comunidad.uuid
        if (this.item.provincia) body.instalacion.provincia = this.item.provincia.uuid
        if (this.item.municipio) body.instalacion.municipio = this.item.municipio.uuid
        if (!this.item.fecha_validez) delete body.instalacion.fecha_validez
        // console.info('body', body);
        if (this.isNew) {
          this.isNewCliente
            ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
            : await this.$store.dispatch('clientes_instalaciones/create', body)
        } else {
          this.isNewCliente
            ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
            : await this.$store.dispatch('clientes_instalaciones/update', body)
        }
        if (!this.isNewCliente) {
          this.$emit('init')
          this.$emit('created', this.item)
        }
        this.close()
      }
    },
  },
}
</script>
