/**
 * @project: certiapp-nuxt
 * @file:    \store\reportes.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Fri Nov 11 2022
 **/

export const state = () => ({})

//commit mutations
export const mutations = {}

//dispatch actions
export const actions = {
  async operadores({ rootState }) {
    rootState.loading = true
    const res = await this.$axios.get('reportes/operadores')
    rootState.loading = false
    if (res) return res.data
  },

  async operadoresCertificados({ rootState }) {
    rootState.loading = true
    const res = await this.$axios.put('reportes/operadores-certificados')
    rootState.loading = false
    if (res) return res.data
  },

  async controles({ rootState }, payload) {
    rootState.loading = true
    const body = {}
    if (payload.fechaInicio && payload.fechaFin) {
      body.fechaInicio = payload.fechaInicio
      body.fechaFin = payload.fechaFin
    }
    const res = await this.$axios.get('reportes/controles', body)
    rootState.loading = false
    if (res) return res.data
  },

  async no_conformidades({ rootState }) {
    rootState.loading = true
    const res = await this.$axios.get('reportes/no-conformidades')
    rootState.loading = false
    if (res) return res.data
  },

  async operadores_baja({ rootState }) {
    rootState.loading = true
    const res = await this.$axios.get('reportes/operadores-baja')
    rootState.loading = false
    if (res) return res.data
  },

  async superficies({ rootState }) {
    rootState.loading = true
    const res = await this.$axios.get('reportes/superficies')
    rootState.loading = false
    if (res) return res.data
  },

  async industrias({ rootState }) {
    rootState.loading = true
    const res = await this.$axios.get('reportes/industrias')
    rootState.loading = false
    if (res) return res.data
  },
}
