<template>
  <div>
    <v-row align="center">
      <v-col cols="6" class="display-1 font-weight-medium text-capitalize">
        {{ headerTitle }}
      </v-col>
      <v-col cols="3" class="text-right">

      </v-col>
      <v-col cols="3" class="text-right">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn small fab depressed color="success" class="white--text" :disabled="!CAN_CREATE" v-on="on" @click="click()" v-if="!noAction">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Crear</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {type: String, default: null},
    noAction: {type: Boolean, default: false},
    emitClick: {type: Boolean, default: false},
  },
  data() {
    return {
      // dialog: false,
      // db: {
      //   checklist: []
      // },
      // selected: [],
      // headers: [
      //   {text: 'Nombre', value: 'Name'},
      //   {text: 'Estado', value: 'Status'},
      //   {text: 'Descripción', value: 'Description'},
      //   {text: 'Standards', value: 'Standards'},
      //   {text: 'Modulos', value: 'Modules'}
      // ],
      // loadTable: false
    }
  },
  methods: {
    click() {
      if (this.emitClick) this.$emit('click')
      else {
        this.$router.currentRoute.name === 'solicitudes'
          ? this.$router.push(this.$router.currentRoute.path + '/nueva')
          : this.$router.push(this.$router.currentRoute.path + '/_')
      }
    },
    // singular(string) {
    //   const idx = string.length - 1
    //   return string[idx] === 's' ? string.substring(0, idx) : string
    // },
    // async getList() {
    //   this.loadTable = true;
    //   this.selected = []
    //   const res = await this.$store.dispatch('auditorias/getGlobalgapPlantillasChecklist')
    //   console.log('res: ', res.Checklists)
    //   this.db.checklist = res.Checklists
    //   this.loadTable = false;
    // },
  },
  computed: {
    headerTitle() {
      if (this.title) return this.title
      else {
        let name = $nuxt.$route.name
        const split = name.split('-')
        if (split.length) name = split[split.length - 1]
        return name.replace('_', ' ')
      }
    },
    CAN_CREATE() {
      return this.$store.state.permisos.can_create
    },
  },
}
</script>
