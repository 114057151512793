<template>
  <v-autocomplete
    v-if="multiple"
    ref="emplazamientos"
    v-model="selected"
    :items="planificableItems"
    label="Emplazamientos"
    item-text="nombre"
    item-value="uuid"
    dense
    multiple
    clearable
    small-chips
    return-object
    :disabled="disabled">
    <template v-slot:item="{ item, attrs, on }">
      <v-subheader v-if="item.header">{{ item.header }}</v-subheader>
      <v-divider v-else-if="item.divider"></v-divider>
      <template v-else>
        <v-list-item v-on="on" v-bind="attrs" @click="rowSelected(item)">
          <v-list-item-action>
            <v-checkbox :value="selected.some((e) => e.uuid === item.uuid)"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >{{ item.nombre
              }}<span style="color: rgb(146, 146, 146); font-size: 12px !important">
                - {{ item.rgseaa }}</span
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>
  </v-autocomplete>

  <v-autocomplete
    v-else
    ref="emplazamientos"
    v-model="selected"
    :items="items"
    label="Emplazamientos"
    item-text="nombre"
    item-value="uuid"
    dense
    clearable
    return-object
    :disabled="disabled">
    <template v-slot:item="{ item, attrs, on }">
      <v-subheader v-if="item.header">{{ item.header }}</v-subheader>
      <v-divider v-else-if="item.divider"></v-divider>
      <template v-else>
        <v-list-item v-on="on" v-bind="attrs" @click="rowSelected(item)">
          <v-list-item-content>
            <v-list-item-title
              >{{ item.nombre
              }}<span
                v-if="item.rgseaa"
                style="color: rgb(146, 146, 146); font-size: 12px !important">
                - {{ item.rgseaa }}</span
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\forms\SelectEmplazamientos.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue May 02 2023
 **/

import * as UTIL from '~/util'

export default {
  name: 'SelectEmplazamientos',
  props: {
    value: [String, Array],
    disabled: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: true },
  },
  data(ctx) {
    return {
      UTIL: UTIL,
      selected: ctx.value,
    }
  },
  watch: {
    value(val) {
      if (!val || val === '') this.$refs.emplazamientos.reset()
      this.selected = val
    },
  },
  computed: {
    planificableItems() {
      const sites = this.items.filter((item) => item.planificable)
      let data = {}
      let tipos = []

      for (let i = 0; i < sites.length; i++) {
        const element = sites[i]

        if (!element.tipo.includes('instalacion') && !element.tipo.includes('productor')) continue

        if (!tipos.includes(element.tipo)) tipos.push(element.tipo)
      }

      for (let i = 0; i < tipos.length; i++) {
        const element = tipos[i]
        data[element] = []
      }

      for (let i = 0; i < sites.length; i++) {
        const element = sites[i]

        if (!element.tipo.includes('instalacion') && !element.tipo.includes('productor')) continue

        let obj = {}
        obj.uuid = element.uuid
        obj.nombre = element.nombre
        if (element.iteracion_uuid) obj.iteracion_uuid = element.iteracion_uuid
        if (element.parent_uuid) obj.parent_uuid = element.parent_uuid
        obj.planificable = element.planificable
        //agregar rgseaa a todos los emplazamientos
        if (element.instalacion) {
          obj['rgseaa'] = element.instalacion.rgseaa
        }
        if (element.site_data && element.site_data.length) {
          for (let k = 0; k < element.site_data.length; k++) {
            const item = element.site_data[k]
            obj[item.keyname] = item.value
          }
        }
        // Assign group for select
        obj.group = UTIL.capitalizeFirstLetter(element.tipo)
        data[element.tipo].push(obj)
      }

      const groupedSites = []

      for (let i = 0; i < tipos.length; i++) {
        const tipo = tipos[i]
        groupedSites.push({ header: UTIL.capitalizeFirstLetter(tipo) })
        const element = data[tipo]
        for (let k = 0; k < element.length; k++) {
          const item = element[k]
          groupedSites.push(item)
        }
        if (i < tipos.length - 1) groupedSites.push({ divider: true })
      }

      return groupedSites
    },
    rules() {
      if (this.required) {
        return this.multiple
          ? [(v) => (!!v && !!v.length) || 'Obligatorio']
          : [(v) => !!v || 'Obligatorio']
      } else return []
    },
    groups() {
      const groups = []
      for (let i = 0; i < this.planificableItems.length; i++) {
        const element = this.planificableItems[i]
        if (element.group && !groups.includes(element.group)) groups.push(element.group)
      }
      return groups
    },
  },
  methods: {
    rowSelected(item) {
      if (this.multiple) {
        const group = item.group
        const uuid = item.uuid
        // const iteracion_uuid = item.iteracion_uuid
        const parent_uuid = item.parent_uuid
        const selected = this.selected.some((e) => e.uuid === item.uuid)
        if (group === this.groups[0]) {
          if (selected) {
            const index = this.selected.findIndex((e) => e.uuid === item.uuid)
            if (index > -1) this.selected.splice(index, 1)
            this.uncheckAllChildren(group, uuid)
          } else {
            this.selected.push(item)
            this.checkAllChildren(group, uuid)
          }
        } else if (group === this.groups[1]) {
          if (selected) {
            const index = this.selected.findIndex((e) => e.uuid === item.uuid)
            if (index > -1) this.selected.splice(index, 1)
            this.uncheckParent(group, parent_uuid)
          } else {
            this.selected.push(item)
            this.checkParent(group, parent_uuid)
          }
        }
      } else {
        this.selected = item
      }
      this.$emit('input', this.selected)
    },
    checkAllChildren(group, iteracion_uuid) {
      return
      if (group === this.groups[0]) {
        // const apigroup1 = this.grid[this.groups[1]].api;
        // apigroup1.forEachNode(node => {
        //   if (node.data.parent_uuid === iteracion_uuid) {
        //     node.setSelected(true)
        //   }
        // })
        for (let i = 0; i < this.planificableItems.length; i++) {
          const element = this.planificableItems[i]
          if (
            element.parent_uuid === iteracion_uuid &&
            !this.selected.some((e) => e.uuid === element.uuid)
          ) {
            this.selected.push(element)
          }
        }
      }
    },
    uncheckAllChildren(group, iteracion_uuid) {
      return
      if (group === this.groups[0]) {
        // const apigroup1 = this.grid[this.groups[1]].api;
        // apigroup1.forEachNode(node => {
        //   if (node.data.parent_uuid === iteracion_uuid) {
        //     node.setSelected(false)
        //   }
        // })
        const uuids = []
        for (let i = 0; i < this.selected.length; i++) {
          const element = this.selected[i]
          if (element.parent_uuid === iteracion_uuid) {
            uuids.push(element.uuid)
          }
        }
        for (let i = 0; i < uuids.length; i++) {
          const uuid = uuids[i]
          const index = this.selected.findIndex((e) => e.uuid === uuid)
          if (index > -1) this.selected.splice(index, 1)
        }
      }
    },
    checkParent(group, parent_uuid) {
      return
      if (group === this.groups[1]) {
        // const apigroup0 = this.grid[this.groups[0]].api;
        // apigroup0.forEachNode(node => {
        //   if (node.data.iteracion_uuid === parent_uuid) {
        //     node.setSelected(true)
        //   }
        // })
        for (let i = 0; i < this.planificableItems.length; i++) {
          const element = this.planificableItems[i]
          if (
            element.iteracion_uuid === parent_uuid &&
            !this.selected.some((e) => e.uuid === element.uuid)
          ) {
            this.selected.push(element)
          }
        }
      }
    },
    uncheckParent(group, parent_uuid) {
      return
      if (group === this.groups[1]) {
        let childrenSelected = false
        // Revisamos si existe alguna parcela del productor seleccionada
        // const apigroup1 = this.grid[this.groups[1]].api;
        // apigroup1.forEachNode(node => {
        //   if (node.data.parent_uuid === parent_uuid && node.isSelected()) {
        //     childrenSelected = true
        //   }
        // })
        for (let i = 0; i < this.selected.length; i++) {
          const element = this.selected[i]
          if (element.parent_uuid === parent_uuid) {
            childrenSelected = true
          }
        }
        // Si no quedan parcelas del productor seleccionados lo quitamos
        if (!childrenSelected) {
          // const apigroup0 = this.grid[this.groups[0]].api;
          // apigroup0.forEachNode(node => {
          //   if (node.data.iteracion_uuid === parent_uuid) {
          //     node.setSelected(false)
          //   }
          // })
          for (let i = 0; i < this.selected.length; i++) {
            const element = this.selected[i]
            if (element.iteracion_uuid === parent_uuid) {
              const index = this.selected.findIndex((e) => e.uuid === element.uuid)
              if (index > -1) this.selected.splice(index, 1)
            }
          }
        }
      }
    },
  },
}
</script>
