<template>
  <div class="black--text">
    <p><strong>SOLICITA:</strong><strong> </strong></p>
    <p><span style="font-weight: 400;">1.- Ser evaluado para verificar la competencia t&eacute;cnica de la empresa conforme a los siguientes documentos:</span></p>
    <p><span style="font-weight: 400;">a.- Pliego de condiciones de la ETG Jam&oacute;n Serrano (98/C371/03). Acogido al Reglamento</span><strong> </strong><span style="font-weight: 400;">(CE)</span><strong> </strong><span style="font-weight: 400;">n. &ordm; 1204/2008 de la Comisi&oacute;n, de 3 de diciembre de 2008, relativo a la inscripci&oacute;n de determinadas denominaciones en el registro de especialidades tradicionales garantizadas establecido en el Reglamento (CE) n&ordm; 509/2006 del Consejo sobre las especialidades tradicionales garantizadas de los productos agr&iacute;colas y alimenticios.</span></p>
    <p><strong>DECLARA:</strong><span style="font-weight: 400;"> </span><span style="font-weight: 400;"> </span></p>
    <p><span style="font-weight: 400;">1.- Conocer y aceptar el procedimiento de certificaci&oacute;n de producto denominado PG-CER-03.</span></p>
    <p><span style="font-weight: 400;">2.- Que la veracidad de los datos contenidos en este registro queda bajo la responsabilidad de la empresa solicitante de la certificaci&oacute;n.</span></p>
    <p><strong>ACEPTA:</strong></p>
    <p><span style="font-weight: 400;">1.- Cumplir con las condiciones descritas en los procedimientos de </span><span style="font-weight: 400;">ARAPORCEI</span><span style="font-weight: 400;"> que le sean de aplicaci&oacute;n y en especial con el PG-CER 04 &ldquo;Procedimiento para el correcto Uso de la Marca de Conformidad&rdquo; as&iacute; como aquellos requisitos establecidos en los documentos normativos y si procede, en los requisitos establecidos en la legislaci&oacute;n nacional y auton&oacute;mica as&iacute; como en los procedimientos establecidos por la Entidad Nacional de Acreditaci&oacute;n.</span></p>
    <p><span style="font-weight: 400;">2.- Permitir cualquier verificaci&oacute;n que sea requerida realizar por ARAPORCEI en el marco de esta certificaci&oacute;n.</span></p>
    <p><span style="font-weight: 400;">3.- Facilitar a ARAPORCEI la informaci&oacute;n necesaria para la realizaci&oacute;n de las auditorias.</span></p>
    <p><span style="font-weight: 400;">4.- Comunicar a ARAPORCEI, en el menor plazo posible, cualquier cambio relevante que afecte o influya en el proceso de certificaci&oacute;n contratado.</span></p>
    <p><span style="font-weight: 400;">5.- Adj&uacute;ntese como Anexo I la documentaci&oacute;n que identifique la personalidad jur&iacute;dica de la empresa solicitante de la certificaci&oacute;n: Escrituras o Estatutos de Constituci&oacute;n de la Empresa o fotocopia del N.I.F, y si aplica, fotocopia del documento de inscripci&oacute;n de la marca (s) comercial (es) en el Registro de Patentes y Marcas.</span></p>
    <p><span style="font-weight: 400;">6.- Aceptar el resultado de las auditor&iacute;as realizadas.&nbsp;</span></p>
    <p><span style="font-weight: 400;">7.- Abonar los servicios contratados conforme a las condiciones establecidas en la oferta-contrato.&nbsp;</span></p>
    <p><span style="font-weight: 400;">8.- Autorizar a ARAPORCEI a comunicar los resultados de las auditor&iacute;as y la informaci&oacute;n relacionada con las mismas a las autoridades competentes en la forma que &eacute;sta establezca.</span></p>
    <p><span style="font-weight: 400;">La aceptaci&oacute;n del Contrato de prestaci&oacute;n de servicios y Oferta econ&oacute;mica para el desarrollo de las labores de certificaci&oacute;n de producto y la firma de esta Solicitud Formal de Certificaci&oacute;n tiene car&aacute;cter contractual, y supone la total aceptaci&oacute;n de los t&eacute;rminos t&eacute;cnicos y econ&oacute;micos de la propuesta y, as&iacute; como de todos los procedimientos aprobados y vigentes de ARAPORCEI que le sean de aplicaci&oacute;n a la empresa solicitante de la certificaci&oacute;n.</span></p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>