<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-form ref="formDialog" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>{{ titulo }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <SelectInstalaciones
                ref="instalaciones"
                :tipos-instalacion="[MODEL.IBERICO.TIPOS_INSTALACION.propia]"
                returnObject
                :rules="RULES.REQUIRED"
                required
                v-model="itemObjects.instalacion"
                :cliente="cliente"
                @input="updateItemObject('instalacion')">
              </SelectInstalaciones>
            </v-col>
            <v-col cols="6">
              <v-select label="Categoría" :rules="RULES.REQUIRED_MULTIPLE" required v-model="item.categoria" multiple :items="MODEL.IBERICO.CATEGORIAS"></v-select>
            </v-col>
            <v-col cols="6">
              <v-select label="Actividad" :rules="RULES.REQUIRED_MULTIPLE" required v-model="item.actividad" multiple :items="MODEL.IBERICO.ACTIVIDADES"></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                label="Actividad Sub Contratada"
                v-model="item.actividad_subcontratada"
                multiple
                :items="MODEL.IBERICO.ACTIVIDADES_SUBCONTRATADAS">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>Productos</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-combobox hint="Utilizar la tecla TAB para separar las marcas" required :rules="RULES.REQUIRED_MULTIPLE" persistent-hint v-model="item.marca" label="Marcas comerciales" multiple chips></v-combobox>
            </v-col>
            <v-col cols="6">
              <v-select label="Producto" :rules="RULES.REQUIRED_MULTIPLE" required v-model="item.producto" multiple :items="MODEL.IBERICO.PRODUCTOS_TIPO"></v-select>
            </v-col>
            <v-col cols="6">
              <v-select label="Pieza" :rules="RULES.REQUIRED_MULTIPLE" required v-model="item.pieza" multiple :items="MODEL.IBERICO.PRODUCTOS_PIEZA"></v-select>
            </v-col>
            <v-col cols="6">
              <v-select label="Racial" :rules="RULES.REQUIRED_MULTIPLE" required v-model="item.racial" multiple :items="MODEL.IBERICO.PRODUCTOS_RACIAL"></v-select>
            </v-col>
            <v-col cols="6">
              <v-select label="Alimentacion" :rules="RULES.REQUIRED_MULTIPLE" required v-model="item.alimentacion" multiple :items="MODEL.IBERICO.PRODUCTOS_ALIMENTACION"></v-select>
            </v-col>
            <v-col cols="6">
              <v-select label="Presentación" :rules="RULES.REQUIRED_MULTIPLE" required v-model="item.presentacion" multiple :items="MODEL.IBERICO.PRODUCTOS_PRESENTACION"></v-select>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="item.manejo" label="Productos procedentes del despiece de la canal para todas sus designaciones por alimentación, manjeo y tipo racial."></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close" text>Cancelar</v-btn>
          <v-btn :disabled="!valid" color="primary" @click="validate()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import customVModelMixin from '~/util/custom.vmodel.mixin'
import SelectInstalaciones from '../../../forms/SelectInstalaciones'
import MODEL from '~/models/certicar/solicitudes'
import RULES from '~/models/form.rules'

export default {
  name: 'AlcanceProductoDialog',
  components: {SelectInstalaciones},
  mixins: [customVModelMixin],
  props: {
    cliente: {
      type: Object,
      default: () => {},
    },
    titulo: {
      type: String,
      default: 'Alcance de certificacion propio',
    },
    itemSelected: {type: Object, default: null},
  },
  data: () => ({
    itemObjects: {},
    dialog: false,
    MODEL,
    RULES,
    valid: true,
    item: {
      categoria: [],
      actividad: [],
      actividad_subcontratada: [],
      pieza: [],
      racial: [],
      producto: [],
      marca: [],
      alimentacion: [],
      manejo: false,
    },
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    dialog(val) {
      if (!val) this.close()
    },

    itemSelected(val) {
      if (val) this.setItem()
    },
  },
  methods: {
    updateItemObject(object) {
      this.item[object + '_codigo'] = this.itemObjects[object].rgseaa
      this.item[object + '_nombre'] = this.itemObjects[object].razon_social
    },

    setItem() {
      if (this.itemSelected) {
        if (this.itemSelected.data) this.item = {...this.itemSelected.data}
        else this.item = {...this.itemSelected}
      }

      window.setTimeout(() => {
        if (this.$refs.instalaciones && this.item.instalacion_codigo) {
          this.$refs.instalaciones.setValueByCodigo(this.item.instalacion_codigo)
        }
      }, 1000)
    },

    async validate() {
      if (this.$refs.formDialog.validate()) {
        this.loading = true
        const data = Object.assign({}, this.item || {})

        setTimeout(() => {
          this.$emit('submit', data)
          this.close()
        }, 500)
      }
    },
    resetForm() {
      if (this.$refs.formDialog) this.$refs.formDialog.reset()
    },
    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
      this.$emit('input', false)
    },
  },
}
</script>

<style scoped>
</style>
