<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <span v-if="title">{{ title}}</span>
            <span v-else>{{ isNew ? 'Nuevo' : 'Editar' }} Alcance del Producto</span>
          </v-card-title>

          <v-card-text style="max-height: 70vh;  overflow: auto;">
            <v-container>

              <slot></slot>
              <v-row>
<!--                <v-col cols="12" v-if="!ampliacion && isProductosIbericos">-->
                <v-col cols="12" v-if="!ampliacion">
                  <SelectInstalaciones
                    ref="instalaciones"
                    label="Instalación"
                    v-model="itemObjects.instalacion"
                    :cliente="cliente"
                    :tipos-instalacion="tiposInstalacion"
                    create
                    required
                    dense
                    returnObject
                    @input="updateItemObject('instalacion')"
                  />
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" class="title black--text">
                  Productos
                </v-col>

                <v-col cols="12">
                  <v-select
                    label="Categoría de productos"
                    v-model="item.categoria_productos"
                    :items="categorias"
                    item-value="text"
                    required
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12"
                  v-if="item.categoria_productos">
                  <v-select
                    label="Tipos de líneas de producción"
                    v-model="item.lineas_produccion"
                    :items="tiposLineasProduccion"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    :multiple="!ampliacion"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Nivel SAE"
                    v-model="item.nivel_sae"
                    :items="['2','3']"
                    required
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <label>
                    Certificados de exportación emitidos (o previstos) anualmente para el alcance solicitado
                  </label>
                  <v-text-field
                    v-model="item.certificados_emitidos"
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <label>
                    Número de partidas suministradas (o previstas) a otros establecimientos en calidad de proveedor anualmente para el alcance solicitado (Nº DACs Emitidos)
                  </label>
                  <v-text-field
                    v-model="item.partidas_suministradas"
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <label>
                    Número de fichas de especificación en vigor
                  </label>
                  <v-text-field
                    v-model="item.fichas_especificacion"
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <label>
                    País
                  </label>
                  <v-text-field
                    v-model="item.pais"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <label>
                    Nº ASE
                  </label>
                  <v-text-field
                    v-model="item.n_ase"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <label>
                    Producto nombre comercial
                  </label>
                  <v-text-field
                    v-model="item.producto_nombre_comercial"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\solicitudes\certicalidad\AlcanceProducto\AlcanceProductoDialogSAE.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 16th September 2022
 * Last Modified: Fri Mar 24 2023
 **/

import SelectInstalaciones from '~/components/forms/SelectInstalaciones'
import SelectActividades from '~/components/forms/SelectActividades'
import MODELS from '~/models/certicalidad/solicitudes'
import CATEGORIAS from '~/models/certicalidad/categoriasProductos'

export default {
  name: 'AlcanceProductoDialog',
  props: {
    value: Boolean,
    valid: { type: Boolean, default: true },
    title: { type: String, default: '' },
    cliente: { type: Object, default: null },
    ampliacion: { type: Boolean, default: false },
    isOperadorResposable: { type: Boolean, default: false },
    isProductosIbericos: { type: Boolean, default: false },
    isETG: { type: Boolean, default: false },
    producto: { type: Object, default: null },
    itemSelected: { type: Object, default: null },
    db: { type: Object, default: {} },
  },
  components: {
    SelectInstalaciones,
    SelectActividades,
  },
  data: () => ({
    tiposInstalacion: [MODELS.TIPOS_INSTALACION.propia],
    itemObjects: {},
    dialog: false,
    dialogInstalaciones: false,
    item: {
      certificados_emitidos: 0,
      partidas_suministradas: 0,
      fichas_especificacion: 0,
    },
    rules: [(v) => !!v || 'El campo es obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'El campo es obligatorio'],

    tiposAlimentacion: MODELS.ALIMENTACION_TIPOS,
    tiposProducto: MODELS.PRODUCTO_TIPOS,
    tiposProductoETG: MODELS.PRODUCTO_TIPOS_ETG,
    tiposRacial: MODELS.RACIAL_TIPOS,
    tiposEstado: MODELS.ESTADOS_TIPOS,
    tiposPresentacion: MODELS.PRESENTACION_TIPOS,
    tiposPresentacionETG: MODELS.PRESENTACION_TIPOS_ETG,
    tiposCertificacion: MODELS.CERTIFICACION_TIPOS,
    actividadesETG: MODELS.ACTIVIDADES_ETG,

    ui: {
      search: '',
    },
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    dialog(val) {
      if (!val) this.close()
    },
    producto(val) {
      if (val) this.getOne()
      else this.resetForm()
    },

    itemSelected(val) {
      if (val) this.setItem()
    },
  },
  computed: {
    isNew() {
      return this.producto === null
    },

    categorias() {
      return CATEGORIAS.ARRAY
    },

    productos() {
      if (this.isProductosIbericos) return this.tiposProducto
      if (this.isETG) return this.tiposProductoETG
      return []
    },

    presentaciones() {
      if (this.isProductosIbericos) return this.tiposPresentacion
      if (this.isETG) return this.tiposPresentacionETG
      return []
    },

    tiposLineasProduccion() {
      let lineas = this.db?.SAE_lineas
        ? this.db.SAE_lineas
        : {
            'CARNE': ['Canales, carnes frescas, despojos y preparados de carne'],
            'PRODUCTOS CÁRNICOS': [
              'Productos cárnicos sin tratamiento térmico',
              'Productos cárnicos con tratamiento térmico',
            ],
            'PRODUCTOS DE LA PESCA': ['No preparados', 'Preparados'],
            'PRODUCTOS DE LA PESCA TRANSFORMADOS': [
              'Pescado, con o sin otros ingredientes, cocidos esterilizados, pasteurizados , en salazón y otros tratamientos',
            ],
            'PRODUCTOS LÁCTEOS': [
              'Leche y nata sin concentrar, y batidos y otras bebidas a base de leche',
              'Leche y nata concentradas y otros productos lácteos en polvo',
              'Leche y nata cuajadas, Yogur y demás leches, Natas fermenadas y postres lácteos',
              'Lactosuero y productos constituidos por componentes naturales de la leche',
              'Mantequilla y demás materias grasas de la leche',
              'Quesos y Requesón',
            ],
            'HUEVOS Y OVOPRODUCTOS': ['Huevo', 'Huevo líquido', 'Ovoproductos'],
            'PRODUCTOS COMPUESTOS': [
              'Productos alimenticios destinados al consumo humano que contengan productos transformados de origen animal y productos de origen vegetal',
            ],
            'OTROS PRODUCTOS DE ORIGEN ANIMAL DESTINADOS AL CONSUMO HUMANO': [
              'Miel',
              'Hemoderivados',
              'Otros',
            ],
          }
      if (lineas && this.item?.categoria_productos) return lineas[this.item.categoria_productos]
      return []
    },

    actividadesSubcontratadasETG() {
      return this.isETG ? this.actividadesETG.filter((e) => !e.responsableOnly) : []
    },
  },
  methods: {
    updateItemObject(object) {
      //console.log('updateItemObject', object,this.itemObjects)
      //if (object !== 'ampliacion' || object !== 'instalacion'){
      console.log('here')
      this.item[object + '_codigo'] = this.itemObjects[object].rgseaa
      this.item[object + '_nombre'] = this.itemObjects[object].razon_social
      //}
    },

    setItem() {
      if (this.itemSelected) {
        if (this.itemSelected.data) this.item = { ...this.itemSelected.data }
        else this.item = { ...this.itemSelected }
        if (this.isString(this.item.marcas)) {
          console.log('marcas is string')
          this.item.marcas = [this.item.marcas]
        }
        console.log('setItem', this.item)
      }

      window.setTimeout(() => {
        if (this.$refs.instalaciones && this.item.instalacion_codigo) {
          this.$refs.instalaciones.setValueByCodigo(this.item.instalacion_codigo)
        }
      }, 3000)
    },

    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    formatDateBack(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },
    async getOne() {
      console.log('getOne', this.producto)
      this.item = this.producto
      console.log('getOne', this.item)
      if (this.item.marcas == null) this.item.marcas = []
      if (this.isString(this.item.marcas)) {
        console.log('marcas is string')
        this.item.marcas = [this.item.marcas]
      }
    },
    async submit() {
      this.$emit('validate')
      if (this.$refs.form.validate() && this.valid) {
        // this.loading = true
        setTimeout(() => {
          this.$emit('submit', this.item)
          this.close()
        }, 500)
      }
    },

    isString(inputText) {
      if (typeof inputText === 'string' || inputText instanceof String) {
        //it is string
        return true
      } else {
        //it is not string
        return false
      }
    },

    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
    // Filters
    filterTiposEstado(item) {
      const isCarnesFrescas = item.producto === 'carnes_frescas'
      return isCarnesFrescas
        ? this.tiposEstado.filter((element) => element.value === 'fresco')
        : this.tiposEstado
    },
    filterTiposPresentacion(item) {
      const isCarnesFrescas = item.producto === 'carnes_frescas'
      const tiposPresentacion = [
        { text: 'Pieza entera', value: 'entera' },
        { text: 'Troceado', value: 'troceado' },
        { text: 'Fileteado', value: 'fileteado' },
      ]
      return isCarnesFrescas ? tiposPresentacion : this.tiposPresentacion
    },
  },
}
</script>
