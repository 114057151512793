<template>
  <v-autocomplete
    ref="grupo_alcance"
    label="Grupo de alcance"
    v-model="selected"
    :items="items"
    item-text="nombre"
    item-value="uuid"
    :required="required"
    :rules="rules"
    :clearable="clearable"
    :disabled="disabled"
    :readonly="readonly"
    :loading="loading"
    :dense="dense"
    :multiple="multiple"
    cache-items
    return-object
    @change="emitChange()"
  ></v-autocomplete>
</template>

<script>
  export default {
    name: "SelectGruposAlcancesObject",
    props: {
      value: [String, Object],
      clearable: { type: Boolean, default: false },
      readonly: { type: Boolean, default: false },
      required: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      dense: { type: Boolean, default: false },
      multiple: { type: Boolean, default: false }
    },
    async mounted() {
      if (this.required) {
        this.rules = [v => !!v || 'Obligatorio']
      }
      this.loading = true
      const res = await this.$store.dispatch('grupos_alcances/list')
      if (res) this.items = res
      this.loading = false
    },
    data(ctx) {
      return {
        rules: [],
        selected: ctx.value,
        items: [],
        loading: false
      };
    },
    watch: {
      value: function (val) {
        if (!val || val === '') this.$refs.grupo_alcance.reset()
        this.selected = val
      }
    },
    methods: {
      emitChange() {
        this.$emit('input', this.selected)
        this.$emit('change', this.selected)
      }
    }
  }
</script>

<style scoped>
</style>
