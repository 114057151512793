const middleware = {}

middleware['checkIsNew'] = require('../middleware/checkIsNew.js')
middleware['checkIsNew'] = middleware['checkIsNew'].default || middleware['checkIsNew']

middleware['controlMenuRoles'] = require('../middleware/controlMenuRoles.js')
middleware['controlMenuRoles'] = middleware['controlMenuRoles'].default || middleware['controlMenuRoles']

middleware['notificaciones'] = require('../middleware/notificaciones.js')
middleware['notificaciones'] = middleware['notificaciones'].default || middleware['notificaciones']

middleware['oneSignal'] = require('../middleware/oneSignal.js')
middleware['oneSignal'] = middleware['oneSignal'].default || middleware['oneSignal']

middleware['public'] = require('../middleware/public.js')
middleware['public'] = middleware['public'].default || middleware['public']

export default middleware
