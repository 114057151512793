/**
 * @project: certiapp-nuxt
 * @file:    plantillas-bloques.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st July 2021
 * Last Modified: Fri Dec 30 2022
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // get()
  // Created on Mon Jul 05 2021
  //+-------------------------------------------------
  async get({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.put(`bloques?ref=${params.modulo_ref}`, {
      modulo: params.modulo,
      modulo_ref: params.modulo_ref,
    })

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // payload:
  // -----
  // Created on Mon Jul 05 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true
    await this.$axios.post(`bloques`, {
      uuid: params.uuid || uuid.v4(),
      plantilla: params.plantilla,
      titulo: params.titulo,
      codigo: params.codigo,
      orden: params.orden,
      alcance: params.alcance.uuid,
      modulo: params.modulo,
      modulo_ref: params.modulo_ref,
      submodulo_ref: params.submodulo_ref,
      tipo: params.tipo,
      content_html: params.content_html,
      content_table: params.content_table,
    })
    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Tue Jul 06 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    payload.bloque = payload.uuid

    const xhr = await this.$axios.patch(`bloques/${params.uuid}`, payload)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Wed Jul 07 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`bloques/${item.uuid}`)
    rootState.loading = false
  },
}
