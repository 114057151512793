export const state = () => ({
  list: [],
  item: {}
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return this.list;
  },
  SET_LIST(state, items) {
    this.list = items;
  }
};


//dispatch actions
export const actions = {

  async list({commit}) {
    const res = await this.$axios.get("empresas");
    if (res) {
      commit('SET_LIST', res.data);
      return res.data
    }
  }

};
