<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <span v-if="title">{{ title}}</span>
            <span v-else>{{ isNew ? 'Nuevo' : 'Editar' }} Alcance del Producto</span>
          </v-card-title>

          <v-card-text style="max-height: 70vh;  overflow: auto;">
            <v-container>

              <slot></slot>
              <v-row>
<!--                <v-col cols="12" v-if="!ampliacion && isProductosIbericos">-->
                <v-col cols="12" v-if="!ampliacion">
                  <SelectInstalaciones
                    ref="instalaciones"
                    label="Instalación"
                    v-model="itemObjects.instalacion"
                    :cliente="cliente"
                    :tipos-instalacion="tiposInstalacion"
                    create
                    required
                    dense
                    returnObject
                    @input="updateItemObject('instalacion')"
                  />
                </v-col>

                <v-col cols="6">
                  <SelectActividades
                    label="Actividades Realizadas"
                    v-model="item.actividades_realizadas"
                    :actividades="actividadesRealizadas"
                    required
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="6">
                  <SelectActividades
                    label="Actividades Subcontratadas"
                    v-model="item.actividades_subcontratadas"
                    :actividades="actividadesSubcontratadas"
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Certificación"
                    v-model="item.certificacion"
                    :items="tiposCertificacion"
                    item-value="text"
                    :rules="item.subcontratadas && item.subcontratadas.length ? rules : []"
                    :required="item.subcontratadas && item.subcontratadas.length"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-combobox
                    v-model="item.marcas"
                    :items="item.marcas || []"
                    :search-input.sync="ui.search"
                    hide-selected
                    label="Marcas"
                    dense
                    multiple
                    small-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item v-if="ui.search && ui.search.length > 0">
                        <v-list-item-content>
                          <v-list-item-title>
                            Pulsa <kbd>enter</kbd> para introducir una marca
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>



                <v-col cols="12" class="title black--text">
                  Productos
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Producto"
                    v-model="item.producto"
                    :items="productos"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    :multiple="!ampliacion"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Presentación"
                    v-model="item.presentacion"
                    :items="presentaciones"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    :multiple="!ampliacion"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" class="title black--text">
                  Volumen
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Jamones Serranos elaborados en las instalaciones"
                    v-model="item.jamones_elaborados"
                    type="number"
                    dense
                    @blur="setTotalJamones"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Jamones Serranos adquiridos a terceros y comercializados en las instalaciones"
                    v-model="item.jamones_adquiridos"
                    type="number"
                    dense
                    @blur="setTotalJamones"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Total Jamones Serranos"
                    v-model="item.total_jamones"
                    persistent-hint
                    type="number"
                    dense
                    @blur="setTotalJamones"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\solicitudes\certicalidad\AlcanceProducto\AlcanceProductoDialogETG.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 15th December 2022
 * Last Modified: Mon Dec 19 2022
 **/

import MODELS from '~/models/certicalidad/solicitudes'

import SelectInstalaciones from '~/components/forms/SelectInstalaciones'
import SelectActividades from '~/components/forms/SelectActividades'

export default {
  name: 'AlcanceProductoDialog',
  props: {
    value: Boolean,
    valid: { type: Boolean, default: true },
    title: { type: String, default: '' },
    cliente: { type: Object, default: null },
    ampliacion: { type: Boolean, default: false },
    isOperadorResposable: { type: Boolean, default: false },
    producto: { type: Object, default: null },
    itemSelected: { type: Object, default: null },
  },
  components: {
    SelectInstalaciones,
    SelectActividades,
  },

  data: () => ({
    tiposInstalacion: [MODELS.TIPOS_INSTALACION.propia],
    itemObjects: {},
    dialog: false,
    dialogInstalaciones: false,
    item: {
      producto: ['Jamón Serrano'],

      jamones_elaborados: 0,
      jamones_adquiridos: 0,
      total_jamones: 0,
    },
    rules: [(v) => !!v || 'El campo es obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'El campo es obligatorio'],
    tiposAlimentacion: MODELS.ALIMENTACION_TIPOS,
    tiposProducto: MODELS.PRODUCTO_TIPOS_ETG,
    tiposRacial: MODELS.RACIAL_TIPOS,
    tiposEstado: MODELS.ESTADOS_TIPOS,
    tiposPresentacion: MODELS.PRESENTACION_TIPOS_ETG,
    tiposCertificacion: MODELS.CERTIFICACION_TIPOS,
    actividades: MODELS.ACTIVIDADES_ETG2,

    ui: {
      search: '',
    },
  }),

  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    dialog(val) {
      if (val) this.formatForm()
      if (!val) this.close()
    },
    producto(val) {
      if (val) this.getOne()
      else this.resetForm()
    },

    itemSelected(val) {
      if (val) this.setItem()
    },
  },

  computed: {
    isNew() {
      return this.producto === null
    },

    productos() {
      return this.tiposProducto
    },

    presentaciones() {
      return this.tiposPresentacion
    },

    actividadesRealizadas() {
      return this.actividades
    },

    actividadesSubcontratadas() {
      return this.actividades
    },
  },
  methods: {
    setTotalJamones() {
      let total = 0

      // this.item.jamones_elaborados = this.item.jamones_elaborados.replace(/^0+/, '')
      this.item.jamones_elaborados = parseInt(this.item.jamones_elaborados)

      // this.item.jamones_adquiridos = this.item.jamones_adquiridos.replace(/^0+/, '')
      this.item.jamones_adquiridos = parseInt(this.item.jamones_adquiridos)

      total = this.item.jamones_elaborados + this.item.jamones_adquiridos
      this.item.total_jamones = total

      this.$forceUpdate()
      console.warn(total, this.item.jamones_elaborados, this.item.jamones_adquiridos)
      return total
    },

    updateItemObject(object) {
      //console.log('updateItemObject', object,this.itemObjects)
      //if (object !== 'ampliacion' || object !== 'instalacion'){
      this.item[object + '_codigo'] = this.itemObjects[object].rgseaa
      this.item[object + '_nombre'] = this.itemObjects[object].razon_social
      //}
    },

    setItem() {
      if (this.itemSelected) {
        if (this.itemSelected.data) this.item = { ...this.itemSelected.data }
        else this.item = { ...this.itemSelected }
        if (this.isString(this.item.marcas)) {
          this.item.marcas = [this.item.marcas]
        }
        console.log('setItem', this.item)
      }

      window.setTimeout(() => {
        if (this.$refs.instalaciones && this.item.instalacion_codigo) {
          this.$refs.instalaciones.setValueByCodigo(this.item.instalacion_codigo)
        }
      }, 3000)
    },

    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },

    async getOne() {
      console.log('getOne', this.producto)
      this.item = this.producto
      console.log('getOne', this.item)
      if (this.item.marcas == null) this.item.marcas = []
      if (this.isString(this.item.marcas)) {
        console.log('marcas is string')
        this.item.marcas = [this.item.marcas]
      }
    },

    async submit() {
      this.$emit('validate')
      if (this.$refs.form.validate() && this.valid) {
        setTimeout(() => {
          this.$emit('submit', this.item)
          this.close()
        }, 500)
      }
    },

    isString(inputText) {
      if (typeof inputText === 'string' || inputText instanceof String) {
        //it is string
        return true
      } else {
        //it is not string
        return false
      }
    },

    // async submit() {
    //   if (this.$refs.form.validate()) {
    //     const body = {
    //       cliente_uuid: this.cliente ? this.cliente : this.$route.uuid,
    //       instalacion: {
    //         ...this.item
    //       }
    //     }
    //     body.instalacion.tramites = this.item.tramites.map(element => element.uuid)
    //     if (this.isNew) {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/create', body)
    //     } else {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/update', body)
    //     }
    //     if (!this.isNewCliente) this.$emit('init')
    //     this.close()
    //   }
    // },

    //+-------------------------------------------------
    // formatForm()
    // Sets initial values
    // -----
    // Created on Thu Dec 15 2022
    //+-------------------------------------------------
    formatForm() {
      if (!this.item.jamones_elaborados) this.item.jamones_elaborados = 0
      if (!this.item.jamones_adquiridos) this.item.jamones_adquiridos = 0
      if (!this.item.total_jamones) this.item.total_jamones = 0
    },

    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
  },
}
</script>
