<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-form ref="form" lazy-validation v-model="valid">
        <v-card>
          <v-card-title>
            {{ isNew ? 'Nuevo' : 'Editar' }} Cultivo
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <SelectLocalidad
                    outlined
                    ref="localidad"
                    v-model="itemObjects.localidad"
                    :codigo="item.localidad_codigo || ''"
                    :returnObject="true"
                    @change="updateLocalidaItem(itemObjects.localidad)"
                  />
                </v-col>

                <v-divider></v-divider>

                <v-col cols="6">
                  <select-metadata
                    ref="clasificacion"
                    label="Calificacion"
                    dense="dense"
                    v-model="itemObjects.clasificacion"
                    :codigo="item.clasificacion_codigo || ''"
                    tipo='calificaciones'
                    familia='produccion_vegetal'
                    @change="updateItemObject('clasificacion')"
                  ></select-metadata>
                </v-col>

                <v-col cols="6" class="pt-0">
                  <v-menu
                    v-model="menuFechaPrimerRegistro"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="mt-0"
                        prepend-icon="mdi-calendar"
                        :value="item.datePicker"
                        label="Primer registro"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.datePicker"
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Polígono"
                    v-model="item.polg"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Parcela"
                    v-model="item.parcela"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Recinto"
                    v-model="item.recinto"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Superficie (ha)"
                    v-model.number="item.superficie"
                    type="number"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <select-metadata
                    ref="cultivos"
                    label="cultivos"
                    dense="dense"
                    v-model="itemObjects.cultivo"
                    :codigo="item.cultivo_codigo || ''"
                    tipo='cultivos'
                    familia='produccion_vegetal'
                    @change="updateItemObject('cultivo')"
                  ></select-metadata>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Variedad"
                    v-model="item.variedad"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-select
                      label="Secano-Regadío"
                      v-model.number="item.secano_regadio"
                      :items="TIPOS_SECANO_REGADIO"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                  v-model="item.observaciones"
                  label="Observaciones"
                  rows="2"
                  auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" :disabled="!valid || loading" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\solicitudes\oce-global\ProductosEcologicos\ProduccionVegetalDialog.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon Oct 17 2022
 **/

import MODELS from '~/models/oceglobal/solicitudes'
import SelectEstaticos from '~/components/forms/SelectEstaticos'
import SelectProvincia from '~/components/forms/SelectProvincia'
import SelectMetadata from '../../../forms/selectMetadata'
import SelectLocalidad from '~/components/forms/SelectLocalidades'

export default {
  name: 'ProduccionVegetalDialog',
  props: {
    value: Boolean,
    itemSelected: { type: Object, default: null },
    alcance: { type: Object, default: () => {} },
  },
  components: {
    SelectMetadata,
    SelectProvincia,
    SelectEstaticos,
    SelectLocalidad,
  },
  data: () => ({
    menuFechaPrimerRegistro: false,
    valid: false,
    dialog: false,
    itemObjects: {},
    item: {
      datePicker: new Date().toISOString().substr(0, 10),
    },
    rules: [(v) => !!v || 'El campo es obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'El campo es obligatorio'],
    rulesWithZero: [
      (v) => {
        if (v == undefined || v.length == 0) {
          return 'El campo es obligatorio.'
        }
        if (v < 0) {
          return 'Valor incorrecto'
        }
        return true
      },
    ],
    TIPOS_CULTIVO: MODELS.TIPOS_CULTIVO,
    TIPOS_SECANO_REGADIO: MODELS.TIPOS_SECANO_REGADIO,
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    dialog(val) {
      if (!val) this.close()
    },
    itemSelected(val) {
      val ? this.getOne() : this.resetForm()
    },
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      },
    },
    isNew() {
      return this.itemSelected === null
    },
    // isNewCliente() {
    //   return this.$route.params.uuid === '_'
    // },
  },
  methods: {
    updateItemObject(object) {
      this.item[object + '_codigo'] = this.itemObjects[object].codigo
      this.item[object + '_nombre'] = this.itemObjects[object].nombre
    },
    updateLocalidaItem(localidad) {
      this.item['localidad_codigo'] = localidad.codigo
      this.item['provincia_codigo'] = localidad.provincia.codigo
      this.item['provincia_nombre'] = localidad.provincia.nombre
      this.item['municipio_codigo'] = localidad.municipio.codigo
      this.item['municipio_nombre'] = localidad.municipio.nombre
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },

    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },

    async getOne() {
      const itemSelected = this.itemSelected

      if (itemSelected.fecha_alta)
        itemSelected.fecha_alta = this.$moment(itemSelected.fecha_alta).format('YYYY-MM-DD')

      this.item = Object.assign({}, itemSelected)

      // date is cloned and formatted to the v-date-picker format
      if (itemSelected.fecha_primer_registro) {
        this.item.datePicker = this.$moment(itemSelected.fecha_primer_registro, [
          'DD-MM-YYYY',
          'MM-DD-YYYY',
        ]).format('YYYY-MM-DD')
      }

      console.log('getOne -> itemSelected', itemSelected)
    },

    async submit() {
      if (this.$refs.form.validate()) {
        const action = this.isNew ? 'add' : 'edit'

        // Return the picker to the original field and format
        this.item.fecha_primer_registro = this.$moment(this.item.datePicker).format('DD-MM-YYYY')

        this.$emit(action, this.item)
        this.close()
      }
    },
    // async submit() {
    //   if (this.$refs.form.validate()) {
    //     const body = {
    //       cliente_uuid: this.cliente ? this.cliente : this.$route.params.uuid,
    //       instalacion: {
    //         ...this.item
    //       }
    //     }
    //     body.instalacion.tramites = this.item.tramites.map(element => element.uuid)
    //     if (this.isNew) {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/create', body)
    //     } else {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/update', body)
    //     }
    //     if (!this.isNewCliente) this.$emit('init')
    //     this.close()
    //   }
    // },
    resetForm() {
      this.item = {}
      this.itemObjects = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
  },
}
</script>
