var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v("\n      Certificación\n      "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary","disabled":!_vm.items.length || _vm.auditoriaCerrada},on:{"click":_vm.newItem}},[_vm._v("\n        Añadir ampliación\n      ")])],1),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12"}},[_c('Datatable',{key:_vm.component,attrs:{"showHeader":false,"headers":_vm.headers,"items":_vm.items,"disableClickRow":false,"disableHover":true,"emitClickRow":true},on:{"click-row":_vm.editItem},scopedSlots:_vm._u([{key:"item.certificado",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.updateSiteCertificado(item)}},model:{value:(item.certificado),callback:function ($$v) {_vm.$set(item, "certificado", $$v)},expression:"item.certificado"}})]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.tipo))])]}},{key:"item.emplazamiento",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.uuid}},[_vm._v("\n                  "+_vm._s(item.parent_uuid
                      ? _vm.sitesObject[item.parent_uuid] && _vm.sitesObject[item.parent_uuid].nombre
                      : _vm.sitesObject[item.uuid] && _vm.sitesObject[item.uuid].nombre)+"\n                ")])]}},{key:"item.actividad",fn:function(ref){
                      var item = ref.item;
return [(!_vm.offlineMode)?_c('div',[_vm._v(_vm._s(item.nombre))]):_c('v-edit-dialog',{ref:'siteNombre' + item.uuid,attrs:{"return-value":item.nombre},on:{"update:returnValue":function($event){return _vm.$set(item, "nombre", $event)},"update:return-value":function($event){return _vm.$set(item, "nombre", $event)},"save":function($event){return _vm.updatSiteNombre(item)},"open":function($event){return _vm.updatSiteNombre(item, true)},"close":function($event){_vm.oldSiteNombre = ''}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"single-line":""},model:{value:(item.nombre),callback:function ($$v) {_vm.$set(item, "nombre", $$v)},expression:"item.nombre"}})]},proxy:true}],null,true)},[_vm._v("\n                "+_vm._s(item.nombre)+"\n                ")])]}}])})],1)],1)],1)],1),_vm._v(" "),_c('Ampliacion',{attrs:{"alcance":_vm.alcance,"planificaciones":_vm.planificaciones,"parentSites":_vm.parentSites,"childrenSites":_vm.childrenSites,"itemSelected":_vm.itemSelected},on:{"close":function($event){_vm.dialog = false},"init":function($event){return _vm.$emit('init')}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }