<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-form ref="form" lazy-validation v-model="valid">
        <v-card>
          <v-card-title>
            {{ isNew ? 'Nuevo' : 'Editar' }} Producto
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>

                <v-col cols="12">
                  <v-text-field
                      label="Razón social de la empresa"
                      v-model="item.razon_social"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      label="CIF"
                      v-model="item.cif"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      label="Número de registro sanitario"
                      v-model="item.numero_registro_sanitario"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      label="Dirección Localidad Provincia Teléfono Fax"
                      v-model="item.direccion_localidad_provincia_telefono_fax"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      label="Actividades realizadas en la instalación bajo el registro sanitario"
                      v-model="item.registro_sanitario"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      label="Tipo de producto etiquetado con Nº GGN/Logotipo de la etiqueta GGN"
                      v-model="item.ggn"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      label="Producción del producto Toneladas/año (puede estar expresado en peso vivo de animales bajo la certificación)"
                      v-model="item.produccion_toneladas"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      label="(*) Sistema de inocuidad alimentaria implantado"
                      v-model="item.sistema_inocuidad"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      label="Existe Producción Paralela SI/NO"
                      v-model="item.produccion_paralela"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      label="(**) Subcontratistas que intervienen en algún proceso"
                      v-model="item.subcontratistas"
                      :rules="rules"
                      required
                      dense
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" :disabled="!valid || loading" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import MODELS from "~/models/ccl/solicitudes"
import moment from 'moment'

export default {
  name: "ProductoresDialog",
  props: {
    value: Boolean,
    itemSelected: { type: Object, default: null }
  },
  data: () => ({
    valid: false,
    dialog: false,
    item: {},
    rules: [ v => !!v || 'El campo es obligatorio' ],
    rulesMultiple: [ v => !!v && v.length > 0 || 'El campo es obligatorio' ],
    tiposVolumenProduccion: MODELS.VOLUMEN_PRODUCCION_TIPOS,
    tiposActividadAsociada: MODELS.ACTIVIDAD_ASOCIADA_TIPOS,
  }),
  watch: {
    value (val) {
      this.dialog = val;
      if (!val) this.resetForm();
    },
    dialog (val) {
      if (!val) this.close()
    },
    itemSelected (val) {
      val ? this.getOne() : this.resetForm()
    }
  },
  computed: {
    loading: {
      get () {
        return this.$store.state.loading
      },
      set (val) {
        this.$store.commit('LOADING', val)
      }
    },
    isNew () {
      return this.itemSelected === null;
    },
    // isNewCliente() {
    //   return this.$route.params.uuid === '_'
    // },
  },
  methods: {
    formatDate (date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },
    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },
    async getOne() {
      this.item = Object.assign({}, this.itemSelected)
      console.log("getOne -> this.itemSelected", this.itemSelected)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const body = {
          ...this.item
        }
        const action = this.isNew ? 'add' : 'edit'
        this.$emit(action, this.item)
        this.close()
      }
    },
    // async submit() {
    //   if (this.$refs.form.validate()) {
    //     const body = {
    //       cliente_uuid: this.cliente ? this.cliente : this.$route.params.uuid,
    //       instalacion: {
    //         ...this.item
    //       }
    //     }
    //     body.instalacion.tramites = this.item.tramites.map(element => element.uuid)
    //     if (this.isNew) {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/create', body)
    //     } else {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/update', body)
    //     }
    //     if (!this.isNewCliente) this.$emit('init')
    //     this.close()
    //   }
    // },
    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    }
  }
};
</script>
