<template>
  <div>
    <!-- HEADER -->
    <!-- <header-component noAction v-if="!cliente && !expediente"/> -->

    <v-row class="align-center" v-if="!cliente && !expediente">
      <v-col cols="6">
        <h1>Auditorías</h1>
      </v-col>

      <v-col cols="6" style="text-align: right">
        <v-btn v-if="ui.showOffline" depressed outlined @click="dropCache" color="purple" class="mr-1">
          <v-icon small class="pr-2">mdi-water-remove</v-icon>
          Vaciar
        </v-btn>

        <v-btn depressed outlined @click="ui.showOffline = !ui.showOffline; refreshRows(true)"
          :color="(ui.showOffline) ? 'success' : ''">
          <v-icon small class="pr-2">mdi-lightning-bolt-circle</v-icon>
          <template v-if="ui.showOffline">
            Estoy preparado
          </template>
          <template v-else>
            Prepararse para ir offline
          </template>
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="isOffline">
        <v-alert dark icon="mdi-wifi-strength-alert-outline">
          Durante el modo sin conexión únicamente se muestran las auditorías que hayan sido precargadas en este
          dispositivo.
        </v-alert>

        <v-alert dark icon="mdi-alert-box-outline">
          Es importante que no refresque la aplicación mientras se encuentra sin conexión o podría quedarse bloqueado.<br>
          También es importante que el dispositivo no entre en modo reposo mientras se encuentra sin conexión.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-show="ui.showOffline" class="my-5">
      <v-col>
        <offlineSelector ref="offline" nope-component="auditoria" :enabled="ui.showOffline" :onInit="['auditorias/list']"
          :onInitBody="[{}]" :cols="[
            { text: 'Estado', value: 'estado' },
            { text: 'Desde', value: 'desde_f' },
            { text: 'hasta', value: 'hasta_f' },
            { text: 'Alcance', value: 'nombre_alcance' },
            { text: 'Cliente', value: 'nombreApellidos' },
            { text: 'Emplazamiento', value: 'emplazamiento' },
          ]" :items="allowedToOffline" :howToLoad="[
  {
    'action': 'clearCache',
    'key': 'auditorias/uuid',
  },
  {
    'action': 'lockAuditoria'
  },
  {
    'component': 'auditoria',
    'uuid': 'uuid'
  },
  {
    'request': 'checklists/list',
  },
  {
    'component': 'checklist',
    'uuid': 'uuid',
    'loop': true,
  },
  {
    'request': 'tomas_muestra/list',
    'uuid': 'itemUUID', // itemUUID auditoria.uuid
  },
  {
    'component': 'muestras',
    'uuid': 'uuid',
    'loop': true,
  },
  {
    'action': 'downloadClientFiles',
    'uuid': 'cliente.uuid',
  }]" />
      </v-col>
    </v-row>

    <!-- LISTADO -->
    <v-row v-show="!ui.showOffline">
      <v-col cols="12">
        <ag-datatable :key="ui.refresh" title="Listado" :headers="headers" :items="items" emitClickRow
          @click-row="clickRow" urlData="auditorias" @gridApi="gridApi = $event">
          <template slot="title">
            <v-switch label="Cabeceras Fijadas" v-model="headersPinned" class="mt-0 ml-4" hide-details
              @change="gridApi && gridApi.refreshCells()"></v-switch>
          </template>
        </ag-datatable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Estados from '~/models/estados'
import AgDatatable from '~/components/AgDatatableOld'
import HeaderComponent from '~/components/HeaderComponent'
import offlineSelector from '~/components/offline/selectorTable'

export default {
  props: {
    iteracion: {
      type: Object,
      default: () => ({}),
    },
    cliente: { type: String, default: null },
    expediente: { type: String, default: null },
  },
  components: {
    AgDatatable,
    HeaderComponent,
    offlineSelector,
  },

  data: () => ({
    headersPinned: true,
    gridApi: null,

    items: [],
    db: { items: [] },
    tipos_auditorias: {},

    auth: {},

    ui: {
      retry: 0,
      refresh: 0,
      showOffline: false,
    },
  }),

  watch: {
    iteracion: {
      handler(newVal, oldVal) {
        if (newVal.uuid !== oldVal.uuid) {
          this.filterIteracion()
        }
      },
      deep: true,
    },

    isOffline: function (weAreOffline) {
      this.filterOffline(weAreOffline)
    },
  },

  computed: {
    isOffline: function () {
      return this.$nuxt.isOffline
    },

    isCliente() {
      return this.$store.getters.isCliente
    },

    headers() {
      let headers = [
        {
          headerName: '---',
          width: 100,
          resizable: true,
          field: 'blockAndOffline',
          // cellRendererFramework: 'AlcanceRenderer',
          cellRenderer: (params) => {
            let content = ''
            const item = params.data
            if (item.cached || item.cached == true) {
              content += `<i
              title="Auditoria preparada para offline"
              class="v-icon mr-1 mdi mdi-lightning-bolt-circle success--text theme--light"
              style="font-size: 17px;"></i>`
            }

            // if (item.cached == 'conflicted') {
            //   content += `<span class="v-badge v-badge--dot theme--dark mr-2">
            //   <i title="La copia en cache tiene conflictos con la nube"
            //   class="v-icon mr-1 mdi mdi-cloud-question warning--text theme--light"
            //   style="font-size: 17px;"></i>
            //   <span class="v-badge__wrapper"><span class="v-badge__badge pink" style="inset: auto auto calc(100% - 7px) calc(100% - 6px);">
            //   </span></span></span>`
            // }

            if (item.bloqueada || item.bloqueada_at) {
              content += `<i
              title="Auditoria bloqueada"
              class="v-icon mr-1 mdi mdi-lock warning--text theme--dark"
              style="font-size: 17px;"></i>`
            }

            return content
          },
        },

        {
          headerName: 'Alcance',
          field: 'nombre_alcance',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          cellRendererFramework: 'AlcanceRenderer',
        },
        {
          headerName: 'Cliente',
          field: 'contacto.nombre',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            if (params.data) {
              const item = params.data.contacto;
              if (item) {
                let nombre = item.nombre;
                if (item.apellido1) nombre += ' ' + item.apellido1;
                if (item.apellido2) nombre += ' ' + item.apellido2;
                if (item.primer_apellido) nombre += ' ' + item.primer_apellido;
                if (item.segundo_apellido) nombre += ' ' + item.segundo_apellido;
                if (item.apellidos) nombre += ' ' + item.apellidos;
                return nombre;
              } else return params.data.nombre_cliente_completo ?? params.data.nombre_cliente;
            }
          },
          cellRenderer: (params) => {
            if (params.data.is_grupo_empresa) return `<strong>${params.value}</strong>`
            return params.value
          },
        },
        {
          headerName: 'Estado',
          field: 'estado',
          pinned: this.headersPinned ? 'right' : null,
          width: 125,
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          cellStyle: function (params) {
            if (params.value === Estados.AUDITORIA_ESTADO.PREVISTA) return { color: '#FB8C00' }
            else if (params.value === Estados.AUDITORIA_ESTADO.ABIERTA) return { color: '#2196F3' }
            else if (params.value === 'REALIZADA') return { color: '#EF5350' }
            else if (params.value === Estados.AUDITORIA_ESTADO.CERRADA) return { color: '#EF5350' }
            else if (params.value === Estados.AUDITORIA_ESTADO.REVISADA_OK)
              return { color: '#43A047' }
            else if (params.value === Estados.AUDITORIA_ESTADO.APTA) return { color: '#43A047' }
            else if (params.value === Estados.AUDITORIA_ESTADO.NO_APTA) return { color: '#EF5350' }
            else return null
          },
          valueGetter: (params) => {
            if (params.data) {
              const item = params.data
              return item.estado == 'CERRADA' ? 'REALIZADA' : item.estado
            }
          },
        },

        {
          headerName: 'Emplazamientos',
          field: 'sites',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            if (params.data) {
              // const sites = params.data?.sites
              // const items = []

              // if (sites !== undefined) {
              //   for (let i = 0; i < sites.length; i++) {
              //     const element = sites[i]
              //     const parent_uuid = element.parent_uuid
              //     const nombre = element.nombre
              //     if (!parent_uuid && !items.includes(nombre)) items.push(nombre)
              //   }
              // }

              let items = ''
              if (params.data.emplazamiento) {
                items = params.data.emplazamiento
              } else {
                items = '-'
              }

              return items
            }
          },
        },

        // {
        //   headerName: 'Emplazamientos',
        //   field: 'sites',
        //   enableRowGroup: true,
        //   sortable: true,
        //   filter: true,
        //   resizable: true,
        //   cellRendererFramework: 'EmplazamientosRenderer',
        // },

        {
          headerName: 'Tipo visita',
          field: 'tipo_visita',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            const alcance_uuid = item && item.alcance && item.alcance.uuid
            const value = item && item.tipo_visita
            return (
              (value &&
                this.tipos_auditorias[alcance_uuid] &&
                this.tipos_auditorias[alcance_uuid][value]) ||
              value
            )
          },
        },
        // {
        //   headerName: 'Bloqueada hasta',
        //   field: 'iteracion.bloqueado_hasta',
        //   sortable: true,
        //   filter: 'agDateColumnFilter',
        //   resizable: true,
        //   valueGetter: (params) => {
        //     const item = params.data
        //     const value = item && item.iteracion.bloqueado_hasta
        //     return value ? this.$moment(value).format('DD/MM/YYYY') : ''
        //   },
        // },
        {
          headerName: 'Desde',
          field: 'desde',
          sortable: true,
          resizable: true,
          comparator: (date1, date2) => {
            var date1Number = this.$moment(date1, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('X') || 0
            var date2Number = this.$moment(date2, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('X') || 0

            if (date1Number == null) {
              return -1
            } else if (date2Number == null) {
              return 1
            }

            return date1Number - date2Number
          },

          valueGetter: (params) => {
            if (params.data) {

              const item = params.data
              const value = item && item.desde
              return this.$moment(item.desde).format('DD/MM/YYYY')
            }
          },
        },
        {
          headerName: 'Hasta',
          field: 'hasta',
          sortable: true,
          resizable: true,

          comparator: (date1, date2) => {
            var date1Number = this.$moment(date1, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('X') || 0
            var date2Number = this.$moment(date2, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('X') || 0

            if (date1Number == null) {
              return -1
            } else if (date2Number == null) {
              return 1
            }

            return date1Number - date2Number
          },

          valueGetter: (params) => {
            const item = params.data
            const value = item && item.hasta
            return value ? this.$moment(value).format('DD/MM/YYYY') : ''
          },
        },

        {
          headerName: 'Fecha creación',
          field: 'created_at',
          sortable: true,
          resizable: true,

          comparator: (date1, date2) => {
            var date1Number = this.$moment(date1, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('X') || 0
            var date2Number = this.$moment(date2, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('X') || 0

            if (date1Number == null) {
              return -1
            } else if (date2Number == null) {
              return 1
            }

            return date1Number - date2Number
          },

          valueGetter: (params) => {
            const item = params.data
            const value = item && item.createdAt
            return value ? this.$moment(value).format('DD/MM/YYYY') : ''
          },
        },
        {
          headerName: 'PACS',
          field: 'n_pacs',
          pinned: this.headersPinned ? 'right' : null,
          width: 125,
          sortable: true,
          filter: true,
          resizable: true,
          cellRendererFramework: 'CheckboxRenderer',
        },
      ]

      if (this.auth.canDelete)
        headers.push({
          headerName: 'Acciones',
          sortable: false,
          pinned: this.headersPinned ? 'right' : null,
          width: 150,
          cellRendererFramework: 'DeleteRenderer',
          valueGetter: (params) => {
            const item = params.data
            const value = item && item.estado
            // return !(
            //   (value === Estados.AUDITORIA_ESTADO.PREVISTA && item.checklists.length === 0) ||
            //   (value === Estados.AUDITORIA_ESTADO.CONFIRMADA && item.checklists.length === 0)
            // )
          },
          cellRendererParams: {
            text: '¿Eliminar la auditoria seleccionada?',
            dispatch: 'auditorias/delete',
            init: this.init.bind(this),
          },
        })

      return headers
    },

    //+-------------------------------------------------
    // allowedToOffline()
    // return items where estado == 'abierta' and checklists.length > 0
    // -----
    // Created on Wed Jan 11 2023
    //+-------------------------------------------------
    allowedToOffline() {
      return this.items.filter(
        (item) => item.estado === Estados.AUDITORIA_ESTADO.ABIERTA //  item.checklists > 0
      )
    },
  },
  methods: {
    async init() {
      const body = {}
      if (this.expediente) body.expediente = this.expediente
      // Cliente
      if (this.cliente) body.cliente = this.cliente
      else if (this.isCliente) {
        const clientes = await this.$store.dispatch('clientes/search', this.$auth.user.uuid)
        if (clientes) body.cliente = clientes[0].uuid
      }

      // Auditorias
      const res = await this.$store.dispatch('auditorias/list', body)
      if (res) {
        this.items = res
        this.db.items = res
      }

      await this.formatRows()
      if (this.$nuxt.isOffline) {
        this.filterOffline(true)
        this.retryOffline()
      }
    },

    async getTiposVisita() {
      const body = {
        empresa: this.$auth.user.empresa.uuid,
        codigos: ['tipos_auditorias'],
      }
      const res = await this.$store.dispatch('estaticos/list', body)
      if (res) {
        const tipos_auditorias = {}
        for (let i = 0; i < res.length; i++) {
          const estatico = res[i]
          const alcance_uuid = estatico.alcance.uuid
          const items = estatico.valor

          if (!tipos_auditorias[alcance_uuid]) tipos_auditorias[alcance_uuid] = {}

          for (let k = 0; k < items.length; k++) {
            const item = items[k]
            tipos_auditorias[alcance_uuid][item.valor] = item.texto
          }
        }
        this.tipos_auditorias = tipos_auditorias
      }
    },

    clickRow(data) {
      if (data) this.$router.push(`/auditorias/${data.uuid}?tab=0`)
    },

    //+-------------------------------------------------
    // refreshRows()
    // Reload rows and cell data
    // -----
    // Created on Mon Mar 07 2022
    //+-------------------------------------------------
    async refreshRows(init = false) {
      if (init) await this.init()
      this.formatRows()
      this.gridApi.refreshCells({ force: true })
    },

    //+-------------------------------------------------
    // formatRows()
    // Prepare data fields for grid
    // Has offline functionality
    // -----
    // Created on Thu Nov 04 2021
    // Updated on Tue Nov 09 2021
    //+-------------------------------------------------
    async formatRows() {
      for (const item of this.items) {
        item.nombreApellidos = item.nombre_cliente

        item.desde_f = this.$moment(item.desde).format('DD/MM/YYYY')
        item.hasta_f = this.$moment(item.hasta).format('DD/MM/YYYY')

        let cache = await this.$store.dispatch('offline/getCache', 'auditorias/' + item.uuid)
        let toRemove = false

        if (cache?.data && cache?.data?.data?.updated_at) {
          let diff = this.$moment(cache?.data && cache?.data?.data?.updated_at).diff(
            item.updated_at,
            'minutes'
          )

          // Delete if cache is older than 2 days
          // Disabled because there is no item.updated_at anymore 💁‍♀️
          // if (diff > 60 * 24 * 2) toRemove = true

          // Delete if item is no longer locked
          // if (!item.bloqueada && !item.bloqueada_at) toRemove = true

          // if (diff * -1 > 30) item.cached = 'conflicted'

          console.warn(
            // diff,
            // cache?.data?.data.updated_at,
            // item.updated_at,
            cache?.data?.data.uuid,
            item.uuid
          )

          item.cached = cache ? true : false
          // item.cached = cache && !toRemove ? true : false
        }

        if (!this.isOffline && toRemove) {
          console.warn(
            `Se ha eliminado el offline de ${item.uuid} porque la auditoria ya no está bloqueada`
          )
          this.$store.commit('offline/removeItem', 'auditorias/' + item.uuid)
        }
      }

      this.ui.refresh++
    },

    //+-------------------------------------------------
    // filterIteracion()
    // Select only items where iteracion match the prop
    // -----
    // Created on Mon Nov 29 2021
    //+-------------------------------------------------
    filterIteracion() {
      if (!this.iteracion?.uuid) {
        this.items = [...this.db.items]
        return
      }

      let items = []
      items = this.db.items.filter((item) => {
        return item.iteracion_uuid == this.iteracion?.uuid
      })

      this.items = items
    },

    //+-------------------------------------------------
    // filterOffline()
    // Set items only with preloaded items
    // Used while offline to see only preloaded items
    // -----
    // Created on Wed Mar 02 2022
    //+-------------------------------------------------
    filterOffline(offline) {
      if (!offline) {
        this.items = [...this.db.items]
        this.formatRows()
        return
      }

      let items = []
      items = this.db.items.filter((item) => {
        return item.cached
      })

      this.items = items
    },

    retryOffline() {
      if (this.ui.retry > 5) return
      if (this.items.length > 0) return

      window.setTimeout(() => {
        this.refreshRows(true)
        this.ui.retry++
      }, 1000)
    },

    //+-------------------------------------------------
    // dropCache()
    // Created on Wed Jan 11 2023
    //+-------------------------------------------------
    dropCache() {
      this.$store.commit('offline/resetCache')
      this.$store.commit('offline/clearLocalData')
      location.reload()
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'auditorias',
        return: 'all',
      })
    },
  },

  mounted() {
    this.$store.commit('menu/setPageTitle', 'Auditorías')
    this.checkRoles()

    // this.$nuxt.$on('offline:loaded', (payload) => {
    //   if (payload.item == 'auditoria') {
    //     this.refreshRows()
    //     // this.lockAuditoria(payload)
    //   }
    // })

    // this.$nuxt.$on('offline:end', (payload) => {
    //   window.setTimeout(() => {
    //     this.refreshRows()
    //   }, 300)
    // })
  },

  async created() {
    this.$store.commit('menu/update', 'auditorias')
    await this.getTiposVisita()
    await this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('offline:loaded')
    this.$nuxt.$off('offline:end')
  },
}
</script>
