<template>
  <div>
    <slot name="filters-top"></slot>
    <v-card :flat="flat" :tile="tile">
      <v-card-title>
        {{ title ? title : '' }}
        <slot name="title"></slot>
        <!-- <span class="title-vuely" v-else>{{`Listado ${$nuxt.$route.name}`}}</span> -->
        <v-spacer v-if="title"></v-spacer>
        <slot name="filters"></slot>
        <v-text-field
          v-if="!hideSearch"
          label="Búsqueda rápida"
          v-model="search"
          append-icon="search"
          single-line
          hide-details
          clearable
          @keyup="updateFilter"
          @input="updateFilter"></v-text-field>
        <v-btn depressed small dark color="info" @click="excelExport" class="mt-5 ml-3">
          <v-icon class="mr-1" size="20">mdi-file-excel</v-icon>
          exportar
        </v-btn>
      </v-card-title>

      <slot></slot>

      <div style="height: 100%">
        <div class="ag-container">
          <ag-grid-vue
            style="width: 100%"
            :style="{ height: height + 'vh' }"
            :suppressCellSelection="true"
            class="ag-theme-material ag-grid"
            id="ag-grid"
            :columnDefs="headers"
            :rowData="items"
            :sideBar="sideBar"
            :autoGroupColumnDef="autoGroupColumnDef"
            :groupSelectsChildren="groupSelectsChildren"
            :animateRows="true"
            :localeText="localeText"
            :pagination="true"
            :paginationAutoPageSize="true"
            :quickFilter="search"
            @grid-ready="onGridReady"
            @rowClicked="clickRow"
            :rowSelection="rowSelection"
            :suppressRowClickSelection="suppressRowClickSelection"
            :masterDetail="!!detailCellRenderer"
            :detailRowHeight="detailRowHeight"
            :detailCellRenderer="detailCellRenderer"
            :detailCellRendererParams="detailCellRendererParams"
            rowGroupPanelShow="never"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :gridOptions="options"
            :frameworkComponents="frameworkComponents"
            :excelStyles="excelStyles"
            @selectionChanged="onSelectionChange()"
            @firstDataRendered="autoSize"></ag-grid-vue>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AgDatatableOld',
  props: {
    options: null,
    detailRowHeight: { type: Number, default: null },
    detailCellRenderer: { type: String, default: null },
    detailCellRendererParams: null,
    title: { type: String, default: null },
    height: { type: String, default: '72.5' },
    tile: { type: Boolean, default: true },
    flat: { type: Boolean, default: true },
    groupSelectsChildren: { type: Boolean, default: true },
    showHover: { type: Boolean, default: true },
    hideOverlay: { type: Boolean, default: false },
    hideSearch: { type: Boolean, default: false },
    showSelect: { type: Boolean, default: false },
    rowSelection: { type: String, default: null },
    sizeColumnsToFit: { type: Boolean, default: false },
    disableClickRow: { type: Boolean, default: false },
    emitClickRow: { type: Boolean, default: false },
    suppressRowClickSelection: { type: Boolean, default: false },
    autoGroupColumnDef: {
      type: Object,
      default: () => {
        resizable: true
      },
    },
    headers: { type: Array, default: () => [] },
    items: { type: Array, required: true, default: () => [] },
  },
  mounted() {
    setTimeout(() => (this.loading = false), 1500)
    if (!this.showHover) this.num_hover = 3
  },
  beforeMount() {
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Por favor, espere mientras se cargan los datos...</span>'
    this.localeText = {
      equals: 'es igual',
      notEqual: 'no es igual',
      lessThan: 'es menor',
      greaterThan: 'es mayor',
      inRange: 'esta en el rango',
      contains: 'contiene',
      noRowsToShow: 'No existen datos',
      filters: 'Filtros',
      columns: 'Columnas',
    }
    this.frameworkComponents = {
      SwitchRenderer: 'SwitchRenderer',
      DeleteRenderer: 'DeleteRenderer',
      BooleanCellEditor: 'BooleanCellEditor',
      AutocompleteCellEditor: 'AutocompleteCellEditor',
      SelectCellEditor: 'SelectCellEditor',
      TextCellEditor: 'TextCellEditor',
      DateCellEditor: 'DateCellEditor',
      AccionCorrectivaRenderer: 'AccionCorrectivaRenderer',
    }
    this.sideBar = {
      // toolPanels: ['filters', 'columns'],
      toolPanels: [],
    }
    this.excelStyles = [
      {
        id: 'default',
        dataType: 'string',
      },
      {
        id: 'stringType',
        dataType: 'string',
      },
      {
        id: 'string',
        dataType: 'string',
      },
    ]
  },
  data() {
    return {
      file: undefined,
      singleSelect: false,
      loading: false,
      search: '',
      page: 1,
      pageCount: 0,
      localeText: {},
      sideBar: {},
      gridApi: null,
      columnApi: null,
      frameworkComponents: null,
      overlayLoadingTemplate: null,
      num_hover: 8,
      excelStyles: null,
    }
  },
  computed: {
    gridOptions: () => this.options,
    IS_LIST() {
      return !this.$route.params.uuid
    },
    CAN_EDIT() {
      return this.IS_LIST ? this.$store.state.permisos.can_edit : true
    },
  },
  watch: {
    items: function (value) {
      // NOTA: cuando se cargan items desde cache,
      // no se esconde el overlay porque no hay gridapi, por algun motivo
      // solucionado quitando que se muestre el overlay en primer lugar linea 230
      if (value && value.length > 0 && this.gridApi) {
        this.gridApi.hideOverlay()
      }
      if (value && value.length === 0 && this.gridApi) {
        this.gridApi.hideOverlay()
      }
    },
  },
  methods: {
    async autoSize() {
      if (this.columnApi) {
        this.columnApi.autoSizeAllColumns()
        const rowGroupColumnsIds = this.columnApi
          .getRowGroupColumns()
          .map((column) => column.getColId())
        await this.columnApi.removeRowGroupColumns(rowGroupColumnsIds)
        setTimeout(() => {
          this.columnApi.autoSizeAllColumns()
          this.columnApi.addRowGroupColumns(rowGroupColumnsIds)
          setTimeout(() => {
            this.columnApi.autoSizeColumn('ag-Grid-AutoColumn')
            this.gridApi.expandAll()
            this.columnApi.autoSizeAllColumns()
          }, 800)
        }, 800)
      }
    },
    checkFile(e) {
      console.info(e)
      console.info(this.file)
    },
    excelImport() {},
    excelExport() {
      // Get Headers keys
      const keys = []
      const headers = this.headers
        .filter((e) => e.field || e.children)
        .map((e) => e.field || e.children)
      for (let i = 0; i < headers.length; i++) {
        const element = headers[i]
        Array.isArray(element)
          ? (keys = keys.concat(element.map((e) => e.field)))
          : keys.push(element)
      }
      const params = {
        allColumns: true,
        columnKeys: keys,
        processCellCallback: (params) => {
          const cellClass = params.column.colDef.cellClass
          const value = params.value
          return cellClass === 'date'
            ? this.$moment(params.value).format('DD-MM-YYYY')
            : params.value
        },
      }
      this.gridApi.exportDataAsExcel(params)
    },
    onGridReady(params) {
      this.gridApi = params.api
      // if (!this.hideOverlay) this.gridApi.showLoadingOverlay()
      this.columnApi = params.columnApi
      if (this.sizeColumnsToFit) this.gridApi.sizeColumnsToFit()
      this.gridApi.expandAll()
      this.$emit('gridApi', this.gridApi)
      this.$emit('columnApi', this.columnApi)

      // this.gridApi.forEachNode(node =>{
      //   node.expanded= true;
      // });
      // this.gridApi.onGroupExpandedOrCollapsed();
    },
    updateFilter() {
      this.gridApi.setQuickFilter(this.search)
    },
    clickRow(row) {
      if (!this.disableClickRow && !this.emitClickRow) {
        if (row.data) {
          if (this.CAN_EDIT) {
            let route = this.$nuxt.$route.name.replace(/-/g, '/')
            route = `/${route}/${row.data.uuid}`
            this.$router.push(route)
          } else {
            this.$store.commit('notification/show', {
              text: 'No tiene permisos suficientes.',
              color: 'error',
              timeout: 5000,
            })
          }
        }
      } else if (!this.disableClickRow && this.emitClickRow) {
        this.$emit('click-row', row.data)
      }
    },
    onSelectionChange() {
      const selection = this.gridApi.getSelectedRows()
      this.$emit('selectionChanged', selection)
    },
  },
}
</script>

<style>
.ag-header-cell,
.ag-header-group-cell {
  border-right: 1px solid #e2e2e2 !important;
}

.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
  background: #eeeeee;
  cursor: pointer !important;
}

.ag-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.ag-grid {
  height: 1px;
  min-height: 1px;
  flex-grow: 1;
}

.ag-cell {
  font-size: 1.1em !important;
}

.ag-header-cell-text {
  font-size: 1.3em !important;
  font-weight: bold;
}

.ag-theme-material .ag-side-bar .ag-side-buttons {
  width: 100% !important;
  padding: 0 !important;
}

.ag-theme-material .ag-side-bar .ag-side-buttons .ag-side-button button {
  min-height: 0 !important;
  padding: 0 0 16px 0 !important;
}
</style>
