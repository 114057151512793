
<template>
  <div>
    <v-row>
      <v-col v-if="!isEmbed" cols="7" class="align-center">
        <h1 v-if="!expediente">Marcas</h1>
      </v-col>

      <v-col :cols="isEmbed ? 12 : 5" style="display: flex; align-items: center;">
        <v-text-field
          v-model="search"
          class="mr-2"
          label="Buscar en la tabla"
          placeholder="Escribe..."
          append-icon="mdi-magnify"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>

        <v-spacer></v-spacer>

        <v-btn depressed outlined
          class="mx-2 "
          @click="$router.push((isPortal) ? '/portal/marcas/nueva' : '/marcas/nueva')">
          Nueva marca
        </v-btn>

      </v-col>

      <v-col cols="12">
        <v-card class="mx-auto">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            @click:row="rowAction"
          >
            <template v-slot:item.auditor="{ item }">
              <span style="font-size: 0.85rem; color: #929292">
                {{ item.auditor && item.auditor.nombre || "--" }}
              </span>
            </template>

            <template v-slot:item.is_propia="{ item }">
              <span v-if="item.is_propia" style="font-size: 18px">
                ✅
              </span>
            </template>

            <template v-if="isQuesoManchego" v-slot:item.is_tercero="{ item }">
              <span v-if="!item.is_propia" style="font-size: 18px">
                ✅
              </span>
            </template>

            <template v-slot:item.is_etiqueta_recibida="{ item }">
              <span v-if="item.is_etiqueta_recibida" style="font-size: 18px">
                ✅
              </span>
            </template>

            <template v-slot:item.is_etiqueta_validada="{ item }">
              <span v-if="item.is_etiqueta_validada" style="font-size: 18px">
                ✅
              </span>
            </template>

            <template v-slot:item.is_imprimir_en_certificado="{ item }">
              <span v-if="item.is_imprimir_en_certificado" style="font-size: 18px">
                ✅
              </span>
            </template>

            <template v-slot:item.fechas="{ item }">
                {{ $moment(item.fecha).format("DD [de] MMMM, YYYY") }}
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip label small outlined>
                {{ item.is_active ? "Activo" : "Inactivo" }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                  <v-icon style="font-size: 20px" color="primary" @click.stop="toDelete(item)">mdi-delete-outline</v-icon>
                </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <confirmation
        v-model="confirmDelete"
        :text="'¿Eliminar el objeto seleccionado?'"
        colorButton="error"
        @confirm="deleteItem()"/>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \pages\portal\marcas\index.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 2nd November 2022
 * Last Modified: Tue Jul 18 2023
 **/

import Confirmation from '~/components/Confirmation'

export default {
  name: 'marcas',

  props: {
    isPortal: { type: Boolean, default: false },
    isEmbed: { type: Boolean, default: false },
    expediente: {
      type: Object,
      default: () => ({}),
    },
    cliente: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Confirmation,
  },

  data() {
    return {
      search: '',
      itemToDelete: {},
      confirmDelete: false,

      user: {},
      client: {},

      db: {
        marcas: [],
      },

      ui: {
        modal: false,
      },
    }
  },

  computed: {
    headers(){
      let headers = []
      if(this.isQuesoManchego){
       headers = [

              { text: '', align: 'start', value: 'status', cellClass: 'subData' },
              {
                text: 'Marca',
                align: 'start',
                value: 'nombre',
              },
              { text: 'Fecha', align: 'start', value: 'fechas', cellClass: 'subData' },

              {
                text: 'Marca propia',
                align: 'center',
                value: 'is_propia',
                cellClass: 'subData',
                sortable: false
              },

              {
                text: 'Marca tercero',
                align: 'center',
                value: 'is_tercero',
                cellClass: 'subData',
                sortable: false
              },

              {
                text: 'Etiqueta recibida',
                align: 'center',
                value: 'is_etiqueta_recibida',
                cellClass: 'subData',
                sortable: false
              },

              {
                text: 'Etiqueta validada',
                align: 'center',
                value: 'is_etiqueta_validada',
                cellClass: 'subData',
                sortable: false
              },

              {
                text: 'En certificado',
                align: 'center',
                value: 'is_imprimir_en_certificado',
                cellClass: 'subData',
                sortable: false
              },
              { text: '', value: 'actions', align: 'left' }
            ]
      }else{
        headers =[

              { text: '', align: 'start', value: 'status', cellClass: 'subData' },
              {
                text: 'Marca',
                align: 'start',
                value: 'nombre',
              },
              { text: 'Fecha', align: 'start', value: 'fechas', cellClass: 'subData' },

              {
                text: 'Marca propia',
                align: 'center',
                value: 'is_propia',
                cellClass: 'subData',
                sortable: false
              },

              {
                text: 'Etiqueta recibida',
                align: 'center',
                value: 'is_etiqueta_recibida',
                cellClass: 'subData',
                sortable: false
              },

              {
                text: 'Etiqueta validada',
                align: 'center',
                value: 'is_etiqueta_validada',
                cellClass: 'subData',
                sortable: false
              },

              {
                text: 'En certificado',
                align: 'center',
                value: 'is_imprimir_en_certificado',
                cellClass: 'subData',
                sortable: false
              },
              { text: '', value: 'actions', align: 'left' }
            ]
      }
      return headers
    },
    items() {
      return this.db.marcas

      if (this.expediente?.uuid)
        return this.db.marcas.filter((m) => m.cliente?.uuid == this.expediente.cliente.uuid)

      if (this.cliente?.uuid)
        return this.db.marcas.filter((m) => m.cliente?.uuid == this.cliente.uuid)
    },
    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },
  },

  methods: {
    rowAction(item) {
      let prefix = this.isPortal ? '/portal' : ''
      this.$router.push(prefix + '/marcas/' + item.uuid)
    },

    toDelete(item) {
      this.confirmDelete = true
      this.itemToDelete = item
    },

    async deleteItem() {
      this.confirmDelete = false
      await this.$store.dispatch('marcas/delete', this.itemToDelete)
      await this.getMarcas()
      this.itemToDelete = {}
    },

    async getMarcas() {
      let xhr = null
      xhr = await this.$store.dispatch('marcas/list', {
        cliente: this.cliente?.uuid || null,
      })

      if (xhr) this.db.marcas = xhr.slice().reverse()
    },

    async init() {
      this.user = this.$auth.user
      this.getMarcas()
    },
  },

  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.form-label {
  font-weight: 500;
  color: #666;
  transform: translateY(9px);
}

.subData {
  color: rgb(146, 146, 146);
  font-size: 12px !important;
  white-space: nowrap;
}

.v-data-table.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
