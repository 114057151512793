export default {
  ROLE_CLIENT: 'ROLE_CLIENT',
  ROLE_TECNICO: 'ROLE_TECNICO',
  ROLE_AUDITOR_FREELANCE: 'ROLE_AUDITOR_FREELANCE',
  ROLE_AUDITOR: 'ROLE_AUDITOR',
  ROLE_AUDITOR_ADMIN: 'ROLE_AUDITOR_ADMIN',
  ROLE_ROOT: 'ROLE_ROOT',
  ROLE_AUDITOR_REVISOR: 'ROLE_AUDITOR_REVISOR',
  ROLE_PERSONAL_INTERNO: 'ROLE_PERSONAL_INTERNO',
}

// export default {
//   ROLE_CLIENT: 'ROLE_CLIENT',
//   ROLE_USER: 'ROLE_USER',
//   ROLE_ROOT: 'ROLE_ROOT',
//   ROLE_AUDITOR_ADMIN: 'ROLE_AUDITOR_ADMIN',
//   ROLE_AUDITOR_FREELANCE: 'ROLE_AUDITOR_FREELANCE',
//   ROLE_AUDITOR: 'ROLE_AUDITOR',
//   ROLE_AGROCOLOR: 'ROLE_AGROCOLOR'
// };
