<template>
  <div class="mt-0 pt-0">
    <v-card-title class="mt-0 pt-0 mb-2">
      <!-- <v-btn small @click="expandirFilas(1)" class="mr-3">
        <v-icon left dark>mdi-arrow-expand-down</v-icon>
        Expandir todo
      </v-btn>
      <v-btn small @click="expandirFilas(0)">
        <v-icon left dark>mdi-arrow-expand-up</v-icon>
        Contraer todo
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search" @keyup="updateFilter" @input="updateFilter"
          append-icon="search" label="Búsqueda rápida"
          single-line hide-details clearable
          style="margin-top: 0px !important; padding-top: 0px !important"></v-text-field>
    </v-card-title>
    <div style="height: 100%">
      <div class="ag-container">
        <!--          :autoGroupColumnDef="autoGroupColumnDef"-->
        <ag-grid-vue
            style="width: 100%; height: 80vh;"
            :suppressCellSelection="true"
            class="ag-theme-material ag-grid"
            id="ag-grid"
            :columnDefs="headers"
            :rowData="items"
            :animateRows="true"
            :pagination="true"
            :sideBar="sideBar"
            :paginationAutoPageSize="true"
            :quickFilter="search"
            @grid-ready="onGridReady"
            @rowClicked="clickRow"
            :singleClickEdit="true"
            rowGroupPanelShow="always"
            :gridOptions="gridOptions"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :frameworkComponents="frameworkComponents"
        ></ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import AgDatatable from '~/components/AgDatatable'

export default {
  name: 'Checklist',
  components: {
    AgDatatable,
  },
  props: {
    auditoria: {
      type: Object,
      default: () => ({}),
    },
    auditoria_uuid: {
      type: String,
      default: null,
    },
    checklist: {
      type: Array,
      default: [],
    },
  },

  data: () => ({
    current_auditoria_uuid: null,
    items: [],
    search: '',
    gridOptions: {
      // groupUseEntireRow: true,
    },
    loading: true,
    page: 1,
    pageCount: 0,
    gridApi: null,
    columnApi: null,
    sideBar: {},
    autoGroupColumnDef: {
      // resizable: true
    },
    headers: [],
    baseHeaders: [
      {
        headerName: 'Auditor',
        field: 'auditor.nombre',
        resizable: true,
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Título',
        field: 'titulo',
        resizable: true,
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Emplazamiento',
        field: 'emplazamiento',
        enableRowGroup: false,
        resizable: true,
        sortable: true,
        filter: true,
        width: 350,
      },
      // {
      //   headerName: 'Mayores',
      //   field: 'cumplimiento.mayores',
      //   enableRowGroup: false,
      //   resizable: true,
      //   sortable: true,
      //   filter: true,
      //   valueGetter: (params) => {
      //     const item = params.data
      //     const value = item && item.cumplimiento && item.cumplimiento.mayores
      //     return value ? value + ' %' : null
      //   },
      // },
      // {
      //   headerName: 'Menores',
      //   field: 'cumplimiento.menores',
      //   enableRowGroup: false,
      //   resizable: true,
      //   sortable: true,
      //   filter: true,
      //   valueGetter: (params) => {
      //     const item = params.data
      //     const value = item && item.cumplimiento && item.cumplimiento.menores
      //     return value ? value + ' %' : null
      //   },
      // },
      // {
      //   headerName: 'Completado',
      //   field: 'cumplimiento.completados',
      //   enableRowGroup: false,
      //   resizable: true,
      //   sortable: true,
      //   filter: true,
      //   valueGetter: (params) => {
      //     const item = params.data
      //     const value = item && item.cumplimiento && item.cumplimiento.completados
      //     return value ? value + ' %' : null
      //   },
      //   cellStyle: function (params) {
      //     if (params.data && params.value < 100) return {color: 'red'}
      //     else if (params.data && params.value === 100) return {color: 'green'}
      //     else return null
      //   },
      // },
    ],

    labels: {
      niveles: {},
    },
  }),

  beforeMount() {
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Por favor, espere mientras se cargan los datos...</span>'
    this.frameworkComponents = {}
    this.sideBar = {
      toolPanels: ['filters', 'columns'],
    }
  },

  watch: {
    checklist(items) {
      this.headers = [...this.baseHeaders]

      items.forEach((item) => {
        if (item.site) {
          item.emplazamiento = item.site.rgseaa
            ? `(${item.site.rgseaa}) ${item.site.nombre}`
            : item.site.nombre
        }

        item.cumplimiento = this.setCumplimiento(item)
        this.addHeaders(item.cumplimiento)
      })

      this.items = items
    },
  },

  computed: {
    offlineMode() {
      return this.$nuxt.isOffline || this.$store.getters['auditorias/offlineMode']
    },
  },

  methods: {
    onGridReady(params) {
      this.gridApi = params.api
      if (!this.offlineMode) this.gridApi.showLoadingOverlay()
      // this.gridApi.sizeColumnsToFit()
    },

    clickRow(params) {
      if (params.data && params.data !== undefined) {
        this.$router.push({
          path: `/auditorias/tabs/checklist/${params.data.uuid}`,
          query: { auditoria_uuid: this.current_auditoria_uuid },
        })
      } else params.node.setExpanded(!params.node.expanded)
    },

    expandirFilas(on) {
      if (on === 1)
        this.gridApi.forEachNode((node) => {
          if (node.group) node.setExpanded(true)
        })
      else
        this.gridApi.forEachNode((node) => {
          if (node.group) node.setExpanded(false)
        })
      // this.gridApi.sizeColumnsToFit()
    },

    updateFilter() {
      this.gridApi.setQuickFilter(this.search)
    },

    addHeaders(item) {
      for (const el in item) {
        this.headers.push({
          headerName: el,
          field: 'cumplimiento.' + el,
          enableRowGroup: false,
          resizable: true,
          sortable: true,
          filter: true,
          valueGetter: (params) => {
            const item = params.data
            const value = item && item.cumplimiento && item.cumplimiento[el]
            return value ? value + ' / ' + item.preguntas.length : null
          },
        })
      }
    },

    setCumplimiento(item) {
      if (!item.preguntas || item.preguntas.length === 0) return {}

      let cumplimiento = {}

      item.preguntas.forEach((pregunta) => {
        if (pregunta.respuesta == 'F') {
          if (!cumplimiento[pregunta.peso]) cumplimiento[pregunta.peso] = 0
          cumplimiento[pregunta.peso]++
        }
      })

      // console.warn(cumplimiento)
      return cumplimiento
    },

    async getEstaticos() {
      const body = {
        alcance: this.auditoria?.alcance?.uuid,
        empresa: this.$auth.user.empresa.uuid,
        codigos: ['checklist_niveles'],
      }

      //const res = await this.$store.dispatch('estaticos/list', body)
      const res = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getEstaticos', body)
        : await this.$store.dispatch('estaticos/list', body)

      // NOTE: 26/01/2022, this.estaticos no deberia ser res[0],
      // sino que se  deberia seleccionar el estatico que corresponda al alcance
      // this.estaticos = [res[0]]

      // this.estaticos.forEach((estatico) => {
      //   estatico.valor.forEach((valor) => {
      //     this.labels.niveles[valor.valor] = valor.texto
      //   })
      // })

      // const index = this.headers.findIndex((header) => header.headerName === 'Mayores')
      // this.headers[index].headerName = this.labels.niveles['mayor']

      // const index_ = this.headers.findIndex((header) => header.headerName === 'Menores')
      // this.headers[index_].headerName = this.labels.niveles['menor']

      // this.gridOptions.api.setColumnDefs(this.headers)
    },

    init() {
      // this.getEstaticos()
      this.headers = [...this.baseHeaders]
    },
  },

  mounted() {
    this.current_auditoria_uuid = this.auditoria_uuid
      ? this.auditoria_uuid
      : this.$route.params.uuid

    this.init()
  },
}
</script>

<style>
.ag-theme-material .ag-icon-checkbox-checked {
  color: #2e3444 !important;
}
</style>
