<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <span v-if="title">{{ title}}</span>
            <span v-else>{{ isNew ? 'Nuevo' : 'Editar' }} Alcance del Producto</span>
          </v-card-title>

          <v-card-text style="max-height: 70vh;  overflow: auto;">
            <v-container>

              <slot></slot>
              <v-row>
                <v-col cols="6">
                  <SelectInstalaciones
                    ref="instalaciones"
                    label="Instalación"
                    v-model="itemObjects.instalacion"
                    :cliente="cliente"
                    create
                    required
                    dense
                    outlined
                    hide-details="auto"
                    returnObject
                    @input="updateItemObject('instalacion')"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Producto"
                    v-model="item.nombre"
                    dense
                    outlined
                    hide-details="auto"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="title black--text">
                  Certificado
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Título del certificado"
                    v-model="item.certificado"
                    dense
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Órden a cumplir"
                    v-model="item.orden"
                    dense
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Anexo"
                    v-model="item.anexo"
                    dense
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Vigencia"
                    v-model="item.vigencia"
                    dense
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Revisión"
                    v-model="item.revision"
                    dense
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" :disabled="loading" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import SelectInstalaciones from '~/components/forms/SelectInstalaciones'

export default {
  name: 'AlcanceProductoDialog',
  props: {
    value: Boolean,
    valid: {type: Boolean, default: true},
    title: {type: String, default: ''},
    cliente: {type: Object, default: null},
    // ampliacion: {type: Boolean, default: false},
    isOperadorResposable: {type: Boolean, default: false},
    isProductosIbericos: {type: Boolean, default: false},
    isETG: {type: Boolean, default: false},
    producto: {type: Object, default: null},
    itemSelected: {type: Object, default: null},
  },
  components: {
    SelectInstalaciones,
  },
  data: () => ({
    itemObjects: {},
    dialog: false,
    dialogInstalaciones: false,
    item: {},
    rules: [(v) => !!v || 'El campo es obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'El campo es obligatorio'],
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    dialog(val) {
      if (!val) this.close()
    },
    producto(val) {
      if (val) this.init()
      else this.resetForm()
    },

    itemSelected(val) {
      if (val) this.setItem()
    },
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      },
    },

    isNew() {
      return this.producto === null
    },
  },

  methods: {
    updateItemObject(object) {
      this.item[object + '_codigo'] = this.itemObjects[object]?.rgseaa
      this.item[object + '_nombre'] = this.itemObjects[object]?.razon_social
    },

    setItem() {
      // CCL Marcas tiene la data dentro de un array[0],
      // es el unico site que tiene data en un array y seguramente es un error de back
      if (this.itemSelected && this.itemSelected.data) this.item = {...this.itemSelected.data}

      window.setTimeout(() => {
        if (this.$refs.instalaciones && this.item.instalacion_codigo) {
          this.$refs.instalaciones.setValueByCodigo(this.item.instalacion_codigo)
        }
      }, 1000)
    },

    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },

    async init() {
      this.item = this.producto
    },

    async submit() {
      this.$emit('validate')
      if (this.$refs.form.validate() && this.valid) {
        this.loading = true
        setTimeout(() => {
          this.$emit('submit', this.item)
          this.close()
        }, 500)
      }
    },

    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },

    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
  },
}
</script>
