<template>
  <div v-if="isNotGroup">

    <span v-if="!hide">
      <v-icon style="font-size: 20px" color="primary" :disabled="!CAN_DELETE"
        @click.stop="controlClick()">mdi-delete-outline</v-icon>
    </span>

    <confirmation v-model="confirmDelete" :text="params.text || '¿Eliminar el objeto seleccionado?'" colorButton="error"
      @confirm="deleteItem()" />

  </div>
</template>

<script>
import Confirmation from '~/components/Confirmation'

export default {
  components: {
    Confirmation,
  },
  data: function () {
    return {
      confirmDelete: false,
    }
  },
  computed: {
    IS_LIST() {
      return !this.$route.params.uuid
    },
    CAN_DELETE() {
      return true
      return this.IS_LIST ? this.$store.state.permisos.can_delete : true
    },
    isNotGroup() {
      return !!this.params.data
    },
    hide() {
      return this.params.value === true
    },
  },
  methods: {
    init() {
      if (this.params.init instanceof Function) this.params.init(true)
    },
    controlClick() {
      if (this.params.dispatch || this.params.confirm) this.confirmDelete = true
      else this.deleteItem()
    },
    async deleteItem() {
      this.confirmDelete = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      let response = { status: 200 }
      if (this.params.dispatch && this.params.node.data.uuid) {
        try {
          response = await this.$store.dispatch(`${this.params.dispatch}`, this.params.node.data)
        } catch (error) {
          console.log('error', error)
        }
        //response = await this.$store.dispatch(`${this.params.dispatch}`, this.params.node.data)
      }
      console.log('response', response)

      if (response && response.status !== 403) {
        // Eliminamos la row del ag-grid y refrescamos
        const groupedItems = this.params.node.allLeafChildren
        if (groupedItems && groupedItems.length) {
          for (let i = 0; i < groupedItems.length; i++) {
            const row = groupedItems[i]
            let indexRow = gridOptions.rowData.indexOf(row.data)
            gridOptions.rowData.splice(indexRow, 1)
          }
        } else {
          //let indexRow = gridOptions.rowData.indexOf(this.params.node.data)
          //gridOptions.rowData.splice(indexRow, 1)
        }

        gridApi.refreshCells()
        this.$store.commit('notification/show', {
          color: 'success',
          text: 'Registro Eliminado correctamente',
          timeout: 8500,
        })
        this.init()
      } else {

      }
    },
  },
}
</script>
