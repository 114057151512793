<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-form ref="form" lazy-validation v-model="valid">
        <v-card>
          <v-card-title>
            {{ isNew ? 'Nuevo' : 'Editar' }} Alcance del Producto
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>

                <v-col cols="10">
                  <select-instalaciones
                    label="Instalación"
                    v-model="item.instalacion"
                    :cliente="cliente"
                    dense
                    returnObject
                    :key="component"
                    :required="true"
                  ></select-instalaciones>
                </v-col>

                <v-col cols="2" class="text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn x-small fab depressed :disabled="!cliente" color="success" class="white--text" v-on="on" @click="dialogInstalaciones = true">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Crear instalación</span>
                  </v-tooltip>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" :disabled="!valid || loading" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- Dialog Instalaciones -->
    <gestion-instalaciones-dialog
      v-model="dialogInstalaciones"
      :cliente="cliente"
      @close="dialogInstalaciones = false"
      @init="component++"/>

  </div>
</template>

<script>
import GestionInstalacionesDialog from '~/components/clientes/instalaciones/certicalidad/GestionInstalacionesDialog'
import SelectInstalaciones from "~/components/forms/SelectInstalaciones"
import MODELS from "~/models/certicalidad/solicitudes"
import moment from 'moment'

export default {
  name: "InstalacionDialog",
  props: {
    value: Boolean,
    cliente: { type: Object, default: null },
    producto: { type: Object, default: null }
  },
  components: {
    GestionInstalacionesDialog,
    SelectInstalaciones
  },
  data: () => ({
    component: 0,
    valid: false,
    dialog: false,
    dialogInstalaciones: false,
    item: {},
    rules: [ v => !!v || 'El campo es obligatorio' ],
    rulesMultiple: [ v => !!v && v.length > 0 || 'El campo es obligatorio' ],
    tiposAlimentacion: MODELS.ALIMENTACION_TIPOS,
    tiposProducto: MODELS.PRODUCTO_TIPOS,
    tiposRacial: MODELS.RACIAL_TIPOS,
    tiposEstado: MODELS.ESTADOS_TIPOS,
    tiposPresentacion: MODELS.PRESENTACION_TIPOS,
    tiposCertificacion: MODELS.CERTIFICACION_TIPOS,
  }),
  watch: {
    value (val) {
      this.dialog = val;
      if (!val) this.resetForm();
    },
    dialog (val) {
      if (!val) this.close()
    },
    producto (val) {
      if (val) this.getOne();
      else this.resetForm();
    }
  },
  computed: {
    loading: {
      get () {
        return this.$store.state.loading
      },
      set (val) {
        this.$store.commit('LOADING', val)
      }
    },
    isNew () {
      return this.producto === null;
		},
    // isNewCliente() {
    //   return this.$route.params.uuid === '_'
    // },
  },
  methods: {
    formatDate (date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },
    formatDateBack (date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },
    async getOne() {
      this.item = this.producto
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const body = {
          ...this.item
        }
        this.loading = true
        setTimeout(() => {
          this.$emit('submit', this.item)
          this.close()
        }, 1000)
      }
    },
    // async submit() {
    //   if (this.$refs.form.validate()) {
    //     const body = {
    //       cliente_uuid: this.cliente ? this.cliente : this.$route.params.uuid,
    //       instalacion: {
    //         ...this.item
    //       }
    //     }
    //     body.instalacion.tramites = this.item.tramites.map(element => element.uuid)
    //     if (this.isNew) {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/create', body)
    //     } else {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/update', body)
    //     }
    //     if (!this.isNewCliente) this.$emit('init')
    //     this.close()
    //   }
    // },
    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
    // Filters
    filterTiposEstado(item) {
      const isCarnesFrescas = item.producto === 'carnes_frescas'
      return isCarnesFrescas
        ? this.tiposEstado.filter(element => element.value === 'fresco')
        : this.tiposEstado
    },
    filterTiposPresentacion(item) {
      const isCarnesFrescas = item.producto === 'carnes_frescas'
      const tiposPresentacion = [
        { text: 'Pieza entera', value: 'entera' },
        { text: 'Troceado', value: 'troceado' },
        { text: 'Fileteado', value: 'fileteado' },
      ]
      return isCarnesFrescas
        ? tiposPresentacion
        : this.tiposPresentacion
    },
  }
};
</script>
