<template>

  <v-hover v-slot:default="{ hover }" :disabled="disableHover">
    <v-card :elevation="disableHover ? 0 : hover ? 8: 3">

      <v-card-title v-if="showHeader">
        <span class="title-vuely" v-if="title">{{title}}</span>
        <span class="title-vuely" v-else>{{`Listado ${$nuxt.$route.name}`}}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Búsqueda rápida"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <slot name="button"></slot>
      </v-card-title>

      <v-data-table @click:row="clickRow"
        :search="search"
        :loading="loading"
        loading-text="Cargando ..."
        :sort-by="['created_at']"
        :sort-desc="true"
        no-data-text="Listado vacío"
        :show-select="showSelect"
        :headers="headers"
        :items="items"
        v-bind="$attrs" v-on="$listeners"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event">

        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>

      </v-data-table>

      <div class="text-center pt-3 pb-3">
        <v-pagination color="info" v-model="page" :length="pageCount"></v-pagination>
      </div>

    </v-card>
  </v-hover>

</template>

<script>
  export default {
    name: "Datatable",
    props: {
      disableHover: {type: Boolean, default: () => false},
      showSelect: {type: Boolean, default: () => false},
      headers: {type: Array, default: () => []},
      items: {type: Array, default: () => []},
      title: {type: String, default: () => null},
      disableClickRow: {type: Boolean, default: () => false},
      emitClickRow: {type: Boolean, default: () => false},
      showHeader: {type: Boolean, default: () => true},
      itemsPerPage: {type: Number, default: () => 10}
    },
    data() {
      return {
        singleSelect: false,
        loading: true,
        search: '',
        page: 1,
        pageCount: 0
      }
    },
    watch: {
      items: function (value) {
        if (value && value.length > 0) this.loading = false
        if (value && value.length === 0) this.loading = false
      }
    },
    mounted(){
      setTimeout(() => this.loading = false, 1500)
    },
    methods: {
      clickRow(row) {
        if (!this.disableClickRow && !this.emitClickRow) {
          let route = (this.$nuxt.$route.name).replace(/-/g, '/')
          route = `/${route}/${row.uuid}`
          this.$router.push(route)
        } else if (!this.disableClickRow && this.emitClickRow) {
          this.$emit('click-row', row)
        }
      }
    }
  }
</script>

<style>
  .theme--light.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
      background: #eeeeee;
      cursor: pointer !important;
  }
</style>
