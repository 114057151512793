<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-form ref="form" lazy-validation v-model="valid">
        <v-card>
          <v-card-title>
            {{ isNew ? 'Nuevo' : 'Editar' }} Producto
          </v-card-title>

          <v-card-text>
            <v-container>
              <!-- <pre>
                {{ item }}
              </pre> -->
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Producto ecólogico"
                    v-model="item.producto_ecologico"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12"
                  v-if="hasVino">
                  <v-text-field
                    label="Añada"
                    v-model="item.anada"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-if="hasVino"
                    label="Volumen de producción"
                    v-model="item.volumen_produccion"
                    dense
                    required
                  ></v-text-field>

                  <v-select
                    v-else
                    label="Volumen de producción"
                    v-model="item.volumen_produccion"
                    :items="tiposVolumenProduccion"
                    :rules="rules"
                    required
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Actividad asociada al producto"
                    v-model="item.actividad_asociada"
                    :items="tiposActividadAsociada"
                    :rules="rules"
                    required
                    dense
                  ></v-select>
                </v-col>


                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      class="py-0"
                      v-model="item.produccion_ecologica"
                      label="Producción ecológica"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      class="py-0"
                      v-model="item.conversion"
                      label="En conversión"
                    ></v-checkbox>
                  </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" :disabled="!valid || loading" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\solicitudes\ccl\ProductionEcologica\ProductosEcologicos\ProductosEcologicosDialog.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Thu Jun 02 2022
 **/

import MODELS from '~/models/ccl/solicitudes'
import SelectEstaticos from '~/components/forms/SelectEstaticos'

export default {
  name: 'ProductosEcologicosDialog',
  components: { SelectEstaticos },
  props: {
    value: Boolean,
    itemSelected: { type: Object, default: null },
    alcance: { type: Object, default: () => {} },
    tipo_operador: { type: Array, default: () => [] },
  },
  data: () => ({
    valid: false,
    dialog: false,
    item: {},
    rules: [(v) => !!v || 'El campo es obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'El campo es obligatorio'],
    tiposVolumenProduccion: MODELS.VOLUMEN_PRODUCCION_TIPOS,
    tiposActividadAsociada: MODELS.ACTIVIDAD_ASOCIADA_TIPOS,
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    dialog(val) {
      if (!val) this.close()
    },
    itemSelected(val) {
      if (val) this.setItem()
    },
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      },
    },
    isNew() {
      return this.itemSelected === null
    },

    hasVino() {
      return this.tipo_operador && this.tipo_operador.indexOf('VINO') > -1
    },
  },
  methods: {
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },

    setItem() {
      if (this.itemSelected && this.itemSelected.data) this.item = { ...this.itemSelected.data }
    },

    // async getOne() {
    //   this.item = Object.assign({}, this.itemSelected)
    //   console.log('getOne -> this.itemSelected', this.itemSelected)
    // },
    async submit() {
      if (this.$refs.form.validate()) {
        const body = {
          ...this.item,
        }
        const action = this.isNew ? 'add' : 'edit'
        this.$emit(action, this.item)
        this.$emit('submit', this.item)
        this.close()
      }
    },

    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
  },
}
</script>
