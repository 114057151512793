<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <span v-if="title">{{ title}}</span>
            <span v-else>{{ isNew ? 'Nuevo' : 'Editar' }} Alcance del Producto</span>
          </v-card-title>

          <v-card-text style="max-height: 70vh;  overflow: auto;">
            <v-container>

              <slot></slot>
              <v-row>
<!--                <v-col cols="12" v-if="!ampliacion && isProductosIbericos">-->
                <v-col cols="12">
                  <SelectInstalaciones
                    ref="instalaciones"
                    label="Instalación"
                    v-model="itemObjects.instalacion"
                    :cliente="cliente"
                    :tipos-instalacion="tiposInstalacion"
                    create
                    required
                    dense
                    returnObject
                    @input="updateItemObject('instalacion')"
                  />
                </v-col>

                <v-col cols="6">
                  <SelectActividades
                    label="Actividades Realizadas"
                    v-model="item.actividades_realizadas"
                    :actividades="actividadesRealizadasETG"
                    required
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="6" v-if="isOperadorResposable">
                  <SelectActividades
                    label="Actividades Subcontratadas"
                    v-model="item.actividades_subcontratadas"
                    :actividades="actividadesSubcontratadasETG"
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-select
                    label="Certificación"
                    v-model="item.certificacion"
                    :items="tiposCertificacion"
                    item-value="text"
                    :rules="item.subcontratadas && item.subcontratadas.length ? rules : []"
                    :required="item.subcontratadas && item.subcontratadas.length"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-text-field
                    label="Marcas amparadas"
                    v-model="item.marcas"
                    :rules="rules"
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" class="title black--text">
                  Productos
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Producto"
                    v-model="item.producto"
                    :items="productos"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-select
                    label="Alimentación"
                    v-model="item.alimentacion"
                    :items="tiposAlimentacion"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-select
                    label="Racial"
                    v-model="item.racial"
                    :items="tiposRacial"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-select
                    label="Estado"
                    v-model="item.estado"
                    :items="tiposEstado"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Presentación"
                    v-model="item.presentacion"
                    :items="presentaciones"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    multiple
                    dense
                  ></v-select>
                </v-col>

              </v-row>

              <v-row>

                <v-col cols="12" class="text-capitalize font-weight-bold">
                  Volumen
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Nº Animales Sacrificados"
                    v-model="item.animales_sacrificados"
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Nº Canales Despiezadas"
                    v-model="item.canales_despiezadas"
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Nº Piezas Elaboradas"
                    v-model="item.piezas_elaboradas"
                    persistent-hint
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Nº Piezas Transformadas"
                    v-model="item.piezas_transformadas"
                    persistent-hint
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Kilos de carne comercializada"
                    v-model="item.carne_comercializada"
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" :disabled="loading" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import SelectInstalaciones from '~/components/forms/SelectInstalaciones'
import SelectActividades from '~/components/forms/SelectActividades'
import MODELS from '~/models/araporcei/solicitudes'

export default {
  name: 'AlcanceProductoDialog',
  props: {
    value: Boolean,
    valid: { type: Boolean, default: true },
    title: { type: String, default: '' },
    cliente: { type: Object, default: null },
    // ampliacion: {type: Boolean, default: false},
    isOperadorResposable: { type: Boolean, default: false },
    isProductosIbericos: { type: Boolean, default: false },
    isETG: { type: Boolean, default: false },
    producto: { type: Object, default: null },
    itemSelected: { type: Object, default: null },
  },
  components: {
    SelectInstalaciones,
    SelectActividades,
  },
  data: () => ({
    tiposInstalacion: [MODELS.TIPOS_INSTALACION.propia],
    itemObjects: {},
    dialog: false,
    dialogInstalaciones: false,
    item: {},
    rules: [(v) => !!v || 'El campo es obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'El campo es obligatorio'],
    tiposAlimentacion: MODELS.ALIMENTACION_TIPOS,
    tiposProducto: MODELS.PRODUCTO_TIPOS,
    tiposProductoETG: MODELS.PRODUCTO_TIPOS_ETG,
    tiposRacial: MODELS.RACIAL_TIPOS,
    tiposEstado: MODELS.ESTADOS_TIPOS,
    tiposPresentacion: MODELS.PRESENTACION_TIPOS,
    tiposPresentacionETG: MODELS.PRESENTACION_TIPOS_ETG,
    tiposCertificacion: MODELS.CERTIFICACION_TIPOS,
    actividadesETG: MODELS.ACTIVIDADES_ETG,
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    dialog(val) {
      if (val) this.formatForm()
      if (!val) this.close()
    },
    producto(val) {
      if (val) this.init()
      else this.resetForm()
    },

    itemSelected(val) {
      if (val) this.setItem()
    },
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      },
    },
    isNew() {
      return this.producto === null
    },
    productos() {
      if (this.isProductosIbericos) return this.tiposProducto
      if (this.isETG) return this.tiposProductoETG
      return []
    },
    presentaciones() {
      if (this.isProductosIbericos) return this.tiposPresentacion
      if (this.isETG) return this.tiposPresentacionETG
      return []
    },
    actividadesRealizadasETG() {
      const actividadesETG = this.isOperadorResposable
        ? this.actividadesETG
        : this.actividadesETG.filter((e) => !e.responsableOnly)
      return this.isETG ? actividadesETG : []
    },
    actividadesSubcontratadasETG() {
      return this.isETG ? this.actividadesETG.filter((e) => !e.responsableOnly) : []
    },
    // isNewCliente() {
    //   return this.$route.params.uuid === '_'
    // },
  },
  methods: {
    updateItemObject(object) {
      this.item[object + '_codigo'] = this.itemObjects[object]?.rgseaa
      this.item[object + '_nombre'] = this.itemObjects[object]?.razon_social
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    formatDateBack(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },

    //+-------------------------------------------------
    // formatForm()
    // Sets initial values
    // on some form fields
    // -----
    // Created on Mon Jan 24 2022
    //+-------------------------------------------------
    formatForm() {
      if (!this.item.animales_sacrificados) this.item.animales_sacrificados = 0
      if (!this.item.canales_despiezadas) this.item.canales_despiezadas = 0
      if (!this.item.piezas_elaboradas) this.item.piezas_elaboradas = 0
      if (!this.item.piezas_transformadas) this.item.piezas_transformadas = 0
      if (!this.item.carne_comercializada) this.item.carne_comercializada = 0
      if (!this.item.actividades_subcontratadas) this.item.actividades_subcontratadas = ''
      if (!this.item.actividades_realizadas) this.item.actividades_realizadas = []
    },

    setItem() {
      if (this.itemSelected && this.itemSelected.data) this.item = { ...this.itemSelected.data }

      window.setTimeout(() => {
        if (this.$refs.instalaciones && this.item.instalacion_codigo) {
          this.$refs.instalaciones.setValueByCodigo(this.item.instalacion_codigo)
        }
      }, 1000)
    },

    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },
    async init() {
      this.item = this.producto
    },
    async submit() {
      this.$emit('validate')
      if (this.$refs.form.validate() && this.valid) {
        this.loading = true
        setTimeout(() => {
          this.$emit('submit', this.item)
          this.close()
        }, 500)
      }
    },
    // async submit() {
    //   if (this.$refs.form.validate()) {
    //     const body = {
    //       cliente_uuid: this.cliente ? this.cliente : this.$route.params.uuid,
    //       instalacion: {
    //         ...this.item
    //       }
    //     }
    //     body.instalacion.tramites = this.item.tramites.map(element => element.uuid)
    //     if (this.isNew) {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/create', body)
    //     } else {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/update', body)
    //     }
    //     if (!this.isNewCliente) this.$emit('init')
    //     this.close()
    //   }
    // },
    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
    // Filters
    filterTiposEstado(item) {
      const isCarnesFrescas = item.producto === 'carnes_frescas'
      return isCarnesFrescas
        ? this.tiposEstado.filter((element) => element.value === 'fresco')
        : this.tiposEstado
    },
    filterTiposPresentacion(item) {
      const isCarnesFrescas = item.producto === 'carnes_frescas'
      const tiposPresentacion = [
        { text: 'Pieza entera', value: 'entera' },
        { text: 'Troceado', value: 'troceado' },
        { text: 'Fileteado', value: 'fileteado' },
      ]
      return isCarnesFrescas ? tiposPresentacion : this.tiposPresentacion
    },
  },
}
</script>
