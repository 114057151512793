
// TODO: PARA BUSCAR LAS LISTAS DE VALIDACIÓN DE UN OBJETO EN CONCRETO
// HAY QUE ESTABLECER EN EL STORE EL ALCANCE Y EL REF_UUID DEL MISMO,
// POR EJEMPLO, EN EL STORE DE AUDITORÍAS, AL HACER EL GET ONE AÑADIRIAMOS LO SIGUIENTE:
/*
      ("item" es el el objeto resultado del GET)
      rootState.listas_validacion.ref_uuid = item.uuid
      if (item.alcance && item.alcance.uuid) rootState.listas_validacion.alcance = item.alcance.uuid
*/

export const state = () => ({
  modulo: null,
  alcance: null,
  ref_uuid: null,
  lista: null,
  verListaValidacion: false,
  disabled: false
});

export const mutations = {
  SET_MODULO(state, modulo) {
    state.modulo = modulo
  },
  SET_ALCANCE(state, alcance) {
    state.alcance = alcance
  },
  SET_REF_UUID(state, ref_uuid) {
    state.ref_uuid = ref_uuid
  },
  SET_LISTA(state, lista) {
    state.lista = lista
  },
  SET_LISTA_DESHABILIDATA(state) {
    state.disabled = true
  },
  SET_LISTA_HABILIDATA(state) {
    state.disabled = false
  },
};

export const getters = {
  listaValidacion(state) {
    if (state.lista) return state.lista.data
    else return []
  },
  validacionesPendientes(state) {
    if (state.lista) {
      let pendientes = false
      for (let index = 0; index < state.lista.data.length; index++) {
        const item = state.lista.data[index]
        if (!item.check) pendientes = true
        if (index === (state.lista.data.length - 1)) return pendientes
      }
    } else return false
  },
  listaDeshabilidata(state){
    return state.disabled
  }
};

export const actions = {

  // Cada vez que se cambia de ruta se resetean los parámetros de las listas de validación
  async reset({ state }, route) {
    const path = route.path.split('/')
    const modulo = path[1] === 'grupo_solicitudes' ? 'solicitudes' : path[1]
    state.modulo = modulo
    state.alcance = null
    state.ref_uuid = null
    state.lista = null
    state.disabled = false
    if (route.name.indexOf('uuid') !== -1) {
      state.verListaValidacion = true
      state.ref_uuid = route.params.uuid
    }
    else state.verListaValidacion = false
  },

  // ########################
  // ### PLANTILLAS
  // ########################

  async listAll({rootState}) {
    rootState.loading = true
    const { data } = await this.$axios.get('listas-validacion/plantillas')
    rootState.loading = false
    return data
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`listas-validacion/plantillas/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, data) {
    rootState.loading = true
    await this.$axios.post('listas-validacion/plantillas', {
      uuid: data.uuid,
      alcance: data.alcance.uuid,
      modulo: data.modulo,
      plantilla: data.plantilla,
      role: data.role
    })
    rootState.loading = false
  },

  async update({rootState}, data) {
    rootState.loading = true
    await this.$axios.patch(`listas-validacion/plantillas/${data.uuid}`, {
      lista_validacion: data.uuid,
      alcance: data.alcance.uuid,
      modulo: data.modulo,
      plantilla: data.plantilla,
      role: data.role
    })
    rootState.loading = false
  },

  async delete({rootState}, lista) {
    rootState.loading = true
    await this.$axios.delete(`listas-validacion/plantillas/${lista.uuid}`)
    rootState.loading = false
  },

	async clone({ commit }, lista) {
		const res = await this.$axios.post(`listas-validacion/plantillas/${lista.uuid}/clonar`, { lista: lista.uuid });
		if (res) return res.data;
  },

  // #############################
  // ### VALIDACIONES CON REF_UUID
  // #############################

  async list({state}, data) {
    if (state.modulo && state.alcance && state.ref_uuid) {
      const roles = this.$auth.user.roles
      // TODO: ¿Añadimos role y cual del array o lo filtramos en el componente?
      const res = await this.$axios.put('listas-validacion', {
        alcance: state.alcance,
        modulo: state.modulo,
        ref_uuid: state.ref_uuid,
        // role: ''
      })
      if (res) state.lista = res.data
    }
    return state.lista
  },

  async save({state}, lista) {
    if (state.modulo && state.alcance && state.ref_uuid) {
      await this.$axios.patch(`listas-validacion/${lista.uuid}`, {
        lista_validacion: lista.uuid,
        data: lista.data
      })
    }
  }
};
