<template>
  <div>
    <label v-if="label" class="text-capitalize font-weight-bold">Marcas</label>
    <Editor
        :initialValue="initalValue"
        :api-key="tinyApiKey"
        :init="editorInit"
        :key="component"
        @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: "RichTextEditor",
  components: {
    Editor
  },
  props: {
    label: {type: String, default: null},
    height: {type: [String, Number], default: 25},
    component: {type: Number, default: 0},
    initalValue: { type: String, default: null}
  },
  data() {
    return {
      value:''
    }
  },
  computed: {
    tinyApiKey() {
      return this.$store.state.tinyApiKey
    },
    editorInit() {
      return {
        height: this.height + 'vh',
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table pagebreak'
        ],
        toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | table pagebreak | help',
        pagebreak_separator: '<div class="page-break-before"></div>'
      }
    }
  },
  // data: () => ({
  //   component: 0,
  //   localValue: this.value
  // }),
  // methods:{
  //   handleVModel(event){
  //     this.$emit('input', event)
  //   }
  // }
}
</script>

<style scoped>

</style>
