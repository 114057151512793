/**
 * @project: certiapp-nuxt
 * @file:    plantillas-bloques.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st July 2021
 * Last Modified: Fri Dec 30 2022
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // -----
  // Created on Thu Jul 01 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    params = params || {}
    const res = await this.$axios.put(`plantilla-bloques?ref=${params.ref || '0077'}`, params)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // get()
  // Created on Thu Apr 29 2021
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`plantilla-bloques/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // payload:
  // -----
  // Created on Fri Jul 02 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true
    await this.$axios.post(`plantilla-bloques`, {
      uuid: params.uuid || uuid.v4(),
      titulo: params.titulo,
      codigo: params.codigo,
      orden: params.orden,
      alcance: params.alcance,
      modulo: params.modulo,
      content: '',
      tipo: 'html',
    })
    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Fri Apr 30 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let payload = { ...params }
    await this.$axios.patch(`plantilla-bloques/${params.uuid}`, payload)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Mon Jul 05 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`plantilla-bloques/${item.uuid}`)
    rootState.loading = false
  },
}
