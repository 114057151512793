<template>
  <div class="resp-container"
    v-if="coordinates || location">
    <iframe
      id="gmap_canvas"
      :src="URL_MAP"
      class="resp-iframe"
    ></iframe>
  </div>
</template>

<style>
.resp-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 4px;
}
.resp-iframe {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>

<script>
import axios from 'axios'

const client = axios.create()

export default {
  name: 'Mapa',
  props: {
    data: { type: Object, default: () => {} },
    lat: { type: Number, default: 40.4165 },
    lng: { type: Number, default: -3.70256 },
  },
  data: () => ({
    key: 'AIzaSyCNafSox9fnS7rrv11qmM_pIKHabqUsFOw',
    coordinates: null,
  }),
  watch: {
    data() {
      this.getData()
    },
  },
  computed: {
    zoom() {
      return this.coordinates ? '' : '&z=5'
    },

    URL_MAP() {
      const coordinates = this.coordinates ? this.coordinates : `${this.lat},${this.lng}`
      return `https://maps.google.com/maps?q=${coordinates}&t=&z=15&ie=UTF8&iwloc=&output=embed${this.zoom}`
    },

    address() {
      let address = ''
      if (this.data) {
        if (this.data.direccion) address += this.data.direccion
        if (this.data.cp !== undefined) address += '+' + this.data.cp
        if (this.data.pais !== undefined) address += '+' + this.data.pais.nombre
        if (this.data.provincia !== undefined) address += '+' + this.data.provincia.nombre
        if (this.data.municipio !== undefined) address += '+' + this.data.municipio.nombre
      }
      return address.replace(/[ .]/g, '+')
    },

    location() {
      let location = ''
      if (!this.data) return location

      if (this.data.direccion) location += this.data.direccion

      // First check if the location is defined via localidad
      if (this.data.localidad?.provincia) location = this.data.localidad.provincia.nombre + ', '
      if (this.data.localidad?.nombre) location = location + this.data.localidad?.nombre
      if (location !== '') return location.toLowerCase()

      // And if not, check for legacy selects
      if (this.data.provincia) location = this.data.provincia.nombre
      if (this.data.municipio && this.data.municipio.nombre !== location)
        location = location + ', ' + this.data.municipio.nombre
      if (this.data.comunidad) location = location + '. ' + this.data.comunidad.nombre

      if (!location) return ''
      return location.toLowerCase()
    },
  },
  methods: {
    async getData() {
      if (this.location) {
        const res = await client.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${this.address}&key=${this.key}`
        )
        if (res) {
          const results = res.data.results
          if (results.length > 0) {
            const location = results[0].geometry.location
            this.coordinates = `${location.lat},${location.lng}`
          }
        }
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>
