export const state = () => ({
});

//commit mutations
export const mutations = {
};


//dispatch actions
export const actions = {

  async list({rootState}) {
    rootState.loading = true
    const res = await this.$axios.get('solicitudes')
    rootState.loading = false
    if (res) return res.data
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`solicitudes/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, solicitud) {
    rootState.loading = true
    await this.$axios.post(`solicitudes`, solicitud)
    rootState.loading = false
  },

  async update({rootState}, item) {
    rootState.loading = true
    const res = await this.$axios.patch(`solicitudes/${item.uuid}`, {solicitud: item.uuid, datos: item.datos})
    rootState.loading = false
  },

  async aprobar({rootState}, item) {
    rootState.loading = true
    await this.$axios.patch(`solicitudes/${item.uuid}/aprobar`, { solicitud: item.uuid })
    rootState.loading = false
  },

};
