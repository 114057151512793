var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-speed-dial',{attrs:{"fixed":"","bottom":"","right":"","open-on-hover":!_vm.isMobile,"direction":"top","transition":_vm.transition},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v("mdi-close")]):_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_vm._v(" "),(_vm.showDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","color":"red"},on:{"click":function($event){_vm.dialogConfirmDelete = true}}},on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,false,1990978603)},[_vm._v(" "),_c('span',[_vm._v("Eliminar")])]):_vm._e(),_vm._v(" "),(_vm.cancel)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","color":"red"},on:{"click":function($event){return _vm.controlCancel()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,950709711)},[_vm._v(" "),_c('span',[_vm._v("Cancelar")])]):_vm._e(),_vm._v(" "),(_vm.save)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","color":"orange"},on:{"click":function($event){return _vm.controlConfirmation()}}},on),[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)]}}],null,false,1012817851)},[_vm._v(" "),_c('span',[_vm._v("Guardar")])]):_vm._e(),_vm._v(" "),(_vm.pdf)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","color":"red"},on:{"click":function($event){return _vm.controlPDF()}}},on),[_c('v-icon',[_vm._v("mdi-adobe-acrobat")])],1)]}}],null,false,4029891141)},[_vm._v(" "),_c('span',[_vm._v("PDF")])]):_vm._e(),_vm._v(" "),(_vm.to && !_vm.dialog)?_c('v-btn',{attrs:{"to":_vm.to,"fab":"","dark":"","color":"light-green"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_vm._v(" "),(_vm.dialog)?_c('v-btn',{attrs:{"fab":"","dark":"","color":"light-green"},on:{"click":function($event){return _vm.emitDialog()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_vm._v(" "),_c('confirmation',{attrs:{"text":_vm.text || '¿Desea guardar los datos?',"textButton":_vm.textButton || 'Guardar'},on:{"confirm":function($event){return _vm.submit()}},model:{value:(_vm.dialogConfirmSave),callback:function ($$v) {_vm.dialogConfirmSave=$$v},expression:"dialogConfirmSave"}}),_vm._v(" "),_c('confirmation',{attrs:{"text":"¿Desea eliminar el objeto?","colorButton":"red","dark":"","textButton":"Eliminar"},on:{"confirm":function($event){return _vm.deleteItem()}},model:{value:(_vm.dialogConfirmDelete),callback:function ($$v) {_vm.dialogConfirmDelete=$$v},expression:"dialogConfirmDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }