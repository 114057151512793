/**
 * @project: certiapp-nuxt
 * @file:    store/contactos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 12th May 2021
 * Last Modified: Fri Jul 14 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Created on Wed May 12 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true
    let data = {}
    if (params=== undefined) {
       data = { 
        ...params,
      }
    } else
    {
       data = { 
        ...params,
        'limit': params.limit ,
        'page': params.page,
  
      }
      
    }
  
    const res = await this.$axios.put(`contactos`, data)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // listPersonalInterno()
  // Created on Fri Jun 25 2021
  //+-------------------------------------------------

  async listPersonalInterno({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.get(`contactos/personal-interno`)

    rootState.loading = false
    if (res) return res.data
  },

  async listAdmins({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.get(`users/admins`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // get()
  // Created on Wed May 12 2021
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`contactos/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // -----
  // Created on Wed May 12 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    let body = { ...params }
    body.uuid = body.uuid || uuid.v4()
    body.tipo = body.tipo || 'potencial'
    await this.$axios.post(`contactos`, body)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // Does not update the file, only the data
  // -----
  // Created on Fri May 21 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let body = {
      contacto: params.uuid,
      tipo: params.tipo,
      forma_juridica: params.forma_juridica,
      email: params.email,
      emails_otros: params.emails_otros,
      nombre: params.nombre,
      primer_apellido: params.primer_apellido,
      segundo_apellido: params.segundo_apellido,
      telefono: params.telefono,
      telefonos_otros: params.telefonos_otros,
      direccion: params.direccion,
      cp: params.cp,
      nif: params.nif,
      cargo: params.cargo,
      nombre_empresa: params.nombre_empresa,
      intereses: params.intereses,
      observaciones: params.observaciones,
      descripcion: params.descripcion,
      trabajador_propio: params.trabajador_propio || false,
      seguimiento_acciones: params.seguimiento_acciones || [],
    }

    if (params.pais) body.pais = params.pais.uuid
    if (params.provincia) body.provincia = params.provincia.uuid
    if (params.municipio) body.municipio = params.municipio.uuid
    if (params.localidad) body.localidad = params.localidad.uuid
    if (params.comunidad) body.comunidad = params.comunidad.uuid

    await this.$axios.patch(`contactos/${params.uuid}`, body)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // convert()
  // "contacto":  ->string
  // "tipo":  ->string
  // "usuario":  ->string
  // "password":  ->string
  // "email": -> tipo email
  // -----
  // Created on Thu May 13 2021
  //+-------------------------------------------------
  async convert({ rootState }, params) {
    rootState.loading = true

    let body = { ...params }
    await this.$axios.post(`contactos/${params.contacto}/convert`, body)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // changeRoles()
  // Update contact roles
  // -----
  // Created on Tue Dec 27 2022
  //+-------------------------------------------------
  async changeRoles({ rootState }, params) {
    rootState.loading = true
    let body = {
      contacto: params.uuid,
      roles: params.roles,
    }
    await this.$axios.patch(`contactos/${params.uuid}/change-roles`, body)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // Created on Wed May 12 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`contactos/${item.uuid}`)
    rootState.loading = false
  },
}
