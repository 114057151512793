import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _553d3b94 = () => interopDefault(import('../pages/alcances/index.vue' /* webpackChunkName: "pages/alcances/index" */))
const _e85fd310 = () => interopDefault(import('../pages/auditores/index.vue' /* webpackChunkName: "pages/auditores/index" */))
const _29f26967 = () => interopDefault(import('../pages/auditorias/index.vue' /* webpackChunkName: "pages/auditorias/index" */))
const _7624db3d = () => interopDefault(import('../pages/backups/index.vue' /* webpackChunkName: "pages/backups/index" */))
const _5442fd83 = () => interopDefault(import('../pages/bloques/index.vue' /* webpackChunkName: "pages/bloques/index" */))
const _c77e5262 = () => interopDefault(import('../pages/cargas/index.vue' /* webpackChunkName: "pages/cargas/index" */))
const _57d5dfac = () => interopDefault(import('../pages/certificados/index.vue' /* webpackChunkName: "pages/certificados/index" */))
const _33dd1d7a = () => interopDefault(import('../pages/clientes/index.vue' /* webpackChunkName: "pages/clientes/index" */))
const _243053ae = () => interopDefault(import('../pages/compartir/index.vue' /* webpackChunkName: "pages/compartir/index" */))
const _728dc56e = () => interopDefault(import('../pages/competencias/index.vue' /* webpackChunkName: "pages/competencias/index" */))
const _133d45b7 = () => interopDefault(import('../pages/componentes/index.vue' /* webpackChunkName: "pages/componentes/index" */))
const _97005b2c = () => interopDefault(import('../pages/comunicaciones/index.vue' /* webpackChunkName: "pages/comunicaciones/index" */))
const _40fa5ec6 = () => interopDefault(import('../pages/comunicaciones_externas/index.vue' /* webpackChunkName: "pages/comunicaciones_externas/index" */))
const _1d3fbb54 = () => interopDefault(import('../pages/cooperativas/index.vue' /* webpackChunkName: "pages/cooperativas/index" */))
const _176c8a40 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _7727dbd1 = () => interopDefault(import('../pages/delegaciones/index.vue' /* webpackChunkName: "pages/delegaciones/index" */))
const _65c3a77a = () => interopDefault(import('../pages/dictamenes/index.vue' /* webpackChunkName: "pages/dictamenes/index" */))
const _eedfc6ee = () => interopDefault(import('../pages/documentos/index.vue' /* webpackChunkName: "pages/documentos/index" */))
const _cd0b0564 = () => interopDefault(import('../pages/dropdowns/index.vue' /* webpackChunkName: "pages/dropdowns/index" */))
const _425de819 = () => interopDefault(import('../pages/emplazamientos/index.vue' /* webpackChunkName: "pages/emplazamientos/index" */))
const _3f7524c3 = () => interopDefault(import('../pages/estaticos/index.vue' /* webpackChunkName: "pages/estaticos/index" */))
const _398dfc2a = () => interopDefault(import('../pages/etiquetas/index.vue' /* webpackChunkName: "pages/etiquetas/index" */))
const _68b44898 = () => interopDefault(import('../pages/expedientes/index.vue' /* webpackChunkName: "pages/expedientes/index" */))
const _f15df0c8 = () => interopDefault(import('../pages/formaciones/index.vue' /* webpackChunkName: "pages/formaciones/index" */))
const _9123edb6 = () => interopDefault(import('../pages/formatos/index.vue' /* webpackChunkName: "pages/formatos/index" */))
const _6381ed61 = () => interopDefault(import('../pages/generador_formatos/index.vue' /* webpackChunkName: "pages/generador_formatos/index" */))
const _7cbbd4c8 = () => interopDefault(import('../pages/grupo_solicitudes/index.vue' /* webpackChunkName: "pages/grupo_solicitudes/index" */))
const _e3f40198 = () => interopDefault(import('../pages/grupos/index.vue' /* webpackChunkName: "pages/grupos/index" */))
const _6a37dfce = () => interopDefault(import('../pages/incompatibilidades/index.vue' /* webpackChunkName: "pages/incompatibilidades/index" */))
const _028effe1 = () => interopDefault(import('../pages/informes/index.vue' /* webpackChunkName: "pages/informes/index" */))
const _9995ccf6 = () => interopDefault(import('../pages/listas_validacion/index.vue' /* webpackChunkName: "pages/listas_validacion/index" */))
const _397851ba = () => interopDefault(import('../pages/localidades/index.vue' /* webpackChunkName: "pages/localidades/index" */))
const _41b339f5 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _9c3130fe = () => interopDefault(import('../pages/marcas/index.vue' /* webpackChunkName: "pages/marcas/index" */))
const _30d24984 = () => interopDefault(import('../pages/mensajes/index.vue' /* webpackChunkName: "pages/mensajes/index" */))
const _1de6d739 = () => interopDefault(import('../pages/metadata/index.vue' /* webpackChunkName: "pages/metadata/index" */))
const _5ee978c8 = () => interopDefault(import('../pages/permisos/index.vue' /* webpackChunkName: "pages/permisos/index" */))
const _87a652a0 = () => interopDefault(import('../pages/planificador/index.vue' /* webpackChunkName: "pages/planificador/index" */))
const _2c3e151a = () => interopDefault(import('../pages/plantillas/index.vue' /* webpackChunkName: "pages/plantillas/index" */))
const _050f5bf6 = () => interopDefault(import('../pages/portal/index.vue' /* webpackChunkName: "pages/portal/index" */))
const _5f8ebc01 = () => interopDefault(import('../pages/portalpacscorrectiva/index.vue' /* webpackChunkName: "pages/portalpacscorrectiva/index" */))
const _a55cbb2c = () => interopDefault(import('../pages/presupuestos/index.vue' /* webpackChunkName: "pages/presupuestos/index" */))
const _af5924da = () => interopDefault(import('../pages/revisiones/index.vue' /* webpackChunkName: "pages/revisiones/index" */))
const _046ac1ad = () => interopDefault(import('../pages/rlogs/index.vue' /* webpackChunkName: "pages/rlogs/index" */))
const _1cf6c397 = () => interopDefault(import('../pages/selectores/index.vue' /* webpackChunkName: "pages/selectores/index" */))
const _02b311a6 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _b3fc6074 = () => interopDefault(import('../pages/tipos_fichero/index.vue' /* webpackChunkName: "pages/tipos_fichero/index" */))
const _30b41c4d = () => interopDefault(import('../pages/tramites/index.vue' /* webpackChunkName: "pages/tramites/index" */))
const _48087c09 = () => interopDefault(import('../pages/visitas/index.vue' /* webpackChunkName: "pages/visitas/index" */))
const _67194641 = () => interopDefault(import('../pages/visitas_copy/index.vue' /* webpackChunkName: "pages/visitas_copy/index" */))
const _4acc1c34 = () => interopDefault(import('../pages/zonas_visita/index.vue' /* webpackChunkName: "pages/zonas_visita/index" */))
const _17863d48 = () => interopDefault(import('../pages/alcances/ficheros/index.vue' /* webpackChunkName: "pages/alcances/ficheros/index" */))
const _5c70674b = () => interopDefault(import('../pages/auditorias/checklists/index.vue' /* webpackChunkName: "pages/auditorias/checklists/index" */))
const _62296cce = () => interopDefault(import('../pages/auditorias/list.vue' /* webpackChunkName: "pages/auditorias/list" */))
const _ec3e92fe = () => interopDefault(import('../pages/auditorias/previstas/index.vue' /* webpackChunkName: "pages/auditorias/previstas/index" */))
const _6d4d76b4 = () => interopDefault(import('../pages/certificados/list.vue' /* webpackChunkName: "pages/certificados/list" */))
const _edf5184e = () => interopDefault(import('../pages/compartir/details.vue' /* webpackChunkName: "pages/compartir/details" */))
const _500dbb3d = () => interopDefault(import('../pages/dictamenes/list.vue' /* webpackChunkName: "pages/dictamenes/list" */))
const _6c042733 = () => interopDefault(import('../pages/dictamenes/lotes/index.vue' /* webpackChunkName: "pages/dictamenes/lotes/index" */))
const _7e1f4a19 = () => interopDefault(import('../pages/documentos/Details.vue' /* webpackChunkName: "pages/documentos/Details" */))
const _426b739a = () => interopDefault(import('../pages/documentos/Sidebar.vue' /* webpackChunkName: "pages/documentos/Sidebar" */))
const _4dcfb961 = () => interopDefault(import('../pages/errors/unauthorized.vue' /* webpackChunkName: "pages/errors/unauthorized" */))
const _1d1f96dd = () => interopDefault(import('../pages/etiquetas/crudDialog.vue' /* webpackChunkName: "pages/etiquetas/crudDialog" */))
const _5cf5b545 = () => interopDefault(import('../pages/etiquetas/nueva/index.vue' /* webpackChunkName: "pages/etiquetas/nueva/index" */))
const _013023d8 = () => interopDefault(import('../pages/etiquetas/stockBar.vue' /* webpackChunkName: "pages/etiquetas/stockBar" */))
const _21aa276e = () => interopDefault(import('../pages/formaciones/categorias/index.vue' /* webpackChunkName: "pages/formaciones/categorias/index" */))
const _1adcf288 = () => interopDefault(import('../pages/formaciones/situacion/index.vue' /* webpackChunkName: "pages/formaciones/situacion/index" */))
const _3c668ecd = () => interopDefault(import('../pages/formatos/plantillas.vue' /* webpackChunkName: "pages/formatos/plantillas" */))
const _5757a33c = () => interopDefault(import('../pages/grupo_solicitudes/nueva/index.vue' /* webpackChunkName: "pages/grupo_solicitudes/nueva/index" */))
const _5c937636 = () => interopDefault(import('../pages/informes/araporcei/index.vue' /* webpackChunkName: "pages/informes/araporcei/index" */))
const _3eb62552 = () => interopDefault(import('../pages/informes/productores/index.vue' /* webpackChunkName: "pages/informes/productores/index" */))
const _36712f8e = () => interopDefault(import('../pages/mensajes/Details.vue' /* webpackChunkName: "pages/mensajes/Details" */))
const _d1c7a8b0 = () => interopDefault(import('../pages/mensajes/Sidebar.vue' /* webpackChunkName: "pages/mensajes/Sidebar" */))
const _a65383f8 = () => interopDefault(import('../pages/personal/agenda.vue' /* webpackChunkName: "pages/personal/agenda" */))
const _2b874f64 = () => interopDefault(import('../pages/personal/auditores.vue' /* webpackChunkName: "pages/personal/auditores" */))
const _876c359e = () => interopDefault(import('../pages/personal/clientes.vue' /* webpackChunkName: "pages/personal/clientes" */))
const _7dbc37bc = () => interopDefault(import('../pages/personal/grupos.vue' /* webpackChunkName: "pages/personal/grupos" */))
const _3bff85f0 = () => interopDefault(import('../pages/personal/personal-interno.vue' /* webpackChunkName: "pages/personal/personal-interno" */))
const _d11a7ae6 = () => interopDefault(import('../pages/personal/profileAuditor.vue' /* webpackChunkName: "pages/personal/profileAuditor" */))
const _b2921d06 = () => interopDefault(import('../pages/personal/profileBasic.vue' /* webpackChunkName: "pages/personal/profileBasic" */))
const _910863e8 = () => interopDefault(import('../pages/personal/profileClient.vue' /* webpackChunkName: "pages/personal/profileClient" */))
const _33cecf06 = () => interopDefault(import('../pages/personal/usuarios.vue' /* webpackChunkName: "pages/personal/usuarios" */))
const _c12b3592 = () => interopDefault(import('../pages/planificador/Content.vue' /* webpackChunkName: "pages/planificador/Content" */))
const _3d761680 = () => interopDefault(import('../pages/planificador/Details.vue' /* webpackChunkName: "pages/planificador/Details" */))
const _c3bddacc = () => interopDefault(import('../pages/planificador/Sidebar.vue' /* webpackChunkName: "pages/planificador/Sidebar" */))
const _853c6728 = () => interopDefault(import('../pages/portal/auditorias/index.vue' /* webpackChunkName: "pages/portal/auditorias/index" */))
const _133b3419 = () => interopDefault(import('../pages/portal/autoevaluaciones/index.vue' /* webpackChunkName: "pages/portal/autoevaluaciones/index" */))
const _660ed51e = () => interopDefault(import('../pages/portal/certificados/index.vue' /* webpackChunkName: "pages/portal/certificados/index" */))
const _6682d81b = () => interopDefault(import('../pages/portal/cooperativas/index.vue' /* webpackChunkName: "pages/portal/cooperativas/index" */))
const _1bff7f8e = () => interopDefault(import('../pages/portal/documentos/index.vue' /* webpackChunkName: "pages/portal/documentos/index" */))
const _0f02ab74 = () => interopDefault(import('../pages/portal/etiquetas/index.vue' /* webpackChunkName: "pages/portal/etiquetas/index" */))
const _61ce0537 = () => interopDefault(import('../pages/portal/formaciones/index.vue' /* webpackChunkName: "pages/portal/formaciones/index" */))
const _5e156026 = () => interopDefault(import('../pages/portal/informes/index.vue' /* webpackChunkName: "pages/portal/informes/index" */))
const _34b3a5aa = () => interopDefault(import('../pages/portal/instalaciones/index.vue' /* webpackChunkName: "pages/portal/instalaciones/index" */))
const _0da31506 = () => interopDefault(import('../pages/portal/marcas/index.vue' /* webpackChunkName: "pages/portal/marcas/index" */))
const _109bb2db = () => interopDefault(import('../pages/portal/perfil/index.vue' /* webpackChunkName: "pages/portal/perfil/index" */))
const _3ae4bb0e = () => interopDefault(import('../pages/portal/solicitudes/index.vue' /* webpackChunkName: "pages/portal/solicitudes/index" */))
const _06d663a9 = () => interopDefault(import('../pages/portal/tecnicos/index.vue' /* webpackChunkName: "pages/portal/tecnicos/index" */))
const _f0cf4e60 = () => interopDefault(import('../pages/portal/upload/index.vue' /* webpackChunkName: "pages/portal/upload/index" */))
const _38f9e026 = () => interopDefault(import('../pages/revisiones/list.vue' /* webpackChunkName: "pages/revisiones/list" */))
const _48772c83 = () => interopDefault(import('../pages/revisiones/lotes/index.vue' /* webpackChunkName: "pages/revisiones/lotes/index" */))
const _340b3674 = () => interopDefault(import('../pages/bloques/edit/simpletableBlockEditor.vue' /* webpackChunkName: "pages/bloques/edit/simpletableBlockEditor" */))
const _645d6f7c = () => interopDefault(import('../pages/bloques/edit/tableBlockEditor.vue' /* webpackChunkName: "pages/bloques/edit/tableBlockEditor" */))
const _068e4bcb = () => interopDefault(import('../pages/bloques/widgets/blockEmbed.vue' /* webpackChunkName: "pages/bloques/widgets/blockEmbed" */))
const _28a722a5 = () => interopDefault(import('../pages/bloques/widgets/crudDialog.vue' /* webpackChunkName: "pages/bloques/widgets/crudDialog" */))
const _7c0b7b73 = () => interopDefault(import('../pages/dictamenes/lotes/form.vue' /* webpackChunkName: "pages/dictamenes/lotes/form" */))
const _6b1658ef = () => interopDefault(import('../pages/etiquetas/nueva/serie.vue' /* webpackChunkName: "pages/etiquetas/nueva/serie" */))
const _0206d997 = () => interopDefault(import('../pages/informes/ccl/controles.vue' /* webpackChunkName: "pages/informes/ccl/controles" */))
const _55ce9baa = () => interopDefault(import('../pages/informes/ccl/informe_industrias/index.vue' /* webpackChunkName: "pages/informes/ccl/informe_industrias/index" */))
const _d95a3a28 = () => interopDefault(import('../pages/informes/ccl/informe_operadores/index.vue' /* webpackChunkName: "pages/informes/ccl/informe_operadores/index" */))
const _e762ab04 = () => interopDefault(import('../pages/informes/ccl/informe_superficies/index.vue' /* webpackChunkName: "pages/informes/ccl/informe_superficies/index" */))
const _250e9ed8 = () => interopDefault(import('../pages/informes/ccl/no_conformidades.vue' /* webpackChunkName: "pages/informes/ccl/no_conformidades" */))
const _59781f72 = () => interopDefault(import('../pages/informes/coplaca/dictamenes.vue' /* webpackChunkName: "pages/informes/coplaca/dictamenes" */))
const _25a255da = () => interopDefault(import('../pages/informes/coplaca/pacs.vue' /* webpackChunkName: "pages/informes/coplaca/pacs" */))
const _6993ec34 = () => interopDefault(import('../pages/informes/coplaca/parcelas.vue' /* webpackChunkName: "pages/informes/coplaca/parcelas" */))
const _568af474 = () => interopDefault(import('../pages/informes/coplaca/situacion.vue' /* webpackChunkName: "pages/informes/coplaca/situacion" */))
const _008519f0 = () => interopDefault(import('../pages/informes/quesomanchego/control_etiquetas.vue' /* webpackChunkName: "pages/informes/quesomanchego/control_etiquetas" */))
const _acda5fa2 = () => interopDefault(import('../pages/informes/quesomanchego/incumplimientos_trimestrales.vue' /* webpackChunkName: "pages/informes/quesomanchego/incumplimientos_trimestrales" */))
const _4bff348a = () => interopDefault(import('../pages/informes/quesomanchego/operadores_certificados.vue' /* webpackChunkName: "pages/informes/quesomanchego/operadores_certificados" */))
const _242ced05 = () => interopDefault(import('../pages/localidades/widgets/crudDialog.vue' /* webpackChunkName: "pages/localidades/widgets/crudDialog" */))
const _190d7ea2 = () => interopDefault(import('../pages/metadata/widgets/crudDialog.vue' /* webpackChunkName: "pages/metadata/widgets/crudDialog" */))
const _f9be8610 = () => interopDefault(import('../pages/personal/edit/tabHistorico.vue' /* webpackChunkName: "pages/personal/edit/tabHistorico" */))
const _11579e74 = () => interopDefault(import('../pages/personal/widgets/contactAltaBaja.vue' /* webpackChunkName: "pages/personal/widgets/contactAltaBaja" */))
const _bee8651a = () => interopDefault(import('../pages/personal/widgets/moduleAdministracion.vue' /* webpackChunkName: "pages/personal/widgets/moduleAdministracion" */))
const _3191685a = () => interopDefault(import('../pages/personal/widgets/moduleCalificaciones.vue' /* webpackChunkName: "pages/personal/widgets/moduleCalificaciones" */))
const _5746b723 = () => interopDefault(import('../pages/personal/widgets/moduleCompetencias.vue' /* webpackChunkName: "pages/personal/widgets/moduleCompetencias" */))
const _cb4f7260 = () => interopDefault(import('../pages/personal/widgets/moduleContactos.vue' /* webpackChunkName: "pages/personal/widgets/moduleContactos" */))
const _04db79a3 = () => interopDefault(import('../pages/personal/widgets/moduleDocumentos.vue' /* webpackChunkName: "pages/personal/widgets/moduleDocumentos" */))
const _667bb258 = () => interopDefault(import('../pages/personal/widgets/moduleExpedientes.vue' /* webpackChunkName: "pages/personal/widgets/moduleExpedientes" */))
const _5e0ca20a = () => interopDefault(import('../pages/personal/widgets/moduleHistorial.vue' /* webpackChunkName: "pages/personal/widgets/moduleHistorial" */))
const _a7398c4a = () => interopDefault(import('../pages/personal/widgets/moduleImportaciones.vue' /* webpackChunkName: "pages/personal/widgets/moduleImportaciones" */))
const _033aec33 = () => interopDefault(import('../pages/personal/widgets/moduleIncompatibilidades.vue' /* webpackChunkName: "pages/personal/widgets/moduleIncompatibilidades" */))
const _1ec8a5e2 = () => interopDefault(import('../pages/personal/widgets/moduleInstalaciones.vue' /* webpackChunkName: "pages/personal/widgets/moduleInstalaciones" */))
const _2c7315ca = () => interopDefault(import('../pages/personal/widgets/moduleMarcas.vue' /* webpackChunkName: "pages/personal/widgets/moduleMarcas" */))
const _74fbd462 = () => interopDefault(import('../pages/personal/widgets/modulePermisos.vue' /* webpackChunkName: "pages/personal/widgets/modulePermisos" */))
const _ed54f804 = () => interopDefault(import('../pages/personal/widgets/moduleTecnicos.vue' /* webpackChunkName: "pages/personal/widgets/moduleTecnicos" */))
const _79b6f2be = () => interopDefault(import('../pages/personal/widgets/quickCreate.vue' /* webpackChunkName: "pages/personal/widgets/quickCreate" */))
const _1e0a030a = () => interopDefault(import('../pages/personal/widgets/quickView.vue' /* webpackChunkName: "pages/personal/widgets/quickView" */))
const _325d6b2a = () => interopDefault(import('../pages/planificador/widgets/editAuditoria.vue' /* webpackChunkName: "pages/planificador/widgets/editAuditoria" */))
const _05a1909d = () => interopDefault(import('../pages/portal/etiquetas/nueva.vue' /* webpackChunkName: "pages/portal/etiquetas/nueva" */))
const _3de1d1d7 = () => interopDefault(import('../pages/portal/instalaciones/create/index.vue' /* webpackChunkName: "pages/portal/instalaciones/create/index" */))
const _1b14ded4 = () => interopDefault(import('../pages/portal/perfil/edit/index.vue' /* webpackChunkName: "pages/portal/perfil/edit/index" */))
const _bdb0c3b0 = () => interopDefault(import('../pages/portal/solicitudes/create/index.vue' /* webpackChunkName: "pages/portal/solicitudes/create/index" */))
const _4e27b210 = () => interopDefault(import('../pages/portal/tecnicos/create/index.vue' /* webpackChunkName: "pages/portal/tecnicos/create/index" */))
const _650ae7ba = () => interopDefault(import('../pages/revisiones/lotes/form.vue' /* webpackChunkName: "pages/revisiones/lotes/form" */))
const _40cb5f34 = () => interopDefault(import('../pages/auditorias/agrocolor/global_gap/cultivos.vue' /* webpackChunkName: "pages/auditorias/agrocolor/global_gap/cultivos" */))
const _479bee9d = () => interopDefault(import('../pages/auditorias/agrocolor/global/cultivos.vue' /* webpackChunkName: "pages/auditorias/agrocolor/global/cultivos" */))
const _c199aaa6 = () => interopDefault(import('../pages/informes/ccl/informe_operadores/operadores_baja.vue' /* webpackChunkName: "pages/informes/ccl/informe_operadores/operadores_baja" */))
const _56937a8e = () => interopDefault(import('../pages/portal/auditorias/pacs/accionCorrectiva.vue' /* webpackChunkName: "pages/portal/auditorias/pacs/accionCorrectiva" */))
const _09289ff2 = () => interopDefault(import('../pages/portal/informes/coplaca/auditoria.vue' /* webpackChunkName: "pages/portal/informes/coplaca/auditoria" */))
const _6f331ecf = () => interopDefault(import('../pages/portal/informes/coplaca/parcelas.vue' /* webpackChunkName: "pages/portal/informes/coplaca/parcelas" */))
const _e608374a = () => interopDefault(import('../pages/auditorias/tabs/checklist/_uuid.vue' /* webpackChunkName: "pages/auditorias/tabs/checklist/_uuid" */))
const _7afaab52 = () => interopDefault(import('../pages/auditorias/tabs/muestras/_uuid.vue' /* webpackChunkName: "pages/auditorias/tabs/muestras/_uuid" */))
const _0c00c532 = () => interopDefault(import('../pages/auditorias/tabs/pacs/_uuid.vue' /* webpackChunkName: "pages/auditorias/tabs/pacs/_uuid" */))
const _a5f4c5e0 = () => interopDefault(import('../pages/alcances/ficheros/_uuid.vue' /* webpackChunkName: "pages/alcances/ficheros/_uuid" */))
const _1c2071da = () => interopDefault(import('../pages/auditorias/checklists/_uuid.vue' /* webpackChunkName: "pages/auditorias/checklists/_uuid" */))
const _02995cf4 = () => interopDefault(import('../pages/bloques/edit/_uuid.vue' /* webpackChunkName: "pages/bloques/edit/_uuid" */))
const _018386fb = () => interopDefault(import('../pages/dictamenes/lotes/_uuid.vue' /* webpackChunkName: "pages/dictamenes/lotes/_uuid" */))
const _55e006ab = () => interopDefault(import('../pages/expedientes/iteracion/_expediente.vue' /* webpackChunkName: "pages/expedientes/iteracion/_expediente" */))
const _5794f5cc = () => interopDefault(import('../pages/informes/productores/_uuid.vue' /* webpackChunkName: "pages/informes/productores/_uuid" */))
const _43a25466 = () => interopDefault(import('../pages/personal/edit/_uuid.vue' /* webpackChunkName: "pages/personal/edit/_uuid" */))
const _52e12c34 = () => interopDefault(import('../pages/portal/auditorias/_uuid.vue' /* webpackChunkName: "pages/portal/auditorias/_uuid" */))
const _ae8ad83e = () => interopDefault(import('../pages/portal/autoevaluaciones/_uuid.vue' /* webpackChunkName: "pages/portal/autoevaluaciones/_uuid" */))
const _07fb903a = () => interopDefault(import('../pages/portal/cooperativas/_uuid.vue' /* webpackChunkName: "pages/portal/cooperativas/_uuid" */))
const _11653602 = () => interopDefault(import('../pages/portal/formaciones/_uuid.vue' /* webpackChunkName: "pages/portal/formaciones/_uuid" */))
const _b9bb1664 = () => interopDefault(import('../pages/portal/marcas/_uuid.vue' /* webpackChunkName: "pages/portal/marcas/_uuid" */))
const _780d0241 = () => interopDefault(import('../pages/portal/solicitudes/_uuid.vue' /* webpackChunkName: "pages/portal/solicitudes/_uuid" */))
const _305ec579 = () => interopDefault(import('../pages/public/documentos/_uuid.vue' /* webpackChunkName: "pages/public/documentos/_uuid" */))
const _0d6647d6 = () => interopDefault(import('../pages/public/formatos/_uuid.vue' /* webpackChunkName: "pages/public/formatos/_uuid" */))
const _4412e76a = () => interopDefault(import('../pages/revisiones/lotes/_uuid.vue' /* webpackChunkName: "pages/revisiones/lotes/_uuid" */))
const _6ae0c1fe = () => interopDefault(import('../pages/alcances/_uuid.vue' /* webpackChunkName: "pages/alcances/_uuid" */))
const _214f7640 = () => interopDefault(import('../pages/auditores/_uuid.vue' /* webpackChunkName: "pages/auditores/_uuid" */))
const _811c6da2 = () => interopDefault(import('../pages/auditorias/_uuid.vue' /* webpackChunkName: "pages/auditorias/_uuid" */))
const _2c7b456a = () => interopDefault(import('../pages/bloques/_uuid.vue' /* webpackChunkName: "pages/bloques/_uuid" */))
const _31c03697 = () => interopDefault(import('../pages/cargas/_uuid.vue' /* webpackChunkName: "pages/cargas/_uuid" */))
const _25558118 = () => interopDefault(import('../pages/certificados/_uuid.vue' /* webpackChunkName: "pages/certificados/_uuid" */))
const _7b90d10b = () => interopDefault(import('../pages/clientes/_uuid.vue' /* webpackChunkName: "pages/clientes/_uuid" */))
const _f931941e = () => interopDefault(import('../pages/compartir/_uuid.vue' /* webpackChunkName: "pages/compartir/_uuid" */))
const _5c387d11 = () => interopDefault(import('../pages/competencias/_uuid.vue' /* webpackChunkName: "pages/competencias/_uuid" */))
const _ae86b502 = () => interopDefault(import('../pages/componentes/_uuid.vue' /* webpackChunkName: "pages/componentes/_uuid" */))
const _0ca73b99 = () => interopDefault(import('../pages/delegaciones/_uuid.vue' /* webpackChunkName: "pages/delegaciones/_uuid" */))
const _629d8c0b = () => interopDefault(import('../pages/dictamenes/_uuid.vue' /* webpackChunkName: "pages/dictamenes/_uuid" */))
const _2ef9dd16 = () => interopDefault(import('../pages/dropdowns/_uuid.vue' /* webpackChunkName: "pages/dropdowns/_uuid" */))
const _5045703e = () => interopDefault(import('../pages/emplazamientos/_uuid.vue' /* webpackChunkName: "pages/emplazamientos/_uuid" */))
const _5616f6ea = () => interopDefault(import('../pages/estaticos/_uuid.vue' /* webpackChunkName: "pages/estaticos/_uuid" */))
const _0398af40 = () => interopDefault(import('../pages/expedientes/_uuid.vue' /* webpackChunkName: "pages/expedientes/_uuid" */))
const _1cd06764 = () => interopDefault(import('../pages/formaciones/_uuid.vue' /* webpackChunkName: "pages/formaciones/_uuid" */))
const _4ced68ed = () => interopDefault(import('../pages/formatos/_uuid.vue' /* webpackChunkName: "pages/formatos/_uuid" */))
const _123b3490 = () => interopDefault(import('../pages/grupo_solicitudes/_uuid.vue' /* webpackChunkName: "pages/grupo_solicitudes/_uuid" */))
const _23855efc = () => interopDefault(import('../pages/grupos/_uuid.vue' /* webpackChunkName: "pages/grupos/_uuid" */))
const _60636fe1 = () => interopDefault(import('../pages/incompatibilidades/_uuid.vue' /* webpackChunkName: "pages/incompatibilidades/_uuid" */))
const _48b4794d = () => interopDefault(import('../pages/listas_validacion/_uuid.vue' /* webpackChunkName: "pages/listas_validacion/_uuid" */))
const _4766c749 = () => interopDefault(import('../pages/marcas/_uuid.vue' /* webpackChunkName: "pages/marcas/_uuid" */))
const _993391fe = () => interopDefault(import('../pages/metadata/_uuid.vue' /* webpackChunkName: "pages/metadata/_uuid" */))
const _343900d2 = () => interopDefault(import('../pages/personal/_uuid.vue' /* webpackChunkName: "pages/personal/_uuid" */))
const _7c85163c = () => interopDefault(import('../pages/plantillas/_uuid.vue' /* webpackChunkName: "pages/plantillas/_uuid" */))
const _42d10232 = () => interopDefault(import('../pages/presupuestos/_uuid.vue' /* webpackChunkName: "pages/presupuestos/_uuid" */))
const _3dd2cd5b = () => interopDefault(import('../pages/revisiones/_uuid.vue' /* webpackChunkName: "pages/revisiones/_uuid" */))
const _9b13b942 = () => interopDefault(import('../pages/selectores/_uuid.vue' /* webpackChunkName: "pages/selectores/_uuid" */))
const _3b812f8e = () => interopDefault(import('../pages/tipos_fichero/_uuid.vue' /* webpackChunkName: "pages/tipos_fichero/_uuid" */))
const _739907d6 = () => interopDefault(import('../pages/tramites/_uuid.vue' /* webpackChunkName: "pages/tramites/_uuid" */))
const _3f690808 = () => interopDefault(import('../pages/zonas_visita/_uuid.vue' /* webpackChunkName: "pages/zonas_visita/_uuid" */))
const _9341e44a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alcances",
    component: _553d3b94,
    name: "alcances"
  }, {
    path: "/auditores",
    component: _e85fd310,
    name: "auditores"
  }, {
    path: "/auditorias",
    component: _29f26967,
    name: "auditorias"
  }, {
    path: "/backups",
    component: _7624db3d,
    name: "backups"
  }, {
    path: "/bloques",
    component: _5442fd83,
    name: "bloques"
  }, {
    path: "/cargas",
    component: _c77e5262,
    name: "cargas"
  }, {
    path: "/certificados",
    component: _57d5dfac,
    name: "certificados"
  }, {
    path: "/clientes",
    component: _33dd1d7a,
    name: "clientes"
  }, {
    path: "/compartir",
    component: _243053ae,
    name: "compartir"
  }, {
    path: "/competencias",
    component: _728dc56e,
    name: "competencias"
  }, {
    path: "/componentes",
    component: _133d45b7,
    name: "componentes"
  }, {
    path: "/comunicaciones",
    component: _97005b2c,
    name: "comunicaciones"
  }, {
    path: "/comunicaciones_externas",
    component: _40fa5ec6,
    name: "comunicaciones_externas"
  }, {
    path: "/cooperativas",
    component: _1d3fbb54,
    name: "cooperativas"
  }, {
    path: "/dashboard",
    component: _176c8a40,
    name: "dashboard"
  }, {
    path: "/delegaciones",
    component: _7727dbd1,
    name: "delegaciones"
  }, {
    path: "/dictamenes",
    component: _65c3a77a,
    name: "dictamenes"
  }, {
    path: "/documentos",
    component: _eedfc6ee,
    name: "documentos"
  }, {
    path: "/dropdowns",
    component: _cd0b0564,
    name: "dropdowns"
  }, {
    path: "/emplazamientos",
    component: _425de819,
    name: "emplazamientos"
  }, {
    path: "/estaticos",
    component: _3f7524c3,
    name: "estaticos"
  }, {
    path: "/etiquetas",
    component: _398dfc2a,
    name: "etiquetas"
  }, {
    path: "/expedientes",
    component: _68b44898,
    name: "expedientes"
  }, {
    path: "/formaciones",
    component: _f15df0c8,
    name: "formaciones"
  }, {
    path: "/formatos",
    component: _9123edb6,
    name: "formatos"
  }, {
    path: "/generador_formatos",
    component: _6381ed61,
    name: "generador_formatos"
  }, {
    path: "/grupo_solicitudes",
    component: _7cbbd4c8,
    name: "grupo_solicitudes"
  }, {
    path: "/grupos",
    component: _e3f40198,
    name: "grupos"
  }, {
    path: "/incompatibilidades",
    component: _6a37dfce,
    name: "incompatibilidades"
  }, {
    path: "/informes",
    component: _028effe1,
    name: "informes"
  }, {
    path: "/listas_validacion",
    component: _9995ccf6,
    name: "listas_validacion"
  }, {
    path: "/localidades",
    component: _397851ba,
    name: "localidades"
  }, {
    path: "/login",
    component: _41b339f5,
    name: "login"
  }, {
    path: "/marcas",
    component: _9c3130fe,
    name: "marcas"
  }, {
    path: "/mensajes",
    component: _30d24984,
    name: "mensajes"
  }, {
    path: "/metadata",
    component: _1de6d739,
    name: "metadata"
  }, {
    path: "/permisos",
    component: _5ee978c8,
    name: "permisos"
  }, {
    path: "/planificador",
    component: _87a652a0,
    name: "planificador"
  }, {
    path: "/plantillas",
    component: _2c3e151a,
    name: "plantillas"
  }, {
    path: "/portal",
    component: _050f5bf6,
    name: "portal"
  }, {
    path: "/portalpacscorrectiva",
    component: _5f8ebc01,
    name: "portalpacscorrectiva"
  }, {
    path: "/presupuestos",
    component: _a55cbb2c,
    name: "presupuestos"
  }, {
    path: "/revisiones",
    component: _af5924da,
    name: "revisiones"
  }, {
    path: "/rlogs",
    component: _046ac1ad,
    name: "rlogs"
  }, {
    path: "/selectores",
    component: _1cf6c397,
    name: "selectores"
  }, {
    path: "/settings",
    component: _02b311a6,
    name: "settings"
  }, {
    path: "/tipos_fichero",
    component: _b3fc6074,
    name: "tipos_fichero"
  }, {
    path: "/tramites",
    component: _30b41c4d,
    name: "tramites"
  }, {
    path: "/visitas",
    component: _48087c09,
    name: "visitas"
  }, {
    path: "/visitas_copy",
    component: _67194641,
    name: "visitas_copy"
  }, {
    path: "/zonas_visita",
    component: _4acc1c34,
    name: "zonas_visita"
  }, {
    path: "/alcances/ficheros",
    component: _17863d48,
    name: "alcances-ficheros"
  }, {
    path: "/auditorias/checklists",
    component: _5c70674b,
    name: "auditorias-checklists"
  }, {
    path: "/auditorias/list",
    component: _62296cce,
    name: "auditorias-list"
  }, {
    path: "/auditorias/previstas",
    component: _ec3e92fe,
    name: "auditorias-previstas"
  }, {
    path: "/certificados/list",
    component: _6d4d76b4,
    name: "certificados-list"
  }, {
    path: "/compartir/details",
    component: _edf5184e,
    name: "compartir-details"
  }, {
    path: "/dictamenes/list",
    component: _500dbb3d,
    name: "dictamenes-list"
  }, {
    path: "/dictamenes/lotes",
    component: _6c042733,
    name: "dictamenes-lotes"
  }, {
    path: "/documentos/Details",
    component: _7e1f4a19,
    name: "documentos-Details"
  }, {
    path: "/documentos/Sidebar",
    component: _426b739a,
    name: "documentos-Sidebar"
  }, {
    path: "/errors/unauthorized",
    component: _4dcfb961,
    name: "errors-unauthorized"
  }, {
    path: "/etiquetas/crudDialog",
    component: _1d1f96dd,
    name: "etiquetas-crudDialog"
  }, {
    path: "/etiquetas/nueva",
    component: _5cf5b545,
    name: "etiquetas-nueva"
  }, {
    path: "/etiquetas/stockBar",
    component: _013023d8,
    name: "etiquetas-stockBar"
  }, {
    path: "/formaciones/categorias",
    component: _21aa276e,
    name: "formaciones-categorias"
  }, {
    path: "/formaciones/situacion",
    component: _1adcf288,
    name: "formaciones-situacion"
  }, {
    path: "/formatos/plantillas",
    component: _3c668ecd,
    name: "formatos-plantillas"
  }, {
    path: "/grupo_solicitudes/nueva",
    component: _5757a33c,
    name: "grupo_solicitudes-nueva"
  }, {
    path: "/informes/araporcei",
    component: _5c937636,
    name: "informes-araporcei"
  }, {
    path: "/informes/productores",
    component: _3eb62552,
    name: "informes-productores"
  }, {
    path: "/mensajes/Details",
    component: _36712f8e,
    name: "mensajes-Details"
  }, {
    path: "/mensajes/Sidebar",
    component: _d1c7a8b0,
    name: "mensajes-Sidebar"
  }, {
    path: "/personal/agenda",
    component: _a65383f8,
    name: "personal-agenda"
  }, {
    path: "/personal/auditores",
    component: _2b874f64,
    name: "personal-auditores"
  }, {
    path: "/personal/clientes",
    component: _876c359e,
    name: "personal-clientes"
  }, {
    path: "/personal/grupos",
    component: _7dbc37bc,
    name: "personal-grupos"
  }, {
    path: "/personal/personal-interno",
    component: _3bff85f0,
    name: "personal-personal-interno"
  }, {
    path: "/personal/profileAuditor",
    component: _d11a7ae6,
    name: "personal-profileAuditor"
  }, {
    path: "/personal/profileBasic",
    component: _b2921d06,
    name: "personal-profileBasic"
  }, {
    path: "/personal/profileClient",
    component: _910863e8,
    name: "personal-profileClient"
  }, {
    path: "/personal/usuarios",
    component: _33cecf06,
    name: "personal-usuarios"
  }, {
    path: "/planificador/Content",
    component: _c12b3592,
    name: "planificador-Content"
  }, {
    path: "/planificador/Details",
    component: _3d761680,
    name: "planificador-Details"
  }, {
    path: "/planificador/Sidebar",
    component: _c3bddacc,
    name: "planificador-Sidebar"
  }, {
    path: "/portal/auditorias",
    component: _853c6728,
    name: "portal-auditorias"
  }, {
    path: "/portal/autoevaluaciones",
    component: _133b3419,
    name: "portal-autoevaluaciones"
  }, {
    path: "/portal/certificados",
    component: _660ed51e,
    name: "portal-certificados"
  }, {
    path: "/portal/cooperativas",
    component: _6682d81b,
    name: "portal-cooperativas"
  }, {
    path: "/portal/documentos",
    component: _1bff7f8e,
    name: "portal-documentos"
  }, {
    path: "/portal/etiquetas",
    component: _0f02ab74,
    name: "portal-etiquetas"
  }, {
    path: "/portal/formaciones",
    component: _61ce0537,
    name: "portal-formaciones"
  }, {
    path: "/portal/informes",
    component: _5e156026,
    name: "portal-informes"
  }, {
    path: "/portal/instalaciones",
    component: _34b3a5aa,
    name: "portal-instalaciones"
  }, {
    path: "/portal/marcas",
    component: _0da31506,
    name: "portal-marcas"
  }, {
    path: "/portal/perfil",
    component: _109bb2db,
    name: "portal-perfil"
  }, {
    path: "/portal/solicitudes",
    component: _3ae4bb0e,
    name: "portal-solicitudes"
  }, {
    path: "/portal/tecnicos",
    component: _06d663a9,
    name: "portal-tecnicos"
  }, {
    path: "/portal/upload",
    component: _f0cf4e60,
    name: "portal-upload"
  }, {
    path: "/revisiones/list",
    component: _38f9e026,
    name: "revisiones-list"
  }, {
    path: "/revisiones/lotes",
    component: _48772c83,
    name: "revisiones-lotes"
  }, {
    path: "/bloques/edit/simpletableBlockEditor",
    component: _340b3674,
    name: "bloques-edit-simpletableBlockEditor"
  }, {
    path: "/bloques/edit/tableBlockEditor",
    component: _645d6f7c,
    name: "bloques-edit-tableBlockEditor"
  }, {
    path: "/bloques/widgets/blockEmbed",
    component: _068e4bcb,
    name: "bloques-widgets-blockEmbed"
  }, {
    path: "/bloques/widgets/crudDialog",
    component: _28a722a5,
    name: "bloques-widgets-crudDialog"
  }, {
    path: "/dictamenes/lotes/form",
    component: _7c0b7b73,
    name: "dictamenes-lotes-form"
  }, {
    path: "/etiquetas/nueva/serie",
    component: _6b1658ef,
    name: "etiquetas-nueva-serie"
  }, {
    path: "/informes/ccl/controles",
    component: _0206d997,
    name: "informes-ccl-controles"
  }, {
    path: "/informes/ccl/informe_industrias",
    component: _55ce9baa,
    name: "informes-ccl-informe_industrias"
  }, {
    path: "/informes/ccl/informe_operadores",
    component: _d95a3a28,
    name: "informes-ccl-informe_operadores"
  }, {
    path: "/informes/ccl/informe_superficies",
    component: _e762ab04,
    name: "informes-ccl-informe_superficies"
  }, {
    path: "/informes/ccl/no_conformidades",
    component: _250e9ed8,
    name: "informes-ccl-no_conformidades"
  }, {
    path: "/informes/coplaca/dictamenes",
    component: _59781f72,
    name: "informes-coplaca-dictamenes"
  }, {
    path: "/informes/coplaca/pacs",
    component: _25a255da,
    name: "informes-coplaca-pacs"
  }, {
    path: "/informes/coplaca/parcelas",
    component: _6993ec34,
    name: "informes-coplaca-parcelas"
  }, {
    path: "/informes/coplaca/situacion",
    component: _568af474,
    name: "informes-coplaca-situacion"
  }, {
    path: "/informes/quesomanchego/control_etiquetas",
    component: _008519f0,
    name: "informes-quesomanchego-control_etiquetas"
  }, {
    path: "/informes/quesomanchego/incumplimientos_trimestrales",
    component: _acda5fa2,
    name: "informes-quesomanchego-incumplimientos_trimestrales"
  }, {
    path: "/informes/quesomanchego/operadores_certificados",
    component: _4bff348a,
    name: "informes-quesomanchego-operadores_certificados"
  }, {
    path: "/localidades/widgets/crudDialog",
    component: _242ced05,
    name: "localidades-widgets-crudDialog"
  }, {
    path: "/metadata/widgets/crudDialog",
    component: _190d7ea2,
    name: "metadata-widgets-crudDialog"
  }, {
    path: "/personal/edit/tabHistorico",
    component: _f9be8610,
    name: "personal-edit-tabHistorico"
  }, {
    path: "/personal/widgets/contactAltaBaja",
    component: _11579e74,
    name: "personal-widgets-contactAltaBaja"
  }, {
    path: "/personal/widgets/moduleAdministracion",
    component: _bee8651a,
    name: "personal-widgets-moduleAdministracion"
  }, {
    path: "/personal/widgets/moduleCalificaciones",
    component: _3191685a,
    name: "personal-widgets-moduleCalificaciones"
  }, {
    path: "/personal/widgets/moduleCompetencias",
    component: _5746b723,
    name: "personal-widgets-moduleCompetencias"
  }, {
    path: "/personal/widgets/moduleContactos",
    component: _cb4f7260,
    name: "personal-widgets-moduleContactos"
  }, {
    path: "/personal/widgets/moduleDocumentos",
    component: _04db79a3,
    name: "personal-widgets-moduleDocumentos"
  }, {
    path: "/personal/widgets/moduleExpedientes",
    component: _667bb258,
    name: "personal-widgets-moduleExpedientes"
  }, {
    path: "/personal/widgets/moduleHistorial",
    component: _5e0ca20a,
    name: "personal-widgets-moduleHistorial"
  }, {
    path: "/personal/widgets/moduleImportaciones",
    component: _a7398c4a,
    name: "personal-widgets-moduleImportaciones"
  }, {
    path: "/personal/widgets/moduleIncompatibilidades",
    component: _033aec33,
    name: "personal-widgets-moduleIncompatibilidades"
  }, {
    path: "/personal/widgets/moduleInstalaciones",
    component: _1ec8a5e2,
    name: "personal-widgets-moduleInstalaciones"
  }, {
    path: "/personal/widgets/moduleMarcas",
    component: _2c7315ca,
    name: "personal-widgets-moduleMarcas"
  }, {
    path: "/personal/widgets/modulePermisos",
    component: _74fbd462,
    name: "personal-widgets-modulePermisos"
  }, {
    path: "/personal/widgets/moduleTecnicos",
    component: _ed54f804,
    name: "personal-widgets-moduleTecnicos"
  }, {
    path: "/personal/widgets/quickCreate",
    component: _79b6f2be,
    name: "personal-widgets-quickCreate"
  }, {
    path: "/personal/widgets/quickView",
    component: _1e0a030a,
    name: "personal-widgets-quickView"
  }, {
    path: "/planificador/widgets/editAuditoria",
    component: _325d6b2a,
    name: "planificador-widgets-editAuditoria"
  }, {
    path: "/portal/etiquetas/nueva",
    component: _05a1909d,
    name: "portal-etiquetas-nueva"
  }, {
    path: "/portal/instalaciones/create",
    component: _3de1d1d7,
    name: "portal-instalaciones-create"
  }, {
    path: "/portal/perfil/edit",
    component: _1b14ded4,
    name: "portal-perfil-edit"
  }, {
    path: "/portal/solicitudes/create",
    component: _bdb0c3b0,
    name: "portal-solicitudes-create"
  }, {
    path: "/portal/tecnicos/create",
    component: _4e27b210,
    name: "portal-tecnicos-create"
  }, {
    path: "/revisiones/lotes/form",
    component: _650ae7ba,
    name: "revisiones-lotes-form"
  }, {
    path: "/auditorias/agrocolor/global_gap/cultivos",
    component: _40cb5f34,
    name: "auditorias-agrocolor-global_gap-cultivos"
  }, {
    path: "/auditorias/agrocolor/global/cultivos",
    component: _479bee9d,
    name: "auditorias-agrocolor-global-cultivos"
  }, {
    path: "/informes/ccl/informe_operadores/operadores_baja",
    component: _c199aaa6,
    name: "informes-ccl-informe_operadores-operadores_baja"
  }, {
    path: "/portal/auditorias/pacs/accionCorrectiva",
    component: _56937a8e,
    name: "portal-auditorias-pacs-accionCorrectiva"
  }, {
    path: "/portal/informes/coplaca/auditoria",
    component: _09289ff2,
    name: "portal-informes-coplaca-auditoria"
  }, {
    path: "/portal/informes/coplaca/parcelas",
    component: _6f331ecf,
    name: "portal-informes-coplaca-parcelas"
  }, {
    path: "/auditorias/tabs/checklist/:uuid?",
    component: _e608374a,
    name: "auditorias-tabs-checklist-uuid"
  }, {
    path: "/auditorias/tabs/muestras/:uuid?",
    component: _7afaab52,
    name: "auditorias-tabs-muestras-uuid"
  }, {
    path: "/auditorias/tabs/pacs/:uuid?",
    component: _0c00c532,
    name: "auditorias-tabs-pacs-uuid"
  }, {
    path: "/alcances/ficheros/:uuid",
    component: _a5f4c5e0,
    name: "alcances-ficheros-uuid"
  }, {
    path: "/auditorias/checklists/:uuid",
    component: _1c2071da,
    name: "auditorias-checklists-uuid"
  }, {
    path: "/bloques/edit/:uuid?",
    component: _02995cf4,
    name: "bloques-edit-uuid"
  }, {
    path: "/dictamenes/lotes/:uuid",
    component: _018386fb,
    name: "dictamenes-lotes-uuid"
  }, {
    path: "/expedientes/iteracion/:expediente?",
    component: _55e006ab,
    name: "expedientes-iteracion-expediente"
  }, {
    path: "/informes/productores/:uuid",
    component: _5794f5cc,
    name: "informes-productores-uuid"
  }, {
    path: "/personal/edit/:uuid?",
    component: _43a25466,
    name: "personal-edit-uuid"
  }, {
    path: "/portal/auditorias/:uuid",
    component: _52e12c34,
    name: "portal-auditorias-uuid"
  }, {
    path: "/portal/autoevaluaciones/:uuid",
    component: _ae8ad83e,
    name: "portal-autoevaluaciones-uuid"
  }, {
    path: "/portal/cooperativas/:uuid",
    component: _07fb903a,
    name: "portal-cooperativas-uuid"
  }, {
    path: "/portal/formaciones/:uuid",
    component: _11653602,
    name: "portal-formaciones-uuid"
  }, {
    path: "/portal/marcas/:uuid",
    component: _b9bb1664,
    name: "portal-marcas-uuid"
  }, {
    path: "/portal/solicitudes/:uuid",
    component: _780d0241,
    name: "portal-solicitudes-uuid"
  }, {
    path: "/public/documentos/:uuid?",
    component: _305ec579,
    name: "public-documentos-uuid"
  }, {
    path: "/public/formatos/:uuid?",
    component: _0d6647d6,
    name: "public-formatos-uuid"
  }, {
    path: "/revisiones/lotes/:uuid",
    component: _4412e76a,
    name: "revisiones-lotes-uuid"
  }, {
    path: "/alcances/:uuid",
    component: _6ae0c1fe,
    name: "alcances-uuid"
  }, {
    path: "/auditores/:uuid",
    component: _214f7640,
    name: "auditores-uuid"
  }, {
    path: "/auditorias/:uuid",
    component: _811c6da2,
    name: "auditorias-uuid"
  }, {
    path: "/bloques/:uuid",
    component: _2c7b456a,
    name: "bloques-uuid"
  }, {
    path: "/cargas/:uuid",
    component: _31c03697,
    name: "cargas-uuid"
  }, {
    path: "/certificados/:uuid",
    component: _25558118,
    name: "certificados-uuid"
  }, {
    path: "/clientes/:uuid",
    component: _7b90d10b,
    name: "clientes-uuid"
  }, {
    path: "/compartir/:uuid",
    component: _f931941e,
    name: "compartir-uuid"
  }, {
    path: "/competencias/:uuid",
    component: _5c387d11,
    name: "competencias-uuid"
  }, {
    path: "/componentes/:uuid",
    component: _ae86b502,
    name: "componentes-uuid"
  }, {
    path: "/delegaciones/:uuid",
    component: _0ca73b99,
    name: "delegaciones-uuid"
  }, {
    path: "/dictamenes/:uuid",
    component: _629d8c0b,
    name: "dictamenes-uuid"
  }, {
    path: "/dropdowns/:uuid",
    component: _2ef9dd16,
    name: "dropdowns-uuid"
  }, {
    path: "/emplazamientos/:uuid",
    component: _5045703e,
    name: "emplazamientos-uuid"
  }, {
    path: "/estaticos/:uuid",
    component: _5616f6ea,
    name: "estaticos-uuid"
  }, {
    path: "/expedientes/:uuid",
    component: _0398af40,
    name: "expedientes-uuid"
  }, {
    path: "/formaciones/:uuid",
    component: _1cd06764,
    name: "formaciones-uuid"
  }, {
    path: "/formatos/:uuid",
    component: _4ced68ed,
    name: "formatos-uuid"
  }, {
    path: "/grupo_solicitudes/:uuid",
    component: _123b3490,
    name: "grupo_solicitudes-uuid"
  }, {
    path: "/grupos/:uuid",
    component: _23855efc,
    name: "grupos-uuid"
  }, {
    path: "/incompatibilidades/:uuid",
    component: _60636fe1,
    name: "incompatibilidades-uuid"
  }, {
    path: "/listas_validacion/:uuid",
    component: _48b4794d,
    name: "listas_validacion-uuid"
  }, {
    path: "/marcas/:uuid",
    component: _4766c749,
    name: "marcas-uuid"
  }, {
    path: "/metadata/:uuid",
    component: _993391fe,
    name: "metadata-uuid"
  }, {
    path: "/personal/:uuid?",
    component: _343900d2,
    name: "personal-uuid"
  }, {
    path: "/plantillas/:uuid",
    component: _7c85163c,
    name: "plantillas-uuid"
  }, {
    path: "/presupuestos/:uuid",
    component: _42d10232,
    name: "presupuestos-uuid"
  }, {
    path: "/revisiones/:uuid",
    component: _3dd2cd5b,
    name: "revisiones-uuid"
  }, {
    path: "/selectores/:uuid",
    component: _9b13b942,
    name: "selectores-uuid"
  }, {
    path: "/tipos_fichero/:uuid",
    component: _3b812f8e,
    name: "tipos_fichero-uuid"
  }, {
    path: "/tramites/:uuid",
    component: _739907d6,
    name: "tramites-uuid"
  }, {
    path: "/zonas_visita/:uuid",
    component: _3f690808,
    name: "zonas_visita-uuid"
  }, {
    path: "/",
    component: _9341e44a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
