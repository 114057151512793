import { render, staticRenderFns } from "./plan-acciones_correctivas.vue?vue&type=template&id=6d134920&scoped=true&"
import script from "./plan-acciones_correctivas.vue?vue&type=script&lang=js&"
export * from "./plan-acciones_correctivas.vue?vue&type=script&lang=js&"
import style0 from "./plan-acciones_correctivas.vue?vue&type=style&index=0&id=6d134920&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d134920",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
