// Solicitud
export const solicitudTipos = [
  {text: 'Inicial', value: 'inicial'},
  {text: 'Modificación de alcance', value: 'modificacion'},
]

export const tiposAmplicaionAlcance = [
  {text: 'Producto', value: 'producto'},
  {text: 'Proceso', value: 'proceso'},
  {text: 'Instalaciones', value: 'instalaciones'},
  {text: 'Marcas comerciales', value: 'marcas_comerciales'},
  {text: 'Revisión de documento normativo', value: 'revision_documento_normativo'},
]

export const tiposModificacionAlcance = [
  {text: 'Ampliación', value: 'ampliacion'},
  {text: 'Reducción', value: 'reduccion'},
]
