import {uuid} from 'vue-uuid'

export const state = () => ({
    list: [],
    item: {},
})

//commit mutations
export const mutations = {
    GET_LIST(state) {
        return this.list
    },
    SET_LIST(state, items) {
        this.list = items
    },
}

//dispatch actions
export const actions = {
    async list({rootState, commit}) {
        rootState.loading = true
        const res = await this.$axios.get('comunicaciones')
        rootState.loading = false
        if (res) {
            commit('SET_LIST', res.data)
            return res.data
        }
    },

    async get({rootState}, uuid) {
        rootState.loading = true
        const res = await this.$axios.get(`comunicaciones/${uuid}`)
        rootState.loading = false
        if (res) return res.data
    },

    async create({rootState}, item) {
        rootState.loading = true
        const body = {
            ...item,
            modulo: item.modulo.toLowerCase(),
            uuid: uuid.v4(),
        }
        console.info('body', body)
        const res = await this.$axios.post(`comunicaciones`, body)
        rootState.loading = false
        if (res) return res.data
    },

    async update({rootState}, item) {
        rootState.loading = true
        const body = {
            ...item,
            modulo: item.modulo.toLowerCase(),
            comunicacion: item.uuid,
        }
        const res = await this.$axios.patch(`comunicaciones/${item.uuid}`, body)
        rootState.loading = false
        if (res) return res.data
    },

    async delete({rootState}, item) {
        rootState.loading = true
        const res = await this.$axios.delete(`comunicaciones/${item.uuid}`)
        rootState.loading = false
        if (res) return res.data
    },
}
