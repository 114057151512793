export const state = () => ({})

//commit mutations
export const mutations = {}

//dispatch actions
export const actions = {
  async list({rootState}) {
    rootState.loading = true
    const res = await this.$axios.get('alcances')
    rootState.loading = false
    if (res) return res.data
  },

  async get({rootState}, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`alcances/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({rootState}, alcance) {
    rootState.loading = true
    let tramites = alcance.tramites
    let body = alcance
    body.grupo = alcance.grupo.uuid
    this.$axios.post('alcances', body).then(() => {
      tramites.map(async (tramite) => {
        let data = {alcance: alcance.uuid, tramite: tramite.uuid}
        await this.$axios.post(`alcances/${alcance.uuid}/tramites`, data).then(() => {})
      })
      rootState.loading = false
    })
  },

  async update({rootState}, alcance) {
    rootState.loading = true
    const body = {
      alcance: alcance.uuid,
      grupo: alcance.grupo.uuid,
      nombre: alcance.nombre,
      esfuerzo: alcance.esfuerzo,
      codigo_esquema: alcance.codigo_esquema,
      color: alcance.color,
    }
    await this.$axios.patch(`alcances/${alcance.uuid}`, body)
    await this.$axios.delete(`alcances/${alcance.uuid}/tramites`)
    alcance.tramites.map(async (tramite) => {
      let data = {alcance: alcance.uuid, tramite: tramite.uuid}
      await this.$axios.post(`alcances/${alcance.uuid}/tramites`, data)
    })
    rootState.loading = false
  },

  // ITERACIONES PENDIENTES DE AUDITAR
  async iteracionesPendientesAuditar({rootState}) {
    rootState.loading = true
    const res = await this.$axios.get(`alcances/iteraciones/pendientes-auditar`)
    rootState.loading = false
    if (res) return res.data
  },
}
