import { uuid } from 'vue-uuid'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }, params) {
    rootState.loading = true
    const body = {}
    if (params.cliente) body.cliente = params.cliente
    if (params.expediente) body.expediente = params.expediente
    
    
    const res = await this.$axios.put('certificados', body)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`certificados/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, dictamen) {
    rootState.loading = true
    let jxr = null
    const res = await this.$axios.get(`revisiones/${dictamen.revision.uuid}`)
    if (res) {
      const revision = res.data
      const body = {
        uuid: uuid.v4(),
        alcance: revision.alcance.uuid,
        expediente: revision.expediente.uuid,
        auditoria: revision.auditoria.uuid,
        dictamen: dictamen.uuid,
        instalacion: dictamen.sites[0].instalacion?.uuid
          ? dictamen.sites[0].instalacion.uuid
          : null,
      }
      jxr = await this.$axios.post('certificados', body)
      rootState.loading = false
    }

    return jxr
  },

  async update({ rootState }, certificado) {
    certificado.certificado = certificado.uuid
    rootState.loading = true

    // https://trello.com/c/CWi9EMrf/435-certicalidad-certificados
    certificado.n_revision = certificado.revision
    delete certificado.revision

    await this.$axios.patch(`certificados/${certificado.uuid}`, certificado)
    rootState.loading = false
  },

  async delete({ rootState }, certificado) {
    rootState.loading = true
    await this.$axios.delete(`certificados/${certificado.uuid}`)
    rootState.loading = false
  },

  async publicar({ rootState }, uuid) {
    const body = {
      certificado: uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`certificados/${uuid}/publicar`, body)
    rootState.loading = false
  },

  async despublicar({ rootState }, uuid) {
    const body = {
      certificado: uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`certificados/${uuid}/despublicar`, body)
    rootState.loading = false
  },

  async activar({ rootState }, uuid) {
    const body = {
      certificado: uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`certificados/${uuid}/activar`, body)
    rootState.loading = false
  },

  async desactivar({ rootState }, uuid) {
    const body = {
      certificado: uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`certificados/${uuid}/desactivar`, body)
    rootState.loading = false
  },
}
