/**
 * @project: certiapp-nuxt
 * @file:    grupo_solicitudes.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon May 15 2023
 **/

export const state = () => ({})

//commit mutations
export const mutations = {}

//dispatch actions
export const actions = {
  async list({ rootState }, cliente) {
    rootState.loading = true
    const body = {}
    if (cliente) body.cliente = cliente
    const res = await this.$axios.put('grupo-solicitudes', body)
    // const res = await this.$axios.get('grupo-solicitudes')
    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // listReducida()
  // 🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️ xD
  // -----
  // Created on Fri Mar 31 2023
  //+-------------------------------------------------

  async listReducida({ rootState }, cliente) {
    rootState.loading = true

    const res = await this.$axios.put('grupo-solicitudes')

    rootState.loading = false
    if (res) return res.data
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`grupo-solicitudes/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async coplaca({ rootState }, body) {
    rootState.loading = true
    await this.$axios.post(`grupo-solicitudes/coplaca`, body)
    rootState.loading = false
    return body.uuid
  },

  async create({ rootState }, item) {
    rootState.loading = true
    const res = await this.$axios.post(`grupo-solicitudes`, item)
    rootState.loading = false
    if (res) return res.data
  },

  async update({ rootState }, item) {
    rootState.loading = true
    const res = await this.$axios.patch(`grupo-solicitudes/${item.grupo_solicitud}`, item)
    rootState.loading = false
  },

  async updateDate({ rootState }, item) {
    rootState.loading = true
    await this.$axios.patch(`grupo-solicitudes/${item.uuid}/cambio-fecha`, item)
    rootState.loading = false
  },

  async aprobar({ rootState }, item) {
    rootState.loading = true
    const body = {
      grupo_solicitud: item.uuid,
    }
    await this.$axios.patch(`grupo-solicitudes/${item.uuid}/aprobar`, body)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // desaprobar()
  // Returns the state to pending
  // -----
  // Created on Wed Feb 16 2022
  //+-------------------------------------------------
  async desaprobar({ rootState }, params) {
    rootState.loading = true

    try {
      const xhr = await this.$axios.delete(`grupo-solicitudes/${params.uuid}/deshacer`)
    } catch (e) {
      this.commit('notification/show', {
        text: `Ha ocurrido un error actualizando: ${key}.`,
        color: 'error',
        timeout: 3000,
      })
    } finally {
      rootState.loading = false
    }
  },

  async logs({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`grupo-solicitudes/${uuid}/logs`, { solicitud: uuid })
    rootState.loading = false
    if (res) return res.data
  },

  async delete({ rootState }, solicitud) {
    rootState.loading = true
    await this.$axios.delete(`grupo-solicitudes/${solicitud.uuid}`)
    rootState.loading = false
  },

  async last({rootState},params) {
    rootState.loading = true
    const res = await this.$axios.put(`grupo-solicitudes/last`, params)
    rootState.loading = false
    if (res) return res.data
  },
}
