export default {
  APROBADA: 'APROBADA',
  PENDIENTE_DE_APROBAR: 'PENDIENTE DE APROBAR',
  NUEVA: 'NUEVA',

  // SOLICITUDES
  ESTADOS: [
    { name: 'NUEVA', color: 'accent', label: 'Nueva' },
    { name: 'EN_PROCESO', color: 'primary', label: 'En proceso' },
    { name: 'POR_REVISAR', color: 'warning', label: 'Por revisar' },
    { name: 'PRESUPUESTADA', color: 'info', label: 'Presupuestada' },
    { name: 'RECHAZADA', color: 'error', label: 'Rechazada' },
    { name: 'ACEPTADA', color: 'success', label: 'Aceptada' },
    { name: 'NUEVO', color: 'accent', label: 'Nuevo' },
    { name: 'ACEPTADO', color: 'success', label: 'Aceptado' },
    { name: 'RECHAZADO', color: 'error', label: 'Rechazado' },
    { name: 'COMPLETADA', color: 'warning', label: 'Completada' },
  ],
  getColor(solicitud) {
    return this.ESTADOS.filter((node) => {
      return solicitud === node.name
    })
  },
  // SOLICITUDES
  SOLICITUD_ESTADO: {
    APROBADA: 'APROBADA',
    PENDIENTE: 'PENDIENTE DE APROBAR',
    PREVISTA: 'PREVISTA',
  },

  // EXPEDIENTES
  ESTADOS_EXPEDIENTE: [{ name: 'ABIERTO', color: 'accent', label: 'Abierto' }],
  EXPEDIENTE: {
    ABIERTO: 'ABIERTO',
    CERRADO: 'CERRADO',
  },
  getColorExpediente(expediente) {
    return this.ESTADOS_EXPEDIENTE.filter((node) => {
      return expediente === node.name
    })
  },

  // AUDITORÍA
  AUDITORIA_ESTADO: {
    PENDIENTE: 'PENDIENTE',
    PREVISTA: 'PREVISTA',
    ABIERTA: 'ABIERTA',
    CONFIRMADA: 'CONFIRMADA',
    CERRADA: 'CERRADA',
    REVISADA_OK: 'REVISADA OK',
    REVISADA_KO: 'REVISADA KO',
    APTA: 'APTA',
    NO_APTA: 'NO APTA',
  },
  AUDITORIA_ESTADOS: [
    'PENDIENTE',
    'PREVISTA',
    'ABIERTA',
    'CONFIRMADA',
    'CERRADA',
    'REVISADA_OK',
    'REVISADA_KO',
    'APTA',
    'NO_APTA',
  ],

  // REVISIONES
  REVISION_ESTADO: {
    PENDIENTE: 'PENDIENTE',
    KO: 'KO',
    OK: 'OK',
  },
  REVISION_INCIDENCIA_ESTADO: {
    RESUELTA: 'RESUELTA',
    PENDIENTE_DE_REVISAR: 'PENDIENTE DE REVISAR',
    PENDIENTE_DE_RESOLVER: 'PENDIENTE DE RESOLVER',
  },

  // DICTAMENES
  DICTAMEN_ESTADO: {
    PENDIENTE: 'PENDIENTE',
    KO: 'KO',
    OK: 'OK',
    RESUELTO: 'RESUELTO',
  },
  DICTAMEN_DECISION_PROPOSICION: {
    APTO: 'APTO',
    NO_APTO: 'NO APTO',
  },
  DICTAMEN_DECISIONES_PROPOSICIONES: ['APTO', 'NO APTO'],
  DICTAMEN_ACCION_NO_APTO: {
    SUSPENDER: 'SUSPENDER',
    RETIRAR: 'RETIRAR',
    BAJA_TEMPORAL: 'BAJA_TEMPORAL',
  },
  DICTAMEN_ACCIONES_NO_APTO: ['SUSPENDER', 'NO EMITIR', 'RETIRAR', 'BAJA_TEMPORAL'],
  DICTAMEN_ACCIONES_APTO: ['EMITIR', 'MANTENER', 'LEVANTAR', 'RENOVAR', 'AMPLIACION', 'REDUCCION'],

  // COMUNICACIONES
  COMUNICACION_CANALES: ['push', 'calendario', 'email'],
  COMUNICACION_MODULOS: [
    'Contactos',
    'Clientes',
    'Solicitudes',
    'Auditorias',
    'Pacs',
    'Auditores',
    'Revisiones',
    'Dictamenes',
    'Certificados',
    'Planificador',
    'Documentos',
    'Etiquetas',
    'Instalaciones',
  ],

  // COMUNICACION_EVENTO: {
  //   CONTACTOS: {
  //     'contacto.created': 'Contacto creado',
  //   },

  //   CLIENTES: {
  //     'cliente.created': 'Cliente creado',
  //   },
  //   SOLICITUDES: {
  //     'grupo_solicitud.created': 'Nueva solicitud creada',
  //   },
  //   AUDITORES: {
  //     'auditor.created': 'Nuevo Auditor creado',
  //   },
  //   AUDITORIAS: {
  //     'auditoria.created': 'Auditoria creada',
  //     'accion_correctiva.created': 'Acción correctiva creada',
  //   },
  //   PACS: {
  //     'pac.created': 'Auditoria PAC creado',
  //   },
  //   REVISIONES: {
  //     'revision.created': 'Revisión creada',
  //     'revision.deleted': 'Revisión eliminada',
  //   },
  //   DICTAMENES: {
  //     'dictamen.created': 'Dictamen creado',
  //   },
  //   CERTIFICADOS: {
  //     'certificado.hasta': 'Fecha de caducidad añadida',
  //   },
  // },

  COMUNICACION_EVENTOS: {
    CONTACTOS: [{ text: 'Contacto creado', value: 'contacto.created' }],
    CLIENTES: [
      { text: 'Cliente creado', value: 'cliente.created' },
      { text: 'Cliente actualizado', value: 'cliente.edited' },
    ],
    SOLICITUDES: [
      { text: 'Nueva solicitud creada', value: 'grupo_solicitud.created' },
      { text: 'Solicitud aprobada', value: 'grupo_solicitud.aprobada' },
    ],
    AUDITORES: [{ text: 'Auditor creado', value: 'auditor.created' }],
    AUDITORIAS: [
      { text: 'Auditoria creada', value: 'auditoria.created' },
      { text: 'Acción correctiva creada', value: 'accion_correctiva.created' },
      { text: 'Auditoria terminada', value: 'auditoria.terminada' },
    ],
    PACS: [{ text: 'Auditoria PAC creado', value: 'pac.created' }],
    REVISIONES: [
      { text: 'Revisión creada', value: 'revision.created' },
      { text: 'Revisión eliminada', value: 'revision.deleted' },
    ],
    DICTAMENES: [{ text: 'Dictamen creada', value: 'dictamen.created' }],
    CERTIFICADOS: [{ text: 'Fecha caducidad añadida', value: 'certificado.hasta' }],
    INCIDENCIAS: [{ text: 'Incidencia creada', value: 'incidencia.created' }],
    PLANIFICADOR: [{ text: 'Evento creado', value: 'planificador.created' }],
    DOCUMENTOS: [{ text: 'Documentación aportada', value: 'documento.client' }],
    ETIQUETAS: [
      {
        text: 'Nueva solicitud de etiqueta',
        value: 'etiqueta.solicitud.nueva',
      },
      {
        text: 'Etiqueta aprobada',
        value: 'etiqueta.aprobada',
      },
    ],
    INSTALACIONES: [
      {
        text: 'Nueva instalación creada',
        value: 'instalacion.created',
      },
      {
        text: 'Instalación actualizada',
        value: 'instalacion.updated',
      },
      {
        text: 'Instalación dada de baja',
        value: 'instalacion.deactivated',
      },
      {
        text: 'Fecha de validez expirada',
        value: 'instalacion.expdate',
      },
    ],
  },

  // CHECKLISTS
  CHECKLISTS_NIVELES: ['Mayor', 'Menor', 'Recomendacion'],

  // LISTAS VALIDACION
  LISTAS_VALIDACION_MODULO: [
    'solicitudes',
    'expedientes',
    'auditorias',
    'revisiones',
    'dictamenes',
    'certificados',
  ],

  // MUESTRAS

  MUESTRAS_ESTADO: {
    NUEVA: 'NUEVA',
    PENDIENTE: 'PENDIENTE',
    FINALIZADA: 'FINALIZADA',
  },
}
