<template>
  <div>

    <!-- HEADER -->
    <!-- <header-component noAction v-if="!uuid" /> -->

    <v-row class="align-center">
      <v-col cols="6">
        <h1 v-if="!ui.showLote">Revisiones</h1>
        <h1 v-else>
          <template v-if="$refs.lotes && $refs.lotes.ui.step == 0">
            {{ ui.panel == 'auditorias' ? 'Auditorías abiertas' : '' }}
            {{ ui.panel == 'revisiones' ? 'Revisiones pendientes' : '' }}
          </template>
          <template v-else>
            Lote de revisiones
          </template>
        </h1>
      </v-col>

      <v-col cols="6" style="text-align: right">
        <v-btn depressed outlined v-if="auth.lotes && auth.lotes.canCreate" @click="ui.showLote = !ui.showLote">
          <v-icon small class="pr-2">mdi-folder-plus-outline</v-icon>
          <template v-if="ui.showLote">
            Volver
          </template>
          <template v-else>
            Nuevo lote de revisiones
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="align-center" v-show="ui.showLote && $refs.lotes && $refs.lotes.ui.step == 0">
      <v-col cols="6" class="pb-0">
        <v-btn-toggle mandatory v-model="ui.panel" color="deep-purple accent-3" style="transform: translateX(-5px);"
          group>
          <v-btn small text style="border-radius: 3px;" value="auditorias" @click="$refs.lotes.selected = []">
            Auditorías abiertas
          </v-btn>

          <v-btn small text style="border-radius: 3px;" value="revisiones" @click="$refs.lotes.selected = []">
            Revisiones pendientes
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-show="ui.showLote" class="my-2 mb-5">
      <v-col>
        <lotesCRUD ref="lotes" lote="Revisiones" :type="ui.panel" />
      </v-col>
    </v-row>

    <!-- LISTADO -->
    <v-row v-show="!ui.showLote">
      <v-col cols="12">
        <ag-datatable :headers="headers" :items="items" urlData="revisiones" :emitClickRow="true"
          @click-row="clickRow"></ag-datatable>
      </v-col>
    </v-row>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    revisiones/index.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Thu Feb 02 2023
 **/

import moment from 'moment'
import Estado from '~/models/estados'
import lotesCRUD from '~/components/lotes/crud'
import AgDatatable from '~/components/AgDatatable'

export default {
  props: ['uuid', 'iteracion'],
  components: {
    AgDatatable,
    lotesCRUD,
  },
  data: () => ({
    items: [],
    db: {
      items: [],
      auditorias: [],
    },
    CERRADA: Estado.AUDITORIA_ESTADO.CERRADA,
    headers: [
      {
        headerName: 'Fecha de revisión',
        field: 'created_at',
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.createdAt
          return value ? moment(value).format('DD/MM/YYYY') : ''
        },
      },
      {
        headerName: 'Cliente',
        field: 'nombre',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params) => {
          if (params.data) {

            const item = params.data && params.data.cliente
            if (item) {
              let nombre = item.nombre
              if (item.apellido1) nombre += ' ' + item.apellido1
              if (item.apellido2) nombre += ' ' + item.apellido2
              if (item.primer_apellido) nombre += ' ' + item.primer_apellido
              if (item.segundo_apellido) nombre += ' ' + item.segundo_apellido
              if (item.apellidos) nombre += ' ' + item.apellidos
              return nombre
            } else return params.data.nombre_cliente_completo ?? params.data.nombre_cliente
          }
        },
      },
      {
        headerName: 'Revisor',
        field: 'auditor.nombre',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params) => {
          if (params.data) {

            const item = params.data && params.data.auditor
            if (item) {
              let nombre = item.nombre
              if (item.apellido1) nombre += ' ' + item.apellido1
              if (item.apellido2) nombre += ' ' + item.apellido2
              if (item.primer_apellido) nombre += ' ' + item.primer_apellido
              if (item.segundo_apellido) nombre += ' ' + item.segundo_apellido
              if (item.apellidos) nombre += ' ' + item.apellidos
              return nombre
            } else return params.data.nombre_auditor ?? ''
          }
        },
      },
      // {
      //   headerName: 'Fecha revisión',
      //   field: 'fecha_proposicion',
      //   sortable: true,
      //   filter: 'agDateColumnFilter',
      //   resizable: true,
      //   valueGetter: (params) => {
      //     const item = params.data
      //     const value = item && item.fecha_proposicion
      //     return value ? moment(value).format('DD/MM/YYYY') : ''
      //   },
      // },
      {
        headerName: 'Alcance',
        field: 'nombre_alcance',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
      },
      {
        headerName: 'Desde',
        field: 'auditoria.fecha_desde',
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.desde
          return value ? moment(value).format('DD/MM/YYYY') : ''
        },
      },
      {
        headerName: 'Hasta',
        field: 'hasta',
        sortable: true,
        filter: 'agDateColumnFilter',
        resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.hasta
          return value ? moment(value).format('DD/MM/YYYY') : ''
        },
      },
      {
        headerName: 'Estado',
        field: 'estado',
        enableRowGroup: true,
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: function (params) {
          if (params.value === Estado.REVISION_ESTADO.PENDIENTE) return { color: '#2196F3' }
          else if (params.value === Estado.REVISION_ESTADO.OK) return { color: '#43A047' }
          else if (params.value === Estado.REVISION_ESTADO.KO) return { color: '#EF5350' }
          else return null
        },
      },
    ],

    auth: {},

    ui: {
      panel: null,
      showLote: false,
    },
  }),

  computed: {
    revisiones() {
      if (!this.items) return this.items
      else {
        return this.items.filter((auditoria) => {
          return auditoria.estado === this.CERRADA
        })
      }
    },

    //+-------------------------------------------------
    // lotesDB()
    // As we have two possible arrays to use in the table,
    // return according to this.ui.panel
    // -----
    // Created on Mon Jul 18 2022
    //+-------------------------------------------------
    lotesDB() {
      if (this.ui.panel === 'auditorias') return this.db.auditorias

      if (this.ui.panel === 'revisiones')
        return this.db.items.filter((auditoria) => {
          return auditoria.estado === Estado.AUDITORIA_ESTADO.PENDIENTE
        })
    },
  },

  watch: {
    iteracion: {
      handler(newVal, oldVal) {
        if (newVal.uuid !== oldVal.uuid) {
          this.filterIteracion()
        }
      },
      deep: true,
    },
  },

  methods: {
    //+-------------------------------------------------
    // filterIteracion()
    // Select only items where iteracion match the prop
    // -----
    // Created on Mon Nov 29 2021
    //+-------------------------------------------------
    filterIteracion() {
      if (!this.iteracion?.uuid) {
        this.items = [...this.db.items]
        return
      }

      let items = []
      items = this.db.items.filter((item) => {
        return item.iteracion_uuid == this.iteracion?.uuid
      })

      this.items = items
    },

    clickRow(data) {
      this.$router.push({ path: `/revisiones/${data.uuid}`, query: { revision: true } })
    },

    async checkRoles() {
      this.auth.lotes = await this.$store.dispatch('user/can', {
        module: 'revisiones',
        submodule: 'lotes',
        return: 'all',
      })
    },

    //+-------------------------------------------------
    // getAuditorias()
    // Loads auditorias to use with lotes
    // -----
    // Created on Thu Jun 02 2022
    //+-------------------------------------------------
    async getAuditorias() {
      const xhr = await this.$store.dispatch('auditorias/list', {
        estados: ['ABIERTA'],
      })
      if (xhr) {
        this.db.auditorias = xhr
      }
    },

    async getRevisiones() {
      const xhr = await this.$store.dispatch('revisiones/list', this.uuid)
      if (xhr) {
        this.items = xhr
        this.db.items = xhr
      }

      this.filterIteracion()
    },

    async init() {
      await this.checkRoles()
      await this.getRevisiones()
      // await this.getAuditorias()
    },
  },

  async mounted() {
    this.init()
  },

  created() {
    if (!this.uuid) this.$store.commit('menu/setPageTitle', 'Revisiones')
  },
}
</script>
