<template>
  <div class="black--text">
    <div class="title">ACEPTA:</div>
    <br>
    1.-  Cumplir con las condiciones descritas en los procedimientos de CERTICALIDAD que le sean de aplicación y en especial con el PG-CER 04 “Procedimiento para el correcto Uso de la Marca de Conformidad” así como aquellos requisitos establecidos en los documentos normativos y si procede, en los requisitos establecidos en la legislación nacional y autonómica, así como en los procedimientos establecidos por la Entidad Nacional de Acreditación.
    <br><br>
    2.- Permitir cualquier verificación que sea requerida realizar por CERTICALIDAD en el marco de esta certificación.
    <br><br>
    3.- Facilitar a CERTICALIDAD la información necesaria para la realización de las auditorias, así como los datos de producción solicitados anualmente por CERTICALIDAD a fin de establecer y cumplir con la frecuencia de evaluación por centro de producción establecido en el Protocolo de Certificación en su edición en vigor.
    <br><br>
    4.- Comunicar a CERTICALIDAD, en el menor plazo posible, cualquier cambio relevante que afecte o influya en el proceso de certificación contratado.
    <br><br>
    5.- Adjuntar la documentación que se identifica en el Anexo 1. 
    <br><br>
    6.- Aceptar el resultado de las auditorías realizadas. 
    <br><br>
    7.- Abonar los servicios contratados conforme a las condiciones establecidas en la oferta-contrato. 
    <br><br>
    8.- Autorizar a CERTICALIDAD a comunicar los resultados de las auditorías y la información relacionada con las mismas a las autoridades competentes en la forma que ésta establezca y expresamente:
    <br><br>
    <div class="pl-8">
      • Autorizo a CERTICALIDAD SL a facilitar la información relativa al control efectuado en las instalaciones bajo el alcance (y la de los proveedores, si procede) siempre y cuando la autoridad competente en la materia se lo solicite, extremo este que el cliente consiente a la firma de la presente solicitud. 
      <br>
      • Autorizo a CERTICALIDAD SL a facilitar información a ASICI y poder proporcionar al sistema de información ITACA los datos relativos a la gestión y uso de precintos de los productos ibéricos, siempre y cuando la autoridad competente en la materia lo entienda oportuno o se lo solicite, extremo este que el cliente consiente a la firma de la presente solicitud.
    </div>
    <br><br>
    9.- En el caso de que el solicitante realice actividades a maquila y excluya parte de la producción de la certificación porque este bajo el alcance de otra Entidad de Certificación, deberá tener a disposición de CERTICALIDAD la siguiente documentación: 
    <br><br>
    <div class="pl-8">
      • Acuerdo- contrato con el propietario (s) del producto en el que se identifique las instalaciones y la parte de la producción que no se acoge a la certificación de CERTICALIDAD.
      <br>
      • Certificado de Conformidad de Producto del propietario (s).
      <br>
      • Compromiso de mantener actualizada la información sobre los propietarios y la producción no sujeta a la certificación.
      <br>
      • Autorización expresa del propietario del producto para que el certificado de conformidad de CERTICALIDAD recoja está exclusión en el alcance de certificación. 
    </div>
    <br><br>
    10.- Cuando el solicitante de la certificación alquile instalaciones para realizar actividades propias o a maquila deberá tener a disposición de CERTICALIDAD la siguiente documentación:
    <br><br>
    <div class="pl-8">
      • Contrato de alquiler con el propietario de la instalación en el que se indique la ubicación de la parte alquilada, tipo de producto y actividad bajo el alcance de certificación. 
      <br>
      • Compromiso de mantener actualizada la información sobre las instalaciones alquiladas y la producción que se encuentra en las instalaciones alquiladas. 
      <br>
      • Autorización expresa del propietario de la instalación alquilada para que el certificado de conformidad recoja la dirección de la instalación alquilada en el alcance de certificación. 
    </div>
    <br><br>
    11.- CERTICALIDAD en la fase del proceso de certificación, revisión de la solicitud, podrá realizar una visita para analizar el riesgo de la certificación cuando se trate de un proveedor que tenga o haya tenido su certificación retirada, suspendida o, en otras circunstancias que CERTICALIDAD considere de riesgo. Esta visita se facturará al cliente y deberá estar abonada antes de su realización. 
    <br><br>
    La aceptación del Contrato de prestación de servicios y Oferta económica para el desarrollo de las labores de certificación de producto y la firma de esta Solicitud Formal de Certificación tiene carácter contractual, y supone la total aceptación de los términos técnicos y económicos de la propuesta y, así como de todos los procedimientos aprobados y vigentes de CERTICALIDAD que le sean de aplicación a la empresa solicitante de la certificación.
    <br><br>
    <div class="title">ANEXO 1: DOCUMENTACION</div>
    <br>
    1.- Envío a CERTICALIDAD SL, adjunto a la presente solicitud:
    <div class="pl-8">
      a.	Procedimiento de Trazabilidad (desde la entrada de las materias primas hasta la expedición de los productos).
      <br>
      b.	Procedimiento de Compras y Control de Proveedores.
      <br>
      c.	Tarjeta CIF/NIF. 
      <br>
      d.	Escrituras de la sociedad. 
      <br>
      e.	Poder de la persona que solicita la certificación.
      <br>
      f.	Si aplica, fotocopia del documento de inscripción de la marca (s) comercial (es) en el Registro de Patentes y Marcas
      <br>
      g.	Copia del registro sanitario.
      <br>
      h.	Carta de declaración de no haber tenido o tener ningún procedimiento de sanción por parte de la administración competente en materia de salud pública y/o fraude.
      <br>
      i.	En caso de haber trabajado con otra entidad antes de aceptar la propuesta de servicios de CERTICALIDAD, carta de declaración de no estar suspendida o retirada la certificación con la entidad anterior. Certificado de conformidad emitido por la anterior entidad de certificación. Último informe de auditoría.
      <br>
      j.	Listado de proveedores con la información solicitada en esta solicitud (si no figura cumplimentada la información en esta solicitud).
    </div>
    <br>
    2.- CERTICALIDAD SL se reserva el derecho de solicitar que se adjunte a la presente solicitud, conforme a nuestro Manual de Cumplimiento Normativo, documentación de acuerdo con la legislación en vigor en materia de prevención de riesgos penales: Manual de Cumplimiento Normativo de su empresa o documento análogo, Certificado de aprobación del mismo por el órgano competente para ello en atención a su estructura social, Protocolo o protocolos de actuación con proveedores y clientes, Certificado de aprobación de tales documentos por el órgano competente para ello en su empresa, Designación del oficial de cumplimiento u órgano que asuma dicha función, así como certificado del acta en que se le designa por el órgano competente de su mercantil. 
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>