<template>
  <div>
    <v-row>
      <v-col cols="6" class="title black--text">
        Elaboración - Transformación
      </v-col>

      <v-col cols="6" class="text-right" v-if="editable">
        <!-- Importar Excel -->
        <!-- <input type="file" ref="inputFile" style="display: none;" @change="importFile($event)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="warning" class="white--text mr-2" v-on="on" @click="$refs.inputFile.click()">
              <v-icon>mdi-file-import</v-icon>
            </v-btn>
          </template>
          <span>Importar excel</span>
        </v-tooltip> -->

        <!-- Añadir Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="success" class="white--text mr-2" v-on="on" @click="dialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Añadir fila</span>
        </v-tooltip>

        <!-- Eliminar Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="error" class="white--text" v-on="on" @click="removeRow()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar fila</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <ag-datatable :title="grid.title" :headers="grid.columnDefs" :items="grid.rowData" :height="'65'" hide-overlay
            emitClickRow :disableClickRow="!editable" @click-row="itemSelected = $event, dialog = true"
            @gridApi="onGridReady($event)"></ag-datatable>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog -->
    <ProductosEcologicosDialog v-model="dialog" :alcance="alcance" :itemSelected="itemSelected"
      :tipo_operador="tipo_operador" @close="dialog = false" @add="addRow($event)" @edit="editRow($event)" />

  </div>
</template>

<script>
import MODELS from '~/models/ccl/solicitudes'
import AgDatatable from '~/components/AgDatatableOld'
import ProductosEcologicosDialog from './ProductosEcologicosDialog'
import * as UTIL from '~/util'

export default {
  name: 'ProductosEcologicosList',
  props: {
    editable: { type: Boolean, default: false },
    tipo_operador: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    alcance: { type: Object, default: () => { } },
  },
  components: {
    AgDatatable,
    ProductosEcologicosDialog,
  },
  data: () => ({
    dialog: false,
    itemSelected: null,
    grid: {
      title: 'Productos Ecológicos y Actividad de Producción',
      api: null,
      rowData: [],
      columnDefs: [],
    },
    UTIL: UTIL,
  }),
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      },
    },
  },
  methods: {
    onChange() {
      this.itemSelected = null
      this.$emit('change', this.grid.rowData)
    },
    // Grid
    onGridReady(api, tipo) {
      this.grid.api = api
    },
    addRow(item) {
      this.grid.rowData.push(item)
      this.grid.api.updateRowData({ add: [item] })
      this.onChange()
    },
    editRow(item) {
      const index = this.grid.rowData.indexOf(this.itemSelected)
      this.grid.rowData.splice(index, 1, item)
      this.onChange()
    },
    removeRow() {
      const selectedRows = this.grid.api.getSelectedRows()
      if (selectedRows && selectedRows.length) {
        selectedRows.forEach((element) => {
          const index = this.grid.rowData.indexOf(element)
          this.grid.rowData.splice(index, 1)
        })
      }
      this.grid.api.updateRowData({ remove: this.grid.api.getSelectedRows() })
      this.onChange()
    },
    init() {
      const vm = this
      this.grid.rowData = this.items
      this.grid.columnDefs = [
        {
          headerName: '#',
          sortable: true,
          filter: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          valueGetter: (params) => params.node.rowIndex + 1,
        },
        {
          headerName: 'Producto Ecólogico',
          field: 'producto_ecologico',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Volumen de producción',
          field: 'volumen_produccion',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Actividad asociada al producto',
          field: 'actividad_asociada',
          sortable: true,
          filter: true,
          resizable: true,
        },
      ]
    },
    async importFile(event) {
      UTIL.importFile(this, event, this.grid, null, this.onChange.bind(this))
      this.$refs.inputFile.value = ''
    },
  },
  created() {
    this.init()
  },
}
</script>
