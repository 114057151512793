<template>
  <div>

    <loading v-model="loading" text="Espere por favor..." />

    <!-- DATOS GENERALES -->
    <v-row class="pa-3 pt-0 pb-3">
      <v-col cols="12" md="8">
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 10 : 5" width="100%">
            <v-card-title>{{ item.titulo }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field label="Auditor" disabled v-model="item.auditor.nombre"></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field label="Emplazamiento" disabled v-model="item.site.nombre"></v-text-field>
                </v-col>

                <!-- <v-col cols="12" md="6"
                  v-if="item.site.lat && item.site.lng">
                  <mapa :lat="item.site.lat" :lng="item.site.lng" :height="250"></mapa>
                </v-col> -->


                <v-col cols="12" md="4" class="mt-0 pt-0">
                  <v-menu v-model="menuFecha" :close-on-content-click="false" max-width="290">
                    <template v-slot:activator="{ on }">
                      <v-text-field :value="formatDate(item.fecha)" clearable label="Fecha" readonly v-on="on"
                        @click:clear="item.fecha = null" :disabled="isDisabled"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="item.fecha" @change="menuFecha = false, saveChecklist()"
                      no-title scrollable :first-day-of-week="1"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" class="mt-0 pt-0">
                  <v-text-field type="time" v-model="item.inicio" @change="saveChecklist()" :disabled="isDisabled"
                    label="Hora inicio"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-0 pt-0">
                  <v-text-field type="time" v-model="item.fin" @change="saveChecklist()" :disabled="isDisabled"
                    label="Hora fin"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea hide-details outlined auto-grow label="Observaciones" v-model="item.observaciones" rows="3"
                    :disabled="isDisabled" @blur="saveChecklist()">
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      <!-- CUMPLIMIENTO -->
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card :elevation="hover ? 10 : 5" width="100%" v-if="item && item.preguntas && cumplimiento.count">
            <v-card-title class="pb-1">Completado al {{ (cumplimiento.answered * 100 / cumplimiento.total).toFixed(0) }}%
              ({{ cumplimiento.answered }} / {{ cumplimiento.total }})</v-card-title>
            <v-card-text>
              <v-chip-group mandatory active-class="success" v-model="ui.cumplimiento">
                <v-chip small label value="success">
                  Cumplimiento
                </v-chip>
                <v-chip small label value="failed">
                  No conformidades
                </v-chip>
              </v-chip-group>

              <v-row class="py-0 my-0">
                <template v-for="(amount, key) in cumplimiento.count">
                  <v-col cols="7" class="title pb-0 mb-0"
                    style="text-transform: capitalize; display: flex; align-items: center;" :key="'acumplimiento' + key">
                    <v-chip label small class="mr-1" :color="ui.cumplimiento == 'success' ? 'success' : 'red'"
                      text-color="white">
                      {{ ui.cumplimiento == 'success' ? labelV : labelF }}
                    </v-chip>
                    {{ key }}
                  </v-col>
                  <v-col cols="5" class=" pb-0 mb-0" :key="'bcumplimiento' + key">
                    <div class="headline pr-2" v-if="cumplimiento[ui.cumplimiento]">
                      {{ (cumplimiento[ui.cumplimiento][key] * 100 / amount).toFixed(0) }}%
                      <span class="deep-purple--text px-2" style="font-size: 1.2rem !important">
                        {{ cumplimiento[ui.cumplimiento][key] }}/{{ amount }}
                      </span>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
            <!-- <pre>
              {{ cumplimiento }}
            </pre> -->
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <!-- FILTROS Y PREGUNTAS -->
    <v-row class="pa-3 pt-0 pb-0">
      <!-- FILTROS -->
      <v-col cols="12" class="mb-0 pb-0">
        <v-card width="100%" color="grey lighten-3" class="pa-5 pt-0" style="border-radius: 0px !important; ">
          <v-row>
            <v-col cols="6" class="pt-4 pb-0">
              <span class="title"> Filtros </span>
            </v-col>

            <v-col cols="6" class="text-right">
              <input type="file" ref="inputFile" style="display: none;" @change="importFile($event)">
              <v-btn color="secondary" class="ml-2" dark small depressed @click="confirmarImportFile()">
                <v-icon class="mr-2">mdi-file-import</v-icon>
                Importar Excel
              </v-btn>

              <v-btn color="secondary" class="ml-2" dark small depressed @click="excelExport()">
                <v-icon class="mr-2">mdi-file-export</v-icon>
                Exportar Excel
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="pt-0 pb-0">
              <v-text-field label="Título" v-model="filtro_titulo" clearable />
            </v-col>

            <v-col cols="12" md="3" class="pt-0 pb-0">

              <SelectEstaticos v-if="auditoria.alcance && estaticos && ui.ready" v-model="filtro_niveles"
                :alcance="auditoria.alcance.uuid" codigo="checklist_niveles" multiple :estaticos="estaticos" />
            </v-col>

            <v-col cols="12" md="3" class="pt-0 pb-0">
              <v-checkbox color="primary" class="ml-5" label="Incompletas" v-model="filtro_incompletas" />
            </v-col>

            <v-col cols="12" class="pt-0 pb-0">
              <v-btn v-show="openPanels.length === 0" color="primary" class="ml-5" dark @click="openPreguntas"
                style="float:right">
                Desglosar todas las preguntas
              </v-btn>
              <v-btn v-if="item.preguntas && openPanels.length > 0 && openPanels.length === filtrarPreguntas.length"
                color="primary" class="ml-5" dark @click="closePreguntas" style="float:right">
                Ocultar todas las preguntas
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- PREGUNTAS -->
      <v-col cols="12" class="mt-0 pt-0">
        <v-data-iterator :items="filtrarPreguntas" :page="pagination.page" :items-per-page="pagination.itemsPerPage"
          :footer-props="{
            'items-per-page-options': pagination.itemsPerPageArray,
          }">
          <template v-slot:default="{ items, isExpanded, expand }">
            <v-expansion-panels v-model="openPanels" multiple class="panel">
              <v-expansion-panel v-for="(pregunta, i) in items" :key="i" :disabled="pregunta.solo_titulo">
                <v-expansion-panel-header class="subtitle-1 pb-0">
                  <v-row>
                    <v-col cols="9" md="7" xl="8" class="titulo-pregunta">
                      <span class="font-weight-bold black--text">
                        {{ pregunta.codigo }}.<span v-html="pregunta.titulo"></span>

                        <v-tooltip bottom v-if="evidencias[pregunta.uuid] && evidencias[pregunta.uuid].length > 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
                              <v-icon color="grey lighten-1">mdi-paperclip</v-icon>
                            </v-btn>
                          </template>
                          <span>Se han adjuntado <strong>{{ evidencias[pregunta.uuid].length }} archivos</strong></span>
                        </v-tooltip>
                      </span>
                    </v-col>

                    <v-col cols="1" md="1" xl="1" style="color: #2196F3">
                      {{ pregunta.relacion }}
                    </v-col>

                    <v-col cols="2" md="4" xl="3" class="pa-0 text-center">
                      <v-row no-gutters>
                        <v-col>
                          <v-btn-toggle tile class="pb-3" v-model="pregunta.respuesta" v-if="!pregunta.solo_titulo"
                            @click.stop="() => { }">
                            <v-btn :disabled="isDisabled" :id="'btnV' + pregunta.uuid"
                              :color="pregunta.respuesta === 'V' ? 'success' : ''"
                              @click.stop="setRespuesta(pregunta, 'V')" value="V">{{ labelV }}</v-btn>
                            <v-btn :disabled="isDisabled" :id="'btnF' + pregunta.uuid"
                              :color="pregunta.respuesta === 'F' ? 'error' : ''" @click.stop="setRespuesta(pregunta, 'F')"
                              value="F">{{ labelF }}</v-btn>
                            <v-btn :disabled="isDisabled" :id="'btnNA' + pregunta.uuid"
                              :color="pregunta.respuesta === 'NA' ? 'grey' : ''"
                              @click.stop="setRespuesta(pregunta, 'NA')" value="NA">{{ labelNA }}</v-btn>
                          </v-btn-toggle>
                        </v-col>

                        <v-col v-if="!isCoplaca">
                          <SelectEstaticos :key="componentNivel" v-if="estaticos && !pregunta.solo_titulo && auditoria.alcance && ui.ready"
                            v-model="pregunta.peso" :alcance="auditoria.alcance.uuid" codigo="checklist_niveles"
                            @change="savePregunta(pregunta)" :estaticos="estaticos" :clearable="true"
                            :disabled="isDisabled" />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" v-if="pregunta.relacion && preguntas_relacion[pregunta.relacion]">
                      <!--                                  Relación: <strong>{{ pregunta.relacion }}</strong>-->
                      <ul class="pb-4">
                        <li v-for="item in preguntas_relacion[pregunta.relacion]" :key="item.id">
                          {{ 'Checklist' + item.checklist.titulo + ' - Código ' + item.pregunta.codigo }}
                        </li>
                      </ul>
                    </v-col>

                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pl-5  pb-0" v-if="openPanels.includes(i) && !pregunta.solo_titulo">
                  <v-row no-gutters class="pa-5">
                    <v-col cols="12" v-html="pregunta.hint">
                    </v-col>
                    <!-- <pre>
                      O. {{ pregunta.observaciones }}
                      C. {{ pregunta.comentarios }}
                    </pre> -->

                    <v-col cols="12" class="mt-1 mb-6">

                      <template v-if="isCerticar">
                        <!--
                          Cuando estos selects se necesiten en otras empresas
                          se deberán crear registros en la tabla estaticos para los valores
                        -->

                        <div style="display: inline-block; max-width: 300px; padding-right: 15px;">
                          <v-select label="Instalación" :items="[
                            'Propia',
                            'Maquila'
                          ]" v-model="pregunta.instalacion" @change="savePregunta(pregunta)"></v-select>
                        </div>

                        <div style="display: inline-block; max-width: 300px; padding-right: 15px;">
                          <v-select label="Tipología" :items="[
                            'Retirada de precintos',
                            'Suspensión de Certificación',
                            'Creada certificador'
                          ]" v-model="pregunta.tipologia" @change="savePregunta(pregunta)"></v-select>
                        </div>
                      </template>

                      <v-btn v-if="!auditoriaCerrada || item.is_revision" color="info" depressed :disabled="isDisabled"
                        class="mt-4" style="width: 200px; margin: auto" nope-click="adjuntarEvidencia(pregunta.uuid)"
                        @click="addEvidencia(pregunta.uuid)">
                        Adjuntar evidencias
                      </v-btn>
                    </v-col>

                    <v-col cols="12" style="padding-right: 30px;"
                      v-if="evidencias[pregunta.uuid] && evidencias[pregunta.uuid].length > 0">
                      <p class="title">Evidencias</p>
                      <!-- <v-alert v-if="evidencias[pregunta.uuid]"
                        text type="success" class="mt-3" style="max-width: 50%;">
                        Se han adjuntado <strong>{{ evidencias[pregunta.uuid] }}</strong> documentos
                      </v-alert> -->


                      <table style="border: 1px solid #ccc;
                        padding: 7px;
                        background: #f9f9f9;
                        border-radius: 3px;
                        box-shadow: 0px 0px 3px 0px #e2e2e2;
                        margin-bottom: 10px;
                        width: 100%;">
                        <thead>
                          <tr>
                            <th class="text-left">
                              Archivo
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template>
                            <tr v-for="(doc, key) in evidencias[pregunta.uuid]" :key="key">

                              <td style="padding: 3px;">{{ doc.titulo }}</td>
                              <td style="padding: 3px;" class="text-right">
                                <v-btn small color="purple" @click="download(doc)">Descargar</v-btn>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>

                    </v-col>

                    <v-col cols="12" class="">
                      <v-row v-if="pregunta.respuesta !== 'F'">
                        <v-col cols="12" class="pr-10">
                          <p class="title mb-2">
                            Observaciones

                            <v-btn-toggle class="px-5" v-if="templates.observaciones && templates.observaciones.length">
                              <v-btn small text v-if="!isDisabled"
                                @click="assignTemplate(pregunta, 'observaciones', 'observaciones', 0)">
                                <span class="hidden-sm-and-down">utilizar plantilla</span>
                              </v-btn>

                              <v-menu :close-on-content-click="true" v-if="templates.observaciones.length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small icon v-bind="attrs" v-on="on">
                                    <v-icon right small class="ma-0">
                                      mdi-chevron-down
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item v-for="(doc, key) in templates.observaciones"
                                    @click="assignTemplate(pregunta, 'observaciones', 'observaciones', key)"
                                    :key="'obs' + key">
                                    <v-list-item-title>
                                      {{ doc.texto.substr(0, 25) + '...' }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-btn-toggle>

                            <v-btn v-if=isQuesoManchego small color="info" @click="ui.showEditor = !ui.showEditor">
                              {{ ui.showEditor ? 'Ocultar' : 'Mostrar' }}
                            </v-btn>
                          </p>

                          <v-card outlined
                            v-if="auditoriaCerrada && !item.is_revision && !caneEditCheckListFromRevisiones">
                            <v-card-text v-html="pregunta.observaciones"></v-card-text>
                          </v-card>

                          <template v-else>

                            <v-textarea v-if="$nuxt.isOffline || isDisabled" :disabled="isDisabled" label="Observaciones"
                              v-model="pregunta.observaciones"
                              @focus="pregunta.observaciones = htmlToPlain(pregunta.observaciones)" rows="6" outlined
                              @blur="savePregunta(pregunta)"></v-textarea>

                            <Editor v-if="isQuesoManchego && ui.showEditor" v-model="pregunta.observaciones"
                              :api-key="tinyApiKey" :init="editorInit" @onBlur="savePregunta(pregunta)" />

                            <Editor v-else-if="!isQuesoManchego" v-model="pregunta.observaciones" :api-key="tinyApiKey"
                              :init="editorInit" @onBlur="savePregunta(pregunta)" />
                          </template>
                        </v-col>
                        <v-col cols="12" class="pr-10" v-if="isCcl">
                          <p class="title mb-2">
                            Observaciones detectadas a emitir

                            <v-btn-toggle class="px-5" v-if="templates.comentarios && templates.comentarios.length">
                              <v-btn small text v-if="!isDisabled"
                                @click="assignTemplate(pregunta, 'comentarios', 'comentarios', 0)">
                                <span class="hidden-sm-and-down">utilizar plantilla</span>
                              </v-btn>

                              <v-menu :close-on-content-click="true" v-if="templates.comentarios.length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small icon v-bind="attrs" v-on="on">
                                    <v-icon right small class="ma-0">
                                      mdi-chevron-down
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item v-for="(doc, key) in templates.comentarios"
                                    @click="assignTemplate(pregunta, 'comentarios', 'comentarios', key)"
                                    :key="'obs' + key">
                                    <v-list-item-title>
                                      {{ doc.texto.substr(0, 25) + '...' }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-btn-toggle>
                          </p>

                          <v-card outlined
                            v-if="auditoriaCerrada && !item.is_revision && !caneEditCheckListFromRevisiones">
                            <v-card-text v-html="pregunta.comentarios"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea v-if="$nuxt.isOffline || isDisabled" :disabled="isDisabled" label="Comentarios"
                              v-model="pregunta.comentarios"
                              @focus="pregunta.comentarios = htmlToPlain(pregunta.comentarios)"
                              nope-:readonly="auditoriaCerrada" rows="6" outlined
                              @blur="savePregunta(pregunta)"></v-textarea>

                            <Editor v-else v-model="pregunta.comentarios" :api-key="tinyApiKey" :init="editorInit"
                              @onBlur="savePregunta(pregunta)" />
                          </template>

                          <!-- <p class="title pt-5">
                              Requisito afectado
                            </p>

                            <v-card outlined v-if="auditoriaCerrada && !item.is_revision && !caneEditCheckListFromRevisiones">
                              <v-card-text v-html="pregunta.requisito_afectado"></v-card-text>
                            </v-card>

                            <template v-else>
                              <v-textarea
                                v-if="$nuxt.isOffline || isDisabled"
                                :disabled="isDisabled"
                                label="Comentarios"
                                v-model="pregunta.requisito_afectado"
                                @focus="pregunta.requisito_afectado = htmlToPlain(pregunta.requisito_afectado)"
                                rows="3"
                                outlined
                                @blur="savePregunta(pregunta)"
                              ></v-textarea>

                              <Editor
                                v-else
                                v-model="pregunta.requisito_afectado"
                                :api-key="tinyApiKey"
                                :init="editorInit"
                                @onBlur="savePregunta(pregunta)"
                            />
                            </template> -->
                        </v-col>
                      </v-row>

                      <v-row v-else>
                        <v-col cols="12" md="6" class="pr-10">
                          <p class="title">
                            Observaciones

                            <v-btn-toggle class="px-5" v-if="templates.observaciones && templates.observaciones.length">
                              <v-btn small text v-if="!isDisabled"
                                @click="assignTemplate(pregunta, 'observaciones', 'observaciones', 0)">
                                <span class="hidden-sm-and-down">utilizar plantilla</span>
                              </v-btn>

                              <v-menu :close-on-content-click="true" v-if="templates.observaciones.length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small icon v-bind="attrs" v-on="on">
                                    <v-icon right small class="ma-0">
                                      mdi-chevron-down
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item v-for="(doc, key) in templates.observaciones"
                                    @click="assignTemplate(pregunta, 'observaciones', 'observaciones', key)"
                                    :key="'obs' + key">
                                    <v-list-item-title>
                                      {{ doc.texto.substr(0, 25) + '...' }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-btn-toggle>
                          </p>
                          <v-card outlined
                            v-if="auditoriaCerrada && !item.is_revision && !caneEditCheckListFromRevisiones">
                            <v-card-text v-html="pregunta.observaciones"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea v-if="$nuxt.isOffline || isDisabled" :disabled="isDisabled" label="Observaciones"
                              v-model="pregunta.observaciones"
                              @focus="pregunta.observaciones = htmlToPlain(pregunta.observaciones)"
                              nope-:readonly="auditoriaCerrada" rows="6" outlined
                              @blur="savePregunta(pregunta)"></v-textarea>

                            <Editor v-else v-model="pregunta.observaciones" :api-key="tinyApiKey" :init="editorInit"
                              @onBlur="savePregunta(pregunta)" />
                          </template>
                        </v-col>
                        <v-col cols="12" md="6" class="pr-10">
                          <p class="title">
                            Texto de no conformidad

                            <v-btn-toggle class="px-5"
                              v-if="templates['no-conformidad'] && templates['no-conformidad'].length">
                              <v-btn small text v-if="!isDisabled"
                                @click="assignTemplate(pregunta, 'no_conformidad', 'no-conformidad', 0)">
                                <span class="hidden-sm-and-down">utilizar plantilla</span>
                              </v-btn>

                              <v-menu :close-on-content-click="true" v-if="templates['no-conformidad'].length > 1">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small icon v-bind="attrs" v-on="on">
                                    <v-icon right small class="ma-0">
                                      mdi-chevron-down
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item v-for="(doc, key) in templates['no-conformidad']"
                                    @click="assignTemplate(pregunta, 'no_conformidad', 'no-conformidad', key)"
                                    :key="'obs' + key">
                                    <v-list-item-title>
                                      {{ doc.texto.substr(0, 25) + '...' }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-btn-toggle>
                          </p>
                          <v-card outlined
                            v-if="auditoriaCerrada && !item.is_revision && !caneEditCheckListFromRevisiones">
                            <v-card-text v-html="pregunta.no_conformidad"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea v-if="$nuxt.isOffline || isDisabled" :disabled="isDisabled"
                              v-model="pregunta.no_conformidad"
                              @focus="pregunta.no_conformidad = htmlToPlain(pregunta.no_conformidad)"
                              label="Texto de no conformidad" nope-:readonly="auditoriaCerrada" rows="6" outlined
                              @blur="savePregunta(pregunta)"></v-textarea>

                            <Editor v-else v-model="pregunta.no_conformidad" :api-key="tinyApiKey" :init="editorInit"
                              @onBlur="savePregunta(pregunta)" />
                            <v-text-field outlined dense v-model="pregunta.numero" :disabled="isDisabled" label="Número"
                              @blur="savePregunta(pregunta)">
                            </v-text-field>

                          </template>
                        </v-col>
                        <v-col cols="12" md="6" class="pr-10" v-if="isCcl">
                          <p class="title">
                            Observaciones detectadas a emitir
                          </p>
                          <v-card outlined
                            v-if="auditoriaCerrada && !item.is_revision && !caneEditCheckListFromRevisiones">
                            <v-card-text v-html="pregunta.comentarios"></v-card-text>
                          </v-card>

                          <template v-else>
                            <v-textarea v-if="$nuxt.isOffline || isDisabled" :disabled="isDisabled" label="Comentarios"
                              v-model="pregunta.comentarios"
                              @focus="pregunta.comentarios = htmlToPlain(pregunta.comentarios)"
                              nope-:readonly="auditoriaCerrada" rows="3" outlined
                              @blur="savePregunta(pregunta)"></v-textarea>

                            <Editor v-else v-model="pregunta.comentarios" :api-key="tinyApiKey" :init="editorInit"
                              @onBlur="savePregunta(pregunta)" />
                          </template>

                          <!-- <p class="title pt-5">
                            Requisito afectado
                          </p> -->

                          <!-- <v-card outlined
                            v-if="auditoriaCerrada && !item.is_revision && !caneEditCheckListFromRevisiones">
                            <v-card-text v-html="pregunta.requisito_afectado"></v-card-text>
                          </v-card> -->

                          <!-- <template v-else>
                            <v-textarea v-if="$nuxt.isOffline || isDisabled" :disabled="isDisabled" label="Comentarios"
                              v-model="pregunta.requisito_afectado"
                              @focus="pregunta.requisito_afectado = htmlToPlain(pregunta.requisito_afectado)" rows="3"
                              outlined @blur="savePregunta(pregunta)"></v-textarea>

                            <Editor v-else v-model="pregunta.requisito_afectado" :api-key="tinyApiKey" :init="editorInit"
                              @onBlur="savePregunta(pregunta)" />
                          </template> -->
                        </v-col>
                      </v-row>

                      <!-- <pre>
                        {{ pregunta.bloques }}
                        --
                        {{ db.bloques.length }}
                        --
                        {{ ui.refresh }}
                      </pre> -->

                      <!-- Si la pregunta tiene bloques asignados -->
                      <v-row v-if="pregunta.bloques && pregunta.bloques.length > 0">
                        <v-col class="pr-10" v-if="db.bloques.length && ui.refresh > 0">

                          <!-- Comprueba todos los bloques que hay en ddbb -->
                          <template v-for="(block, index) in db.bloques">
                            <!-- <pre :key="index">
                              {{ pregunta.uuid }}
                              {{ block.uuid }}
                              {{ pregunta.bloques.indexOf(block.uuid) }}
                              {{ preguntaHasBloque(pregunta, block.uuid) }}
                            </pre> -->

                            <!-- Y carga los que coincidan de pregunta <- -> con plantilla -->
                            <v-row class="my-3" v-if="preguntaHasBloque(pregunta, block.uuid)"
                              :key="'block-' + index + pregunta.uuid">

                              <v-col cols="8" :alt="index">
                                <p class="title mb-0">{{ block.titulo }}</p>
                              </v-col>



                              <!-- <pre>
                                {{ getBlocksForUUID(block.uuid, pregunta.uuid) }}
                              </pre> -->

                              <!-- Busca si existen custom blocks para la uuid de la template -->
                              <template v-for="(bl, o) in getBlocksForUUID(block.uuid, pregunta.uuid)">

                                <v-col cols="6" class="mx-auto px-10 pb-0" v-if="o > 0" :key="'block-divider-' + o"
                                  :title="o">
                                  <v-divider style="border-style: dashed;"></v-divider>
                                </v-col>

                                <v-col cols="8" :key="'block-title-' + o" v-if="o > 0"></v-col>
                                <v-col v-if="isDisabled" cols="4" :key="'block-export-' + o">
                                  <v-btn color="secondary" class="ml-2" dark small depressed
                                    @click.stop="exportBlock(bl.uuid, bl)">
                                    <v-icon class="mr-2">mdi-file-export</v-icon>
                                    Exportar Bloque
                                  </v-btn>
                                </v-col>
                                <v-col v-if="!isDisabled" class="text-right" cols="4" :key="'block-actions-' + o">
                                  <span
                                    v-if="$refs['blck-' + bl.uuid] && $refs['blck-' + bl.uuid][0] && $refs['blck-' + bl.uuid][0].item.updated_at"
                                    style="color: rgb(146, 146, 146);  font-size: 12px !important; margin-right: 10px">
                                    Actualizado {{ $moment($refs['blck-' + bl.uuid][0].item.updated_at).fromNow() }}
                                  </span>

                                  <v-btn-toggle>
                                    <v-btn small text @click="updateBlock(bl.uuid, bl)">
                                      <span class="hidden-sm-and-down">Guardar</span>
                                      <v-icon right>
                                        mdi-content-save
                                      </v-icon>
                                    </v-btn>

                                    <v-menu offset-y :close-on-content-click="true">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn small icon v-bind="attrs" v-on="on">
                                          <v-icon right small class="ma-0">
                                            mdi-chevron-down
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <v-list>
                                        <v-list-item @click.stop="cloneBlock(bl.uuid, bl)">
                                          <v-list-item-title>
                                            Añadir otro como este
                                          </v-list-item-title>
                                          <v-list-item-icon>
                                            <v-icon small>
                                              mdi-content-copy
                                            </v-icon>
                                          </v-list-item-icon>
                                        </v-list-item>

                                        <v-list-item v-if="!block.isNew" color="error darken-3"
                                          @click.stop="resetBlock(bl.uuid, bl)">
                                          <v-list-item-title>
                                            Eliminar datos
                                          </v-list-item-title>
                                          <v-list-item-icon>
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-bind="attrs" v-on="on">
                                                  mdi-refresh
                                                </v-icon>
                                              </template>
                                              <span>Reiniciar bloque.<br>Los datos se eliminarán y la estructura se
                                                actualizará desde la plantilla</span>
                                            </v-tooltip>
                                          </v-list-item-icon>
                                        </v-list-item>
                                        <v-list-item v-if="!block.isNew" color="error darken-3"
                                          @click.stop="exportBlock(bl.uuid, bl)">
                                          <v-list-item-title>
                                            Exportar Bloque
                                          </v-list-item-title>
                                          <v-list-item-icon>
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-bind="attrs" v-on="on">
                                                  mdi-file-export
                                                </v-icon>
                                              </template>
                                            </v-tooltip>
                                          </v-list-item-icon>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </v-btn-toggle>
                                </v-col>

                                <v-col cols="12" class="pt-0 mb-4" :key="'block-embed-' + o">
                                  <block-embed :ref="'blck-' + bl.uuid" :block="bl" :submodulo_ref="pregunta.uuid">
                                  </block-embed>
                                </v-col>
                              </template>

                            </v-row>
                          </template>
                        </v-col>
                      </v-row>
                    </v-col>

                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

          <!-- <template v-slot:footer>
            <v-pagination
              v-model="pagination.page"
              :length="numberOfPages"
            ></v-pagination>
          </template> -->
        </v-data-iterator>
      </v-col>
    </v-row>

    <!-- CONFIRMACIÓN DE CAMBIO A MODO ONLINE -->
    <!-- <Confirmation v-model="dialogModoOnline"
                  :text="texto_confirmacion"
                  textButton="Aceptar"
                  colorButton="primary"
                  @confirm="toggleOnline()"/> -->

    <!-- CONFIRMACIÓN IMPORTAR PREGUNTAS -->
    <Confirmation v-model="dialogImport" :text="texto_confirmacion" textButton="Aceptar" colorButton="error"
      @confirm="dialogImport = false, $refs.inputFile.click()">
      <v-card-text class="text-center">
        Esta acción reemplazará todos los datos de la checklist.
      </v-card-text>
    </Confirmation>

    <!-- DIALOG PARA SUBIR EVIDENCIAS -->
    <v-dialog eager v-model="dialogAddEvidencia" max-width="40%" hide-overlay persistent>
      <v-card>
        <v-card-title>Adjuntar evidencia</v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation v-model="valid" style="height: 100%">

            <v-row class="pl-10 pr-10" v-if="evidencia">
              <v-col cols="12">
                <v-text-field label="Título" required :rules="rules" v-model="evidencia.titulo"></v-text-field>
              </v-col>
              <v-col cols="12" v-if="evidencia">
                <v-file-input required :rules="rules" label="Documento" v-model="evidencia.file"></v-file-input>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pr-3">
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" class="mr-3" text @click="closeDialogAddEvidencia()">
            Cancelar
          </v-btn>
          <v-btn depressed color="primary" @click="adjuntarEvidencia()">
            <v-icon left>mdi-upload</v-icon>
            Adjuntar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    pages/aditorias/tabs/checklist/_uuid.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Thu Jun 29 2023
 **/

import * as UTIL from '~/util'
import Loading from '~/components/Loading'
import Mapa from '~/components/maps/Mapa'
import Editor from '@tinymce/tinymce-vue'
import Estados from '~/models/estados'
import Estado from '~/models/estados'
import blockEmbed from '~/pages/bloques/widgets/blockEmbed'
import Confirmation from '~/components/Confirmation'
import SelectEstaticos from '~/components/forms/SelectEstaticos'

export default {
  props: ['uuid', 'auditoriaUUID'],
  components: {
    Loading,
    Mapa,
    Editor,
    blockEmbed,
    Confirmation,
    SelectEstaticos,
  },
  async created() {
    this.$store.commit('menu/setPageTitle', 'Auditoría checklist')
    //console.log('aqui');
    await this.checkOffline()
  },
  data: () => ({
    valid: false,
    loading: false,
    dialogAddEvidencia: false,
    dialogModoOnline: false,
    dialogImport: false,
    texto_confirmacion: '',
    componentNivel: 0,
    menuFecha: false,
    openPanels: [],
    pagination: {
      itemsPerPageArray: [500, -1], // 20, 50, 100, 500,
      itemsPerPage: 500,
      page: 1,
    },

    item: {
      auditor: {},
      site: {},
      observaciones: '',
      preguntas: [],
    },

    niveles: Estado.CHECKLISTS_NIVELES,
    estaticos: null,
    filtro_titulo: null,
    filtro_niveles: null,
    filtro_incompletas: null,
    auditoria: {},
    estado: Estados.AUDITORIA_ESTADO,
    estadoRevisiones: Estados.REVISION_ESTADO,
    // cumplimientoChecklist: {
    //   mayores: 0,
    //   menores: 0,
    //   superiores: 0,
    //   completados: 0,
    // },
    rules: [(v) => !!v || 'El campo es obligatorio'],
    evidencia: {
      uuid: '',
      titulo: null,
      file: null,
    },
    evidencias: [],

    editorInit: {
      height: '25vh',
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap',
        'searchreplace visualblocks code fullscreen',
        'print preview anchor insertdatetime media',
        'paste code help wordcount table pagebreak',
      ],
      toolbar:
        'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | table pagebreak | help',
      pagebreak_separator: '<div class="page-break-before"></div>',
    },
    preguntas_relacion: {},
    columnDefs: [
      { headerName: 'Código', field: 'codigo' },
      { headerName: 'Relación', field: 'relacion' },
      { headerName: 'Título', field: 'titulo' },
      { headerName: 'Respuesta', field: 'respuesta' },
      { headerName: 'Nivel', field: 'peso' },
      { headerName: 'Observaciones', field: 'observaciones' },
      { headerName: 'Texto de no conformidad', field: 'no_conformidad' },
    ],

    db: {
      bloques: [],
      misBloques: [],
    },

    labels: {
      niveles: {},
    },

    templates: {},

    auth: {},

    ui: {
      refresh: 0,
      ready: false,
      cumplimiento: false,
      loadingBlocks: false,
      showEditor: false,
    },
  }),

  watch: {
    // 'item.preguntas': function () {
    //   this.cumplimiento()
    // },

    actualizarEvidencias(val) {
      if (val) this.getEvidencias()
    },
  },

  computed: {
    //+-------------------------------------------------
    // isDisabled()
    // Casos en los que no se permite la edición de campos
    // -----
    // Created on Fri Oct 14 2022
    //+-------------------------------------------------
    isDisabled() {
      if (!this.auth.canEdit) return true

      if (this.auditoriaCerrada) return true
      if (!this.auditoria || !this.auditoria.responsable) return true

      let isAuditor = this.$auth.user.roles.includes('ROLE_AUDITOR')
      let isTheAuditor = this.$auth.user.uuid === this.auditoria.responsable.uuid
      let isAuditorAdmin = this.$auth.user.roles.includes('ROLE_AUDITOR_ADMIN')
      let isAuditorInAuditores = this.auditoria.auditores.some((auditor) => {
        return this.$auth.user.uuid === auditor.uuid
      })

      if (isAuditor && !isTheAuditor && !isAuditorInAuditores && !isAuditorAdmin) return true

      return false
    },

    caneEditCheckListFromRevisiones() {
      if (this.auditoria) {
        return (
          this.auditoria.revisiones[0].estado === this.estadoRevisiones.PENDIENTE ||
          this.auditoria.estado === this.estadoRevisiones.KO
        )
      }
    },
    auditoria_uuid() {
      return this.auditoriaUUID ? this.auditoriaUUID : this.$route.query.auditoria_uuid
      return this.$route.query.auditoria_uuid
    },
    checklist_uuid() {
      return this.uuid ? this.uuid : this.$route.params.uuid
      return this.$route.params.uuid
    },
    tinyApiKey() {
      return this.$store.state.tinyApiKey
    },
    actualizarEvidencias() {
      return this.$store.state.gestor_documental.actualizarEvidencias
    },
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },
    filtrarPreguntas() {
      if (
        !this.filtro_titulo &&
        (!this.filtro_niveles || this.filtro_niveles.length === 0) &&
        !this.filtro_incompletas
      )
        return this.sortPreguntas(this.item.preguntas) || []
      else {
        let titulo = null
        if (this.filtro_titulo) titulo = this.filtro_titulo.toLowerCase()
        let niveles = null
        if (this.filtro_niveles) niveles = this.filtro_niveles
        let incompleta = false
        if (this.filtro_incompletas) incompleta = this.filtro_incompletas

        let preguntas = this.item.preguntas.filter((pregunta) => {
          return (
            (titulo && pregunta.titulo.toLowerCase().indexOf(titulo) > -1) ||
            (titulo && pregunta.codigo.toLowerCase().indexOf(titulo) > -1) ||
            (niveles && niveles.indexOf(pregunta.peso) > -1) ||
            (incompleta && !pregunta.respuesta)
          )
        })

        return this.sortPreguntas(preguntas)
      }
    },

    numberOfPages() {
      return Math.ceil(this.filtrarPreguntas.length / this.pagination.itemsPerPage)
    },

    auditoriaCerrada() {
      return (
        (this.auditoria && this.auditoria.estado?.toUpperCase() === this.estado.CERRADA) ||
        (this.auditoria && this.auditoria.estado?.toUpperCase() == 'REALIZADA')
      )
    },

    isCcl() {
      return this.$auth.user.empresa.nombre == 'Ccl'
    },

    isCoplaca() {
      return this.$auth.user.empresa?.codigo == 'coplaca'
    },

    isCerticar() {
      return this.$auth.user.empresa.codigo == 'certicar'
    },

    isAraporcei() {
      return this.$auth.user.empresa.codigo == 'araporcei'
    },

    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },

    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },

    labelV() {
      if (
        this.isCcl ||
        this.isCerticar ||
        this.isAraporcei ||
        this.isOceGlobal ||
        this.isQuesoManchego
      )
        return 'C'
      else return 'V'
    },

    labelF() {
      if (
        this.isCcl ||
        this.isCerticar ||
        this.isAraporcei ||
        this.isOceGlobal ||
        this.isQuesoManchego
      )
        return 'NC'
      else return 'F'
    },

    labelNA() {
      if (this.isQuesoManchego) return 'O'
      else return 'NA'
    },

    cumplimiento() {
      let cumplimiento = {
        count: {},
        failed: {},
        success: {},

        total: 0,
        answered: 0,
      }

      if (!this.item.preguntas || this.item.preguntas.length === 0 || this.ui.refresh == 0)
        return cumplimiento

      this.item.preguntas.forEach((pregunta) => {
        if (pregunta.solo_titulo) return
        if (!pregunta.peso) return
        let peso = pregunta.peso
        if (peso == '') return
        peso = peso.toLowerCase()
        if (!cumplimiento.count[peso]) cumplimiento.count[peso] = 0
        if (!cumplimiento.failed[peso]) cumplimiento.failed[peso] = 0
        if (!cumplimiento.success[peso]) cumplimiento.success[peso] = 0

        cumplimiento.total++
        cumplimiento.count[peso]++
        if (pregunta.respuesta) cumplimiento.answered++
        if (pregunta.respuesta == 'F') cumplimiento.failed[peso]++
        if (pregunta.respuesta == 'V') cumplimiento.success[peso]++
      })

      return cumplimiento
    },
  },

  methods: {
    htmlToPlain(html) {
      if (!html) return ''

      // Create a new div element
      var tempDivElement = document.createElement('div')

      // Set the HTML content with the given value
      tempDivElement.innerHTML = html

      // Retrieve the text property of the element
      return tempDivElement.textContent || tempDivElement.innerText || ''
    },

    //+-------------------------------------------------
    // assignTemplate()
    // Assigns template text to an element property
    // -----
    // Created on Thu May 19 2022
    //+-------------------------------------------------
    assignTemplate(question, field, template, index) {
      console.warn('assignTemplate', question, field, template, index)
      question[field] = this.templates[template]?.[index]?.texto || '-- sin plantilla --'
      this.$forceUpdate()
      this.savePregunta(question)
    },

    async getEstaticos() {
      const body = {
        alcance: this.auditoria?.alcance?.uuid,
        empresa: this.$auth.user.empresa.uuid,
        codigos: ['checklist_niveles'],
      }

      const res = await this.$store.dispatch('estaticos/list', body)
      console.log('getEstaticos', res)
      // this.estaticos = res
      if (res.length == 0) return

      // NOTE: 26/01/2022, this.estaticos no deberia ser res[0],
      // sino que se  deberia seleccionar el estatico que corresponda al alcance
      this.estaticos = [res[0]]

      this.estaticos.forEach((estatico) => {
        estatico.valor.forEach((valor) => {
          this.labels.niveles[valor.valor] = valor.texto || ''
        })
      })
    },

    sortPreguntas(preguntas) {
      if (preguntas == undefined) return []

      let sorted = []
      const codes = preguntas.map(({ codigo }) => codigo)
      const sort = codes.sort(this.sortAlphaNum)
      sort.forEach((code) => {
        let item = preguntas.filter((obj) => {
          return obj.codigo === code
        })[0]

        sorted.push(item)
      })

      return sorted
    },

    sortAlphaNum(a, b) {
      return a.localeCompare(b, 'en', { numeric: true })
    },

    async checkOffline() {
      const offlineMode = localStorage.getItem('offlineMode')
      const offlineUuid = localStorage.getItem('offlineUuid')
      if (offlineMode && offlineUuid) {
        //console.log('ofline')
        this.toggleOffline(offlineUuid)
      } else {
        this.getChecklist()
      }
    },
    async toggleOffline(uuid) {
      this.dialogModoOffline = false
      const auditoria_uuid = uuid ? uuid : this.auditoria_uuid
      await this.$store.commit('auditorias/OFFLINE_MODE', true)
      await this.$store.commit('auditorias/OFFLINE_UUID', auditoria_uuid)
      localStorage.setItem('offlineMode', true)
      localStorage.setItem('offlineUuid', auditoria_uuid)
      this.getChecklist()
    },
    confirmarModoOnline() {
      this.texto_confirmacion = '¿Está seguro de que desea volver al Modo Online?'
      this.dialogModoOnline = true
    },

    // async toggleOnline() {
    //   this.dialogModoOnline = false
    //   await this.$store.dispatch('auditorias/goOnline')
    //   this.getChecklist()
    // },

    //+-------------------------------------------------
    // getSnapshot()
    // Gets snapshotted data from offline db
    // -----
    // Created on Tue Mar 08 2022
    //+-------------------------------------------------
    async getSnapshot() {
      if (!this.$nuxt.isOffline) return

      const xhr = await this.$store.dispatch('offline/getSnapshot', this.$route.path)
      if (xhr) {
        for (const key in xhr) {
          this[key] = xhr[key]
          console.warn(key, this[key])
        }
      }
    },

    async getChecklist() {
      this.preguntas_relacion = {}

      const body = {
        auditoria_uuid: this.auditoria_uuid,
        checklist_uuid: this.checklist_uuid,
      }
      const res = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getChecklist', body)
        : await this.$store.dispatch('checklists/get', body)
      if (res) this.item = res
      await this.getSnapshot()

      if (this.item.inicio) this.item.inicio = this.formatHora(this.item.inicio)
      if (this.item.fin) this.item.fin = this.formatHora(this.item.fin)

      // Obtenemos la auditoría
      const auditoria = await this.$store.dispatch('auditorias/get', this.auditoria_uuid)
      if (auditoria) {
        this.auditoria = auditoria
        await this.getAllChecklists()
      }
      await this.getEstaticos()
      if (this.isCcl){
        await this.openPreguntas()
      }
      await this.getEvidencias()
      this.assignDatesFromAuditoria()
      await this.getPlantillaBloques()
      await this.getTextTemplates()

      $nuxt.$emit('offline:loaded', {
        item: 'checklist',
        uuid: this.item.uuid,
        data: this.item,
      })
    },

    async getAllChecklists() {
      this.loading = true
      const planificaciones = this.auditoria.planificaciones.map((e) => {
        const obj = {
          uuid: e.uuid,
          site: e.site.uuid,
        }
        if (e.site.parent_uuid) obj.parent_site = e.site.parent_uuid
        return obj
      })

      // Se obtiene el site de la checklist
      let currentSite = ''
      for (let i = 0; i < planificaciones.length; i++) {
        const element = planificaciones[i]
        if (this.$route.params.uuid === element.uuid) {
          // Se asigna el parent si existe
          currentSite = element.site.parent_uuid || element.site.uuid
          break
        }
      }

      // Se buscan todos los checklist con el mismo site
      const checklists = []
      for (let i = 0; i < planificaciones.length; i++) {
        const element = planificaciones[i]
        // Se commpara el currentSite con el parent o el site
        if (currentSite === element.parent_uuid || currentSite === element.site)
          checklists.push(element.uuid)
      }

      // Iteramos las checklist de la misma planificacion
      for (let i = 0; i < checklists.length; i++) {
        const checklist_uuid = checklists[i]
        const body = {
          auditoria_uuid: this.auditoria_uuid,
          checklist_uuid: checklist_uuid,
        }
        const res = this.offlineMode
          ? await this.$store.dispatch('offlineMode/getChecklist', body)
          : await this.$store.dispatch('checklists/get', body)
        if (res) {
          // Filtramos las preguntas con la misma relacion
          const preguntas = res.preguntas.filter((e) => e.relacion)
          for (let k = 0; k < preguntas.length; k++) {
            const pregunta = preguntas[k]
            const relacion = pregunta.relacion
            const obj = {
              checklist: res,
              pregunta: pregunta,
            }
            delete obj.checklist.preguntas
            if (!this.preguntas_relacion[relacion]) this.preguntas_relacion[relacion] = []
            this.preguntas_relacion[relacion].push(obj)
          }
        }
      }
      this.loading = false
    },
    openPreguntas() {
      this.loading = true
      const openPanels = []
      /*if (this.filtrarPreguntas) {
         for (let i = 0; i < this.filtrarPreguntas.length; i++) {
          const pregunta = this.filtrarPreguntas[i]
          if (pregunta.respuesta === 'F' && !this.openPanels.includes(i)) openPanels.push(i)
        } */
      for (let i = 0; i < this.item.preguntas.length; i++) {
        if (!this.openPanels.includes(i)) openPanels.push(i)
      }
      this.openPanels = openPanels
      this.loading = false
    },

    closePreguntas() {
      this.loading = true
      this.openPanels = []
      this.loading = false
    },

    async getEvidencias() {
      const submodulo_ref = this.filtrarPreguntas.map((element) => element.uuid)
      const body = {
        route: this.$route,
        modulo: 'auditorias',
        modulo_ref: this.auditoria_uuid,
        submodulo: 'checklist_pregunta',
        submodulo_ref: submodulo_ref,
      }
      this.offlineMode
        ? await this.$store.dispatch('offlineMode/listCustomDocument', body)
        : await this.$store.dispatch('gestor_documental/listCustom', body)
    },

    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    formatHora(date) {
      return date ? this.$moment(date).subtract('hour', 1).format('HH:mm') : ''
    },

    //+-------------------------------------------------
    // setRespuesta()
    // -----
    // Updated on Mon Jan 02 2023
    //+-------------------------------------------------
    async setRespuesta(pregunta, value, checklist_uuid) {
      //validate if user is auth and has uuid
      if (!this.$auth.user || !this.$auth.user.uuid) {

        this.$store.commit('notification/show', {
          text: 'No se puede guardar la respuesta, su sesión ha expirado',
          color: 'error',
        })
        return

      }

      //validate if user has internet connection
      if (!navigator.onLine) {
        this.$store.commit('notification/show', {
          text: 'No se puede guardar la respuesta, no hay conexión a internet',
          color: 'error',
        })
        return
      }
      console.log('validations pass')
      if (!checklist_uuid && this.checklist_uuid) checklist_uuid = this.checklist_uuid

      if (
        this.auditoria.estado !== this.estado.CERRADA ||
        (true === this.item.is_revision && this.caneEditCheckListFromRevisiones)
      ) {
        if (pregunta.relacion && !checklist_uuid) {
          const body = {
            relacion: pregunta.relacion,
            value: value,
          }
          this.setRespuestaPreguntasRelacion(body)
        } else {
          if (pregunta.respuesta && pregunta.respuesta === value) value = false
          if (this.isCcl) {
            if (value === 'V') {
              //clear selected options pregunta.peso
              if (pregunta.peso == 'Grave' || pregunta.peso == 'Leve' || pregunta.peso == 'Crítica') {
                pregunta.peso = ""
              }
            }
            if (value === 'NA') {
              //clear selected options pregunta.peso
              if (pregunta.peso == 'Grave' || pregunta.peso == 'Leve' || pregunta.peso == 'Crítica') {
                pregunta.peso = ""
              }
            }
          }

          const params = {
            auditoria_uuid: this.auditoria_uuid,
            checklist_uuid: checklist_uuid || this.checklist_uuid,
            checklist_pregunta_uuid: pregunta.uuid,
            observaciones: pregunta.observaciones,
            no_conformidad: pregunta.no_conformidad,
            respuesta: value,
            peso: pregunta.peso,
          }
          try {
            let xhr = await this.$store.dispatch('auditorias/editRespuesta', params)
            console.log('setRespuesta', xhr)
            //validate response status of the http request
            if (xhr) {
              //clear selected 
              // Actualizamos la respuesta de la pregunta
              for (let i = 0; i < this.filtrarPreguntas.length; i++) {
                const element = this.filtrarPreguntas[i]
                if (element.uuid === pregunta.uuid) {
                  this.filtrarPreguntas[i].respuesta = value
                  //this.filtrarPreguntas[i].peso = xhr.peso
                  break
                }
              }
            }
            /* else {
              this.$store.commit('notification/show', {
                text: 'No se puede guardar la respuesta, intente nuevamente',
                color: 'error',
              })
            } */
          } catch (error) {
            console.log('error', error)
            this.$store.commit('notification/show', {
              text: 'No se puede guardar la respuesta, intente nuevamente',
              color: 'error',
            })
          }



        }
      }
    },
    async savePregunta(pregunta) {
      console.log('savePregunta', pregunta)
      const params = {
        auditoria_uuid: this.auditoria_uuid,
        checklist_uuid: this.checklist_uuid,
        checklist_pregunta_uuid: pregunta.uuid,
        observaciones: pregunta.observaciones,
        no_conformidad: pregunta.no_conformidad,
        respuesta: pregunta.respuesta,
        peso: pregunta.peso,
        numero: pregunta.numero,
        tipologia: pregunta.tipologia,
        instalacion: pregunta.instalacion,
        comentarios: pregunta.comentarios,
        requisito_afectado: pregunta.requisito_afectado,
      }
      await this.$store.dispatch('auditorias/editRespuesta', params)
    },

    async saveChecklist() {
      let inicio = this.item.inicio
      let fin = this.item.fin
      if (inicio === '') inicio = null
      if (fin === '') fin = null

      await this.$store.dispatch('auditorias/saveChecklist', {
        auditoria: this.auditoria_uuid,
        checklist: this.item.uuid,
        observaciones: this.item.observaciones,
        fecha: this.$moment(this.item.fecha).format('YYYY-MM-DD'),
        inicio: inicio,
        fin: fin,
      })

      this.$forceUpdate()
    },

    async setRespuestaPreguntasRelacion({ relacion, value }) {
      this.loading = true
      const preguntas = this.preguntas_relacion[relacion]
      if (preguntas) {
        for (let i = 0; i < preguntas.length; i++) {
          const element = preguntas[i]
          const pregunta = element.pregunta
          const checklist_uuid = element.checklist.uuid
          await this.setRespuesta(pregunta, value, checklist_uuid)
        }
      }
      this.loading = false
    },
    // Evidencias
    closeDialogAddEvidencia() {
      this.dialogAddEvidencia = false
      this.evidencia = {
        uuid: '',
        submodulo_ref: '',
        titulo: null,
        tipo: { name: 'PDF', accept: 'application/pdf' },
        file: null,
      }
      this.$refs.form.resetValidation()
    },
    async adjuntarEvidencia(pregunta_uuid) {
      if (pregunta_uuid) {
        this.evidencia.submodulo_ref = pregunta_uuid
        this.dialogAddEvidencia = true
      } else if (this.$refs.form.validate()) {
        const body = {
          uuid: this.$uuid.v4(),
          modulo: 'auditorias',
          modulo_ref: this.auditoria_uuid,
          submodulo: 'checklist_pregunta',
          submodulo_ref: this.evidencia.submodulo_ref,
          documento: this.evidencia,
        }
        console.log('adjuntarEvidencia -> body', body)

        await this.$store.commit('gestor_documental/ADD_CUSTOM', body)

        this.offlineMode
          ? await this.$store.dispatch('offlineMode/createCustomDocument', body)
          : await this.$store.dispatch('gestor_documental/createCustom', body)

        this.closeDialogAddEvidencia()

        this.getEvidencias()
      }
    },

    //+-------------------------------------------------
    // addEvidencia()
    // Replaces adjuntarevidencia to attach files with the
    // New document uploader
    // -----
    // Created on Fri Jul 16 2021
    //+-------------------------------------------------
    addEvidencia(preguntaUUID) {
      let config = {
        // maxFiles: 1,
        modulo: 'auditorias',
        submodulo: 'checklist_pregunta',
        payload: preguntaUUID,
      }

      config.modulo_ref = this.auditoria.uuid
      config.submodulo_ref = preguntaUUID

      config.cliente = this.auditoria.cliente.uuid
      config.cliente_name = this.auditoria.cliente.nombre

      this.$nuxt.$emit('documents:open', config)
    },

    RemoveAccents(str) {
      let accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
      let accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
      str = str.split('')
      let strLen = str.length
      let i, x
      for (i = 0; i < strLen; i++) {
        if ((x = accents.indexOf(str[i])) != -1) {
          str[i] = accentsOut[x]
        }
      }
      return str.join('').toLowerCase()
    },

    formatDataToExport(data) {
      let preguntas = JSON.parse(JSON.stringify(data))
      if (this.isCcl) {
        const nivelesValores = Object.values(this.labels.niveles)
        preguntas.forEach((e) => {
          if (e.respuesta == 'V') e.respuesta = this.labelV
          if (e.respuesta == 'F') e.respuesta = this.labelF
          if (e.respuesta == 'NA') e.respuesta = this.labelNA
          if (!nivelesValores.includes(e.peso)) e.peso = ""
        })
      } else {
        preguntas.forEach((e) => {
          if (e.respuesta == 'V') e.respuesta = this.labelV
          if (e.respuesta == 'F') e.respuesta = this.labelF
          if (e.respuesta == 'NA') e.respuesta = this.labelNA
        })
      }
      return preguntas
    },

    // Excel
    excelExport() {
      const columnDefs = this.columnDefs
      let data = this.filtrarPreguntas.filter((e) => !e.solo_titulo)
      let formatted = this.formatDataToExport(data)
      const filename = this.item.titulo
      const ws_name = 'Preguntas'
      console.log(columnDefs)
      console.log(formatted)
      UTIL.exportFile(this, filename, ws_name, columnDefs, formatted)
    },

    confirmarImportFile() {
      this.texto_confirmacion = '¿Está seguro de que desea importar las preguntas?'
      this.dialogImport = true
    },
    async importFile(event) {
      console.log('resimportFile', event)
      this.loading = true
      const allowEmptyCells = true
      const grid = null
      const columnDefs = this.columnDefs
      // Validations
      const body = {
        empresa: this.$auth.user.empresa.uuid,
        alcance: this.auditoria.alcance.uuid,
        codigos: ['checklist_niveles'],
      }

      const res = await this.$store.dispatch('estaticos/list', body)
      console.log('res', res)
      let valores = []
      if (res.length > 0) {
        valores = res[0].valor.map((e) => e.texto)
      }
      valores.push(' ')
      const validations = [
        {
          header: 'peso',
          error: 'no es un nivel valido.',
          items: valores,
        },
        {
          header: 'respuesta',
          error: 'no es una respuesta valida.',
          items: (this.isCcl ? ['V', 'F', 'NA', 'NC', 'C', ' '] : ['V', 'F', 'NA', ' ']),
        },
      ]


      await UTIL.importFile(
        this,
        event,
        grid,
        columnDefs,
        this.onImportEnd.bind(this),
        validations,
        allowEmptyCells
      )
      this.$refs.inputFile.value = ''
    },

    async onImportEnd(data) {
      console.log('data', data)
      let error = false
      const updated_preguntas = []
      // Buscamos el código de las preguntas y reemplazamos las respuestas
      const preguntas = this.item.preguntas.filter((e) => !e.solo_titulo)
      for (let i = 0; i < data.length; i++) {
        const pregunta = data[i]
        const index = preguntas.findIndex((e) => e.codigo == pregunta.codigo)
        if (index > -1) {
          const old_pregunta = Object.assign({}, preguntas[index])
          const old_pregunta_keys = Object.keys(old_pregunta)
          // Asignamos los valores que existan de la nueva pregunta
          const new_pregunta = {}
          for (let k = 0; k < old_pregunta_keys.length; k++) {
            const key = old_pregunta_keys[k]
            // Mantenemos el uuid
            new_pregunta[key] = key == 'uuid' ? old_pregunta[key] : pregunta[key] || ''
          }
          // Asignamos la nueva pregunta

          if (pregunta.respuesta === 'F') {
            new_pregunta.no_conformidad = pregunta.no_conformidad || ''
          }

          if(pregunta.comentarios){
            new_pregunta.comentarios = pregunta.comentarios || ''
          }

          updated_preguntas.push(new_pregunta)
        } else {
          error = true
          const alert = {
            text: `El código de pregunta ${pregunta.codigo} no existe.`,
            color: 'error',
            timeout: 4000,
          }
          this.$store.commit('notification/show', alert)
          break
        }
      }
      console.info('error', error)
      console.log('updated_preguntas', updated_preguntas)
      // Actualizamos las preguntas
      if (!error) {
        for (let i = 0; i < updated_preguntas.length; i++) {
          const pregunta = updated_preguntas[i]
          await this.savePregunta(pregunta)
        }
      }
      await this.getChecklist().then(() => {
        this.loading = false
        this.componentNivel++
      })

    },

    //+-------------------------------------------------
    // assignDatesFromAuditoria()
    // Assign initial dates from this.auditoria if they are not set
    // -----
    // Created on Mon Feb 07 2022
    //+-------------------------------------------------
    assignDatesFromAuditoria() {
      let update = false
      this.$moment.locale('es')

      if (this.auditoria.desde && !this.item.fecha) {
        update = true
        this.item.fecha = this.auditoria.desde
      }

      if (this.auditoria.desde_inicio && !this.item.inicio) {
        update = true
        this.item.inicio = this.$moment(this.auditoria.desde_inicio).utc().format('HH:mm')
      }

      if (this.auditoria.desde_fin && !this.item.fin) {
        update = true
        this.item.fin = this.$moment(this.auditoria.desde_fin).utc().format('HH:mm')
      }

      if (update) {
        this.saveChecklist()
      }
    },

    //+-------------------------------------------------
    // getTextTemplates()
    // Gets templates from static data
    // -----
    // Created on Mon Apr 11 2022
    //+-------------------------------------------------
    async getTextTemplates() {
      const body = {
        alcance: this.auditoria?.alcance?.uuid,
        empresa: this.$auth.user.empresa.uuid,
        codigos: ['plantillas-checklist'],
      }

      const res = await this.$store.dispatch('estaticos/list', body)
      if (res && res.length > 0) {
        let templates = {}
        let items = res[0].valor

        for (const element of items) {
          if (templates[element.valor] == undefined) templates[element.valor] = []
          templates[element.valor].push(element)
        }

        this.templates = templates
      }
    },

    //+-------------------------------------------------
    // getDocuments()
    // Clones and indexes documents by question uuid
    // Then this table is used to print the files on questions
    // -----
    // Created on Wed Jul 21 2021
    //+-------------------------------------------------
    async getDocuments() {
      await this.getEvidencias()
      let docs = this.$store.getters['gestor_documental/documentos']
      this.evidencias = []

      for (const doc of docs) {
        let ref = doc.submodulo_ref
        if (this.evidencias[ref] == undefined) {
          this.evidencias[ref] = []
        }
        this.evidencias[ref].push(doc)
      }

      this.$forceUpdate()
    },

    async getPlantillaBloques() {
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      const xhr = await this.$store.dispatch('plantillas-bloques/list', {
        modulo: 'checklists',
        alcance: this.auditoria.alcance.uuid,
        ref: uuid,
      })

      if (xhr) {
        this.db.bloques = xhr
      }
    },

    //+-------------------------------------------------
    // getBloques()
    // Carga los bloques asociados a la auditoría
    // Si no hay, carga las plantillas de la auditoria
    // -----
    // Created on Fri Apr 22 2022
    //+-------------------------------------------------
    async getBloques() {
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      const xhr = await this.$store.dispatch('bloques/get', {
        modulo: 'checklists',
        modulo_ref: uuid,
      })

      this.db.misBloques = []
      if (xhr) this.db.misBloques = xhr

      this.ui.refresh = Date.now()
    },

    preguntaHasBloque(pregunta, bloque) {
      console.warn('buscando bloque', pregunta, bloque)
      let bloques = pregunta.bloques.map((b) => b.plantilla.uuid)
      console.warn('plantillas', bloques)
      if (bloques.includes(bloque)) return true
      return false
    },

    //+-------------------------------------------------
    // getBlocksForUUID()
    // Returns blocks based on uuid template
    // Otherwise return template
    // -----
    // Created on Wed Oct 27 2021
    // Updated on Fri May 06 2022
    //+-------------------------------------------------
    getBlocksForUUID(uuid, submodulo_ref) {
      let mine = this.db.misBloques.filter(
        (block) => block.plantilla?.uuid == uuid && block.submodulo_ref == submodulo_ref
      )
      // mine.hola = true
      if (mine.length > 0) return { ...mine }

      let template = this.db.bloques.filter((block) => block.uuid == uuid)
      return JSON.parse(JSON.stringify(template))
    },

    updateBlock(key, block, append = false) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doUpdate(block)
      block.isNew = false
    },

    resetBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doReset(block)

      block.isNew = true
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      block.modulo_ref = uuid
    },

    cloneBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doClone(block)
    },

    exportBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doExport(block)
    },

    setColumnDefs() {
      let columns = [
        { headerName: 'Código', field: 'codigo' },
        { headerName: 'Relación', field: 'relacion' },
        { headerName: 'Título', field: 'titulo' },
        { headerName: 'Respuesta', field: 'respuesta' },
        { headerName: 'Nivel', field: 'peso' },
        { headerName: 'Observaciones', field: 'observaciones' },
        { headerName: 'Texto de no conformidad', field: 'no_conformidad' },
      ];

      if (this.isCcl) {
        columns.splice(columns.length - 1, 0, { headerName: 'Observaciones detectadas a emitir', field: 'comentarios' });
      }

      this.columnDefs = columns;
    },

    async download(doc) {
      await this.$store.dispatch('gestor_documental/download', doc.uuid)
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'auditorias',
        submodule: 'checklists',
        return: 'all',
      })
    },

    async init() {
      this.checkRoles()
      await this.getDocuments()
      await this.getBloques()

      this.ui.ready = true
    },
  },

  mounted() {
    this.init()

    this.$nuxt.$on('bloques:refresh', (payload) => {
      this.getBloques()
    })

    this.$nuxt.$on('documents:uploaded', (body) => {
      this.getDocuments()
    })

    this.$nuxt.$on('offline:snapshot', (payload) => {
      this.$store.commit('offline/snapshotPage', {
        item: this.item,
      })
    })

    this.setColumnDefs()
  },

  beforeDestroy() {
    this.$nuxt.$off('bloques:refresh')
    this.$nuxt.$off('offline:snapshot')
    this.$nuxt.$off('documents:uploaded')
  },
}
</script>

<style>
.panel {
  z-index: 0 !important;
}

.titulo-pregunta p {
  display: inline;
  padding-left: 10px;
}
</style>
