import { uuid } from 'vue-uuid'

export const state = () => ({
  list: [],
  new: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  SET_NEW(state, items) {
    state.new = items
  },
  ADD_NEW(state, item) {
    item.uuid = uuid.v4()
    state.new.push(item)
  },
  EDIT_NEW(state, item) {
    const idx = state.new.indexOf((element) => element.uuid === item.uuid)
    state.new.splice(idx, 1, item)
  },
  REMOVE_NEW(state, item) {
    const idx = state.new.indexOf((element) => element.uuid === item.uuid)
    state.new.splice(idx, 1)
  },
}
//dispatch actions
export const actions = {
  async listCliente({ rootState, commit }, cliente) {
    console.log('listCliente', cliente)
    debugger
    rootState.loading = true
    const res = await this.$axios.get(`clientes/${cliente}/instalaciones`)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async listEmpresa({ rootState, commit }, empresa) {
    rootState.loading = true
    const res = await this.$axios.get(`empresas/${empresa}/instalaciones`)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async create({ rootState, state }, payload) {
    rootState.loading = true
    const instalacion = payload.instalacion
    const cliente_uuid = payload.cliente_uuid
    if (state.new.length > 0) {
      for (let i = 0; i < state.new.length; i++) {
        let body = {
          ...state.new[i],
          cliente: cliente_uuid,
        }
        await this.$axios.post(`clientes/${cliente_uuid}/instalaciones`, body)
      }
    } else if (instalacion) {
      let body = {
        ...instalacion,
        cliente: cliente_uuid,
        uuid: uuid.v4(),
      }
      await this.$axios.post(`clientes/${cliente_uuid}/instalaciones`, body)
    }
    rootState.loading = false
  },

  async update({ rootState, state }, params) {
    rootState.loading = true
    const body = {
      ...params.instalacion,
      cliente: params.cliente_uuid,
      instalacion: params.instalacion.uuid,
    }
    await this.$axios.patch(
      `clientes/${params.cliente_uuid}/instalaciones/${params.instalacion.uuid}`,
      body
    )
    rootState.loading = false
  },

  //+-------------------------------------------------
  // clone()
  // -----
  // Created on Mon Jun 27 2022
  //+-------------------------------------------------
  async clone({ rootState }, params) {
    rootState.loading = true
    await this.$axios.post(`clientes/duplicar-instalacion`, {
      ...params,
    })
    rootState.loading = false
  },

  async delete({ rootState }, params) {
    rootState.loading = true
    const jxr = await this.$axios.delete(
      `clientes/${params.cliente_uuid}/instalaciones/${params.uuid}`
    )
    rootState.loading = false

    return jxr
  },
}
