import {uuid} from 'vue-uuid'

export const state = () => ({
  list: [],
  new: []
});

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  SET_NEW(state, items) {
    state.new = items
  },
  ADD_NEW(state, item) {
    item.uuid = uuid.v4()
    state.new.push(item)
  },
  EDIT_NEW(state, item) {
    const idx = state.new.indexOf(element => element.uuid === item.uuid)
    state.new.splice(idx, 1, item)
  },
  REMOVE_NEW(state, item) {
    const idx = state.new.indexOf(element => element.uuid === item.uuid)
    state.new.splice(idx, 1)
  }
};
//dispatch actions
export const actions = {

  async listCliente({rootState, commit}, cliente) {
    rootState.loading = true
    const res = await this.$axios.get(`clientes/${cliente}/tecnicos`)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async listEmpresa({rootState, commit}, empresa) {
    rootState.loading = true
    const res = await this.$axios.get(`empresas/${empresa}/tecnicos`)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  async create({rootState, state}, payload) {
    const tecnico = payload.tecnico
    const cliente_uuid = payload.cliente_uuid
    rootState.loading = true
    if (state.new.length > 0) {
      for (let i=0; i < state.new.length; i++) {
        const item = state.new[i]
        let body = {
          ...item,
          cliente: cliente_uuid,
          username: item.user.username,
          email: item.user.email,
        }
        await this.$axios.post(`clientes/${cliente_uuid}/tecnicos`, body)
      }
    }
    else if (tecnico) {
      let body = {
        ...tecnico,
        cliente: cliente_uuid,
        uuid: uuid.v4()
      }
      await this.$axios.post(`clientes/${cliente_uuid}/tecnicos`, body)
    }
    rootState.loading = false
  },

  async update({rootState, state}, params) {
    const body = {
      ...params.tecnico,
      username: params.tecnico.user.username,
      cliente: params.cliente_uuid,
      tecnico: params.tecnico.uuid
    }
    rootState.loading = true
    await this.$axios.patch(`clientes/${params.cliente_uuid}/tecnicos/${params.tecnico.uuid}`, body)
    rootState.loading = false
  },

  async delete({rootState}, params) {
    rootState.loading = true
    await this.$axios.delete(`clientes/${params.cliente_uuid}/tecnicos/${params.uuid}`)
    rootState.loading = false
  },

  async changePassword({rootState}, params) {
    rootState.loading = true
    const body = {
      tecnico: params.tecnico.uuid,
      password: params.tecnico.password
    }
    await this.$axios.patch(`clientes/${params.cliente_uuid}/tecnicos/${params.tecnico.uuid}/change-password`, body)
    rootState.loading = false
  },

};
