<template>
  <div>
    <v-form ref="form" lazy-validation v-model="valid">
    <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="4" class="pb-0">
                    <v-menu
                      v-model="ui.datepicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          :value="$moment(item.fecha_proposicion).format('D [de] MMMM, YYYY')"
                          label="Fecha de proposición"
                          clearable
                          dense
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        color="primary"
                        locale="es-es"
                        :first-day-of-week="1"
                        v-model="item.fecha_proposicion"
                        @input="ui.datepicker = false"
                      ></v-date-picker>
                    </v-menu>

                    <v-menu
                        v-model="ui._datepicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          :value="$moment(item.fecha_decision).format('D [de] MMMM, YYYY')"
                          label="Fecha decisión"
                          clearable
                          dense
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <!--
                        Fecha minima en fecha_decision
                        Se ha eliminado la fecha minima en el selector.
                        https://kaamit.atlassian.net/browse/CERTIAPP-988
                        :min="moment().subtract(1, 'days').format()"
                      -->
                      <v-date-picker
                          color="primary"
                          locale="es-es"
                          :first-day-of-week="1"
                          v-model="item.fecha_decision"
                          :disabled="item.estado == estados.DICTAMEN_ESTADO.RESUELTO"
                          @input="ui._datepicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4" class="pb-0">
                    <select-auditores-object
                      class="mb-6"
                      v-model="item.auditor1"
                      dense
                      required
                      outlined
                      title="Firma en el certificado"
                      hide-details="none"
                      :rules="[v => !!v || 'Debes seleccionar un auditor']"
                    />

                    <v-text-field v-if="item.auditor1"
                      dense
                      outlined
                      label="En calidad de"
                      v-model="item.auditor1.en_calidad_de"
                    ></v-text-field>

                  </v-col>
                  <v-col cols="4" class="pb-0">
                    <select-auditores-object
                      class="mb-6"
                      v-model="item.auditor2"
                      dense
                      outlined
                      hide-details="none"
                      title="2º Auditor"/>

                    <v-text-field v-if="item.auditor2"
                      dense
                      outlined
                      hide-details="none"
                      label="En calidad de"
                      v-model="item.auditor2.en_calidad_de"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <v-textarea
                      label="Observaciones"
                      v-model="item.motivo"
                      outlined
                      rows="3"
                      auto-grow
                      hide-details="auto"
                    ></v-textarea>
                  </v-col>

                  <v-col v-if="this.item.action == 'create' || this.item.action == 'update'">
                    <v-btn
                      v-if="auth.canEdit"
                      depressed
                      color="success"
                      @click="submit">
                      Guardar lote
                    </v-btn>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="11" class="mx-auto py-3 my-2">
          <v-divider></v-divider>
        </v-col>

        <!-- <pre>
          {{ is_revision }}
          {{ _lote }}
        </pre> -->
        <v-col cols="12" class="mb-2">
          <v-card outlined class="row mx-0">
            <v-col cols="2" class="d-flex align-center text-center">
              <h3 class="pl-2">
                {{ batch.length || 0 }}
              </h3>

              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-title>Seleccionadas</v-list-item-title>
                <v-list-item-subtitle>Modificar estados</v-list-item-subtitle>
              </v-list-item-content>
            </v-col>

            <v-col cols="2" class="py-2 d-flex align-center">
              <v-select
                v-model="apply.decision"
                label="Decisión"
                :items="decisiones"
                :disabled="!batch.length"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col cols="2" class="py-2 d-flex align-center">
              <v-select
                v-model="apply.accion"
                label="Acción"
                :items="acciones"
                :disabled="!apply.decision"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="2" class="py-2 d-flex align-center">
              <v-btn
                  block
                  small
                  depressed
                  color="success"
                  @click="applyState">
                  Aplicar estados
                </v-btn>
            </v-col>

          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card outlined class="row mx-0">
            <v-col cols="2" class="text-center">

              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-subtitle>Seleccionar y añadir <br>un nuevo dictámen al lote</v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
            <v-col cols="4" class="py-2 d-flex align-center">
              <v-select
                v-model="apply.selected"
                label="Seleccionar un dictámen"
                :items="db.dictamenes"
                :item-text="(item) => item.codigo_expediente + ' (' + item.nombre_cliente + ')'"
                item-value="uuid"
                outlined
                dense
                hide-details="auto"
                return-object
              ></v-select>
            </v-col>

            <v-col cols="2" class="py-2 d-flex align-center">
              <v-btn
                  block
                  small
                  depressed
                  color="success"
                  @click="addToLote">
                  Añadir al lote
                </v-btn>
            </v-col>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card outlined>
            <!-- <pre>
              {{ selected[0] }}
            </pre> -->
            <v-data-table
              ref="datatable"
              v-model="batch"
              item-key="uuid"
              show-select
              hide-default-footer
              items-per-page='100'

              :items="selected"
              :headers="[
                { text: 'Estado', value: 'estado', align: 'left', cellClass: 'subData', width: 75, sortable: false },
                {
                  text: 'Fecha creación',
                  value: 'dates',
                  cellClass: 'subData',
                  width: 75,
                  sortable: false,
                },
                { text: 'Alcance', value: 'alcance', cellClass: 'subData', width: 150, sortable: false },
                { text: 'Código', value: 'codigo_expediente', cellClass: 'subData', width: 150, sortable: false },

                { text: 'Cliente', value: 'client', sortable: false },
                {
                  text: 'Decisión',
                  value: 'decision',
                  cellClass: 'subData',
                  width: 200,
                  sortable: false,
                },
                {
                  text: 'Acción',
                  value: 'accion',
                  cellClass: 'subData',
                  width: 200,
                  sortable: false,
                },
                { text: 'Revisión', align: 'center', value: 'gotorevision', width: 25, sortable: false },
                { text: 'Dictámen', align: 'center', value: 'gotodictamen', width: 25, sortable: false },
                { text: '', align: 'center', value: 'unlink', width: 25, cellClass: 'table-actions', sortable: false },
              ]"
              :items-per-page="50"

              :search="table.search"
              :class="{'disabled': ui.loading}"
              :loading="ui.loading">
              <template v-slot:item.alcance="{ item }">
                <span v-if="item.nombre_alcance">
                  {{ item.nombre_alcance }}
                </span>
                <span v-else-if="item.auditoria">
                  {{ item.auditoria.alcance }}
                </span>
              </template>

              <template v-slot:item.client="{ item }">
                <span v-if="item.nombre_cliente">
                  {{ item.nombre_cliente }}
                </span>
                <span v-else-if="item.cliente">
                  {{ item.cliente.nombre }}
                </span>
              </template>

              <template v-slot:item.dates="{ item }">
                {{ $moment(item.created_at).format("DD [de] MMMM, YYYY") }}
              </template>

              <template v-slot:item.decision="{ item }">
                <span v-if="item.decision">
                  {{ item.decision }}
                </span>
                <!-- <span v-else-if="item.dictamen.decision">
                  {{ item.dictamen.decision }}
                </span> -->
                <span v-else-if="item.proposicion">
                  {{ item.proposicion }}
                </span>
              </template>

              <template v-slot:item.accion="{ item }">
                <span v-if="item.accion">
                  {{ item.accion }}
                </span>
                <!-- <span v-else-if="item.dictamen.accion">
                  {{ item.dictamen.accion }}
                </span> -->
              </template>

              <template v-slot:item.alcance="{ item }">
                <span v-if="item.alcance">
                  {{ item.alcance }}
                </span>
                <span v-else-if="item.auditoria && item.auditoria.alcance">
                  {{ item.auditoria.alcance || '--' }}
                </span>
                <!-- <pre>
                  {{ item.uuid }}
                </pre> -->
              </template>

              <template v-slot:item.gotorevision="{ item }">
                <v-btn v-if="item.revision && item.revision.uuid"
                  @click.stop="$router.push(`/revisiones/${item.revision.uuid}`)"
                  small
                  dense
                  text outlined icon
                  color="primary">
                  <v-icon dark small>
                    mdi-checkbox-multiple-marked-outline
                  </v-icon>
                </v-btn>
              </template>

              <template v-slot:item.gotodictamen="{ item }">
                <v-btn v-if="item.decision"
                  @click.stop="$router.push(`/dictamenes/${item.uuid}`)"
                  small
                  dense
                  text outlined icon
                  color="primary">
                  <v-icon
                    dark small >
                    mdi-gavel
                  </v-icon>
                </v-btn>
              </template>

              <template v-slot:item.unlink="{ item }">
              <v-btn small
                dense
                text
                color="secondary"
                @click.stop="unlink(item)">
                <v-icon small class="mr-2">mdi-link-off</v-icon>
                Desenlazar
              </v-btn>
            </template>
            </v-data-table>
          </v-card>
        </v-col>
    </v-row>
    </v-form>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \pages\revisiones\lotes\data.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 2nd June 2022
 * Last Modified: Fri Jul 21 2023
 **/

import { uuid } from 'vue-uuid'
import estados from '~/models/estados'
import SelectAuditoresObject from '~/components/forms/SelectAuditoresObject'

import notifications from '~/util/notificationHelper'

export default {
  props: ['readonly', '_lote', '_batch', '_selected', 'is_revision', 'type'],
  components: {
    SelectAuditoresObject,
  },
  data: () => ({
    estados: estados,
    decisiones: estados.DICTAMEN_DECISIONES_PROPOSICIONES,

    valid: false,
    batch: [],
    selected: [],
    item: {
      action: 'create',

      auditor1: {},
      auditor2: {},
      fecha_decision: new Date().toISOString().substr(0, 10),
      fecha_proposicion: new Date().toISOString().substr(0, 10),
      motivo: '',
    },

    apply: {
      selected: null,
      decision: null,
      accion: null,
    },

    table: {
      search: '',
      headers: [{ text: 'Estado', value: 'status', cellClass: 'subData', width: 100 }],
    },

    db: {
      dictamenes: [],
    },

    auth: {},

    ui: {
      loading: true,
    },
  }),

  computed: {
    acciones() {
      return this.apply.decision === estados.DICTAMEN_DECISION_PROPOSICION.APTO
        ? estados.DICTAMEN_ACCIONES_APTO
        : estados.DICTAMEN_ACCIONES_NO_APTO
    },
  },

  methods: {
    //+-------------------------------------------------
    // populateData()
    // Sets data from props
    // -----
    // Created on Fri Jun 10 2022
    //+-------------------------------------------------
    populateData() {
      if (this._lote) {
        this.item = { ...this._lote }

        if (!this.item.auditor1) this.item.auditor1 = {}
        if (!this.item.auditor2) this.item.auditor2 = {}

        if (this.item.en_calidad_de_auditor1)
          this.item.auditor1.en_calidad_de = this.item.en_calidad_de_auditor1

        if (this.item.en_calidad_de_auditor2)
          this.item.auditor2.en_calidad_de = this.item.en_calidad_de_auditor2
      }

      if (this._batch) this.batch = [...this._batch]
      if (this._selected) this.selected = [...this._selected]
      if (this._lote?.revisiones) this.selected = [...this._lote.revisiones]
      if (this._lote?.dictamenes) this.selected = [...this._lote.dictamenes]

      this.mergeSelectedWithDictamenes()
    },

    //+-------------------------------------------------
    // mergeSelectedWithDictamenes()
    //
    // -----
    // Created on Tue Jul 05 2022
    //+-------------------------------------------------
    mergeSelectedWithDictamenes() {
      if (!this._lote) return
      this.selected.forEach((selected) => {
        console.warn(this._lote)
        let dictamen = this._lote.dictamenes.find(
          (dictamen) => dictamen.uuid === selected.dictamen?.uuid
        )
        if (dictamen) {
          selected.dictamen = dictamen
        }
      })
    },

    addToLote() {
      this.item.dictamenes.push(this.apply.selected)
      this.selected.push(this.apply.selected)
      this.apply.selected = null
    },

    applyState() {
      this.batch.forEach((item) => {
        let index = this.selected.findIndex((selected) => selected.uuid == item.uuid)
        if (index > -1) {
          this.selected[index].updated = true
          this.selected[index].accion = this.apply.accion
          this.selected[index].decision = this.apply.decision
        }
      })

      this.apply.accion = null
      this.apply.decision = null
      if (this.item.action !== 'create') this.item.action = 'update'
      this.batch = []
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    unlink(item) {
      let entity = this.item.dictamenes?.length > 0 ? 'dictamen' : 'revision'
      let index = this.selected.findIndex((selected) => selected.uuid == item.uuid)
      if (index > -1) {
        this.selected.splice(index, 1)
      }

      if (this.item.uuid)
        this.$store.dispatch('lotes/unlink', {
          lote: this.item.uuid,
          group: 'dictamenes',
          entity_class: entity,
          entity_uuid: item.uuid,
        })
    },

    validate() {
      if (!this.$refs.form.validate()) return false
      if (!this.item.auditor1?.uuid) return false
      return true
    },

    async submit() {
      let step = false

      // 1 . Form validations
      if (this.validate()) step = 'valid'
      // step = 'valid'

      // 2. Do api requests
      if (step == 'valid') {
        await this.store()
        step = 'stored'
      } else
        notifications.show({
          text: 'Debes revisar los campos antes de continuar',
          color: 'warning',
        })

      // 3. do post-request actions
      if (step == 'stored') {
        this.$emit('lotes:reset', {})
        $nuxt.$emit('lotes:stored', {})
        notifications.show({ text: 'Todos los cambios se han guardado correctamente' })
        this.$router.push('/dictamenes/lotes')
      }
    },

    async store() {
      this.ui.loading = true

      let action = this.item.action
      if (action == 'create') this.item.uuid = uuid.v4()

      let type = this.type
      if (!type) type = this.item.dictamenes?.length > 0 ? 'dictamenes' : 'revisiones'

      let payload = { ...this.item }
      payload[type] = []
      // payload.revisiones = []

      this.selected.forEach((item) => {
        let uuid = item.uuid
        if (type == 'revisiones' && item.revision?.uuid) uuid = item.revision.uuid
        // uuid: item.revision ? item.revision.uuid : item.uuid,

        payload[type].push({
          uuid: uuid,
          accion: item.accion,
          decision: item.decision,
        })
      })

      console.warn(action, payload, this.selected)

      try {
        payload.group = 'dictamenes'
        await this.$store.dispatch('lotes/' + action, payload)
      } catch (e) {
        console.info(e)
        notifications.show({
          text: 'Ha ocurrido un error guardando los cambios',
          color: 'error',
        })
      } finally {
        this.ui.loading = false
      }
    },

    //+-------------------------------------------------
    // Async API store requests
    //+-------------------------------------------------

    async getDictamenes() {
      const res = await this.$store.dispatch('dictamenes/list')
      this.db.dictamenes = res.filter((item) => item.estado == 'PENDIENTE')
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'dictamenes',
        submodule: 'lotes',
        return: 'all',
      })
    },

    async init() {
      this.checkRoles()
      this.populateData()
      this.getDictamenes()

      this.ui.refresh = Date.now()
      this.ui.loading = false

      if (this.readonly) this.item.action = 'update'
    },
  },

  mounted() {
    this.init()
    notifications.onSaved(this)
  },
}
</script>
