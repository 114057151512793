<template>
  <div>

    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <span v-if="title">{{ title}}</span>
            <span v-else>{{ isNew ? 'Nuevo' : 'Editar' }} Alcance del Producto</span>
          </v-card-title>

          <v-card-text style="max-height: 70vh;  overflow: auto;">
            <v-container>

              <slot></slot>
              <v-row>
<!--                <v-col cols="12" v-if="!ampliacion && isProductosIbericos">-->
                <v-col cols="12" v-if="!ampliacion">
                  <SelectInstalaciones
                    ref="instalaciones"
                    label="Instalación"
                    v-model="itemObjects.instalacion"
                    :cliente="cliente"
                    :tipos-instalacion="tiposInstalacion"
                    create
                    required
                    dense
                    returnObject
                    @input="updateItemObject('instalacion')"
                  />
                </v-col>

                <v-col cols="6">
                  <SelectActividades
                    label="Actividades Realizadas"
                    v-model="item.actividades_realizadas"
                    :actividades="actividadesRealizadasETG"
                    required
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="6" v-if="isOperadorResposable">
                  <SelectActividades
                    label="Actividades Subcontratadas"
                    v-model="item.actividades_subcontratadas"
                    :actividades="actividadesSubcontratadasETG"
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-select
                    label="Certificación"
                    v-model="item.certificacion"
                    :items="tiposCertificacion"
                    item-value="text"
                    :rules="item.subcontratadas && item.subcontratadas.length ? rules : []"
                    :required="item.subcontratadas && item.subcontratadas.length"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-combobox
                    v-model="item.marcas"
                    :items="item.marcas || []"
                    :search-input.sync="ui.search"
                    hide-selected
                    label="Marcas"
                    dense
                    multiple
                    small-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item v-if="ui.search && ui.search.length > 0">
                        <v-list-item-content>
                          <v-list-item-title>
                            Pulsa <kbd>enter</kbd> para introducir una marca
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                  <!-- <v-text-field
                    label="Marcas amparadas"
                    v-model="item.marcas"
                    dense
                  ></v-text-field> -->
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" class="title black--text">
                  Productos
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Producto"
                    v-model="item.producto"
                    :items="productos"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    :multiple="!ampliacion"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-select
                    label="Alimentación"
                    v-model="item.alimentacion"
                    :items="tiposAlimentacion"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    :multiple="!ampliacion"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-select
                    label="Racial"
                    v-model="item.racial"
                    :items="tiposRacial"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    :multiple="!ampliacion"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6" v-if="isProductosIbericos">
                  <v-select
                    label="Estado"
                    v-model="item.estado"
                    :items="tiposEstado"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    :multiple="!ampliacion"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-select
                    label="Presentación"
                    v-model="item.presentacion"
                    :items="presentaciones"
                    item-value="text"
                    :rules="rulesMultiple"
                    required
                    :multiple="!ampliacion"
                    dense
                  ></v-select>
                </v-col>

              </v-row>

              <v-row>

                <v-col cols="12" class="text-capitalize font-weight-bold">
                  Volumen
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Nº Animales Sacrificados"
                    v-model="item.animales_sacrificados"
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Nº Canales Despiezadas"
                    v-model="item.canales_despiezadas"
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Nº de jamones"
                    v-model="item.numero_jamones"
                    persistent-hint
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Nº de paletas"
                    v-model="item.numero_paletas"
                    persistent-hint
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Nº de cañas de lomo"
                    v-model="item.numero_canas_lomo"
                    persistent-hint
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Kilos de productos obtenidos del despiece de la canal que se comercializan en fresco"
                    v-model="item.carne_comercializada"
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" :disabled="loading" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import SelectInstalaciones from '~/components/forms/SelectInstalaciones'
import SelectActividades from '~/components/forms/SelectActividades'
import MODELS from '~/models/certicalidad/solicitudes'
import moment from 'moment'

export default {
  name: 'AlcanceProductoDialog',
  props: {
    value: Boolean,
    valid: { type: Boolean, default: true },
    title: { type: String, default: '' },
    cliente: { type: Object, default: null },
    ampliacion: { type: Boolean, default: false },
    isOperadorResposable: { type: Boolean, default: false },
    isProductosIbericos: { type: Boolean, default: false },
    isETG: { type: Boolean, default: false },
    producto: { type: Object, default: null },
    itemSelected: { type: Object, default: null },
  },
  components: {
    SelectInstalaciones,
    SelectActividades,
  },
  data: () => ({
    tiposInstalacion: [MODELS.TIPOS_INSTALACION.propia],
    itemObjects: {},
    dialog: false,
    dialogInstalaciones: false,
    item: {},
    rules: [(v) => !!v || 'El campo es obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'El campo es obligatorio'],
    tiposAlimentacion: MODELS.ALIMENTACION_TIPOS,
    tiposProducto: MODELS.PRODUCTO_TIPOS,
    tiposProductoETG: MODELS.PRODUCTO_TIPOS_ETG,
    tiposRacial: MODELS.RACIAL_TIPOS,
    tiposEstado: MODELS.ESTADOS_TIPOS,
    tiposPresentacion: MODELS.PRESENTACION_TIPOS,
    tiposPresentacionETG: MODELS.PRESENTACION_TIPOS_ETG,
    tiposCertificacion: MODELS.CERTIFICACION_TIPOS,
    actividadesETG: MODELS.ACTIVIDADES_ETG,

    ui: {
      search: '',
    },
  }),
  watch: {
    value(val) {
      this.dialog = val
      if (!val) this.resetForm()
    },
    dialog(val) {
      if (val) this.formatItem()
      if (!val) this.close()
    },
    producto(val) {
      if (val) this.getOne()
      else this.resetForm()
    },

    itemSelected(val) {
      if (val) this.setItem()
    },
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      },
    },
    isNew() {
      return this.producto === null
    },
    productos() {
      if (this.isProductosIbericos) return this.tiposProducto
      if (this.isETG) return this.tiposProductoETG
      return []
    },
    presentaciones() {
      if (this.isProductosIbericos) return this.tiposPresentacion
      if (this.isETG) return this.tiposPresentacionETG
      return []
    },
    actividadesRealizadasETG() {
      const actividadesETG = this.isOperadorResposable
        ? this.actividadesETG
        : this.actividadesETG.filter((e) => !e.responsableOnly)
      return this.isETG ? actividadesETG : []
    },
    actividadesSubcontratadasETG() {
      return this.isETG ? this.actividadesETG.filter((e) => !e.responsableOnly) : []
    },
    // isNewCliente() {
    //   return this.$route.uuid === '_'
    // },
  },
  methods: {
    updateItemObject(object) {
      //console.log('updateItemObject', object,this.itemObjects)
      if (this.itemObjects[object]) {
        this.item[object + '_codigo'] = this.itemObjects[object].rgseaa
        this.item[object + '_nombre'] = this.itemObjects[object].razon_social
      }
    },

    setItem() {
      if (this.itemSelected) {
        if (this.itemSelected.data) this.item = { ...this.itemSelected.data }
        else this.item = { ...this.itemSelected }

        console.log('setItem', this.item)
        this.formatItem()
      }

      window.setTimeout(() => {
        if (this.$refs.instalaciones && this.item.instalacion_codigo) {
          this.$refs.instalaciones.setValueByCodigo(this.item.instalacion_codigo)
        }
      }, 3000)
    },

    formatDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },
    formatDateBack(date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },

    async getOne() {
      console.log('getOne', this.producto)
      this.item = this.producto
      console.log('getOne', this.item)
      if (this.item.marcas == null) this.item.marcas = []

      if (this.isString(this.item.marcas)) {
        console.log('marcas is string')
        this.item.marcas = [this.item.marcas]
      }
    },

    async submit() {
      if (this.item.actividades_subcontratadas.length) {
        this.item.actividades_subcontratadas = this.item.actividades_subcontratadas.filter(
          (item) => item !== ''
        )
      }
      // debugger

      this.$emit('validate')
      if (this.$refs.form.validate() && this.valid) {
        // this.loading = true
        setTimeout(() => {
          this.$emit('submit', this.item)
          this.close()
        }, 500)
      }
    },

    isString(inputText) {
      if (typeof inputText === 'string' || inputText instanceof String) {
        //it is string
        return true
      } else {
        //it is not string
        return false
      }
    },

    // async submit() {
    //   if (this.$refs.form.validate()) {
    //     const body = {
    //       cliente_uuid: this.cliente ? this.cliente : this.$route.uuid,
    //       instalacion: {
    //         ...this.item
    //       }
    //     }
    //     body.instalacion.tramites = this.item.tramites.map(element => element.uuid)
    //     if (this.isNew) {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/create', body)
    //     } else {
    //       this.isNewCliente
    //         ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
    //         : await this.$store.dispatch('clientes_instalaciones/update', body)
    //     }
    //     if (!this.isNewCliente) this.$emit('init')
    //     this.close()
    //   }
    // },

    //+-------------------------------------------------
    // formatItem()
    // Sets initial values
    // on some form fields
    // -----
    // Created on Mon Jan 24 2022
    //+-------------------------------------------------
    formatItem() {
      console.warn(this.item)

      if (!this.item.animales_sacrificados) this.item.animales_sacrificados = 0
      if (!this.item.canales_despiezadas) this.item.canales_despiezadas = 0
      if (!this.item.carne_comercializada) this.item.carne_comercializada = 0
      if (!this.item.numero_canas_lomo) this.item.numero_canas_lomo = 0
      if (!this.item.numero_jamones) this.item.numero_jamones = 0
      if (!this.item.numero_paletas) this.item.numero_paletas = 0
      if (!this.item.actividades_subcontratadas) this.item.actividades_subcontratadas = ''
      if (!this.item.actividades_realizadas) {
        this.item.actividades_realizadas = []
      }

      const fields = [
        'marcas',
        'actividades_realizadas',
        'actividades_subcontratadas',
        'producto',
        'alimentacion',
        'racial',
        'presentacion',
      ]
      fields.forEach((field) => {
        if (this.isString(this.item[field])) {
          this.item[field] = this.item[field].split(',')
          this.item[field] = this.item[field].map((item) => item.trim())
        }
      })
    },

    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },
    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },
    // Filters
    filterTiposEstado(item) {
      const isCarnesFrescas = item.producto === 'carnes_frescas'
      return isCarnesFrescas
        ? this.tiposEstado.filter((element) => element.value === 'fresco')
        : this.tiposEstado
    },
    filterTiposPresentacion(item) {
      const isCarnesFrescas = item.producto === 'carnes_frescas'
      const tiposPresentacion = [
        { text: 'Pieza entera', value: 'entera' },
        { text: 'Troceado', value: 'troceado' },
        { text: 'Fileteado', value: 'fileteado' },
      ]
      return isCarnesFrescas ? tiposPresentacion : this.tiposPresentacion
    },
  },
}
</script>
