export default {
  SOLICITUD_TIPOS: [
    { text: 'Solicitud Inicial', value: 'inicial' },
    { text: 'Modificación de la Solicitud Inicial', value: 'modificacion' },
  ],

  TIPOS_INSTALACION: {
    propia: 'propia',
    alquilada: 'alquilada',
    subcontratada: 'subcontratada',
    maquila: 'maquila',
  },

  PROCEDENCIA_AGUA: [
    { text: 'Pozo', value: 'procedencia_agua_pozo' },
    { text: 'Embalse', value: 'procedencia_agua_embalse' },
    { text: 'Río', value: 'procedencia_agua_rio' },
    { text: 'Manantial', value: 'procedencia_agua_manantial' },
    { text: 'Embalse', value: 'procedencia_agua_embalse' },
    { text: 'Comunidad de regantes', value: 'procedencia_agua_comunidad' },
    { text: 'Otros', value: 'procedencia_agua_otros' },
  ],

  SISTEMA_RIEGO: [
    { text: 'Goteo', value: 'sistema_riego_goteo' },
    { text: 'Aspersión', value: 'sistema_riego_aspersion' },
    { text: 'Superficial', value: 'sistema_riego_superficial' },
    { text: 'Otros', value: 'sistema_riego_otros' },
  ],

  MANEJO_SUELO: [
    {
      text: `Orgánica, mediante estiércol, restos de vegetales o poda, abonados orgánicos preparados por marcas comerciales autorizados (Anexo II del Reglamento 2021/1165).`,
      value: 'manejo_suelo_organica',
    },
    {
      text: 'Plan de rotación en cultivos anuales (herbáceos y hortícolas)',
      value: 'manejo_suelo_rotacion',
    },
    {
      text: 'Uso de leguminosas y abonos verdes',
      value: 'uso_leguminosas_abonos_verdes',
    },
    {
      text: 'Otras medidas que eviten la compactación/erosión del suelo',
      value: 'manejo_suelo_otras',
    },
  ],

  CONTROL_PLAGAS: [
    {
      text: 'No se realizan tratamientos debido a la poca incidencia de plagas y enfermedades en los cultivos.',
      value: 'control_plagas_poca_incidencia',
    },
    {
      text: 'Si hay incidencias por plagas y enfermedades, para las que se realizan los siguientes tratamientos',
      value: 'control_plagas_enfermedades',
    },
    {
      text: 'Las hierbas se eliminan con los siguientes tratamientos',
      value: 'control_plagas_metodos_naturales',
    },
  ],
  CONTROL_PLAGAS_ENFERMEDADES: [
    {
      text: 'Uso de enemigos naturales',
      value: 'control_plagas_productos_autorizados_enemigos_naturales',
    },
    {
      text: 'Eleccion de especies, variedades y materiales adaptados a la zona o de probada resistencia fitosanitaria.',
      value: 'control_plagas_productos_autorizados_fitosanitaria',
    },
    {
      text: 'Rotacion de cultivos',
      value: 'control_plagas_productos_autorizados_rotacion',
    },
    {
      text: 'Tecnicas de cultivo como la biofumigacion, metodos mecanicos y fisicos',
      value: 'control_plagas_productos_autorizados_biofumigacion',
    },
    {
      text: 'Procesos termicos',
      value: 'control_plagas_productos_autorizados_termicos',
    },
    {
      text: 'Control aplicando productos fitosanitarios autorizados (Anexo II del Reglamento 2021/1165).',
      value: 'control_plagas_productos_autorizados',
    },
  ],

  CONTROL_PLAGAS_HIERBAS: [
    {
      text: 'Laboreo con maquinaria',
      value: 'control_plagas_metodos_naturales_maquinaria',
    },
    {
      text: 'Pastoreo',
      value: 'control_plagas_metodos_naturales_pastoreo',
    },
    {
      text: 'Siega o Corte',
      value: 'control_plagas_metodos_naturales_siega',
    },
    {
      text: 'Escardas',
      value: 'control_plagas_metodos_naturales_escardas',
    },
    {
      text: 'Otros tratamientos',
      value: 'control_plagas_metodos_naturales_otros',
    },
  ],

  DERIVAS_MEDIDAS: [
    {
      text: 'Dejar la 1ª fila de la linde sin cosechar, para autoconsumo o para el mercado convencional',
      value: 'derivas_medidas_primera_fila',
    },
    { text: 'Utilizar setos u otras barreras físicas', value: 'derivas_medidas_setos' },
    { text: 'Señalizar con banderas blancas', value: 'derivas_medidas_banderas_blancas' },
    { text: 'Otras', value: 'derivas_medidas_otras' },
  ],

  USO_COMPARTIDO_MEDIDAS: [
    { text: 'Separación física e identificación', value: 'separacion_fisica' },
    {
      text: 'Mantenimiento de registros (de limpieza, de tratamientos, de comercialización, etc...)',
      value: 'mantenimiento_registros',
    },
    { text: 'Otras', value: 'otras' },
  ],

  TIPOS_SECANO_REGADIO: [
    { text: 'Secano', value: 'secano' },
    { text: 'Regadío', value: 'regadio' },
  ],

  TIPOS_CULTIVO: [
    'AVENA',
    'CEBADA',
    'CEBADA CABALLAR',
    'CEBADA CERVECERA',
    'CENTENO',
    'TRANQUILLÓN',
    'ESCAÑA O ESCANDA',
    'TRITICUM SPELTA (ESPELTA)',
    'TRIGO BLANDO',
    'TRIGO DURO',
    'TRIGO SEMIDURO',
    'TRITICALE',
    'TRITORDEUM',
    'MAÍZ',
    'MAÍZ GRANO',
    'MEZCLA MAZORCA',
    'MIJO',
    'ALPISTE',
    'SORGO (GRANO)',
    'MEZCLA AVENA-TRIGO',
    'MEZCLA AVENA-CEBADA',
    'MEZCLA AVENA-TRITICALE',
    'OTRAS MEZCLAS DE CEREALES PARA GRANO',
    'OTROS CEREALES PARA GRANO (EXC. ARROZ)',
    'ARROZ (CÁSCARA)',
    'ARROZ',
    'ALBERJÓN',
    'ALGARROBA',
    'ALHOLVA O FENOGRECO',
    'ALMORTA',
    'ALTRAMUZ',
    'ALTRAMUZ DULCE',
    'ALVERJA',
    'GUISANTE PROTEAGINOSO',
    'HABA SECA PIENSO',
    'HABONCILLOS',
    'TITARRO',
    'VEZAS GRANO PIENSO',
    'VEZA',
    'YEROS PIENSO',
    'ALUBIAS',
    'FRÍJOL',
    'GARBANZOS GRANO C. H.',
    'GUISANTE SECO C. H.',
    'HABA SECA C.H.',
    'JUDÍA SECA',
    'LENTEJAS GRANO C. H.',
    'MEZCLA GUISANTE - AVENA',
    'MEZCLA GUISANTE - CEBADA',
    'MEZCLA VEZA - AVENA',
    'MEZCLA VEZA-CEBADA',
    'MEZCLA VEZA-TRIGO',
    'MEZCLA VEZA-TRITICALE',
    'MEZCLA ZULLA - AVENA',
    'MEZCLA ZULLA - CEBADA',
    'MEZCLA DE CEREALES Y LEGUMINOSAS PARA PIENSO',
    'OTRAS LEGUMBRES SECAS PARA CONSUMO HUMANO',
    'OTRAS PROTEAGINOSAS PARA PIENSO',
    'OTRAS LEGUMBRES SECAS PARA PIENSO',
    'OTRAS LEGUMINOSAS-PROTEAGINOSAS PARA GRANO',
    'PATATA EXTRATEMPRANA',
    'PATATA TEMPRANA',
    'PATATA MEDIA ESTACIÓN',
    'PATATA TARDÍA',
    'OTRAS PATATAS',
    'REMOLACHA AZUCARERA',
    'REMOLACHA FORRAJERA',
    'NABO FORRAJERO',
    'BATATA O BONIATO FORRAJEROS',
    'PATACA',
    'CHIRIVÍA',
    'ÑAME',
    'CALABAZA FORRAJERA',
    'COL FORRAJERA',
    'ZANAHORIA FORRAJERA',
    'MANDIOCA',
    'RAÍCES Y TUBÉRCULOS CONSUMO HUMANO (T. A.)',
    'OTROS TUBÉRCULOS (T. A.)',
    'OTRAS RAÍCES DE TIERRAS ARABLES (T. A.)',
    'GIRASOL',
    'LINO (ACEITE DE LINAZA Y NO TEXTIL)',
    'COLZA',
    'NABINA',
    'SOJA',
    'ADORMIDERA',
    'CACAHUETE',
    'CALABAZA PARA ACEITE',
    'CAMELINA',
    'CÁRTAMO',
    'CHUFA',
    'MOSTAZA',
    'SÉSAMO',
    'OTROS CULTIVOS PARA PRODUCIR GRANOS OLEAGINOSOS',
    'TABACO',
    'LÚPULO',
    'ALGODÓN',
    'CÁÑAMO DE MANILA',
    'CÁÑAMO PARA FIBRA TEXTIL',
    'KENAF',
    'LINO PARA FIBRA TEXTIL',
    'PITA PARA FIBRA TEXTIL',
    'RAFIA PARA FIBRA TEXTIL',
    'SISAL',
    'YUTE',
    'OTROS AGAVES PARA FIBRA TEXTIL',
    'OTRAS PLANTAS TEXTILES',
    'ACHICORIA INDUSTRIAL',
    'ADORMIDERA (AROM-MEDIC-CONDIMENT)',
    'AGAVE PARA FIBRA NO TEXTIL (DISTINTO DEL SISAL)',
    'AJEDREA',
    'AJENJO',
    'ALBAHACA',
    'ALCARAVEA',
    'ALOE VERA',
    'OTROS ALOES',
    'AMAPOLA',
    'ANGÉLICA',
    'ANÍS',
    'ARRHENATHERUM',
    'ARTEMISA',
    'AZAFRÁN',
    'BELLADONA',
    'CALÉNDULA',
    'CAÑA COMÚN (ARUNDO DONAX)',
    'CAÑA DE AZÚCAR',
    'CEBOLLINO',
    'CILANTRO',
    'COMINO',
    'CÚRCUMA',
    'DIGITAL',
    'ENELDO',
    'ESPLIEGO',
    'ESTEVIA',
    'ESTRAGÓN',
    'EUCALYPTUS',
    'GAYUBA',
    'GENCIANA',
    'GUINIDILLA',
    'HELICRISO',
    'HIERBABUENA',
    'HIERBALUISA',
    'HINOJO',
    'HIPÉRICO',
    'HISOPO',
    'JACARANDA',
    'JATROPHA',
    'JAZMÍN',
    'LAUREL',
    'LAVANDA',
    'LAVANDÍN',
    'LAVÁNDULA',
    'MANZANILLA',
    'MATALAUGA',
    'MEJORANA',
    'MELISA O TORONJIL',
    'MENTA',
    'MORINGA',
    'ORÉGANO',
    'PEREJIL',
    'PERIFOLLO',
    'PERVINCA',
    'PHYTOLACCA',
    'PIMIENTO PARA PIMENTÓN',
    'PLANTAGO (SEMILLAS)',
    'REGALIZ',
    'ROMERO',
    'SALVIA',
    'SANTOLINA',
    'THYMUS MASTICHINA',
    'TILA',
    'TOMILLO',
    'VALERIANA',
    'VERBENA',
    'ZUMAQUE',
    'OTRAS AROMÁTICAS-MEDIC-CONDIMENT-COSMÉTICA',
    'OTROS CULTIVOS INDUSTRIALES',
    'MAÍZ FORRAJERO',
    'SORGO FORRAJERO',
    'RAY-GRASS ANUALES',
    'AVENA PARA FORRAJE',
    'VEZA PARA FORRAJE',
    'POA',
    'COLZA PARA FORRAJE',
    'PASTO DEL SUDÁN',
    'PHACELIA TANACETIFOLIA',
    'OTRAS PLANTAS ANUALES COSECHADAS EN VERDE PARA FORRAJE',
    'BROMO',
    'COLA DE ZORRO',
    'DACTILO',
    'FESTUCA',
    'FLEO',
    'MAZORRA O AVENA ALTA',
    'RAY-GRASS HÍBRIDO PLURIANUAL',
    'RAY-GRASS INGLÉS',
    'RAY-GRASS ITALIANO',
    'TRÉBOL',
    'VALLICO',
    'MEZCLAS DE ESPECIES PRATENSES',
    'OTRAS GRAMÍNEAS (PASTOS Y PRADERAS TEMPORALES)',
    'PASTOS DE MENOS DE 5 AÑOS',
    'PRADERAS ARTIFICIALES TEMPORALES',
    'OTRAS PRADERAS Y PASTOS TEMPORALES',
    'ALFALFA',
    'ESPARCETA',
    'ZULLA',
    'OTRAS PLANTAS NO ANUALES COSECHADAS EN VERDE PARA FORRAJE',
    'SEMILLA DE TRIGO DURO',
    'SEMILLA DE TRIGO BLANDO',
    'SEMILLA DE TRIGO SEMIBLANDO',
    'SEMILLA DE ESPELTA',
    'SEMILLA DE CENTENO',
    'SEMILLA DE TRANQUILLÓN',
    'SEMILLA DE CEBADA',
    'SEMILLA DE AVENA',
    'SEMILLA DE MAÍZ ',
    'SEMILLA DE TRITICALE',
    'SEMILLA DE MIJO',
    'SEMILLA DE ALPISTE',
    'SEMILLA DE OTROS CEREALES',
    'SEMILLA DE ARROZ',
    'SEMILLA DE LEGUMBRES SECAS Y PROTEAGINOSAS PARA LA PRODUCCIÓN DE GRANO',
    'SEMILLA DE GUISANTE PROTEAGINOSO (PIENSO)',
    'SEMILLA DE HABAS PARA PIENSO',
    'SEMILLA DE HABONCILLOS',
    'SEMILLA DE YEROS',
    'SEMILLA DE VEZA',
    'SEMILLA DE OTRAS ESPECIES PROTEAGINOSAS PARA PIENSO',
    'SEMILLA DE LENTEJAS',
    'SEMILLA DE GARBANZOS',
    'SEMILLA DE JUDÍAS (ALUBIAS)',
    'SEMILLA DE OTRAS LEGUMBRES SECAS PARA CONSUMO HUMANO',
    'PATATA DE SIEMBRA',
    'REMOLACHA AZUCARERA PARA SIEMBRA',
    'MATERIAL DE REPRODUCCIÓN DE PATACA',
    'MATERIAL DE REPRODUCCIÓN DE NABO FORRAJERO',
    'MATERIAL DE REPRODUCCIÓN DE REMOLACHA FORRAJERA',
    'MATERIAL DE REPRODUCCIÓN DE OTRAS RAÍCES (T. A.)',
    'MATERIAL DE REPRODUCCIÓN DE OTROS TUBÉRCULOS (T. A.)',
    'SEMILLA DE GIRASOL',
    'SEMILLA DE SOJA',
    'SEMILLA DE COLZA',
    'SEMILLA DE NABINA',
    'SEMILLA DE LINO (ACEITE DE LINAZA)',
    'SEMILLA DE OTROS CULTIVOS OLEAGINOSOS N.C.O.',
    'SEMILLA DE TABACO',
    'SEMILLA DE LÚPULO',
    'SEMILLA DE ALGODÓN',
    'SEMILLA DE OTRAS PLANTAS TEXTILES',
    'SEMILLA DE OTROS CULTIVOS INDUSTRIALES (NO TEXTILES - OLEAGINOSOS)',
    'SEMILLA DE MAÍZ FORRAJERO',
    'SEMILLA DE SORGO FORRAJERO',
    'SEMILLA DE RAY-GRASS ANUALES',
    'SEMILLA DE AVENA PARA FORRAJE',
    'SEMILLA DE VEZA PARA FORRAJE',
    'SEMILLA DE OTRAS PLANTAS ANUALES COSECHADAS EN VERDE',
    'SEMILLA DE PRADERAS Y PASTOS TEMPORALES',
    'SEMILLA DE ALFALFA',
    'SEMILLA DE OTRAS PLANTAS TEMPORALES COSECHADAS EN VERDE',
    'SEMILLA DE OTRAS ESPECIES DE TIERRAS ARABLES',
    'ALFORFÓN O TRIGO SARRACENO',
    'QUINOA',
    'TEFF',
    'OTROS CULTIVOS DE TIERRAS ARABLES',
    'BERZA',
    'BRÓCOLI',
    'COL',
    'COL CHINA',
    'COL MILÁN',
    'COL ROJA',
    'COLES DE BRUSELAS',
    'COLIFLOR',
    'COLIRRÁBANO',
    'REPOLLO (BLANCO)',
    'ROMANESCU',
    'OTRAS COLES HORTÍCOLAS',
    'OTROS REPOLLOS',
    'ACEDERA',
    'ACELGA',
    'ACHICORIA',
    'ACHICORIA VERDE',
    'ALCACHOFA',
    'APIO',
    'BERRO DE AGUA',
    'BERRO HORTELANO - MASTUERZO',
    'BORRAJA',
    'CANÓNIGOS',
    'CARDILLO',
    'CARDO',
    'DIENTE DE LEÓN',
    'ENDIVIA',
    'ESCAROLA',
    'ESPÁRRAGO',
    'ESPINACA',
    'LECHUGA',
    'PUERRO',
    'RÚCULA',
    'RUIBARBO',
    'VERDOLAGA',
    'OTRAS HORTALIZAS DE HOJAS Y TALLO (SALVO BRASSICAS)',
    'ALCAPARRA',
    'BERENJENA',
    'CALABACÍN',
    'CALABAZA',
    'CALABAZA DEL PEREGRINO',
    'CALABAZA MOSCADA',
    'MELÓN',
    'PEPINILLO',
    'PEPINO',
    'PIMIENTO',
    'SANDÍA',
    'TOMATE',
    'TOMATE PARA TRANSFORMACIÓN',
    'OTRAS HORTALIZAS CULTIVADAS POR EL FRUTO',
    'AJO',
    'APIO NABO / APIRRÁBANO',
    'BATATA O BONIATO',
    'CEBOLLA',
    'CEBOLLETA',
    'CHALOTA',
    'NABO',
    'RÁBANO',
    'REMOLACHA DE MESA',
    'ZANAHORIA',
    'OTRAS HORTALIZAS DE BULBOS Y TUBÉRCULOS',
    'GARROFÓN',
    'GUISANTE VERDE C. H.',
    'HABA VERDE C. H.',
    'JUDÍA VERDE',
    'OTRAS LEGUMBRES DE VERDEO PARA CONSUMO HUMANO',
    'MAÍZ DULCE',
    'HORTALIZAS AIRE LIBRE',
    'HORTALIZAS INVERNADERO',
    'MULTICULTIVO DE HORTALIZAS',
    'OTRAS HORTALIZAS',
    'FRESAS CULTIVADAS',
    'FRESONES',
    'CHAMPIÑÓN',
    'SETAS CULTIVADAS',
    'PRODUCCIÓN DE SEMILLAS DE HORTALIZAS',
    'PRODUCCIÓN DE PLÁNTULAS DE HORTALIZAS (PLANTELES)',
    'PRODUCCIÓN DE MATERIAL DE MULTIPLICACIÓN DE FRESAS',
    'PRODUCCIÓN DE MATERIAL DE MULTIPLICACIÓN DE SETAS',
    'PRODUCCIÓN DE MATERIALES DE MULTIPLICACIÓN DE OTRAS HORTALIZAS-FRESAS-SETAS',
    'ALBARICOQUERO',
    'CEREZO Y GUINDO',
    'CIRUELO',
    'ENDRINO',
    'MANZANO',
    'MANZANO PARA MESA',
    'MANZANO PARA SIDRA',
    'MELOCOTONERO',
    'MEMBRILLERO',
    'NASHI',
    'NECTARINO',
    'NÍSPERO DEL JAPÓN (NÍSPERO)',
    'NÍSPERO EUROPEO',
    'PARAGUAYO',
    'PERAL',
    'PLATERINA',
    'OTROS FRUTALES DE ZONAS CLIMÁTICAS TEMPLADAS',
    'ACEROLO (CULTIV.)',
    'ARÁNDANO (CULTIV.)',
    'ESPINO CERVAL-AMARILLO (CULTIV.)',
    'FRAMBUESA (CULTIVADA)',
    'GROSELLA BLANCA Y ROJA (CULTIVADA)',
    'GROSELLA ESPINOSA (CULTIVADA)',
    'GROSELLA NEGRA',
    'MORERA (CULTIVADA)',
    'SAÚCO (CULTIV.)',
    'SERBAL (CULTIV.)',
    'ZARZAMORA (CULTIVADA)',
    'OTRAS ESPECIES DE PEQUEÑOS FRUTOS CULTIVADOS',
    'ALMENDRO AMARGO (CULTIV.)',
    'ALMENDRO DULCE (CULTIV.)',
    'OTROS ALMENDROS (CULTIV.)',
    'AVELLANO (CULTIV.)',
    'CASTAÑO (CULTIV.)',
    'NOGAL (CULTIV.)',
    'PISTACHO',
    'OTROS FRUTOS SECOS (CULTIVADOS)',
    'AGUACATE',
    'ANONAS',
    'BANANAS ',
    'PLÁTANOS',
    'BANANAS Y PLÁTANOS',
    'CAQUI (CULTIV.)',
    'CHIRIMOYO',
    'CHUMBERA (CULTIV.)',
    'GRANADO',
    'GUAYABA',
    'HIGUERA (CULTIV.)',
    'KIWI',
    'LICHI',
    'MANGO',
    'MARACUYÁ - FRUTO DE LA PASIÓN',
    'OPUNTIA (CULTIV.)',
    'PALMERA DATILERA (CULTIV.)',
    'PAPAYO',
    'PIÑA',
    'OTRAS FRUTAS SUBTROPICALES',
    'BERGAMOTA',
    'CIDRO',
    'CLEMENTINAS',
    'KUMQUAT',
    'LIMA ÁCIDA',
    'LIMONES',
    'LIMONES Y LIMAS ÁCIDAS',
    'MANDARINO',
    'NARANJO',
    'NARANJO AMARGO',
    'NARANJO DULCE',
    'NARANJO SANGUINO',
    'POMELO',
    'TORONJA',
    'TORONJAS Y POMELOS',
    'SATSUMAS',
    'CÍTRICOS HÍBRIDOS',
    'OTROS CÍTRICOS',
    'VIÑEDO VINIFICACIÓN',
    'VIÑEDO MESA',
    'VIÑEDO PASIFICACIÓN',
    'OLIVAR MESA',
    'OLIVAR ACEITE',
    'ALGARROBO',
    'BAMBÚ',
    'CAFETO',
    'JUNCO LACUSTRE (CULTIV.)',
    'MIMBRERA (CULTIVADA)',
    'VIÑA - OLIVAR',
    'OTROS CULTIVOS LEÑOSOS',
    'OTROS CULTIVOS PERMANENTES SIN CLASIFICACIÓN',
    'VIVERO DE FRUTALES',
    'VIVERO DE PEQUEÑOS FRUTOS',
    'VIVERO DE VID',
    'VIVERO DE OLIVO',
    'VIVERO DE OTROS CULTIVOS PERMANENTES',
    'AGROSTIS (PASTO)',
    'MISCANTHUS',
    'PASTIZAL DE 5 O MÁS AÑOS',
    'PASTO ARBOLADO DE 5 O MÁS AÑOS (EXC. PASTOS POBRES)',
    'PASTO ARBUSTIVO DE 5 O MÁS AÑOS (EXC. PASTOS POBRES)',
    'PASTOS PERMANENTES DE 5 O MÁS AÑOS (EXC. PASTOS POBRES)',
    'PRADERAS NATURALES PERMANENTES',
    'PRADERAS ARTIFICIALES PERMANENTES',
    'ERIAL (PASTO)',
    'DEHESA',
    'ALCORNOQUE (DEHESA)*',
    'BOSQUE PASTABLE (INCL. PECOREO)',
    'BARBECHO SIN PRODUCCIÓN',
    'ABONO VERDE',
    'RASTROJERAS',
    'BARBECHO TRADICIONAL',
    'BARBECHO MEDIOAMBIENTAL',
    'TERRENO FORESTAL Y PLANTAS SILVESTRES (SIN USO GANADERO)',
    'TRUFA',
    'SABINA*',
    'CHOPOS*',
    'SUPERFICIES FORESTALES DE ROTACIÓN CORTA*',
    'SUPERFICIES FORESTALES MADERABLES*',
    'ACACIA*',
    'ACEBUCHE*',
    'MONTE*',
    'PAULOWNIA*',
    'PINOS*',
    'ROBINIA*',
    'SÁLIX*',
    'MIMBRERA*',
    'JUNCO LACUSTRE*',
    'ROBLE*',
    'GLEDITSIA*',
    'LEUCAENA*',
    'SETAS SILVESTRES',
    'FRESAS SILVESTRES',
    'ZARZAMORA (SILVESTRE)',
    'SAÚCO (SILVESTRE)',
    'MORERA (SILVESTRE)',
    'ACEROLO (SILVESTRE)',
    'SERBAL (SILVESTRE)',
    'GROSELLA BLANCA Y ROJA (SILVESTRE)',
    'GROSELLA ESPINOSA (SILVESTRE)',
    'ESPINO CERVAL-AMARILLO (SILVESTRE)',
    'MADROÑO',
    'FRUTOS DEL BOSQUE (SILVESTRE)',
    'OTROS PEQUEÑOS FRUTOS SILVESTRES',
    'PIÑONES',
    'CASTAÑO (FORESTAL)',
    'ALCORNOQUE (FORESTAL)',
    'TÉ DE ROCA',
    'OTRAS RECOLECCIONES DE PLANTAS SILVESTRES (DISTINTAS DE FRUTOS PEQUEÑOS)',
    'ROSA DE DAMASCO (ROSA DAMASCENA)',
    'ÁRBOLES DE NAVIDAD',
    'AILANTHUS ',
    'CLAVEL',
    'CRISANTEMO',
    'ROSA',
    'FLOR CORTADA',
    'OTRAS FLORES Y PLANTAS ORNAMENTALES',
    'OTRAS UTILIZACIONES NO AGRARIAS NI FORESTALES',
  ],
}
