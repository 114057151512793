<template>
  <v-autocomplete
    ref="select"
    label="Pais"
    v-model="selected"
    :items="items"
    item-text="nombre"
    item-value="uuid"
    :rules="rules"
    :required="required"
    :disabled="disabled"
    :outlined="outlined"
    :hide-details="hideDetails"
    :dense="dense"
    :filled="filled"
    :error="error"
    :error-messages="errorMessages"
    no-data-text="Listado vacío"
    @change="emitChange()"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'SelectPais',
  props: {
    value: [String, Object],
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessages: { type: String, default: '' },
    hideDetails: { type: String, default: 'auto' },
  },
  data: (ctx) => ({
    selected: ctx.value,
    items: [],
  }),
  computed: {
    rules() {
      return this.required ? [(v) => !!v || 'Obligatorio'] : []
    },
  },
  watch: {
    value(val) {
      if (!val && this.$refs.select) this.$refs.select.resetValidation()
      this.selected = val
    },
  },
  methods: {
    emitChange() {
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
    },
    async init() {
      const res = await this.$axios.get('territorios/paises')
      if (res) this.items = res.data
      //if(!this.selected)this.selected = this.items[0].uuid
      this.emitChange()
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
</style>
