<template>
  <div>
    <loading v-model="$store.getters.loading" text="Espere por favor ..." />

    <v-form ref="form" lazy-validation v-model="valid" style="height: 100%">
      <v-row class="ml-1 mr-1">
        <!-- ALERTA MODO OFFLINE -->
        <!-- <v-expand-transition>
          <v-col cols="12" class="pb-0" v-if="offlineMode">
            <v-alert type="error">
              <v-row no-gutters>
                <v-col cols="8">
                  Estas usando el <span class="font-weight-bold">Modo Offline</span>, tus cambios serán efectuados una
                  vez vuelvas a estar online.
                  <br>
                  No puedes subir archivos mientras te encuentres en este modo.
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-btn small class="mr-4" color="success" @click="confirmarModoOnline()">Volver Online</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-expand-transition> -->

        <v-col>
          <!-- <v-sheet class="mt-2 mb-4 pa-3 d-flex d-lg-none" v-if="!isCliente" style="justify-content: flex-end;">
            <v-spacer/>

            <v-btn
                class="ml-3"
                color="grey"
                small
                outlined
                depressed
                @click="gotoExpediente()"
            >
              Ir al expediente
            </v-btn>

            <v-btn
                v-if="auth.canClose && esResponsable && !consulta && item && auditoriaAbiertaPrevista"
                :disabled="offlineMode"
                class="ml-3 white--text"
                color="error"
                small
                depressed
                @click="confirmarCambioEstado(estado.CERRADA)"
            >
              Cerrar auditoría
            </v-btn>

            <v-btn
                v-if="auth.canOpen && esResponsable && !consulta && item && auditoriaCerrada"
                :disabled="offlineMode"
                class="ml-3 white--text"
                color="success"
                small
                depressed
                @click="confirmarCambioEstado(estado.CONFIRMADA)"
            >
              Abrir auditoría
            </v-btn>

            <div v-if="esResponsable && dictamen">
              <v-btn @click="dialogIntroducirMotivo = true" small color="error" class="estado-auditoria-left">NO APTA
              </v-btn>
              <v-btn @click="confirmarCambioEstado(estado.APTA)" small color="green" dark class="estado-auditoria">
                APTA
              </v-btn>
            </div>
          </v-sheet> -->

          <v-tabs v-model="tab" @change="setTabUrl()">
            <v-tab key="general">General</v-tab>
            <v-tab key="checklist" v-show="!isCliente && auth.checklists && auth.checklists.canList"
              >Checklists</v-tab
            >
            <!-- Delete this line in the future -->
            <!-- <v-tab :disabled="$nuxt.isOffline || ui.remainDisabled" key="checklist-preguntas" v-if="!isCliente">Checklists preguntas</v-tab> -->
            <v-tab :disabled="$nuxt.isOffline || ui.remainDisabled" key="pacs">Pacs</v-tab>
            <v-tab key="muestras" v-if="!isCliente">Muestras</v-tab>
            <v-tab key="bloques">Bloques</v-tab>

            <v-spacer />

            <!-- ESTAS OPERACIONES SOLO LAS PUEDE HACER EL RESPONSABLE DE LA AUDITORÍA -->
            <div v-if="!isCliente">
              <!-- EXPEDIENTE -->
              <v-btn
                class="mt-2 mr-4"
                color="grey"
                small
                outlined
                depressed
                @click="gotoExpediente()">
                Ir al expediente
              </v-btn>

              <!-- MODO OFFLINE -->
              <!-- <v-btn
                  v-if="item && auditoriaAbiertaPrevista && !consulta && !offlineMode"
                  class="mt-2 mr-4 white--text"
                  color="grey"
                  small
                  depressed
                  @click="confirmarModoOffline()"
              >
                Modo Offline
              </v-btn> -->

              <!-- valorar auditoria -->
              <template v-if="(isCcl || isQuesoManchego) && item && !item.valoracion">
                <v-btn
                  v-if="tab == 0"
                  class="mt-2 mr-4 white--text"
                  color="purple"
                  small
                  depressed
                  @click="ui.showValoration = true">
                  Valorar
                </v-btn>
                <v-btn
                  v-if="tab == 0 && isCcl && !auditoriaCerrada"
                  class="mt-2 mr-4 white--text"
                  color="orange"
                  small
                  depressed
                  :disabled="item.estado === 'TERMINADA'"
                  @click="confirmarCambioEstado('TERMINADA')">
                  Terminar auditoría
                </v-btn>
              </template>

              <!-- Cerrar -->
              <v-btn
                v-if="
                  auth.canClose &&
                  esResponsable &&
                  !consulta &&
                  item &&
                  auditoriaAbiertaPrevista &&
                  !auditoriaCerrada
                "
                :disabled="offlineMode"
                class="mt-2 mr-4 white--text"
                color="error"
                small
                depressed
                @click="confirmarCambioEstado(estado.CERRADA)">
                Cerrar auditoría
              </v-btn>

              <!-- CERRADA -->
              <v-btn
                v-if="
                  auth.canOpen &&
                  esResponsable &&
                  !consulta &&
                  item &&
                  auditoriaCerrada &&
                  !haveDictamen
                "
                :disabled="offlineMode"
                class="mt-2 mr-4 white--text"
                color="success"
                small
                depressed
                @click="confirmarCambioEstado(estado.ABIERTA)">
                Abrir auditoría
              </v-btn>

              <!-- REVISIÓN -->
              <!-- <div v-if="esResponsable && revision">
                <v-btn @click="dialogIntroducirMotivo = true" small color="error" class="estado-auditoria-left">Revisión KO</v-btn>
                <v-btn @click="confirmarCambioEstado(estado.REVISADA_OK)" small color="green" dark class="estado-auditoria">Revisión OK</v-btn>
              </div> -->

              <!-- DICTAMEN -->
              <div v-if="esResponsable && dictamen">
                <v-btn
                  @click="dialogIntroducirMotivo = true"
                  small
                  color="error"
                  class="estado-auditoria-left"
                  >NO APTA
                </v-btn>
                <v-btn
                  @click="confirmarCambioEstado(estado.APTA)"
                  small
                  color="green"
                  dark
                  class="estado-auditoria">
                  APTA
                </v-btn>
              </div>
            </div>

            <!-- TAB GENERAL -->
            <v-tab-item
              :transition="false"
              :reverse-transition="false"
              key="general"
              style="background: rgb(246, 248, 249) !important"
              :class="{ isLocked: isLocked }">
              <v-row v-if="ui.showValoration">
                <v-col cols="6" class="mx-auto pa-8 text-center">
                  <v-icon style="font-size: 80px; color: #d2d5db" class="my-4">
                    mdi-message-check-outline
                  </v-icon>
                  <h2>Valorar auditoría</h2>

                  <p>
                    Antes de cerrar la auditoría, es necesario valorarla <br />Por favor, selecciona
                    la valoración que corresponda
                  </p>

                  <template v-if="!ui.loading">
                    <v-btn
                      :outlined="!item.valoracion || item.valoracion !== 'Satisfactoria'"
                      color="success darken-2"
                      depressed
                      @click="
                        item.valoracion = 'Satisfactoria'
                        guardarCambiosAuditoria()
                      ">
                      <!-- Satisfactoria -->
                      {{ ui.text._('auditorias.valorar.satisfactoria') }}
                    </v-btn>
                    <span class="mx-2">&nbsp;</span>
                    <v-btn
                      :outlined="!item.valoracion || item.valoracion !== 'No satisfactoria'"
                      color="error darken-2"
                      depressed
                      @click="
                        item.valoracion = 'No satisfactoria'
                        guardarCambiosAuditoria()
                      ">
                      <!-- No Satisfactoria -->
                      {{ ui.text._('auditorias.valorar.no_satisfactoria') }}
                    </v-btn>
                    <div class="text-caption grey--text py-3" v-if="item.valoracion">
                      La auditoría se ha valorado como {{ item.valoracion }}
                    </div>
                  </template>
                  <v-progress-linear
                    v-else
                    color="#d22bc5"
                    indeterminate
                    rounded
                    height="6"></v-progress-linear>
                </v-col>
              </v-row>

              <!-- DATOS GENERALES -->
              <v-card class="mt-5" v-if="item">
                <v-card-text>
                  <v-row class="mb-6">
                    <v-col cols="4" md="4" class="mb-0 pb-0">
                      <v-text-field
                        label="Código"
                        v-model="item.codigo"
                        @change="guardarCambiosAuditoria()"
                        :disabled="isDisabled"></v-text-field>
                    </v-col>
                    <v-col
                      v-if="isOceGlobal"
                      cols="4"
                      md="4"
                      class="mb-0 pb-1"
                      style="display: flex; align-items: flex-end">
                      <v-select
                        label="Esquema auditado"
                        v-model="item.esquema_auditado"
                        @change="guardarCambiosAuditoria()"
                        :items="esquemasAuditadosProduccionVegetal"
                        :disabled="isDisabled"
                        item-text="text"
                        item-value="value"
                        dense
                        clearable
                        return-object
                        multiple>
                      </v-select>
                    </v-col>
                    <v-col v-if="!isOceGlobal" ls="4" md="4" class="mb-0 pb-0"></v-col>
                    <v-col class="mb-0 pb-0">
                      <v-text-field label="Estado" v-model="item.estado" disabled></v-text-field>
                    </v-col>
                    <v-col class="mb-0 pb-0" v-if="auditoriaCerrada">
                      <v-text-field
                        prepend-icon="mdi-calendar"
                        label="Fecha cierre"
                        :value="formatDate(item.fecha_cierre)"
                        disabled></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- <pre>
                      x {{ item.desde }}
                      x {{ item.desde_inicio }}
                      x {{ item.desde_fin }}
                      ---
                      x {{ item.hasta }}
                      x {{ item.hasta_inicio }}
                      x {{ item.hasta_fin }}
                    </pre> -->
                  <v-row class="mb-6">
                    <v-col cols="12" md="2" class="mt-0 pb-0">
                      <v-menu v-model="menuFecha1" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            nope-prepend-icon="mdi-calendar"
                            :value="formatDate(item.desde)"
                            label="Fecha de inicio (Desde)"
                            readonly
                            v-on="on"
                            :disabled="isDisabled"></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="item.desde"
                          color="primary"
                          no-title
                          scrollable
                          first-day-of-week="1"
                          @change="
                            ;(menuFecha1 = false), guardarCambiosAuditoria()
                          "></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="1" class="mt-0 pb-0">
                      <v-text-field
                        type="time"
                        label="Hora inicio"
                        :value="horaToTime(item.desde_inicio)"
                        @change="setTime('desde_inicio', $event)"
                        :disabled="isDisabled"></v-text-field>
                      <!-- v-model="item.desde_inicio"
                        @change="item.desde_inicio = item.desde_inicio + ':00'; guardarCambiosAuditoria()" -->
                    </v-col>

                    <v-col cols="1" class="mt-0 pb-0">
                      <v-text-field
                        type="time"
                        label="Hora fin"
                        :value="horaToTime(item.desde_fin)"
                        @change="setTime('desde_fin', $event)"
                        :disabled="isDisabled"></v-text-field>
                    </v-col>

                    <v-col cols="2" class="mb-0 pb-0">
                      <v-menu v-model="menuFecha2" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            nope-prepend-icon="mdi-calendar"
                            :value="formatDate(item.hasta)"
                            label="Fecha de fin (Hasta)"
                            readonly
                            v-on="on"
                            :disabled="isDisabled"></v-text-field>
                        </template>
                        <v-date-picker
                          color="primary"
                          v-model="item.hasta"
                          first-day-of-week="1"
                          no-title
                          scrollable
                          @change="
                            ;(menuFecha2 = false), guardarCambiosAuditoria()
                          "></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="1" class="mt-0 pb-0">
                      <v-text-field
                        type="time"
                        label="Hora inicio"
                        :value="horaToTime(item.hasta_inicio)"
                        @change="setTime('hasta_inicio', $event)"
                        :disabled="isDisabled"></v-text-field>
                    </v-col>

                    <v-col cols="1" class="mt-0 pb-0">
                      <v-text-field
                        type="time"
                        label="Hora fin"
                        :value="horaToTime(item.hasta_fin)"
                        @change="setTime('hasta_fin', $event)"
                        :disabled="isDisabled"></v-text-field>
                    </v-col>

                    <v-col cols="4" class="d-flex align-center">
                      <v-btn
                        text
                        block
                        outlined
                        noclass="mx-auto"
                        color="primary lighten-2"
                        @click="ui.showFechas = !ui.showFechas"
                        :disabled="isDisabled">
                        Gestión de fechas
                        <v-icon right dark> mdi-calendar-clock </v-icon>
                      </v-btn>
                    </v-col>

                    <!-- <v-col v-else cols="4" class="mb-0 pb-0">
                      <v-menu
                          v-model="menuFechaCierre"
                          disabled
                          :close-on-content-click="false"
                          max-width="290"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                              prepend-icon="mdi-calendar"
                              :value="formatDate(item.fecha_cierre)"
                              label="Fecha cierre"
                              disabled
                              v-on="on"
                              @click:clear="item.fecha_cierre = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            color="primary"
                            v-model="item.fecha_cierre"
                            first-day-of-week="1"
                            @change="menuFechaCierre = false"
                            no-title scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col> -->

                    <v-row
                      v-if="ui.showFechas"
                      class="mx-3 my-2"
                      style="padding: 6px; background: #f0f0f0">
                      <v-col cols="12">
                        <h4>Gestión granular de horarios</h4>
                      </v-col>

                      <template v-for="(f, i) in item.fechas">
                        <v-col cols="2" class="d-flex align-center justify-center mt-0 pb-0">
                          <v-icon class="px-3" style="transform: translate(-7px, -5px)"
                            >mdi-calendar-outline</v-icon
                          >

                          <v-menu v-model="f.model" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :value="formatDate(f.desde)"
                                label="Fecha de inicio (Desde)"
                                readonly
                                v-on="on"></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="f.desde"
                              color="primary"
                              first-day-of-week="1"
                              no-title
                              scrollable
                              @change="
                                ;(f.model = false), $forceUpdate(), guardarCambiosAuditoria()
                              "></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="1" class="mt-0 pb-0">
                          <v-text-field
                            type="time"
                            label="Hora inicio"
                            v-model="f.d_inicio"
                            @change="
                              f.d_inicio = f.d_inicio + ':00'
                              guardarCambiosAuditoria()
                            "></v-text-field>
                        </v-col>

                        <v-col cols="1" class="mt-0 pb-0">
                          <v-text-field
                            type="time"
                            label="Hora fin"
                            v-model="f.d_fin"
                            @change="
                              f.d_fin = f.d_fin + ':00'
                              guardarCambiosAuditoria()
                            "></v-text-field>
                        </v-col>

                        <v-col cols="2" class="mb-0 pb-0">
                          <v-menu
                            v-model="f.model_"
                            :close-on-content-click="false"
                            max-width="290">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :value="formatDate(f.hasta)"
                                label="Fecha de fin (Hasta)"
                                readonly
                                v-on="on"></v-text-field>
                            </template>
                            <v-date-picker
                              color="primary"
                              v-model="f.hasta"
                              first-day-of-week="1"
                              no-title
                              scrollable
                              @change="
                                ;(f.model_ = false), $forceUpdate(), guardarCambiosAuditoria()
                              "></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="1" class="mt-0 pb-0">
                          <v-text-field
                            type="time"
                            label="Hora inicio"
                            v-model="f.h_inicio"
                            @change="
                              f.h_inicio = f.h_inicio + ':00'
                              guardarCambiosAuditoria()
                            "></v-text-field>
                        </v-col>

                        <v-col cols="1" class="mt-0 pb-0">
                          <v-text-field
                            type="time"
                            label="Hora fin"
                            v-model="f.h_fin"
                            @change="
                              f.h_fin = f.h_fin + ':00'
                              guardarCambiosAuditoria()
                            "></v-text-field>
                        </v-col>

                        <v-col cols="1" class="d-flex align-center">
                          <v-btn
                            text
                            small
                            depressed
                            color="error darken-3"
                            @click="removeFecha(i)">
                            <v-icon small class="pr-2">mdi-sort-variant-remove</v-icon>
                            Eliminar
                          </v-btn>
                        </v-col>
                        <v-col cols="3"></v-col>
                      </template>

                      <v-col cols="12" class="d-flex align-center">
                        <v-btn text small depressed color="success darken-3" @click="addFecha">
                          <v-icon class="pr-2"> mdi-table-row-plus-after </v-icon>
                          Añadir rango
                        </v-btn>

                        <v-btn text small depressed color="dark" @click="ui.showFechas = false">
                          Cerrar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-row>

                  <v-row class="mb-6">
                    <!-- <v-col cols="12">
                      <v-divider style="max-width: 75%" class="mx-auto my-5"></v-divider>
                    </v-col> -->

                    <v-col cols="4" class="mt-0 pt-0">
                      <SelectAuditores
                        v-model="item.auditores"
                        title="Auditores"
                        required
                        compatibles
                        :alcance="item.alcance.uuid"
                        :cliente="item.cliente.uuid"
                        :disabled="isDisabled || !canEditAuditores"
                        @change="
                          updateCargos()
                          guardarCambiosAuditoria()
                        " />
                    </v-col>

                    <v-col cols="4" class="mt-0 pt-0" v-if="item.responsable">
                      <v-autocomplete
                        v-model="item.responsable.uuid"
                        label="Responsable"
                        :items="item.auditores"
                        :item-text="nombreCompleto"
                        item-value="uuid"
                        :disabled="isDisabled || !item.auditores.length || !canEditAuditores"
                        @change="guardarCambiosAuditoria()"></v-autocomplete>
                    </v-col>

                    <v-col cols="4" class="d-flex align-center">
                      <v-btn
                        text
                        block
                        outlined
                        color="primary lighten-2"
                        :disabled="isDisabled"
                        @click="
                          initCargos()
                          ui.showCargos = !ui.showCargos
                        ">
                        Gestión de auditores
                        <v-icon right dark> mdi-account-tie </v-icon>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="12"
                      class="v-data-table theme--light pt-0"
                      v-if="ui.showCargos && item.cargos">
                      <div class="v-data-table__wrapper">
                        <v-btn
                          @click="ui.addAuditor = !ui.addAuditor"
                          style="float: right; margin-bottom: 10px"
                          >Actualizar auditor</v-btn
                        >
                        <v-col cols="6" v-show="ui.addAuditor">
                          <SelectAuditores
                            v-if="item.auditores"
                            ref="selectAuditores"
                            :items_auditores="item.auditores"
                            v-model="item.auditores"
                            title="Auditores"
                            required
                            dense
                            compatibles
                            :disabled="!item.desde"
                            :desde="item.desde"
                            :alcance="item.alcance.uuid"
                            :cliente="item.cliente.uuid"
                            @change="updateCargos()" />
                        </v-col>
                        <table
                          style="
                            border: 1px solid #ccc;
                            background: #f9f9f9;
                            border-radius: 3px;
                            box-shadow: 0px 0px 3px 0px #e2e2e2;
                          ">
                          <thead>
                            <tr>
                              <th class="text-left">Auditor</th>
                              <th class="text-left">En calidad de</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <template>
                              <tr v-for="(aud, key) in item.cargos" :key="key">
                                <td>{{ aud.nombre }} {{ aud.apellido }}</td>
                                <td>
                                  <v-text-field
                                    dense
                                    hide-details="auto"
                                    v-model="aud.cargo"
                                    n-disabled="!canEditAuditores"
                                    @change="guardarCambiosAuditoria()">
                                  </v-text-field>
                                </td>
                                <td>
                                  <v-btn @click="clearAuditor(key)">Eliminar auditor</v-btn>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </v-col>

                    <v-col
                      cols="12"
                      class="v-data-table theme--light pt-0"
                      v-if="ui.showCargos && (isCcl || isOceGlobal)">
                      <div class="v-data-table__wrapper">
                        <table
                          style="
                            border: 1px solid #ccc;
                            background: #f9f9f9;
                            border-radius: 3px;
                            box-shadow: 0px 0px 3px 0px #e2e2e2;
                          ">
                          <thead>
                            <tr>
                              <th class="text-left" style="width: 50%">Auditor revisor</th>
                              <th class="text-left" style="width: 50%">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template>
                              <tr>
                                <td>
                                  <SelectRevisor
                                    v-model="item.revisor"
                                    :title="'Revisor'"
                                    required
                                    :alcance="item.alcance.uuid"
                                    :cliente="item.cliente.uuid"
                                    @change="guardarCambiosAuditoria()" />
                                  <v-btn v-if="item.revisor" @click="clearSelection"
                                    >Eliminar Revisor</v-btn
                                  >
                                </td>
                                <td></td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </v-col>

                    <v-col v-if="ui.showCargos" cols="12" class="v-data-table theme--light pt-0">
                      <div class="v-data-table__wrapper">
                        <table
                          style="
                            border: 1px solid #ccc;
                            background: #f9f9f9;
                            border-radius: 3px;
                            box-shadow: 0px 0px 3px 0px #e2e2e2;
                          ">
                          <thead>
                            <tr>
                              <th class="text-left">Acompañante</th>
                              <th class="text-left">En calidad de</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="py-5">
                                <v-text-field
                                  label="Primer Acompañante"
                                  v-model="item.acompanante_1"
                                  hide-details="auto"
                                  dense
                                  :disabled="!canEditAuditores"
                                  @change="guardarCambiosAuditoria()"></v-text-field>
                              </td>
                              <td class="py-5">
                                <v-text-field
                                  v-model="item.cargo_acompanante_1"
                                  hide-details="auto"
                                  dense
                                  :disabled="!canEditAuditores"
                                  @change="guardarCambiosAuditoria()"></v-text-field>
                              </td>
                            </tr>

                            <tr>
                              <td class="py-5">
                                <v-text-field
                                  label="Segundo Acompañante"
                                  v-model="item.acompanante_2"
                                  hide-details="auto"
                                  dense
                                  :disabled="!canEditAuditores"
                                  @change="guardarCambiosAuditoria()"></v-text-field>
                              </td>
                              <td class="py-5">
                                <v-text-field
                                  v-model="item.cargo_acompanante_2"
                                  hide-details="auto"
                                  dense
                                  :disabled="!canEditAuditores"
                                  @change="guardarCambiosAuditoria()"></v-text-field>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="4" class="mt-0 pt-0">
                      <v-text-field
                        label="Expediente"
                        v-model="item.expediente.codigo"
                        disabled></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" class="mt-0 pt-0">
                      <v-text-field
                        label="Cliente"
                        v-model="item.cliente.nombre"
                        disabled></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" class="mt-0 pt-0">
                      <v-text-field
                        label="Alcance"
                        v-model="item.alcance.nombre"
                        disabled></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="4" class="mt-0 pt-0">
                      <SelectEstaticos
                        v-if="auditoria && auditoria.alcance"
                        label="Nivel de control"
                        v-model="item.nivel"
                        :alcance="auditoria.alcance.uuid"
                        codigo="auditoria-nivel-de-control"
                        required
                        :disabled="!item || isDisabled || consulta || isCliente"
                        @change="guardarCambiosAuditoria()"
                        :estaticos="estaticos" />
                    </v-col>

                    <v-col cols="12" md="4" class="mt-0 pt-0">
                      <SelectEstaticos
                        v-if="auditoria && auditoria.alcance"
                        label="Tipo de auditoría"
                        v-model="item.tipo_visita"
                        :alcance="auditoria.alcance.uuid"
                        codigo="tipos_auditorias"
                        required
                        multiple
                        :disabled="!item || isDisabled || consulta || isCliente"
                        @change="guardarCambiosAuditoria()"
                        :estaticos="estaticos" />
                    </v-col>

                    <!--
                      Activado para araporcei
                      https://kaamit.atlassian.net/browse/CERTIAPP-1218
                      && auditoria.alcance.codigo == 'productos_ibericos'
                    -->
                    <v-col cols="4" class="mt-0 pt-0" v-if="item && isAraporcei && auditoria">
                      <SelectActividades
                        label="Actividades Realizadas"
                        v-model="item.actividades_realizadas"
                        :actividades="actividadesETG"
                        @blurred="guardarCambiosAuditoria()"
                        multiple />
                    </v-col>

                    <!--
                      Activado para certicaliad
                      https://kaamit.atlassian.net/browse/CERTIAPP-1102
                    -->
                    <v-col
                      cols="4"
                      class="mt-0 pt-0"
                      v-else-if="item && isCerticalidad && auditoria">
                      <v-text-field
                        label="Número de acta"
                        v-model="item.numero_acta[0]"
                        nope-change="guardarCambiosAuditoria()"
                        readonly
                        disabled></v-text-field>
                    </v-col>

                    <!--
                      Activado para Oce Global
                      https://trello.com/c/eN1dzZqw
                    -->
                    <v-col cols="4" class="mt-0 pt-0" v-else-if="item && isOceGlobal && auditoria">
                      <SelectEstaticos
                        v-model="item.laboratorio"
                        label="Laboratorio"
                        :alcance="auditoria.alcance.uuid"
                        codigo="laboratorios"
                        :disabled="!item || auditoriaCerrada || consulta || isCliente"
                        @change="guardarCambiosAuditoria()"
                        :estaticos="estaticos" />
                    </v-col>

                    <!--
                      Activado para CCL
                      https://trello.com/c/dVbfVnie
                    -->
                    <v-col cols="4" class="mt-0 pt-0" v-else-if="item && isCcl && auditoria">
                      <v-menu
                        v-model="menuFechaInforme"
                        :close-on-content-click="false"
                        max-width="290">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            nope-prepend-icon="mdi-calendar"
                            :value="formatDate(item.fecha_informe)"
                            label="Fecha de informe"
                            readonly
                            v-on="on"
                            :disabled="isDisabled"></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="item.fecha_informe"
                          color="primary"
                          no-title
                          scrollable
                          first-day-of-week="1"
                          @change="
                            ;(menuFechaInforme = false), guardarCambiosAuditoria()
                          "></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="4" v-else></v-col>
                  </v-row>

                  <v-row v-if="cliente">
                    <v-col class="pt-3 pt-0">
                      <v-text-field
                        label="Representante"
                        :disabled="isDisabled"
                        :value="item.representante.nombre"
                        @change="
                          item.representante.nombre = $event
                          guardarCambiosAuditoria()
                        "></v-text-field>
                    </v-col>

                    <v-col class="pt-3 pt-0">
                      <v-text-field
                        label="Representante Email"
                        :disabled="isDisabled"
                        :value="item.representante.email"
                        @change="
                          item.representante.email = $event
                          guardarCambiosAuditoria()
                        "></v-text-field>
                    </v-col>

                    <v-col class="pt-3 pt-0">
                      <v-text-field
                        label="N.I.F. del representante"
                        :disabled="isDisabled"
                        :value="item.representante.nif"
                        @change="
                          item.representante.nif = $event
                          guardarCambiosAuditoria()
                        "></v-text-field>
                    </v-col>

                    <v-col class="pt-3 pt-0">
                      <v-text-field
                        label="Representante Tlf."
                        :disabled="isDisabled"
                        :value="item.representante.telefono"
                        @change="
                          item.representante.telefono = $event
                          guardarCambiosAuditoria()
                        "></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- PLANIFICACIÓN -->
              <v-card class="mt-5" v-if="item && !isCliente">
                <v-card-title>
                  Checklists
                  <v-spacer></v-spacer>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="auth.checklists && auth.checklists.canCreate"
                        :disabled="
                          (!ampliacionIsAllowed && (isDisabled || offlineMode)) ||
                          !planificador_auditor ||
                          !checklist_selected.length ||
                          !sites_selected.length
                        "
                        class="white--text mt-4"
                        color="success"
                        fab
                        small
                        depressed
                        style="font-size: 11px"
                        @click="addPlanificacion()"
                        v-on="on">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Añadir</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-row v-if="auditoriaAbiertaPrevista && !consulta">
                    <v-col cols="12" md="3" class="mb-0 pb-0">
                      <!-- SELECCIÓN DE AUDITOR -->
                      <v-select
                        label="Auditor"
                        v-model="planificador_auditor"
                        :items="
                          ampliacionIsAllowed
                            ? item.auditores.filter(
                                (a) => a.uuid == (item.revisor && item.revisor.uuid)
                              )
                            : item.auditores
                        "
                        item-text="nombre_completo"
                        dense
                        clearable
                        return-object
                        :disabled="!ampliacionIsAllowed && (isDisabled || offlineMode)"></v-select>
                    </v-col>
                    <v-col cols="12" md="4" class="mb-0 pb-0">
                      <!-- SELECCIÓN DE CHECKLIST -->
                      <v-select
                        label="Listas de validación"
                        v-model="checklist_selected"
                        :items="
                          ampliacionIsAllowed ? checklist.filter((c) => c.is_revision) : checklist
                        "
                        item-text="titulo"
                        dense
                        multiple
                        clearable
                        small-chips
                        deletable-chips
                        return-object
                        :disabled="!ampliacionIsAllowed && (isDisabled || offlineMode)"></v-select>
                    </v-col>
                    <v-col cols="12" md="5" class="mb-0 pb-0">
                      <!-- SELECCIÓN DE SITES -->
                      <SelectEmplazamientos
                        v-model="sites_selected"
                        :items="sitesAvailable"
                        :disabled="!ampliacionIsAllowed && (isDisabled || offlineMode)" />

                      <!-- <v-autocomplete
                        v-model="sites_selected"
                        :items="sitesAvailable"
                        label="Emplazamientos"
                        item-text="nombre"
                        item-value="uuid"
                        dense
                        multiple
                        clearable
                        small-chips
                        deletable-chips
                        return-object
                      ></v-autocomplete> -->
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="mt-0 pt-0">
                      <v-alert
                        v-if="ui.showPlanificacionError"
                        text
                        outlined
                        color="deep-orange"
                        icon="mdi-alert-octagon">
                        <h3>Ha ocurrido un error al eliminar la planificación</h3>

                        <v-row align="center" no-gutters>
                          <v-col class="grow">
                            No se puede borrar una planificacion que contenga pacs
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col class="shrink">
                            <v-btn outlined @click="ui.showPlanificacionError = false">
                              Entendido
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-alert>

                      <!-- TABLA DE PLANIFICACIONES -->
                      <datatable
                        :showHeader="false"
                        :headers="[
                          { text: 'Auditor', align: 'left', value: 'auditor.nombre' },
                          { text: 'Título de la lista', align: 'left', value: 'checklist.titulo' },
                          { text: 'Emplazamiento', align: 'left', value: 'site.nombre' },
                          { text: '', align: 'left', value: 'actions' },
                        ]"
                        :items="item.planificaciones"
                        :disableClickRow="true"
                        :disableHover="true"
                        :key="component">
                        <template v-slot:item.actions="{ item }">
                          <v-row v-if="!consulta && !isDisabled">
                            <v-col cols="6">
                              <v-icon
                                v-if="auditoria.estado === 'ABIERTA' && pacs.length === 0"
                                small
                                class="mr-2"
                                @click="replaceSite(item)"
                                style="font-size: 20px">
                                mdi-pencil-outline
                              </v-icon>
                            </v-col>
                            <v-col cols="6">
                              <v-icon
                                v-if="auth.checklists && auth.checklists.canDelete"
                                small
                                class="mr-2"
                                @click.stop="confirmDeletePlanificacion(item)"
                                style="font-size: 20px">
                                mdi-delete-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </template>

                        <template v-slot:item.site.nombre="{ item }">
                          <div>
                            {{ item.site.nombre }}
                            <span style="color: rgb(146, 146, 146); font-size: 12px !important">
                              - {{ findRGSEAA(item.site) }}
                            </span>
                          </div>
                          <!-- <div v-if="!offlineMode">{{ item.site.nombre }}</div> -->

                          <!-- <v-edit-dialog
                              v-else-if="item"
                              :ref="'siteNombre' + item.uuid"
                              :return-value.sync="item.site.nombre"
                              @save="updatSiteNombre(item)"
                              @open="updatSiteNombre(item, true)"
                              @close="oldSiteNombre = ''"
                          >
                            {{ item.site.nombre }}
                            <template v-slot:input>
                              <v-text-field
                                  v-model="item.site.nombre"
                                  single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog> -->
                        </template>
                      </datatable>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Certificacion -->
              <certificado-sites
                v-if="item && !isCliente && item.planificaciones.length > 0 && expedienteLoaded"
                height="40vh"
                :auditoria="auditoria"
                :solicitud="item.solicitud"
                :iteracion="iteracion"
                :sites="sitesAvailable"
                :cliente="cliente"
                :alcance="item.alcance"
                :planificaciones="item.planificaciones"
                :disabled="isDisabled"
                @init="getOne()">
              </certificado-sites>

              <!-- ANOTACIONES -->
              <v-card class="mt-5" v-if="item && !isCliente">
                <v-card-title> Anotaciones </v-card-title>
                <v-card-text>
                  <v-textarea
                    outlined
                    v-model="item.anotaciones"
                    :disabled="consulta || isDisabled"
                    @blur="guardarCambiosAuditoria()"></v-textarea>
                </v-card-text>
              </v-card>

              <v-row class="my-5" v-if="item && item.auditores">
                <v-col cols="4" v-for="(aud, key) in item.auditores" :key="'aud' + key">
                  <v-card
                    flat
                    style="border: 2px solid #ccc; cursor: pointer"
                    @click.stop="$refs['firmaAUD' + key][0].sign()"
                    :disabled="isDisabled">
                    <v-card-text>
                      <Signature
                        :ref="'firmaAUD' + key"
                        :signed="item.firmas"
                        :options="{
                          modulo: 'auditorias',
                          modulo_ref: item.uuid,
                          typeOfUser: 'auditor',
                          created_by: aud.user_uuid,
                          created_name: aud.nombre_completo,
                        }" />
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="4" v-if="item.auditores.length == 1"></v-col>

                <v-col cols="4" @click.stop="$refs.firmaCLI.sign()" :disabled="isDisabled">
                  <v-card flat style="border: 2px solid #ccc; cursor: pointer">
                    <v-card-text>
                      <Signature
                        ref="firmaCLI"
                        :signed="item.firmas"
                        :options="{
                          modulo: 'auditorias',
                          modulo_ref: item.uuid,
                          typeOfUser: 'cliente',
                          created_by: item.cliente.user_uuid,
                          created_name: ui.f.fullName(item.cliente),
                        }" />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- TAB CHECKLIST -->
            <v-tab-item
              :transition="false"
              :reverse-transition="false"
              key="checklist"
              class="mt-5"
              style="background: transparent"
              v-if="!isCliente"
              :class="{ isLocked: isLocked }">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="mt-0 pt-0">
                      <checklist
                        v-if="!!auditoria_checklist"
                        :checklist="auditoria_checklist"
                        :auditoria_uuid="uuid"
                        :auditoria="auditoria" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- TAB CHECKLIST PREGUNTAS -->
            <!-- Delete this line in the future -->
            <!-- <v-tab-item :transition="false" :reverse-transition="false" key="checklist-preguntas" class="mt-5"
                        style="background: transparent" v-if="!isCliente">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="mt-0 pt-0">
                      <checklist-preguntas
                          v-if="!!auditoria_checklist"
                          :checklist="auditoria_checklist"
                          :auditoriaCerrada="auditoriaCerrada"
                          @init="getEvidencias()"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item> -->

            <!-- TAB PACS -->
            <v-tab-item
              :transition="false"
              :reverse-transition="false"
              key="pacs"
              style="background: rgb(246, 248, 249) !important">
              <v-card class="mt-5">
                <v-card-title>
                  <span class="mr-2">Listado de PACS</span>
                </v-card-title>
                <v-card-text>
                  <datatable
                    :showHeader="false"
                    :headers="headers_pacs"
                    :items="pacs"
                    :disableClickRow="true"
                    :disableHover="true"
                    @click:row="clickRowPACS($event)">
                    <template v-slot:item.created_at="{ item }">
                      {{ item.created_at | formatDate('DD/MM/YYYY') }}
                    </template>
                    <template v-slot:item.no_conformidades="{ item }">
                      <span>{{ getInfoNoConformidades(item) }}</span>
                    </template>
                    <template v-slot:item.no_conformidades_num="{ item }">
                      <span>{{ getNumPac(item) }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }" v-if="esResponsable">
                      <v-icon
                        v-if="!isDisabled && !consulta && auditoria && auditoriaAbiertaPrevista"
                        small
                        style="font-size: 20px"
                        @click.stop="confirmarBorradoPAC(item)">
                        mdi-delete-outline
                      </v-icon>
                    </template>
                  </datatable>
                </v-card-text>
              </v-card>

              <v-card class="mt-5">
                <v-card-title>
                  No conformidades pendientes de asignar
                  <v-spacer></v-spacer>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <!-- <pre>
                        {{ isCliente }} || {{  esResponsable }} {{ !consulta }} {{ auditoriaAbiertaPrevista }}
                        </pre> -->
                      <v-btn
                        v-show="
                          isCliente ||
                          (esResponsable && !consulta && item && auditoriaAbiertaPrevista)
                        "
                        :disabled="isDisabled || !no_conformidades_seleccionadas.length"
                        class="white--text"
                        color="success"
                        fab
                        small
                        depressed
                        @click="crearPAC()"
                        v-on="on">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Crear PAC</span>
                  </v-tooltip>
                </v-card-title>

                <!-- LISTADO DE NO CONFORMIDADES -->
                <v-data-table
                  class="auditoria"
                  v-model="no_conformidades_seleccionadas"
                  :headers="headers_no_conformidades"
                  :items="no_conformidades"
                  item-key="uuid"
                  :single-select="false"
                  :show-select="
                    isCliente ||
                    (esResponsable && !consulta && item && auditoriaAbiertaPrevista && !isDisabled)
                  "
                  :expanded.sync="expanded"
                  show-expand
                  :page.sync="page"
                  :items-per-page="10"
                  hide-default-footer
                  no-data-text="Listado vacío"
                  @page-count="pageCount = $event">
                  <template v-slot:item.created_at="{ item }">
                    {{ item.created_at | formatDate('DD/MM/YYYY') }}
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-row>
                        <!-- Titulo -->
                        <v-col cols="12" class="text-center pt-5">
                          <div v-html="item.titulo"></div>
                          <span
                            ><br />
                            <i>{{ item.texto }}</i></span
                          >
                        </v-col>

                        <!-- Divider -->
                        <v-col cols="12">
                          <v-divider></v-divider>
                        </v-col>

                        <!-- Observaciones -->
                        <v-col cols="6">
                          <div class="mb-3 font-weight-bold text-center caption">Observaciones</div>
                          <v-card outlined style="background: none">
                            <v-card-text v-html="item.observaciones"></v-card-text>
                          </v-card>
                        </v-col>

                        <!-- Texto de no conformidad -->
                        <v-col cols="6">
                          <div class="mb-3 font-weight-bold text-center caption">
                            Texto de no conformidad
                          </div>
                          <v-card outlined style="background: none">
                            <v-card-text v-html="item.no_conformidad"></v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </v-data-table>

                <div class="text-center pt-3 pb-3">
                  <v-pagination color="info" v-model="page" :length="pageCount"></v-pagination>
                </div>

                <v-card-text> </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- TAB MUESTRAS -->
            <v-tab-item
              :transition="false"
              :reverse-transition="false"
              key="muestras"
              style="background: rgb(246, 248, 249) !important"
              v-if="!isCliente">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="mt-0 pt-0">
                      <Muestras :auditoria="auditoria" :sitesAvailable="sitesAvailablebyMuestras" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item
              :transition="false"
              :reverse-transition="false"
              key="bloques"
              class="mt-5"
              style="background: #f6f8f9">
              <div v-if="ui.loadingBlocks">
                Cargando bloques...
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"></v-progress-linear>
              </div>

              <template v-if="ui.refresh > 0">
                <!-- {{ db.bloques.length }} -- {{ db.misBloques.length }} -->

                <v-row
                  v-for="(block, index, i) in db.bloques"
                  class="my-3"
                  :key="'block-' + index + ui.refresh">
                  <v-col cols="8">
                    <h2>{{ block.titulo }}</h2>
                  </v-col>

                  <template v-for="(bl, o) in getBlocksForUUID(block.uuid)">
                    <v-col
                      cols="6"
                      class="mx-auto px-10 pb-0"
                      v-if="o > 0"
                      :key="'block-divider-' + o"
                      :title="o">
                      <v-divider style="border-style: dashed"></v-divider>
                    </v-col>

                    <v-col cols="8" :key="'block-title-' + o" v-if="o > 0"></v-col>
                    <v-col class="text-right" cols="4" :key="'block-actions-' + o">
                      <span
                        v-if="
                          $refs['blck-' + bl.uuid] &&
                          $refs['blck-' + bl.uuid][0] &&
                          $refs['blck-' + bl.uuid][0].item.updated_at
                        "
                        style="
                          color: rgb(77, 171, 245);
                          font-size: 12px !important;
                          margin-right: 10px;
                        ">
                        Actualizado
                        {{ $moment($refs['blck-' + bl.uuid][0].item.updated_at).fromNow() }}
                      </span>

                      <v-btn-toggle :key="componentBloques" v-if="!isDisabled">
                        <v-btn small text @click="updateBlock(bl.uuid, bl)">
                          <span
                            v-show="
                              $refs['blck-' + bl.uuid] &&
                              $refs['blck-' + bl.uuid][0] &&
                              $refs['blck-' + bl.uuid][0].item.updated_at
                            "
                            class="hidden-sm-and-down"
                            >Actualizar</span
                          >
                          <span
                            v-show="
                              !(
                                $refs['blck-' + bl.uuid] &&
                                $refs['blck-' + bl.uuid][0] &&
                                $refs['blck-' + bl.uuid][0].item.updated_at
                              )
                            "
                            class="hidden-sm-and-down"
                            >Guardar</span
                          >
                          <v-icon
                            right
                            v-if="
                              $refs['blck-' + bl.uuid] &&
                              $refs['blck-' + bl.uuid][0] &&
                              $refs['blck-' + bl.uuid][0].item.updated_at
                            "
                            color="success">
                            mdi-refresh
                          </v-icon>
                          <v-icon right v-else> mdi-content-save </v-icon>
                        </v-btn>

                        <v-menu
                          offset-y
                          :close-on-content-click="true"
                          v-if="
                            $refs['blck-' + bl.uuid] &&
                            $refs['blck-' + bl.uuid][0] &&
                            $refs['blck-' + bl.uuid][0].item.updated_at
                          ">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small icon v-bind="attrs" v-on="on">
                              <v-icon
                                right
                                small
                                class="ma-0"
                                v-if="
                                  $refs['blck-' + bl.uuid] &&
                                  $refs['blck-' + bl.uuid][0] &&
                                  $refs['blck-' + bl.uuid][0].item.updated_at
                                "
                                color="success">
                                mdi-chevron-down
                              </v-icon>
                              <v-icon right small class="ma-0" v-else> mdi-chevron-down </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-show="
                                db.misBloques.length > 0 &&
                                $refs['blck-' + bl.uuid] &&
                                $refs['blck-' + bl.uuid][0] &&
                                $refs['blck-' + bl.uuid][0].item.updated_at
                              "
                              @click.stop="cloneBlock(bl.uuid, bl)">
                              <v-list-item-title> Añadir otro como este </v-list-item-title>
                              <v-list-item-icon>
                                <v-icon small> mdi-content-copy </v-icon>
                              </v-list-item-icon>
                            </v-list-item>

                            <v-list-item
                              v-if="!block.isNew"
                              color="error darken-3"
                              @click.stop="resetBlock(bl.uuid, bl)">
                              <v-list-item-title> Eliminar datos </v-list-item-title>
                              <v-list-item-icon>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon small v-bind="attrs" v-on="on"> mdi-refresh </v-icon>
                                  </template>
                                  <span
                                    >Reiniciar bloque.<br />Los datos se eliminarán y la estructura
                                    se actualizará desde la plantilla</span
                                  >
                                </v-tooltip>
                              </v-list-item-icon>
                            </v-list-item>
                            <v-list-item
                              v-show="
                                db.misBloques.length > 0 &&
                                $refs['blck-' + bl.uuid] &&
                                $refs['blck-' + bl.uuid][0] &&
                                $refs['blck-' + bl.uuid][0].item.updated_at
                              "
                              color="error darken-3"
                              @click.stop="exportBlock(bl.uuid, bl)">
                              <v-list-item-title> Exportar Bloque </v-list-item-title>
                              <v-list-item-icon>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon small v-bind="attrs" v-on="on">
                                      mdi-file-export
                                    </v-icon>
                                  </template>
                                </v-tooltip>
                              </v-list-item-icon>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-btn-toggle>
                    </v-col>

                    <v-col cols="12" class="pt-0 mb-4" :key="'block-embed-' + o">
                      <block-embed :ref="'blck-' + bl.uuid" :block="bl"> </block-embed>
                    </v-col>
                  </template>
                </v-row>
              </template>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar
      v-if="item && !$nuxt.isOffline"
      v-model="item.bloqueada"
      right
      :timeout="-1"
      :multi-line="true">
      <v-icon color="warning" class="mr-2"> mdi-lock </v-icon>

      <div style="display: inline-flex">
        Esta auditoría ha sido bloqueada y no permite cambios.<br />
        Bloqueado por {{ item.bloqueada_por.username }} el
        {{ $moment(item.bloqueada_at).format('D [de] MMMM, YYYY') }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="unlockAuditoria"> Desbloquear </v-btn>
      </template>
    </v-snackbar>

    <!-- FORMULARIO PARA INTRODUCIR MOTIVOS DE RECHAZO O NO APTA -->
    <v-dialog v-model="dialogIntroducirMotivo" persistent max-width="40%">
      <v-card v-if="item">
        <v-card-text>
          <v-icon @click="dialogIntroducirMotivo = false" style="float: right">mdi-close</v-icon>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  name="input-7-1"
                  label="Motivo"
                  v-model="item.motivo"
                  hint="Introduzca el motivo por el que selecciona esta opción"
                  persistent-hint
                  outlined></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-2 mb-2" text @click="dialogIntroducirMotivo = false"> Cerrar</v-btn>
          <v-btn
            v-if="revision"
            class="mr-2 mb-2"
            color="error"
            @click="confirmarCambioEstado(estado.ABIERTA)">
            REVISION KO
          </v-btn>
          <v-btn
            v-else-if="dictamen"
            class="mr-2 mb-2"
            color="error"
            @click="confirmarCambioEstado(estado.NO_APTA)">
            NO APTA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CONFIRMACIÓN DE BORRADO DE PLANIFICACIÓN -->
    <confirmation
      v-model="dialogConfirmarBorradoPlanificacion"
      text="¿Seguro que desea eliminar la planificación?"
      textButton="Eliminar"
      colorButton="primary"
      @confirm="removePlanificacion()" />

    <!-- CONFIRMACIÓN DE CAMBIO DE ESTADO -->
    <confirmation
      v-model="dialogConfirmarCambioEstado"
      :text="texto_confirmacion"
      textButton="Sí"
      colorButton="primary"
      @confirm="cambiarEstadoAuditoria()">
      <v-card-text>
        <v-form ref="createForm">
          <v-row v-if="!auditoriaCerrada"> </v-row>
        </v-form>
      </v-card-text>
    </confirmation>

    <!-- CONFIRMACIÓN DE BORRADO DE PAC -->
    <confirmation
      v-model="dialogConfirmarBorradoPAC"
      :text="texto_confirmacion"
      textButton="Eliminar"
      colorButton="primary"
      @confirm="deletePAC()" />

    <!-- CONFIRMACIÓN DE CAMBIO A MODO OFFLINE -->
    <confirmation
      v-model="dialogModoOffline"
      :text="texto_confirmacion"
      textButton="Aceptar"
      colorButton="primary"
      @confirm="toggleOffline()" />

    <!-- CONFIRMACIÓN DE CAMBIO A MODO ONLINE -->
    <confirmation
      v-model="dialogModoOnline"
      :text="texto_confirmacion"
      textButton="Aceptar"
      colorButton="primary"
      @confirm="toggleOnline()" />

    <!-- Dialog for replace site checlist -->
    <v-dialog v-if="auditoriaAbiertaPrevista" v-model="ui.dialogReplaceSite" max-width="500">
      <v-card>
        <v-card-title class="mb-3">
          <span>Reemplazar emplazamiento</span>
        </v-card-title>
        <v-card-text>
          <SelectEmplazamientos
            v-model="selectedSite"
            :items="instalaciones_cliente"
            label="Emplazamiento"
            :disabled="!ampliacionIsAllowed && (isDisabled || offlineMode)"
            :multiple="false" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="ui.dialogReplaceSite = false">Cancelar</v-btn>
          <v-btn color="green darken-3" text @click="replaceSiteChecklist()">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \pages\auditorias\_uuid.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Wed Jun 21 2023
 **/

import moment from 'moment'
import text from '~/models/labels'
import format from '~/util/formatHelper'

import Loading from '~/components/Loading'
import Datatable from '~/components/Datatable'
import Confirmation from '~/components/Confirmation'
import Certificacion from '~/components/auditorias/Certificacion'
import Checklist from '~/components/auditorias/Checklist'
// import ChecklistPreguntas from '~/components/auditorias/ChecklistPreguntas'
import Muestras from '~/components/auditorias/muestras/Muestras'
import Estados from '~/models/estados'
import ROLES from '~/models/role'
import MODELS from '~/models/araporcei/solicitudes'

import HeaderComponent from '../../components/HeaderComponent'
import SelectAuditores from '~/components/forms/SelectAuditoresMultiple'
import SelectRevisor from '~/components/forms/SelectAuditores'
import SelectEstaticos from '~/components/forms/SelectEstaticos'
import SelectActividades from '~/components/forms/SelectActividades'
import SelectEmplazamientos from '~/components/forms/SelectEmplazamientos'

import Signature from '~/components/signatures/Show'
import blockEmbed from '~/pages/bloques/widgets/blockEmbed'
import CertificadoSites from '../../components/auditorias/CertificadoSites'

export default {
  props: ['uuid', 'haveDictamen'],
  components: {
    HeaderComponent,
    Loading,
    Confirmation,
    Datatable,
    Certificacion,
    Checklist,
    // ChecklistPreguntas,
    Muestras,
    SelectAuditores,
    SelectEstaticos,
    SelectActividades,
    SelectEmplazamientos,
    blockEmbed,
    Signature,
    CertificadoSites,
    SelectRevisor,
  },

  data: () => ({
    expedienteLoaded: false,
    estaticos: null,
    oldSiteNombre: '',
    menuFechaAuditoria: false,
    create_auditoria: {},
    expanded: [],
    sitesAvailable: [],
    sitesAvailablebyMuestras: [],
    component: 0,
    componentBloques: 0,
    componentBloquesActualizado: 0,
    dialogModoOffline: false,
    dialogModoOnline: false,
    valid: false,
    componente_cultivos: null,
    tab: null,
    menuFecha1: false,
    menuFecha2: false,
    menuFechaCierre: false,
    menuFechaInforme: false,
    menuFechaPrevista: false,
    item: null,
    auditoria: null,
    cliente: null,
    iteracion: null,
    auditoria_checklist: [],
    planificador_auditor: null,
    checklist: [],
    checklist_selected: [],
    sites: [],
    sites_selected: [],
    pacs: [],
    selected_pac: null,
    dialogConfirmarBorradoPAC: false,
    no_conformidades: [],
    no_conformidades_seleccionadas: [],
    // headers_checklists: [
    //   { text: 'Auditor', align: 'left', value: 'auditor.nombre' },
    //   { text: 'Título de la lista', align: 'left', value: 'titulo' },
    //   { text: 'Emplazamiento', align: 'left', value: 'site.nombre' },
    //   { text: 'Mayores', align: 'left', value: 'cumplimiento.mayores' },
    //   { text: 'Menores', align: 'left', value: 'cumplimiento.menores' },
    //   { text: 'Completado', align: 'left', value: 'cumplimiento.completados' },
    // ],
    headers_pacs: [
      { text: 'Fecha creación', align: 'left', value: 'created_at' },
      { text: 'No conformidades', align: 'left', value: 'no_conformidades' },
      { text: 'Nº no conformidad', align: 'left', value: 'no_conformidades_num' },
      { text: '', align: 'left', value: 'actions' },
    ],
    headers_no_conformidades: [
      // {text: "Fecha", align: "left", value: "created_at"},
      { text: 'Código pregunta', align: 'left', value: 'codigo' },
      { text: 'Emplazamiento', align: 'left', value: 'site.nombre' },
      { text: 'Número', align: 'left', value: 'numero' },
    ],
    esquemas_auditados: [
      {
        text: 'Vegetales y productos vegetales no transformados, incluidas las semillas y demás materiales de reproducción vegetal',
        value: 'a',
      },
      {
        text: 'Productos agrarios transformados, incluidos los productos de la acuicultura, destinados a ser utilizados para la alimentación humana',
        value: 'b',
      },
      { text: 'Vino', value: 'f' },
      {
        text: 'Otros productos enumerados en el anexo I del presente Reglamento o no incluidos en las categorías anteriores',
        value: 'g',
      },
    ],

    page: 1,
    pageCount: 0,
    estados: Estados.AUDITORIA_ESTADOS,
    estado: Estados.AUDITORIA_ESTADO,
    dialogIntroducirMotivo: false,
    dialogConfirmarCambioEstado: false,
    texto_confirmacion: '',
    revision: false,
    dictamen: false,
    niveles_control: ['Normal', 'Reducido', 'Alto'],
    actividadesETG: MODELS.ACTIVIDADES_ETG,
    roles: ROLES,
    dialogConfirmarBorradoPlanificacion: false,
    current_planificacion: null,
    rules: [(v) => !!v || 'Obligatorio'],

    db: {
      auditores: [],

      bloques: [],
      misBloques: [],
    },

    auth: {},

    ui: {
      f: format,
      text: text,

      refresh: 0,
      loading: false,
      loadingBlocks: false,
      remainDisabled: false,

      showCargos: false,
      showFechas: false,
      showValoration: false,
      showPlanificacionError: false,
      addAuditor: false,
      dialogReplaceSite: false,
    },
    selectedChecklist: null,
    selectedSite: null,
    instalaciones_cliente: [],
  }),
  watch: {
    '$store.state.gestor_documental.actualizarEvidencias': function () {
      if (this.$store.state.gestor_documental.actualizarEvidencias) {
        this.$store.commit('gestor_documental/ACTUALIZAR_EVIDENCIAS', false)
        this.getAuditoriaChecklist()
      }
    },
    'actualizarEvidencias'(val) {
      if (val) this.getEvidencias()
    },
    'dialogConfirmarCambioEstado'(val) {
      if (!val) {
        this.create_auditoria = {}
        this.$refs.createForm.reset()
      }
    },
    '$nuxt.isOffline'() {
      if (this.$nuxt.isOffline) {
        this.ui.remainDisabled = true
      }
    },
  },
  computed: {
    //+-------------------------------------------------
    // ampliacionIsAllowed()
    // Read if the auditoria is in a revision (via prop uuid)
    // Check if the user can ampliar the auditoria
    // -----
    // Created on Tue Nov 22 2022
    //+-------------------------------------------------
    ampliacionIsAllowed() {
      if (!this.uuid) return false
      if (!this.auth.canAmpliar) return false

      return true
    },

    //+-------------------------------------------------
    // isDisabled()
    // Casos en los que no se permite la edición de campos
    // -----
    // Created on Thu Oct 13 2022
    //+-------------------------------------------------
    isDisabled() {
      if (this.auditoriaCerrada) return true
      if (!this.auth.canEdit) return true
      if (!this.item) return true
      let isAuditor = this.$auth.user.roles.includes('ROLE_AUDITOR')
      let isTheAuditor = this.$auth.user.uuid === this.item.responsable.uuid
      let isAuditorAdmin = this.$auth.user.roles.includes('ROLE_AUDITOR_ADMIN')
      let isAuditorInAuditores = this.item.auditores.some((auditor) => {
        return this.$auth.user.uuid === auditor.uuid
      })

      //if (isAuditor && !isTheAuditor && !isAuditorInAuditores && !isAuditorAdmin) return true
      if (isAuditor && !isAuditorInAuditores && !isAuditorAdmin) return true

      return false
    },

    isMobile() {
      return this.$device.isTablet || this.$device.isMobile
    },

    isCliente() {
      return this.$store.getters.isCliente
    },

    isAraporcei() {
      return this.$auth.user.empresa.codigo == 'araporcei'
    },

    isCerticalidad() {
      return this.$auth.user.empresa.nombre == 'certicalidad'
    },

    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },

    isCcl() {
      return this.$auth.user.empresa.nombre == 'Ccl'
    },

    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },

    consulta() {
      // return this.revision || this.dictamen
      return this.dictamen
    },

    actualizarEvidencias() {
      return this.$store.state.gestor_documental.actualizarEvidencias
    },

    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },

    auditoriaAbiertaPrevista() {
      // En revision, permitir edición
      if (this.revision) return true

      const estado = this.item && this.item.estado.toUpperCase()
      return (
        estado === this.estado.CONFIRMADA ||
        estado === this.estado.ABIERTA ||
        estado === this.estado.PREVISTA ||
        estado === 'TERMINADA'
      )
    },

    auditoriaCerrada() {
      return (
        (this.item && this.item.estado.toUpperCase() === this.estado.CERRADA) ||
        (this.item && this.item.estado.toUpperCase() == 'REALIZADA')
      )
    },

    esResponsable() {
      // nota: solo el responsable puede crear PACS!!!
      if (this.item && this.item.responsable) {
        const isResponsable = this.$auth.user.auditor?.uuid === this.item.responsable.uuid
        const isResponsableUser = this.$auth.user.uuid === this.item.responsable.user_uuid
        const isRoot = this.$auth.user.roles.includes(this.roles.ROLE_ROOT)
        const isAuditorAdmin = this.$auth.user.roles.includes(this.roles.ROLE_AUDITOR_ADMIN)
        const isAuditor = this.$auth.user.roles.includes(this.roles.ROLE_AUDITOR)
        return isResponsable || isResponsableUser || isRoot || isAuditorAdmin || isAuditor
      } else return false
    },

    canEditAuditores() {
      return this.item.planificaciones.length > 0 ? false : true
    },

    //+-------------------------------------------------
    // isLocked()
    // Returns true when the item is locked and the app is not offline
    // -----
    // Created on Wed Feb 02 2022
    //+-------------------------------------------------
    isLocked() {
      return !$nuxt.isOffline && this.item && this.item.bloqueada
    },

    esquemasAuditadosProduccionVegetal() {
      if (this.item.alcance.nombre === 'Producción Vegetal Ecológica') {
        // Filtrar los esquemas auditados para producción vegetal
        return this.esquemas_auditados.filter(
          (esquema) => esquema.value === 'a' || esquema.value === 'g'
        )
      } else {
        // Filtrar los esquemas auditados para producción vegetal
        return this.esquemas_auditados.filter(
          (esquema) => esquema.value === 'b' || esquema.value === 'f'
        )
      }
    },
  },

  watch: {
    tab(newTab) {
      if (newTab == 4) {
        this.getBloques()
        this.componentBloques++
      }
    },
  },
  methods: {
    isBlockUpdated(block) {
      return (
        this.$refs['blck-' + block.uuid] &&
        this.$refs['blck-' + block.uuid][0] &&
        this.$refs['blck-' + block.uuid][0].item.updated_at
      )
    },

    async replaceSite(item) {
      this.ui.loading = true
      this.selectedChecklist = item
      this.selectedSite = item.site
      this.instalaciones_cliente = this.item.sites.filter((site) => site.planificable)

      this.ui.dialogReplaceSite = true
      this.ui.loading = false
    },

    replaceSiteChecklist() {
      this.ui.dialogReplaceSite = false
      this.$store
        .dispatch('auditorias/replaceSiteChecklist', {
          checklist: this.selectedChecklist.uuid,
          site: this.selectedSite.uuid,
        })
        .then(() => {
          this.selectedChecklist = null
          this.selectedSite = null

          const uuid = this.uuid ? this.uuid : this.$route.params.uuid

          this.$store.dispatch('auditorias/get', uuid).then((res) => {
            if (!res.representante) res.representante = {}
            if (res) this.item = this.auditoria = res

            if (res.estado == 'CERRADA') {
              this.item.estado = 'REALIZADA'
            }

            this.component++
          })
          this.$store.commit('notification/show', {
            text: 'Emplazamiento reemplazado correctamente',
            color: 'success',
            timeout: 3000,
          })
        })
    },

    nombreCompleto(item) {
      return format.fullName(item, { is: 'auditor' })
    },

    //+-------------------------------------------------
    // findRGSEAA()
    // Tries to find rgseaa data from another source
    // this could be in format helper
    // -----
    // Created on Tue Jul 26 2022
    //+-------------------------------------------------
    findRGSEAA(item) {
      if (item.data?.rgseaa) return item.data.rgseaa
      if (item.data?.instalacion_codigo) return item.data.instalacion_codigo

      const instalacion = this.item.instalaciones.find((i) => i.uuid === item.uuid)

      if (instalacion) {
        if (instalacion.rgseaa) return instalacion.rgseaa
        if (instalacion.instalacion.rgseaa) return instalacion.instalacion.rgseaa
      }

      return ''
    },

    //+-------------------------------------------------
    // horaToTime()
    // Returns a time string to use with input time
    // -----
    // Created on Wed May 25 2022
    //+-------------------------------------------------
    horaToTime(time) {
      if (!time) return ''

      if (time && time.length > 5) {
        return time.substring(0, 5)
      }
      return time
    },

    //+-------------------------------------------------
    // setTime()
    // Sets time value to input with ddbb format friendly
    // -----
    // Created on Wed May 25 2022
    //+-------------------------------------------------
    setTime(field, value) {
      let time = value

      if (value && value.length < 6) {
        time = value + ':00'
      }

      if (value == '' || value == undefined || !value) {
        time = null
      }

      this.item[field] = time
      this.setTramos()
      this.guardarCambiosAuditoria()
    },

    //+-------------------------------------------------
    // setTramos()
    // For orceglobal, defines three entries in fechas
    // -----
    // Created on Wed Mar 15 2023
    //+-------------------------------------------------
    setTramos() {
      if (!this.isOceGlobal) return
      if (!this.item.desde_inicio || !this.item.desde_fin) return

      this.ui.showFechas = true
      if (this.item.fechas.length > 0) return

      let date = this.$moment(this.item.desde).format('YYYY-MM-DD')
      let start = this.$moment(date + ' ' + this.item.desde_inicio)
      let end = this.$moment(date + ' ' + this.item.desde_fin)
      // debugger
      this.item.fechas.push({
        desde: date,
        d_inicio: start.format('HH:mm'),
        d_fin: start.add(15, 'minutes').format('HH:mm'),
        hasta: date,
        h_inicio: start.subtract(15, 'minutes').format('HH:mm'),
        h_fin: start.add(15, 'minutes').format('HH:mm'),
        model: false,
        model_: false,
      })

      this.item.fechas.push({
        desde: date,
        d_inicio: start.format('HH:mm'),
        d_fin: end.subtract(15, 'minutes').format('HH:mm'),
        hasta: date,
        h_inicio: start.format('HH:mm'),
        h_fin: end.format('HH:mm'),
        model: false,
        model_: false,
      })

      this.item.fechas.push({
        desde: date,
        d_inicio: end.format('HH:mm'),
        d_fin: end.add(15, 'minutes').format('HH:mm'),
        hasta: date,
        h_inicio: end.subtract(15, 'minutes').format('HH:mm'),
        h_fin: end.add(15, 'minutes').format('HH:mm'),
        model: false,
        model_: false,
      })
    },

    //+-------------------------------------------------
    // addFecha()
    // Adds a new array in this.item and adds one row
    // -----
    // Created on Mon May 16 2022
    //+-------------------------------------------------
    addFecha() {
      if (!this.item.fechas) {
        this.item.fechas = []
      }

      this.item.fechas.push({
        desde: this.$moment(this.item.desde).format('YYYY-MM-DD'),
        d_inicio: '07:00',
        d_fin: '15:00',
        hasta: this.$moment(this.item.hasta).format('YYYY-MM-DD'),
        h_inicio: '15:00',
        h_fin: '21:00',
        model: false,
        model_: false,
      })

      this.$forceUpdate()
    },

    //+-------------------------------------------------
    // removeFecha()
    // Removes a row from this.item.fechas
    // -----
    // Created on Mon May 16 2022
    //+-------------------------------------------------
    removeFecha(index) {
      this.item.fechas.splice(index, 1)

      this.guardarCambiosAuditoria()
      this.$forceUpdate()
    },

    async getEstaticos() {
      const body = {
        empresa: this.$auth.user.empresa.uuid,
        codigos: ['tipos_auditorias', 'auditoria-nivel-de-control', 'laboratorios'],
      }

      this.estaticos = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getEstaticos', body)
        : await this.$store.dispatch('estaticos/list', body)
    },

    gotoExpediente() {
      this.$router.push(`/expedientes/${this.item.expediente.uuid}`)
    },

    async updatSiteNombre(item, open) {
      const dialog = 'siteNombre' + item.uuid
      if (open) this.oldSiteNombre = this.$refs[dialog].returnValue
      else {
        try {
          const body = {
            ...item.site,
          }
          await this.$store.dispatch('sites/update', body)
        } catch (e) {
          item.site.nombre = this.oldSiteNombre
          this.component++
        }
      }
    },

    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : ''
    },

    initCargos() {
      if (this.item.cargos.length == 0) this.updateCargos()
    },

    //+-------------------------------------------------
    // updateCargos()
    // Initializes the array of cargos with the selected auditores
    // -----
    // Created on Mon Feb 14 2022
    //+-------------------------------------------------
    updateCargos() {
      this.item.cargos = []
      for (const aud of this.item.auditores) {
        this.item.cargos.push({
          auditor: aud.uuid,
          nombre: aud.nombre,
          apellido: aud.apellido,
          cargo: 'Auditor',
        })
      }
      this.$forceUpdate()
    },

    //+-------------------------------------------------
    // formatHours()
    // Returns only hours for input type time
    // -----
    // Created on Fri Dec 10 2021
    //+-------------------------------------------------
    formatHours() {
      this.item.desde = this.item.desde ? this.$moment(this.item.desde).format('YYYY-MM-DD') : null
      this.item.hasta = this.item.hasta ? this.$moment(this.item.hasta).format('YYYY-MM-DD') : null

      this.item.desde_inicio = this.item.desde_inicio
        ? this.$moment(this.item.desde_inicio).utc().format('HH:mm:ss')
        : null

      this.item.desde_fin = this.item.desde_fin
        ? this.$moment(this.item.desde_fin).utc().format('HH:mm:ss')
        : null

      this.item.hasta_inicio = this.item.hasta_inicio
        ? this.$moment(this.item.hasta_inicio).utc().format('HH:mm:ss')
        : null

      this.item.hasta_fin = this.item.hasta_fin
        ? this.$moment(this.item.hasta_fin).utc().format('HH:mm:ss')
        : null
    },

    async getOne() {
      this.component++
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      if (this.uuid) this.revision = true
      else this.$store.commit('menu/setPageTitle', 'Auditoría')

      const res = await this.$store.dispatch('auditorias/get', uuid).then((res) => {
        if (!res.representante) res.representante = {}
        return res
      })

      if (res) this.item = this.auditoria = res

      if (res.estado == 'CERRADA') {
        this.item.estado = 'REALIZADA'
      }

      this.formatHours()

      await this.getFichasCultivos()
      await this.getChecklist()
      await this.getAuditoriaChecklist()
      await this.getSites()
      await this.getCliente()
      // PACS
      await this.getPACS()
      await this.getListadoNoConformidadesPendientesPac()
      // Offline
      // if (this.offlineMode) {
      //   // Auditores
      //   await this.$store.dispatch('offlineMode/getAuditores')
      //   // Muestras
      //   const tomas_muestra = await this.$store.dispatch('tomas_muestra/list', uuid)
      //   if (tomas_muestra) {
      //     for (let i = 0; i < tomas_muestra.length; i++) {
      //       const element = tomas_muestra[i]
      //       await this.$store.dispatch('tomas_muestra/listMuestra', element.uuid)
      //     }
      //   }
      //   // Estaticos
      //   const body = {
      //     offlineMode: this.offlineMode,
      //     empresa: this.$auth.user.empresa,
      //     alcance: this.auditoria.alcance.uuid,
      //     codigos: ['tipos_auditorias', 'checklist_niveles', 'laboratorios', 'datos_muestra'],
      //   }
      //   this.estaticos = await this.$store.dispatch('offlineMode/getEstaticos', body)
      // }
      // Formatos
      this.loadFormatosData()

      // Bloques Añadido el 05/07/2021
      await this.getPlantillaBloques()
      await this.getBloques()
    },
    async getSites() {
      const res = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getSites', [
            this.item.expediente.uuid,
            this.item.iteracion.uuid,
          ])
        : await this.$store.dispatch('expedientes/iteracion', [
            this.item.expediente.uuid,
            this.item.iteracion.uuid,
          ])
      if (res) {
        this.iteracion = res
        let sites = []
        if (this.auditoria.sites && this.auditoria.sites.length) sites = this.auditoria.sites
        this.sitesAvailable = sites
        this.sitesAvailablebyMuestras = sites.filter((site) => !site.tipo.includes('instalacion'))
      }
      this.expedienteLoaded = true
    },
    // async getSites2(){
    //   let vm = this
    //   const res =  vm.offlineMode
    //     ? await vm.$store.dispatch("offlineMode/getSites", [vm.item.expediente.uuid, vm.item.iteracion.uuid])
    //     : await vm.$store.dispatch("expedientes/iteracion", [vm.item.expediente.uuid, vm.item.iteracion.uuid])
    //   if (res) {
    //     vm.iteracion = res
    //     // Añadimos sites de CENTRALES
    //     vm.sites.push({ header: 'CENTRALES' })
    //     if (vm.iteracion.sites && vm.iteracion.sites.CENTRALES && vm.iteracion.sites.CENTRALES.rows){
    //       await vm.iteracion.sites.CENTRALES.rows.forEach(site => {
    //         vm.sites.push(site)
    //       });
    //     } // Añadimos sites de PRODUCTORES
    //     vm.sites.push({ divider: true })
    //     vm.sites.push({ header: 'PRODUCTORES' })
    //     if (vm.iteracion.sites && vm.iteracion.sites.PRODUCTORES && vm.iteracion.sites.PRODUCTORES.rows){
    //       await vm.iteracion.sites.PRODUCTORES.rows.forEach(site => {
    //         vm.sites.push(site)
    //       });
    //     } // Añadimos sites de PRODUCTOS
    //     vm.sites.push({ divider: true })
    //     vm.sites.push({ header: 'PRODUCTOS' })
    //     if (vm.iteracion.sites && vm.iteracion.sites.PRODUCTOS && vm.iteracion.sites.PRODUCTOS.rows){
    //       await vm.iteracion.sites.PRODUCTOS.rows.forEach(site => {
    //         site.nombre = site.nombre + ' '
    //         vm.sites.push(site)
    //       });
    //     }
    //   }
    // },
    async checkOffline() {
      const offlineMode = localStorage.getItem('offlineMode')
      const offlineUuid = localStorage.getItem('offlineUuid')
      if (offlineMode && offlineUuid) this.toggleOffline(offlineUuid)
      else this.getOne()
    },
    confirmarModoOffline() {
      this.texto_confirmacion = '¿Está seguro de que desea entrar en Modo Offline?'
      this.dialogModoOffline = true
    },

    confirmarModoOnline() {
      this.texto_confirmacion = '¿Está seguro de que desea volver al Modo Online?'
      this.dialogModoOnline = true
    },

    async toggleOffline(uuid) {
      this.dialogModoOffline = false
      const auditoria_uuid = uuid ? uuid : this.$route.params.uuid
      await this.$store.commit('auditorias/OFFLINE_MODE', true)
      await this.$store.commit('auditorias/OFFLINE_UUID', auditoria_uuid)
      localStorage.setItem('offlineMode', true)
      localStorage.setItem('offlineUuid', auditoria_uuid)
      this.getOne()
    },
    async toggleOnline() {
      this.dialogModoOnline = false
      await this.$store.dispatch('auditorias/goOnline')
      this.getOne()
    },
    setTabUrl() {
      window.history.pushState(
        { key: Date.now().toFixed(3) },
        '',
        this.$route.path + '?tab=' + this.tab
      )
      if (this.tab === 1 || this.tab === 2) this.getAuditoriaChecklist() // Obtenemos el listado de checklist de la auditoria
      if (this.tab === 3) this.getPACS(), this.getListadoNoConformidadesPendientesPac()
    },
    async getFichasCultivos() {
      let alcance = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getAlcance', this.item.alcance.uuid)
        : await this.$store.dispatch('alcances/get', this.item.alcance.uuid)
      if (alcance) {
        let grupo_alcance = alcance.grupo.nombre.replace(' ', '_').toLowerCase()
        let empresa = this.$auth.user.empresa.codigo
        this.componente_cultivos = () =>
          import(`~/pages/auditorias/${empresa}/${grupo_alcance}/cultivos`)
      }
    },
    async getChecklist() {
      const res = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getPlantillasChecklist')
        : await this.$store.dispatch('plantillas-checklist/list', this.item.alcance.uuid)
      if (res) {
        this.checklist = res.filter((element) => element.activo)
        this.selectedSite = this.checklist.site
      }
    },
    async getAuditoriaChecklist() {
      if (this.item) {
        const res = this.offlineMode
          ? await this.$store.dispatch('offlineMode/getAuditoriaChecklist', this.item.uuid)
          : await this.$store.dispatch('checklists/list', this.item.uuid)
        if (res) {
          for (let index = 0; index < res.length; index++) {
            let checklist = res[index]
            checklist.cumplimiento = await this.$store.dispatch(
              'auditorias/cumplimientoChecklist',
              checklist.preguntas
            )
          }
          this.auditoria_checklist = res
        }
      }
    },
    async getEvidencias() {
      await this.$store.dispatch(`gestor_documental/list`, this.$route)
    },
    async getCliente() {
      if (this.item && this.item.cliente) {
        const res = this.offlineMode
          ? await this.$store.dispatch('offlineMode/getCliente', this.item.cliente.uuid)
          : await this.$store.dispatch('clientes/get', this.item.cliente.uuid)
        if (res) {
          this.cliente = res
          // this.item.cliente.nombre = this.cliente.nombre_completo
        }
      }
    },
    formatDate(date) {
      return date ? moment(date.substring(0, 10)).format('DD-MM-YYYY') : ''
    },
    async addPlanificacion() {
      // Nos quedamos solo con los childrens
      const childrenSites = this.sites_selected.filter((e) => e.parent_uuid)
      const sites = childrenSites.length ? childrenSites : this.sites_selected

      for (let i = 0; i < this.checklist_selected.length; i++) {
        const checklist = this.checklist_selected[i]
        for (let k = 0; k < sites.length; k++) {
          const site = sites[k]

          const planificacion = {
            auditor: {
              nombre: this.planificador_auditor.nombre,
              uuid: this.planificador_auditor.uuid,
            },
            site: site,
            plantilla_checklist: checklist,
            certificado: true,
          }
          // Añadimos la planificación
          const body = { auditoria: this.item.uuid, planificacion: planificacion }
          const res = await this.$store.dispatch(`auditorias/addPlanificacion`, body)
          if (res) this.item.planificaciones = res.planificaciones
        }
      }
      this.planificador_auditor = null
      this.checklist_selected = []
      this.sites_selected = []
      if (this.offlineMode) localStorage.setItem('auditoria', JSON.stringify(this.item))
      this.getOne()
    },
    removeSiteSelected(item) {
      const index = this.sites_selected.indexOf(item)
      if (index >= 0) this.sites_selected.splice(index, 1)
      if (this.offlineMode) localStorage.setItem('auditoria', JSON.stringify(this.item))
    },
    clickRowChecklist(item) {
      this.$router.push({
        path: `/auditorias/tabs/checklist/${item.uuid}`,
        query: { auditoria_uuid: this.item.uuid },
      })
    },
    confirmDeletePlanificacion(planificacion) {
      this.current_planificacion = planificacion
      this.dialogConfirmarBorradoPlanificacion = true
    },
    async removePlanificacion() {
      this.dialogConfirmarBorradoPlanificacion = false

      try {
        const xhr = await this.$store.dispatch(`auditorias/removePlanificacion`, {
          auditoria: this.item.uuid,
          planificacion: this.current_planificacion.uuid,
        })

        const res = await this.$store.dispatch('auditorias/get', this.item.uuid)
        if (res) this.item = this.auditoria = res
      } catch (e) {
        console.info(e)
        this.$store.commit('notification/show', {
          text: 'No se ha podido eliminar la planificación',
          color: 'error',
          timeout: 3000,
        })

        this.ui.showPlanificacionError = true
      }

      this.dialogConfirmarBorradoPlanificacion = false
    },

    clearSelection() {
      this.item.revisor = null // Set the selected item to null to clear the selection
      this.guardarCambiosAuditoria()
    },

    clearAuditor(index) {
      this.item.cargos.splice(index, 1)
    },
    addAuditor() {
      this.ui.addAuditor = true
    },
    updateCargos() {
      this.item.cargos = []
      for (const aud of this.item.auditores) {
        this.item.cargos.push({
          auditor: aud.uuid,
          nombre: aud.nombre,
          apellido: aud.apellidos,
          cargo: 'Auditor',
        })
      }
      this.$forceUpdate()
    },

    async guardarCambiosAuditoria() {
      this.ui.loading = true

      // Format dates and hours
      let desde = this.$moment().format('YYYY-MM-DD')
      let $desde = this.$moment().format('YYYY-MM-DD')

      // this.item.desde = this.item.desde ? this.item.desde : null
      // if (this.item.desde) {
      //   desde = this.$moment(this.item.desde).format('YYYY-MM-DD')
      //   this.item.desde = this.$moment(this.item.desde).format('YYYY-MM-DDTHH:mm:ss')
      // }

      // console.warn(this.item.desde, desde, this.item.desde_inicio)

      // this.item.desde_inicio = this.item.desde_inicio ? this.item.desde_inicio : null
      // if (this.item.desde_inicio) {
      //   this.item.desde_inicio = this.$moment(`${desde} ${this.item.desde_inicio}`).format(
      //     'YYYY-MM-DDTHH:mm:ss'
      //   )
      // }

      // console.warn(this.item.desde, this.item.desde_inicio)
      // debugger

      console.warn('guardarCambiosAuditoria', this.item, this.$refs.form.validate())

      if (this.$refs.form.validate()) {
        await this.$store.dispatch(`auditorias/update`, this.item)
      }

      this.ui.loading = false
      this.$forceUpdate()
    },

    confirmarCambioEstado(estado) {
      // if (this.isCcl && estado == this.estado.CERRADA && !this.item.valoracion) {
      //   this.ui.showValoration = true
      //   return
      // }

      if (this.isCcl && estado === 'TERMINADA') {
        this.nuevo_estado = estado
        this.texto_confirmacion = 'TERMINAR AUDITORÍA'
      } else {
        this.nuevo_estado = estado
        this.texto_confirmacion = this.auditoriaCerrada ? 'ABRIR AUDITORÍA' : 'CIERRE DE AUDITORÍA'

        if (!this.auditoriaCerrada)
          this.texto_confirmacion +=
            '<br> Se debe cerrar la auditoría cuando el check list, pacs, y muestras estén realizados totalmente. No se podrá volver a abrir ¿Está seguro de cerrar la auditoría?'
      }
      this.dialogConfirmarCambioEstado = true
      this.dialogIntroducirMotivo = false
    },

    async cambiarEstadoAuditoria(crearAuditoria) {
      let valid = true
      if (crearAuditoria) valid = this.$refs.createForm.validate()
      if (!!valid) {
        await this.$store.dispatch(`auditorias/cambiarEstado`, {
          uuid: this.item.uuid,
          estado: this.nuevo_estado,
        })
        this.dialogConfirmarCambioEstado = false
        if (crearAuditoria) await this.createAuditoria()
        if (this.revision) this.$router.push({ path: `/revisiones` })
        else if (this.dictamen) this.$router.push({ path: `/dictamenes` })
        else this.$router.push({ path: `/auditorias` })
      }
    },
    async createAuditoria() {
      const validForm = this.create_auditoria.fecha_prevista && this.create_auditoria.tipo_visita
      if (validForm) {
        const body = {
          ...this.item,
        }
        delete body.desde
        delete body.hasta
        body.fecha_prevista = this.create_auditoria.fecha_prevista
        body.tipo_visita = this.create_auditoria.tipo_visita
        body.cliente = body.cliente.uuid
        body.alcance = body.alcance.uuid
        body.auditores = body.auditores.map((e) => e.uuid)
        body.sites = body.sites.map((e) => e.uuid)
        await this.$store.dispatch('auditorias/create', body)
      }
    },
    // PACS
    async getPACS() {
      if (this.item && this.item.uuid) {
        const res = await this.$store.dispatch('auditorias/pacs', this.item.uuid)
        if (res) this.pacs = res
      }
    },
    clickRowPACS(pac) {
      this.$router.push({
        path: `/auditorias/tabs/pacs/${pac.uuid}`,
        query: { auditoria_uuid: this.item.uuid },
      })
    },
    async getListadoNoConformidadesPendientesPac() {
      if (this.item && this.item.uuid) {
        const res = await this.$store.dispatch(
          'auditorias/noConformidadesPendientesPac',
          this.item.uuid
        )
        if (res) this.no_conformidades = res
      }
    },
    async crearPAC() {
      await this.$store.dispatch('auditorias/crearPac', {
        auditoria: this.item.uuid,
        no_conformidades: this.no_conformidades_seleccionadas,
      })
      this.no_conformidades_seleccionadas = []
      this.getPACS()
      this.getListadoNoConformidadesPendientesPac()
    },
    confirmarBorradoPAC(pac) {
      this.selected_pac = pac
      this.texto_confirmacion = '¿Seguro que desea eliminar el PAC seleccionado?'
      this.dialogConfirmarBorradoPAC = true
    },
    async deletePAC() {
      await this.$store.dispatch('auditorias/deletePac', {
        auditoria: this.item.uuid,
        pac: this.selected_pac.uuid,
      })
      this.dialogConfirmarBorradoPAC = false
      this.getPACS()
      this.getListadoNoConformidadesPendientesPac()
    },

    getNumPac(pac) {
      const nums = pac.no_conformidades.map((pac) => pac.numero).join(',')
      return nums
    },

    getInfoNoConformidades(pac) {
      let info = ''
      for (let index = 0; index < pac.no_conformidades.length; index++) {
        const no_conformidad = pac.no_conformidades[index]
        if (info === '') info += no_conformidad.codigo
        else info += ', ' + no_conformidad.codigo
      }
      return info
    },
    // INFORME
    async loadFormatosData(plan_auditoria) {
      this.$store.commit('formatos/SET_MODULO', 'auditorias')
      this.$store.commit('formatos/SET_MODULO_REF', this.item.uuid)
      this.$store.commit('formatos/SET_ALCANCE', this.auditoria.alcance)
      console.info('this.auditoria.cliente:', this.auditoria.cliente)
      this.$store.commit('formatos/SET_CLIENTE', this.auditoria.cliente)

      // Formato Tipos
      this.$store.commit('formatos/GET_TIPOS', this.$auth.user.empresa.codigo)

      // Formato Data
      const body = {
        ...this.item,
        checklists: this.auditoria_checklist,
        cliente: this.cliente,
      }

      // Solicitud
      const solicitud_uuid = this.iteracion.grupo_solicitud.uuid
      const solicitud = this.offlineMode
        ? await this.$store.dispatch('offlineMode/getSolicitud', solicitud_uuid)
        : await this.$store.dispatch('grupo_solicitudes/get', solicitud_uuid)
      if (solicitud) body.solicitud = solicitud

      // Instalacion
      if (this.cliente && solicitud) {
        const instalaciones = this.cliente.instalaciones
        let rgseaa = ''
        if (solicitud.params.alcance_producto && solicitud.params.alcance_producto.length) {
          rgseaa = solicitud.params.alcance_producto[0].rgseaa
        }
        for (let i = 0; i < instalaciones.length; i++) {
          const element = instalaciones[i]
          if (element.rgseaa === rgseaa) {
            body.instalacion = element
            break
          }
        }
      }

      // Productor
      const productor = this.item.sites.filter((element) => element.tipo === 'productor')
      if (productor.length) body.productor = productor[0].nombre

      // Planificaciones
      const emplazamientos = {}
      for (let i = 0; i < this.item.planificaciones.length; i++) {
        const element = this.item.planificaciones[i]
        const parent_uuid = element.site.parent_uuid
        const site_uuid = element.site.uuid
        const obj = {
          uuid: element.uuid,
          auditor: element.auditor.nombre,
          titulo_lista: element.plantilla_checklist?.titulo,
          emplazamiento: element.site.nombre,
          no_conformidades: [],
          no_conformidades_pendientes: [],
          site: {
            nombre: element.site.nombre,
            parent_uuid: parent_uuid,
            uuid: site_uuid,
          },
        }
        // if (!emplazamientos[parent_uuid]) emplazamientos[parent_uuid] = {}
        if (!emplazamientos[site_uuid]) emplazamientos[site_uuid] = {}
        // emplazamientos[parent_uuid].push(obj)
        emplazamientos[site_uuid] = obj
      }

      // PACS
      const no_conformidades = {}
      for (let i = 0; i < this.pacs.length; i++) {
        const pac_uuid = this.pacs[i].uuid
        const pac = await this.$store.dispatch('auditorias/pac', {
          auditoria: this.item.uuid,
          pac: pac_uuid,
        })

        // Acciones Correctivas PAC
        const no_conformidades_pac = {}
        const acciones_correctivas_pac = await this.$store.dispatch(
          'auditorias/getAccionesCorrectivasPAC',
          {
            auditoria: this.item.uuid,
            pac: pac_uuid,
          }
        )
        for (let k = 0; k < acciones_correctivas_pac.length; k++) {
          const accion_correctiva = acciones_correctivas_pac[k]
          const no_conformidades_accion = accion_correctiva.no_conformidades
          for (let n = 0; n < no_conformidades_accion.length; n++) {
            const no_conformidad_accion = no_conformidades_accion[n]
            const obj = {
              codigo: no_conformidad_accion.codigo,
              hint: no_conformidad_accion.hint,
              no_conformidad: no_conformidad_accion.no_conformidad,
              observaciones: no_conformidad_accion.observaciones,
              respuesta: no_conformidad_accion.respuesta,
              accion_correctiva: {
                responsable_implantacion: accion_correctiva.responsable_implantacion,
                fecha_implantacion: accion_correctiva.fecha_implantacion,
                created_at: accion_correctiva.created_at,
                causa: accion_correctiva.causa,
                accion_propuesta: accion_correctiva.accion_propuesta,
                evidencias_aportadas: accion_correctiva.evidencias_aportadas,
                valoracion_accion: accion_correctiva.valoracion_accion,
                valoracion_causa: accion_correctiva.valoracion_causa,
                valoracion_evidencias: accion_correctiva.valoracion_evidencias,
              },
            }
            no_conformidades_pac[no_conformidad_accion.uuid] = obj
          }
        }

        // No Conformidades PAC
        for (let k = 0; k < pac.no_conformidades.length; k++) {
          const no_conformidad = pac.no_conformidades[k]
          const emplazamiento = no_conformidad.site.uuid
          const obj = {
            ...no_conformidad,
            accion_correctiva: no_conformidades_pac[no_conformidad.uuid]
              ? no_conformidades_pac[no_conformidad.uuid].accion_correctiva
              : {},
          }
          emplazamientos[emplazamiento].no_conformidades.push(obj)
        }
      }

      // No Conformidades Pendientes
      for (let i = 0; i < this.no_conformidades.length; i++) {
        const no_conformidad_pendiente = this.no_conformidades[i]
        const emplazamiento = no_conformidad_pendiente.site.uuid
        emplazamientos[emplazamiento].no_conformidades_pendientes.push(no_conformidad_pendiente)
      }

      // Limpiando las no conformidades del body para agregar las que tienen accion correctiva
      body.no_conformidades = []

      // Datos por Instalacion
      const planificaciones = Object.values(emplazamientos)
      const instalaciones = {}
      for (let i = 0; i < planificaciones.length; i++) {
        const planificacion = planificaciones[i]
        const parent_uuid = planificacion.site.parent_uuid
        if (!instalaciones[parent_uuid]) {
          instalaciones[parent_uuid] = {
            ...body,
            planificaciones: [],
          }
        }
        instalaciones[parent_uuid].planificaciones.push(planificacion)
        // Añadimos las no conformidades con sus acciones correctivas
        instalaciones[parent_uuid].no_conformidades = instalaciones[
          parent_uuid
        ].no_conformidades.concat(planificacion.no_conformidades)
        instalaciones[parent_uuid].no_conformidades_pendientes = instalaciones[
          parent_uuid
        ].no_conformidades.concat(planificacion.no_conformidades_pendientes)
      }
      const datos = Object.values(instalaciones)
      if (!this.offlineMode) this.$store.commit('formatos/SET_DATOS', datos)
      // let route = '/formatos?plantilla='
      // route += plan_auditoria ? 'plan' : 'informe'
      // this.$router.push(route)

      // New v2 formatos load request
      $nuxt.$emit('formatos:load', {
        alcance: this.auditoria.alcance,
      })
    },

    async unlockAuditoria() {
      await this.$store.dispatch('auditorias/toggleLock', {
        uuid: this.item.uuid,
        action: 'unlock',
      })

      this.init()
    },

    //+-------------------------------------------------
    // getBloques()
    // Carga los bloques asociados a la auditoría
    // Si no hay, carga las plantillas de la auditoria
    // -----
    // Created on Mon Jul 05 2021
    //+-------------------------------------------------
    async getBloques() {
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      const xhr = await this.$store.dispatch('bloques/get', {
        modulo: 'auditorias',
        modulo_ref: uuid,
      })
      console.log(xhr)
      this.db.misBloques = []
      if (xhr) this.db.misBloques = xhr

      this.ui.refresh = Date.now()
    },

    async getPlantillaBloques() {
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      const xhr = await this.$store.dispatch('plantillas-bloques/list', {
        modulo: 'auditorias',
        alcance: this.auditoria.alcance.uuid,
      })

      if (xhr) {
        this.db.bloques = xhr
        // for (const block of xhr) {
        //   block.isNew = true
        //   block.modulo_ref = uuid
        //   this.db.bloques[block.codigo] = block
        // }
      }
    },

    //+-------------------------------------------------
    // getBlocksForUUID()
    // Returns blocks based on uuid template
    // Otherwise return template
    // -----
    // Created on Wed Oct 27 2021
    //+-------------------------------------------------
    getBlocksForUUID(uuid) {
      console.warn(this.db.misBloques.length, 'searching for blocks for uuid', uuid)
      let mine = this.db.misBloques.filter((block) => block.plantilla?.uuid == uuid)
      console.warn(mine.length, 'found blocks for uuid', uuid)
      if (mine.length > 0) return mine

      return this.db.bloques.filter((block) => block.uuid == uuid)
    },

    async updateBlock(key, block) {
      if (this.$refs['blck-' + key]) {
        await this.$refs['blck-' + key][0].doUpdate(block)
      }
      await this.getBloques()
      block.isNew = false
      this.componentBloquesActualizado++
      this.componentBloques++
    },

    resetBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doReset(block)

      block.isNew = true
      const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      block.modulo_ref = uuid
    },

    cloneBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doClone(block)
    },

    exportBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doExport(block)
    },

    // async getAuditores() {
    //   const xhr = await this.$store.dispatch('auditores/list')
    //   this.db.auditores = [...xhr]
    // },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'auditorias',
        return: 'all',
      })

      this.auth.checklists = await this.$store.dispatch('user/can', {
        module: 'auditorias',
        submodule: 'checklists',
        return: 'all',
      })

      this.auth.revisiones = await this.$store.dispatch('user/can', {
        module: 'revisiones',
        return: 'all',
      })
    },

    //+-------------------------------------------------
    // getSnapshot()
    // Gets snapshotted data
    // -----
    // Created on Tue Mar 08 2022
    //+-------------------------------------------------
    async getSnapshot() {
      if (!this.$nuxt.isOffline) return

      const xhr = await this.$store.dispatch('offline/getSnapshot', this.$route.path)
      if (xhr) {
        for (const key in xhr) {
          this[key] = xhr[key]
          console.warn(key, this[key])
        }
      }
    },

    async init() {
      await this.checkRoles()
      await this.getEstaticos()
      // await this.checkOffline() -- removed for new offline
      await this.getOne()
      await this.getSnapshot()

      let params = this.$route.fullPath.split('?tab=')
      if (params[1]) this.tab = parseInt(params[1])
      // Con los datos de la query controlamos si venimos de una revisión o de un dictamen
      if (this.$route.query.revision) this.revision = true
      else if (this.$route.query.dictamen) this.dictamen = true
      if (!this.consulta) this.setTabUrl()

      if (this.isCcl && this.item.revisor) {
        this.item.planificaciones.forEach((planificacion) => {
          if (this.item.revisor.uuid === planificacion.auditor.uuid) {
            planificacion.type = 'Revisor'
          }
        })
      }

      $nuxt.$emit('offline:loaded', {
        item: 'auditoria',
        uuid: this.uuid,
        data: this.item,
      })

      // if offfline
      // if cache this url
      // replace this[key] = cache.data
    },
  },

  async mounted() {
    await this.init()

    this.$nuxt.$on('bloques:refresh', (payload) => {
      this.getBloques()
    })

    this.$nuxt.$on('offline:snapshot', (payload) => {
      this.$store.commit('offline/snapshotPage', {
        item: this.item,
      })
    })
  },

  beforeDestroy() {
    this.$nuxt.$off('bloques:refresh')
    this.$nuxt.$off('offline:snapshot')
  },
}
</script>

<style>
.estado-auditoria {
  position: absolute;
  min-width: 120px !important;
  right: 15px;
  top: 10px;
}

.estado-auditoria-left {
  position: absolute;
  min-width: 120px !important;
  right: 155px;
  top: 10px;
}

.theme--light.v-data-table.auditoria tbody tr:hover:not(.v-data-table__expand-row) {
  background: #eeeeee;
  cursor: context-menu !important;
}

.isLocked * {
  opacity: 0.95;
  pointer-events: none;
  cursor: pointer;
}
</style>
