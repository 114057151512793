<template>
  <div>
    <v-row>
      <v-col cols="6" class="title black--text">
        Elaboración - Transformación
      </v-col>

      <v-col cols="6" class="text-right" v-if="editable">
        <!-- Importar Excel -->
        <input type="file" ref="inputFile" style="display: none;" @change="importFile($event)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="warning" class="white--text mr-2" v-on="on" @click="$refs.inputFile.click()">
              <v-icon>mdi-file-import</v-icon>
            </v-btn>
          </template>
          <span>Importar excel</span>
        </v-tooltip>

        <!-- Añadir Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="success" class="white--text mr-2" v-on="on" @click="dialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Añadir fila</span>
        </v-tooltip>

        <!-- Eliminar Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="error" class="white--text" v-on="on" @click="removeRow()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar fila</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <ag-datatable
            :title="grid.title"
            :headers="grid.columnDefs"
            :items="grid.rowData"
            :height="'65'"
            hide-overlay
            emitClickRow
            :disableClickRow="!editable"
            @click-row="itemSelected = $event, dialog = true"
            @gridApi="onGridReady($event)"
          ></ag-datatable>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog -->
    <ProductoresDialog
      v-model="dialog"
      :itemSelected="itemSelected"
      @close="dialog = false"
      @add="addRow($event)"
      @edit="editRow($event)"/>

  </div>
</template>

<script>
import MODELS from "~/models/ccl/solicitudes"
import AgDatatable from "~/components/AgDatatable"
import ProductoresDialog from './ProductoresDialog'
import * as UTIL from '~/util'

export default {
  name: "ProductoresList",
  props: {
    editable: { type: Boolean, default: false },
    items: { type: Array, default: () => [] }
  },
  components:{
    AgDatatable,
    ProductoresDialog
  },
  data: () => ({
    dialog: false,
    itemSelected: null,
    grid: {
      title: 'Productos Ecológicos y Actividad de Producción',
      api: null,
      rowData: [],
      columnDefs: []
    },
    UTIL: UTIL
  }),
  computed: {
    loading: {
      get () {
        return this.$store.state.loading
      },
      set (val) {
        this.$store.commit('LOADING', val)
      }
    }
  },
  methods: {
    onChange() {
      this.itemSelected = null
      this.$emit('change', this.grid.rowData)
    },
    // Grid
    onGridReady(api, tipo) {
      this.grid.api = api
    },
    addRow(item) {
      this.grid.rowData.push(item)
      this.grid.api.updateRowData({ add: [item] })
      this.onChange()
    },
    editRow(item) {
      const index = this.grid.rowData.indexOf(this.itemSelected)
      this.grid.rowData.splice(index, 1, item)
      this.onChange()
    },
    removeRow() {
      const selectedRows = this.grid.api.getSelectedRows()
      if (selectedRows && selectedRows.length) {
        selectedRows.forEach(element => {
          const index = this.grid.rowData.indexOf(element)
          this.grid.rowData.splice(index, 1)
        })
      }
      this.grid.api.updateRowData({remove: this.grid.api.getSelectedRows()})
      this.onChange()
    },
    init () {
      const vm = this
      this.grid.rowData = this.items
      this.grid.columnDefs = [
        {
          headerName: "#", sortable: true, filter: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true, valueGetter: params => params.node.rowIndex + 1
        },
        {
          headerName: "Razón social de la empresa", field: "razon_social", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
        {
          headerName: "CIF", field: "cif", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
        {
          headerName: "Número de registro sanitario", field: "numero_registro_sanitario", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
        {
          headerName: "Dirección Localidad Provincia Teléfono Fax", field: "direccion_localidad_provincia_telefono_fax", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
        {
          headerName: "Actividades realizadas en la instalación bajo el registro sanitario", field: "registro_sanitario", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
        {
          headerName: "Tipo de producto etiquetado con Nº GGN/Logotipo de la etiqueta GGN", field: "ggn", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
        {
          headerName: "Producción del producto Toneladas/año (puede estar expresado en peso vivo de animales bajo la certificación)", field: "produccion_toneladas", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
        {
          headerName: "(*) Sistema de inocuidad alimentaria implantado", field: "sistema_inocuidad", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
        {
          headerName: "Existe Producción Paralela SI/NO", field: "produccion_paralela", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
        {
          headerName: "(**) Subcontratistas que intervienen en algún proceso", field: "subcontratistas", sortable: true, filter: true, resizable: true, enableRowGroup: true,
        },
      ]
    },
    async importFile (event) {
      UTIL.importFile(event, this.grid, null, this.onChange.bind(this))
      this.$refs.inputFile.value = ''
    }
  },
  created () {
    this.init()
  }
}
</script>
