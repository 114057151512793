<template>
  <div>
      <loading v-if="ui.loading"></loading>

      <!--
        Show all blocks set for this modulo
        Loaded from db.bloques ( templates )
      -->
      <template v-if="ui.refresh > 0">
        <v-row v-for="(block, index) in db.bloques"
          :key="'block-' + index + ui.refresh"
          class="pb-4">

          <v-col cols="5">
            <h3 v-if="ui.showHeader">{{ block.titulo }}</h3>
          </v-col>

          <!-- Search custom blocks loaded already for this uuid -->
          <template v-for="(bl, o) in getBlocksForUUID(block.uuid)">
            <v-col cols="7" class="mx-auto px-10 pb-0" v-if="o > 0" :key="'block-divider-'+o" :title="o">
              <v-divider style="border-style: dashed;"></v-divider>
            </v-col>

            <v-col cols="5" :key="'block-title-'+o" v-if="o > 0"></v-col>

            <v-col class="text-right" cols="7" :key="'block-actions-'+o">
              <!--
                Comentado porque aunque funciona correctamente, la reactividad no funciona muy bien.
              <span v-if="$refs['blck-' + bl.uuid] && $refs['blck-' + bl.uuid][0] && $refs['blck-' + bl.uuid][0].item.updated_at"
                :key="$refs['blck-' + bl.uuid][0].ui.key"
                style="color: rgb(146, 146, 146);  font-size: 12px !important; margin-right: 10px">
                Actualizado {{ $moment($refs['blck-' + bl.uuid][0].item.updated_at).fromNow() }}
              </span>
              -->

              <template v-if="bl.tipo == 'simpletable'">
                <v-btn
                  small
                  text
                  color="success darken-3"
                  @click="excelExport(bl)"
                  class="ml-1">
                  Exportar
                  <v-icon dark class="ml-1" small>
                    mdi-file-excel-box-outline
                  </v-icon>
                </v-btn>

                <v-btn
                  small
                  text
                  color="success darken-3"
                  @click="excelImport(bl)"
                  class="ml-1">
                  Importar
                  <v-icon dark class="ml-1" small>
                    mdi-file-excel-box-outline
                  </v-icon>
                </v-btn>
              </template>

              <v-btn-toggle>
                <v-btn small text @click="updateBlock(bl.uuid, bl)">
                  <span class="hidden-sm-and-down">Guardar</span>
                  <v-icon right>
                    mdi-content-save
                  </v-icon>
                </v-btn>

                <v-menu offset-y :close-on-content-click="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon right small class="ma-0">
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click.stop="cloneBlock(bl.uuid, bl)"
                    >
                      <v-list-item-title>
                        Añadir otro como este
                      </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon small>
                        mdi-content-copy
                      </v-icon>
                    </v-list-item-icon>
                    </v-list-item>

                    <v-list-item
                      v-if="!block.isNew"
                      color="error darken-3"
                      @click.stop="resetBlock(bl.uuid, bl)"
                    >
                      <v-list-item-title>
                        Reiniciar
                      </v-list-item-title>
                      <v-list-item-icon>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs"
                                v-on="on">
                              mdi-refresh
                            </v-icon>
                          </template>
                          <span>Eliminar bloque.<br>Los datos se vaciarán y la información se reestablecerá desde la plantilla</span>
                        </v-tooltip>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item
                      v-if="!block.isNew"
                      color="error darken-3"
                       @click.stop="exportBlock(bl.uuid, bl)"
                    >
                      <v-list-item-title>
                        Exportar Bloque
                      </v-list-item-title>
                      <v-list-item-icon>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs"
                                v-on="on">
                              mdi-file-export
                            </v-icon>
                          </template>
                        </v-tooltip>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn-toggle>
            </v-col>

            <v-col v-if="false" cols="12" class="text-right pb-1" :key="'block-export-'+o">
              <v-menu
                v-if="false"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    text
                    color="primary"
                    nope-click.stop="ui.filters = !ui.filters"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1">
                    Añadir filtros
                    <v-icon dark class="ml-2" small>
                      mdi-tune
                    </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <!-- <v-list-item-avatar>
                        <img
                          src="https://cdn.vuetifyjs.com/images/john.jpg"
                          alt="John"
                        >
                      </v-list-item-avatar> -->

                      <v-list-item-content>
                        <v-list-item-title>Filtros avanzados</v-list-item-title>
                        <v-list-item-subtitle>Aplica filtros para refinar la búsqueda</v-list-item-subtitle>
                      </v-list-item-content>

                      <!-- <v-list-item-action>
                        <v-btn
                          :class="fav ? 'red--text' : ''"
                          icon
                          @click="fav = !fav"
                        >
                          <v-icon>mdi-heart</v-icon>
                        </v-btn>
                      </v-list-item-action> -->
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-list-item-action>
                        <v-switch
                          v-model="message"
                          color="purple"
                        ></v-switch>
                      </v-list-item-action>
                      <v-list-item-title>Enable messages</v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action>
                        <v-switch
                          v-model="hints"
                          color="purple"
                        ></v-switch>
                      </v-list-item-action>
                      <v-list-item-title>Enable hints</v-list-item-title>
                    </v-list-item>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      text
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="menu = false"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>

            <v-col cols="12" class="pt-0 mb-4" :key="'block-embed-'+o">
              <block-embed
                :ref="'blck-'+bl.uuid"
                :block="bl">
              </block-embed>
            </v-col>
          </template>
        </v-row>
      </template>

      <v-row v-if="!ui.loading && codigo && db.bloques.length == 0">
        <v-col cols="10" class="mx-auto pa-8 text-center">
          <v-icon style="font-size: 80px; color: #d2d5db" class="my-4">
            mdi-folder-alert-outline
          </v-icon>
          <h2>No se han encontrado bloques</h2>
          <p>No existe ningún bloque con código <code>{{ codigo }}</code> en el módulo de <code>auditores</code>.<br>
          Puedes crear uno y aparecerá aquí</p>
        </v-col>
      </v-row>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\bloques\section.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 6th May 2022
 * Last Modified: Tue Apr 25 2023
 **/

import { mapState, mapGetters } from 'vuex'

import Loading from '~/components/loadingEmbed.vue'
import blockEmbed from '~/pages/bloques/widgets/blockEmbed'

export default {
  name: 'BlocksSection',

  components: {
    Loading,
    blockEmbed,
  },

  props: ['codigo', 'alcance', 'modulo', 'modulo_ref', '_ui'],

  data() {
    return {
      items: [],

      db: {
        bloques: [],
        misBloques: [],
      },

      ui: {
        showHeader: true,

        refresh: 100,
        loading: true,
      },
    }
  },

  watch: {},

  computed: {
    ...mapState(['context']),
    ...mapGetters({
      getState: 'context/getState',
    }),
  },

  methods: {
    //+-------------------------------------------------
    // formatForExcel()
    // Formats the block data to use with excelBridge
    // Data comes from ref, not from the current block
    // -----
    // Created on Thu May 12 2022
    //+-------------------------------------------------
    formatForExcel(block) {
      let data = {
        body: [],
        headers: [],
      }

      let source = this.$refs['blck-' + block.uuid][0]?.item || {}

      var abc = []
      for (let i = 65; i <= 90; i++) {
        abc[i - 65] = String.fromCharCode(i).toLowerCase()
      }

      let _body = [...source.content_table.rows]
      let _headers = [...source.content_table.headers]

      data.headers = _headers.map((header, key) => {
        return {
          headerName: header.label,
          field: abc[key],
        }
      })

      data.body = _body.map((row) => {
        let _row = {}
        row.forEach((item, key) => {
          _row[abc[key]] = item
        })
        return _row
      })

      return data
    },

    //+-------------------------------------------------
    // excelImport()
    // Communicates with excelBridge
    // Sends headers and an import request
    // -----
    // Created on Thu May 12 2022
    //+-------------------------------------------------
    async excelImport(block) {
      let data = this.formatForExcel(block)

      $nuxt.$emit('excelBridge:load', {
        ref: block.uuid,
        action: 'import',
        headers: data.headers,
      })
    },

    //+-------------------------------------------------
    // setTableData()
    // Sets data from AGgrid to selected table
    // requires payload.body and payload.ref
    // -----
    // Created on Fri May 13 2022
    //+-------------------------------------------------
    setTableData(payload) {
      let block = this.$refs['blck-' + payload.ref][0]?.item?.content_table

      let body = payload.body.map((row) => {
        let _row = []
        for (let key in row) {
          _row.push(row[key])
        }
        return _row
      })

      block.rows = [...body]
      this.$refs['blck-' + payload.ref][0].ui.key++
      // this.ui.refresh = Date.now()
    },

    //+-------------------------------------------------
    // excelExport()
    // Communicates with excelBridge
    // Sends headers, body and export request
    // -----
    // Created on Thu May 12 2022
    //+-------------------------------------------------
    excelExport(block) {
      let data = this.formatForExcel(block)

      $nuxt.$emit('excelBridge:load', {
        ref: block.uuid,
        action: 'export',
        headers: data.headers,
        body: data.body,
      })
    },

    //+-------------------------------------------------
    // getTemplates()
    // Carga los bloques creados para modulo y alcance
    // Y los almacena como plantillas para ser usados
    // -----
    // Created on Mon May 09 2022
    // Updated on Fri Jun 10 2022
    //+-------------------------------------------------
    async getTemplates() {
      let xhr = await this.$store.dispatch('plantillas-bloques/list', {
        modulo: this.modulo,
        alcance: this.alcance,
      })

      if (this.codigo) xhr = xhr.filter((item) => item.codigo == this.codigo)

      if (xhr) this.db.bloques = [...xhr]
    },

    //+-------------------------------------------------
    // getBloques()
    // Carga los bloques asociados al modulo y modulo_ref
    // -----
    // Created on Mon Jul 05 2021
    //+-------------------------------------------------
    async getBloques() {
      const xhr = await this.$store.dispatch('bloques/get', {
        modulo: this.modulo,
        modulo_ref: this.modulo_ref,
      })

      if (xhr) this.db.misBloques = [...xhr]
      this.ui.refresh = Date.now()
    },

    //+-------------------------------------------------
    // getBlocksForUUID()
    // Returns blocks based on uuid template
    // Otherwise return template
    // -----
    // Created on Wed Oct 27 2021
    //+-------------------------------------------------
    getBlocksForUUID(uuid) {
      let mine = this.db.misBloques.filter((block) => block.plantilla?.uuid == uuid)
      if (mine.length > 0) return mine

      return this.db.bloques.filter((block) => block.uuid == uuid)
    },

    updateBlock(key, block) {
      block.isNew = false
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doUpdate({ ...block })
      // this.ui.refresh = Date.now()
      // this.$forceUpdate()
    },

    resetBlock(key, block) {
      block.isNew = true
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doReset(block)

      // const uuid = this.uuid ? this.uuid : this.$route.params.uuid
      // block.modulo_ref = uuid
    },

    exportBlock(key, block) {
      if (this.$refs['blck-' + key]) this.$refs['blck-' + key][0].doExport(block)
    },

    async init() {
      await this.getTemplates()
      await this.getBloques()

      this.ui.loading = false
      this.ui.refresh = Date.now()
      this.ui = { ...this.ui, ...this._ui }

      this.$nuxt.$on('bloques:refresh', (payload) => {
        this.getBloques()
      })

      this.$nuxt.$on('excelBridge:imported', (payload) => {
        this.setTableData(payload)
      })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('bloques:refresh')
    this.$nuxt.$off('excelBridge:imported')
  },
}
</script>
