/**
 * @project: certiapp-nuxt
 * @file:    \store\plantillas-checklist.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Wed Jun 21 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  // Checklist
  async list({ rootState, commit }, alcance_uuid) {
    rootState.loading = true
    const res = await this.$axios.get('plantillas-checklist')
    let listResults = []
    if (alcance_uuid) {
      listResults = res.data.filter((node) => node.alcance.uuid === alcance_uuid)
    } else {
      listResults = res.data
    }
    rootState.loading = false
    if (res) {
      commit('SET_LIST', listResults)
      return listResults
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`plantillas-checklist/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, plantilla) {
    // rootState.loading = true
    const body = {
      uuid: plantilla.uuid,
      titulo: plantilla.titulo,
      alcance: plantilla.alcance.uuid,
      is_revision: plantilla.isRevision,
    }
    await this.$axios.post('plantillas-checklist', body)
    // rootState.loading = false
  },

  async update({ rootState }, plantilla) {
    // rootState.loading = true

    // create an array of uuids from plantilla.preguntas with map
    const preguntas = plantilla.preguntas.map((pregunta) => {
      return pregunta.uuid
    })

    const body = {
      uuid: plantilla.uuid,
      titulo: plantilla.titulo,
      plantilla_checklist: plantilla.uuid,
      is_revision: plantilla.is_revision,
      preguntas: preguntas,
    }

    await this.$axios.patch(`plantillas-checklist/${plantilla.uuid}`, body)
    // rootState.loading = false
  },

  async updateIsRevision({ rootState }, plantilla) {
    rootState.loading = true
    const body = {
      plantilla_checklist: plantilla.uuid,
      is_revision: plantilla.is_revision,
    }
    await this.$axios.patch(`plantillas-checklist/${plantilla.uuid}/update-is-revision`, body)
    rootState.loading = false
  },

  async updateActivo({ rootState }, plantilla) {
    rootState.loading = true
    const body = {
      plantilla_checklist: plantilla.uuid,
      activo: plantilla.activo,
    }
    await this.$axios.patch(`plantillas-checklist/${plantilla.uuid}/update-activo`, body)
    rootState.loading = false
  },

  async delete({ rootState }, plantilla) {
    rootState.loading = true
    await this.$axios.delete(`plantillas-checklist/${plantilla.uuid}`).then((res) => {
      this.commit('notification/show', {
        color: 'success',
        text: 'Checklist eliminada correctamente',
      })
    })
    rootState.loading = false
  },

  async clone({ commit }, plantilla) {
    const res = await this.$axios.get(`plantillas-checklist/${plantilla.uuid}/clonar`)
    if (res) return res.data
  },

  // Preguntas
  async createPregunta({ rootState }, pregunta) {
    // rootState.loading = true
    pregunta.uuid = uuid.v4()
    await this.$axios.post(
      `plantillas-checklist/${pregunta.plantilla_checklist}/preguntas`,
      pregunta
    )
    // rootState.loading = false
  },

  async updatePregunta({ rootState }, params) {
    let loadingDisabled = false
    let pregunta = params
    if (params && params.loadingDisabled) {
      loadingDisabled = true
      pregunta = params.pregunta
    }
    if (!loadingDisabled) rootState.loading = true
    const body = {
      titulo: pregunta.titulo,
      informe: pregunta.informe,
      solo_titulo: pregunta.solo_titulo,
      plantilla_checklist: pregunta.plantilla_checklist,
      plantilla_checklist_pregunta: pregunta.uuid,
      nivel: pregunta.nivel,
      codigo: pregunta.codigo,
      relacion: pregunta.relacion,
      hint: pregunta.hint,
      observaciones: pregunta.observaciones,
      respuesta: pregunta.respuesta,
      bloques: pregunta.bloques,
      requisito_afectado: pregunta.requisito_afectado,
    }

    await this.$axios.patch(
      `plantillas-checklist/${pregunta.plantilla_checklist}/preguntas/${pregunta.uuid}`,
      body
    )
    if (!loadingDisabled) rootState.loading = false
  },

  async deletePregunta({ rootState }, pregunta) {
    rootState.loading = true
    await this.$axios
      .delete(`plantillas-checklist/${pregunta.plantilla_checklist}/preguntas/${pregunta.uuid}`)
      .then((res) => {
        this.commit('notification/show', {
          color: 'success',
          text: 'Pregunta eliminada correctamente',
        })
      })
    rootState.loading = false
  },

  async fusionar({ rootState }, params) {
    rootState.loading = true
    const body = {
      uuid: uuid.v4(),
      titulo: params.titulo,
      alcance: params.alcance,
      checklists: params.checklists,
    }
    await this.$axios.patch(`plantillas-checklist/fusionar`, body)
    rootState.loading = false
  },
}
