/**
 * @project: certiapp-nuxt
 * @file:    store/documentos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 8th July 2021
 * Last Modified: Thu Jan 20 2022
 **/

import {uuid} from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Returns all families
  // -----
  // Created on Tue Jun 01 2021
  //+-------------------------------------------------
  async list({rootState}, params) {
    rootState.loading = true

    // const res = await this.$axios.get(`documentos`)


    let data = { 
      ...params,
    }
   
    const res = await this.$axios.put(`documentos`, data)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // v2 of the create method in gestor_documental
  // -----
  // Created on Thu Jul 15 2021
  // Updated on Tue Sep 21 2021
  //+-------------------------------------------------

  async create({rootState}, params) {
    rootState.loading = true

    let data = new FormData()
    data.append('uuid', params.uuid || uuid.v4())
    data.append('file', params.file)
    data.append('titulo', params.titulo)
    data.append('modulo', params.modulo)
    data.append('modulo_ref', params.modulo_ref)
    data.append('submodulo', params.submodulo)
    data.append('submodulo_ref', params.submodulo_ref)

    // preferencia sobre cliente si se envian los dos
    data.append('contacto', params.contacto)
    data.append('cliente', params.cliente)

    data.append('visible', params.visible ? 1 : 0)
    data.append('autogenerado', params.generated ? 1 : 0)

    const xhr = await this.$axios.post(`documentos`, data)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  // async update({rootState}, params) {
  //   rootState.loading = true
  //   await this.$axios.patch(`metadata/${params.uuid}`, {
  //     metadata: params.uuid,
  //     nombre: params.nombre,
  //     codigo: params.codigo,
  //     descripcion: params.descripcion || '',
  //     datos: params.datos,
  //     metadata_tipo: params.tipo,
  //   })
  //   rootState.loading = false
  // },

  //+-------------------------------------------------
  // toggleVisibility()
  // Created on Thu Apr 29 2021
  //+-------------------------------------------------
  async toggleVisibility({rootState}, params) {
    rootState.loading = true
    const res = await this.$axios.patch(`documentos/${params.uuid}/toggle-visible`, {
      documento: params.uuid,
      visible: params.visible,
    })

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Fri Oct 22 2021
  //+-------------------------------------------------
  async delete({rootState}, item) {
    rootState.loading = true
    await this.$axios.delete(`documentos/${item.uuid}`)
    rootState.loading = false
  },
}
