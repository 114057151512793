<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="tipo in Object.keys(grid)" :key="tipo.id" :href="`#${tipo}`"
        :disabled="tipo === 'convencionales' && !disponeConvencional">
        <span class="text-capitalize">{{ tipo }}</span>
      </v-tab>

      <v-spacer></v-spacer>

      <span class="pt-2" v-if="editable">
        <!-- Importar Excel -->
        <input type="file" ref="inputFile" style="display: none;" @change="importFile($event)" />
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="warning" class="white--text mr-2" v-on="on"
              @click="$refs.inputFile.click()">
              <v-icon>mdi-file-import</v-icon>
            </v-btn>
          </template>
          <span>Importar excel</span>
        </v-tooltip>

        <!-- Añadir Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="success" class="white--text mr-2" v-on="on" @click="dialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Añadir fila</span>
        </v-tooltip>

        <!-- Eliminar Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="error" class="white--text" v-on="on" @click="removeRow()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar fila</span>
        </v-tooltip>
      </span>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tipo in Object.keys(grid)" :key="tipo.id" :value="tipo">
        <v-card outlined>

          <!-- Grid -->
          <ag-datatable :title="grid[tipo].title" :headers="columnDefs[tipo]" :items="grid[tipo].rowData" :height="'65'"
            :rowSelection="'multiple'" hideOverlay emitClickRow :disableClickRow="!editable"
            @click-row="itemSelected = $event, dialog = true" @gridApi="onGridReady($event, tipo)"></ag-datatable>

        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!-- Total -->
    <v-container>
      <v-row class="black--text">
        <v-col cols="4">
          <span class="font-weight-bold">Total Superficie {{ grid['ecologicos'].title }}: </span>
          {{ superficieTotal['ecologicos'] }}
          <div v-for="item in superficieCultivos['ecologicos']" :key="item.id">
            <span class="font-weight-bold">{{ item.nombre }}: </span> {{ item.superficie }}
          </div>
        </v-col>

        <v-col cols="4" v-if="disponeConvencional">
          <span class="font-weight-bold">Total Superficie {{ grid['convencionales'].title }}: </span>
          {{ superficieTotal['convencionales'] }}
          <div v-for="item in superficieCultivos['convencionales']" :key="item.id">
            <s<span class="font-weight-bold">{{ item.nombre }}: </span> {{ item.superficie }}
          </div>
        </v-col>

        <v-col cols="4" v-if="disponeConvencional">
          <span class="font-weight-bold">{{ superficiePE }}</span>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog -->
    <ProduccionVegetalDialog v-model="dialog" :tab="tab" :itemSelected="itemSelected" :cliente="cliente"
      :alcance="alcance" @close="closeDialog()" @add="addRow($event)" @edit="editRow($event)" />

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\solicitudes\ccl\ProductionEcologica\ProduccionVegetal\ProduccionVegetalList.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 24th August 2022
 * Last Modified: Fri Oct 28 2022
 **/

import AgDatatable from '~/components/AgDatatableOld'
import ProduccionVegetalDialog from './ProduccionVegetalDialog'
import * as UTIL from '~/util'
import moment from 'moment'

export default {
  name: 'ProduccionVegetalList',
  props: {
    editable: { type: Boolean, default: false },
    disponeConvencional: { type: Boolean, default: false },
    cultivos: { type: Object, default: () => { } },
    alcance: { type: Object, default: () => { } },
    cliente: { type: Object, default: () => { } },
  },
  components: {
    AgDatatable,
    ProduccionVegetalDialog,
  },
  data: () => ({
    dialog: false,
    tab: 'ecologicos',
    itemSelected: null,
    grid: {
      ecologicos: { title: 'Cultivos Ecólogicos', api: null, rowData: [] },
      convencionales: { title: 'Cultivos Convencionales', api: null, rowData: [] },
    },
    UTIL: UTIL,
  }),
  watch: {
    disponeConvencional(val) {
      if (!val) this.tab = 'ecologicos'
    },
    cultivos() {
      this.init()
    },
  },
  computed: {
    superficieTotal() {
      const total = {
        ecologicos: 0,
        convencionales: 0,
      }
      const ecologicos = this.grid['ecologicos'].rowData
      const convencionales = this.grid['convencionales'].rowData
      if (ecologicos) {
        let superficie = ecologicos.reduce((sum, element) => {
          return sum + (Number(element.superficie_parcela) || 0)
        }, 0)
        total.ecologicos = parseFloat(superficie.toFixed(2))
      }
      if (convencionales) {
        let superficie = convencionales.reduce((sum, element) => {
          return sum + (Number(element.superficie_parcela) || 0)
        }, 0)
        total.convencionales = parseFloat(superficie.toFixed(2))
      }
      return total
    },
    superficiePE() {
      return this.superficieTotal.convencionales > (this.superficieTotal.ecologicos * 50) / 100
        ? 'PE Convencional Supone más del 50%'
        : 'PE Convencional Supone menos del 50%'
    },
    superficieCultivos() {
      const total = {
        ecologicos: [],
        convencionales: [],
      }
      const ecologicos = this.grid['ecologicos'].rowData
      const convencionales = this.grid['convencionales'].rowData
      if (ecologicos) {
        ecologicos.forEach((cultivo) => {
          total.ecologicos.push({
            nombre: cultivo.cultivo_nombre,
            superficie: parseFloat(cultivo.superficie_plantada).toFixed(2),
          })
        })
      }
      if (convencionales) {
        convencionales.forEach((cultivo) => {
          total.convencionales.push({
            nombre: cultivo.cultivo_nombre,
            superficie: parseFloat(cultivo.superficie_plantada).toFixed(2),
          })
        })
      }
      return total
    },
    columnDefs() {
      const headers = [
        {
          headerName: '#',
          sortable: true,
          filter: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          valueGetter: (params) => params.node.rowIndex + 1,
        },
        {
          headerName: 'Código Provincia',
          field: 'provincia_codigo',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Provincia',
          field: 'provincia_nombre',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Código municipio',
          field: 'municipio_codigo',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Municipio',
          field: 'municipio_nombre',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Código Localidad',
          field: 'localidad_codigo',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Paraje',
          field: 'paraje',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'POLG',
          field: 'polg',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Parcel',
          field: 'parcel',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'RC',
          field: 'rc',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Cultivo',
          field: 'cultivo_nombre',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Código Cultivo',
          field: 'cultivo_codigo',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Fecha Alta',
          field: 'fecha_alta',
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            const value = item && item.fecha_alta
            return value ? moment(value).format('DD/MM/YYYY') : ''
          },
        },
        {
          headerName: 'Sup. Parcela',
          field: 'superficie_parcela',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Sup. Plantada',
          field: 'superficie_plantada',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Propiedad',
          field: 'propiedad',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'CALF',
          field: 'calf',
          sortable: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: 'Agregado',
          field: 'agregado',
          sortable: true,
          filter: true,
          resizable: true,
        },
      ]
      return {
        ecologicos: headers,
        convencionales: headers,
      }
    },
  },
  methods: {
    init() {
      this.grid['ecologicos'].rowData = this.cultivos['ecologicos']
      this.grid['convencionales'].rowData = this.cultivos['convencionales']
    },

    onChange() {
      this.itemSelected = null
      const body = {
        dispone_convencional: this.disponeConvencional,
        ecologicos: this.grid['ecologicos'].rowData,
        convencionales: this.grid['convencionales'].rowData,
        superficie_pe: this.superficiePE,
        superficie_total: {
          ecologicos: this.superficieTotal.ecologicos,
          convencionales: this.superficieTotal.convencionales,
        },
        superficie_cultivos: {
          ecologicos: this.superficieCultivos.ecologicos,
          convencionales: this.superficieCultivos.convencionales,
        },
      }
      console.log('onChange -> body', body)
      this.$emit('change', body)
    },
    onGridReady(api, tipo) {
      this.grid[tipo].api = api
      this.grid[tipo].api.addEventListener('cellClicked', this.onCellClick)
    },
    onCellClick(params) {
      params.api.startEditingCell({ rowIndex: params.rowIndex, colKey: params.column.getId() })
    },
    addRow(item) {
      const tipo = this.tab
      this.grid[tipo].rowData.push(item)
      this.grid[tipo].api.updateRowData({ add: [item] })
      this.onChange()
    },
    editRow(item) {
      console.log('item->', item)
      const tipo = this.tab
      const index = this.grid[tipo].rowData.indexOf(this.itemSelected)
      this.grid[tipo].rowData.splice(index, 1, item)
      this.onChange()
    },
    closeDialog() {
      this.itemSelected = null
      this.dialog = false
    },
    removeRow() {
      const tipo = this.tab
      const selectedRows = this.grid[tipo].api.getSelectedRows()
      if (selectedRows && selectedRows.length) {
        selectedRows.forEach((element) => {
          const index = this.grid[tipo].rowData.indexOf(element)
          this.grid[tipo].rowData.splice(index, 1)
        })
      }
      this.grid[tipo].api.updateRowData({ remove: this.grid[tipo].api.getSelectedRows() })
      this.onChange()
    },

    doImport() {
      this.$refs.inputFile.click()
    },

    async importFile(event) {
      const tab = this.tab
      console.log('importFile -> tab', tab)
      // Validations
      const validations = []
      const body = {
        empresa: this.$auth.user.empresa.uuid,
        alcance: this.alcance.uuid,
        codigos: ['cultivos'],
      }
      const res = await this.$store.dispatch('estaticos/list', body)
      if (res && res.length) {
        const obj = {
          header: 'cultivo',
          error: 'no es un cultivo valido.',
          items: res[0].valor.map((e) => e.texto),
        }
        validations.push(obj)
      }
      console.info('validations', validations)
      // Import File
      UTIL.importFile(
        this,
        event,
        this.grid[tab],
        this.columnDefs[tab],
        this.onChange.bind(this),
        validations
      )
      this.$refs.inputFile.value = ''
    },
  },
  created() {
    this.init()
  },
}
</script>

<style></style>
