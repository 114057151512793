<template>

<v-dialog v-model="_ui.quickCreate" max-width="750" scrollable>
      <v-card>
        <v-card-title class="grey lighten-4 py-3 mb-4">
          Nuevo contacto rápido
          <v-spacer></v-spacer>
          <v-btn icon text @click="_ui.quickCreate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row class="pb-1 pt-2 mb-5 align-baseline" style="">
            <v-col cols="2" class="form-label text-right px-0 pr-7">Detalles</v-col>

            <v-col cols="10" md="10" sm="12" class="row ma-0 pa-0">

              <v-col cols="12" class="py-0">
                <v-radio-group
                  v-model="contact.forma_juridica" row class="py-0">
                  <v-radio
                    label="Persona física"
                    value="fisica"
                    class="py-0"
                  ></v-radio>
                  <v-radio
                    label="Entidad jurídica"
                    value="juridica"
                    class="py-0"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" class="pt-0">
                <v-text-field
                  outlined dense
                  hide-details="auto"
                  :label="contact.forma_juridica == 'fisica' || ui.showLastName ? 'Nombre *' : 'Razón social *'"
                  v-model="contact.nombre"
                  @blur="$v.contact.nombre.$touch()"
                  @input="$v.contact.nombre.$touch()"
                  :error="$v.contact.nombre.$error"
                >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator='{ on }'>
                      <v-icon v-on='on' style='cursor: pointer'
                        @click="ui.showLastName = !ui.showLastName"
                        v-show="contact.forma_juridica !== 'fisica'">
                        {{ showLastName ? 'mdi-eye-outline' : 'mdi-eye-remove-outline' }}
                      </v-icon>
                    </template>
                    Mostrar apellidos de contacto
                  </v-tooltip>
                </template>
                </v-text-field>
              </v-col>

              <v-col cols="6" v-show="showLastName">
                <v-text-field
                  outlined dense
                  hide-details="auto"
                  label="Primer apellido"
                  v-model="contact.primer_apellido"
                ></v-text-field>
              </v-col>

              <v-col cols="6" v-show="showLastName">
                <v-text-field
                  outlined dense
                  hide-details="auto"
                  label="Segundo apellido"
                  v-model="contact.segundo_apellido"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined dense
                  hide-details="auto"
                  :label="contact.forma_juridica == 'fisica' ? 'NIF' : 'CIF'"
                  v-model="contact.nif"
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>

          <v-row class="pb-1 pt-2 mb-3 align-baseline" style="">
            <v-col cols="2" class="form-label text-right px-0 pr-7">Contacto</v-col>
            <v-col cols="5">
              <v-text-field
                outlined dense
                hide-details="auto"
                label="Email"
                v-model="contact.email"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                outlined dense
                hide-details="auto"
                label="Teléfono"
                v-model="contact.telefono"
              ></v-text-field>
            </v-col>
          </v-row>


          <v-divider></v-divider>

          <v-row class="pb-1 pt-2 mt-3 align-baseline" style="">
            <v-col cols="2" class="form-label text-right px-0 pr-7">Otros</v-col>

            <v-col cols="10" md="10" sm="12" class="row ma-0 pa-0">

              <v-col cols="12">
                <v-checkbox
                    color="primary"
                    v-model="contact.trabajador_propio"
                    hide-details="auto"
                >
                <template v-slot:label>
                  <div>
                    Marcar como trabajador propio
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on" style="margin-left: 5px; cursor: help">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      Los usuarios marcados como trabajador propio serán agrupadas en un listado específico
                    </v-tooltip>
                  </div>
                </template>
                </v-checkbox>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  outlined dense
                  hide-details="auto"
                  label="Persona de contacto"
                  v-model="contact.persona_de_contacto"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-autocomplete
                  outlined dense small
                  label="Tipo de contacto"
                  hide-details="auto"
                  :items="db.tipos"
                  v-model="contact.tipo"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  outlined dense
                  hide-details="auto"
                  label="Cargo"
                  v-model="contact.cargo"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  outlined dense
                  hide-details="auto"
                  label="Empresa"
                  v-model="contact.nombre_empresa"
                ></v-text-field>
              </v-col>

              <v-col cols="6" v-if="empresa && empresa.codigo == 'ccl'">
                <v-autocomplete
                  outlined dense multiple small
                  label="Intereses del contacto"
                  hide-details="auto"
                  :items="[
                    'VTCYL',
                    'VV',
                    'VTC',
                    'ECO ET',
                    'ECO PV',
                    'VEGANO'
                  ]"
                  nope-item-text="nombre"
                  nope-item-value="nombre"
                  v-model="contact.intereses"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6" v-if="empresa && empresa.codigo == 'ccl'">
                <v-autocomplete
                  outlined dense multiple small
                  label="Tipo de consulta"
                  hide-details="auto"
                  :items="[
                    'Telefónicamente',
                    'Mail info@...',
                    'Chat',
                    'Jornada Eco',
                  ]"
                  nope-item-text="nombre"
                  nope-item-value="nombre"
                  v-model="contact.intereses"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-textarea
                  outlined dense auto-grow
                  rows="3"
                  label="Descripción"
                  hide-details="auto"
                  v-model="contact.descripcion"
                ></v-textarea>
              </v-col>

              <v-col cols="6">
                <v-textarea
                  outlined dense auto-grow
                  rows="3"
                  label="Observaciones"
                  hide-details="auto"
                  v-model="contact.observaciones"
                ></v-textarea>
              </v-col>

            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-btn color="error darken-1" text @click="_ui.quickCreate = false"
            :disabled="ui.loading">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="submit"
          text outlined
          :disabled="ui.loading"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    /pages/personal/quickCreate.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 11th May 2021
 * Last Modified: Wed Jun 08 2022
 **/

import moment from 'moment'
import notifications from '~/util/notificationHelper'

import { uuid } from 'vue-uuid'
import { validationMixin } from 'vuelidate'
import { email, minLength, required, requiredIf, sameAs } from 'vuelidate/lib/validators'

export default {
  props: ['_ui'],
  mixins: [validationMixin],

  validations: {
    contact: {
      nombre: { required },
    },
  },

  data: () => ({
    contact: {
      tipo: 'Potencial',
      forma_juridica: 'fisica',
    },

    db: {
      alcances: [],
      tipos: [
        'Potencial',
        'Comercial',
        'Proveedor',
        'Administrativo',
        'Administraciones',
        'Contrataciones',
        'Otros',
      ],
    },

    ui: {
      loading: false,
      showLastName: false,
    },
  }),

  watch: {
    '_ui.quickCreate': function(value) {
      if (value == false) this.reset()
    },
  },

  computed: {
    empresa() {
      return this.$auth.user.empresa
    },

    showLastName() {
      return this.contact.forma_juridica == 'fisica' || this.ui.showLastName
    },
  },

  methods: {
    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------
    async submit() {
      let step = false

      // 1 . Form validations
      if (this.validate()) step = 'valid'

      // 2. Api requests
      if (step == 'valid') this.store()
    },

    validate() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.warn(this.$v)
        notifications.show({
          text: 'El nombre del contacto es obligatorio',
          color: 'warning',
        })

        return false
      }

      return true
    },

    reset() {
      this.$v.$reset()
      this.contact = {
        tipo: 'Potencial',
        forma_juridica: 'fisica',
      }
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async store() {
      const payload = { ...this.contact }
      payload.uuid = uuid.v4()

      try {
        this.ui.loading = true

        await this.$store.dispatch('contactos/create', payload)
        this.$emit('created', payload)
      } catch (e) {
        console.info(e)
        notifications.show({
          text: 'Ha ocurrido un error guardando los cambios',
          color: 'error',
        })
      } finally {
        this.ui.loading = false
      }
    },

    //+-------------------------------------------------
    // Async API requests
    //+-------------------------------------------------

    async getAlcances() {
      const xhr = await this.$store.dispatch('alcances/list')
      this.db.alcances = xhr
    },
  },

  mounted() {
    this.getAlcances()
    // this.ui = { ...this._ui }
  },
}
</script>

<style>
</style>
