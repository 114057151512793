<template>
  <div>
    <v-card class="mt-5">
      <v-card-title>
        Certificaciones
        <v-spacer></v-spacer>
        <!-- <pre>
          {{ isAmpliacionEnabled }}
          {{ isAmpliacionAvailable }}
          {{ ampliacion }}

          {{ isOtrosEnabled }}
        </pre> -->
        <template v-if="isAmpliacionEnabled && isAmpliacionAvailable">
          <v-btn
            :key="ui.refresh"
            v-if="isCCLVegetal"
            small
            text
            depressed
            color="primary"
            :disabled="disabled"
            @click="doImportGrid">
            Importar en excel
          </v-btn>

          <v-btn
            small
            text
            depressed
            color="primary"
            :disabled="disabled"
            @click="ui.dialog = true">
            Añadir ampliación
          </v-btn>
        </template>
      </v-card-title>
      <v-card-text>
        <!-- <h4 class="mt-1 mb-2">Alcance de producto propio</h4> -->
        <v-data-table
          ref="datatable"
          v-model="table.selected"
          :show-select="false"
          :headers="table.dinamicHeaders"
          :items="items"
          nope-click:row="rowAction"
          :items-per-page="50"
          :hide-default-footer="true"
          sort-by="name"
          loading-text="Cargando, por favor espera"
          :class="{ disabled: ui.loading }"
          style="border: 3px solid #e0e0e0"
          :loading="ui.loading">
          <!-- { text: '', align: 'right', value: 'actions', width: 10, sortable: false }, -->

          <template v-slot:item.data.estado="{ item }">
            <v-chip-group v-if="Array.isArray(item.data.estado)">
              <v-chip small label v-for="(el, i) in item.data.estado" :key="i">
                {{ el }}
              </v-chip>
            </v-chip-group>
            <v-chip v-else small label>{{ item.data.estado }}</v-chip>
          </template>

          <template v-slot:item.data.actividades_realizadas="{ item }">
            <v-chip-group
              v-if="
                Array.isArray(item.data.actividades_realizadas) &&
                item.data.actividades_realizadas[0]
              ">
              <v-chip small label v-for="(el, i) in item.data.actividades_realizadas" :key="i">
                {{ el }}
              </v-chip>
            </v-chip-group>
            <v-chip v-else small label>{{ item.data.actividades_realizadas }}</v-chip>
          </template>

          <template v-slot:item.data.actividades_subcontratadas="{ item }">
            <v-chip-group v-if="Array.isArray(item.data.actividades_subcontratadas)">
              <v-chip small label v-for="(el, i) in item.data.actividades_subcontratadas" :key="i">
                {{ el }}
              </v-chip>
            </v-chip-group>
            <v-chip v-else-if="item.data.actividades_subcontratadas" small label>{{
              item.data.actividades_subcontratadas
            }}</v-chip>
          </template>

          <template v-slot:item.data.producto="{ item }">
            <v-chip-group v-if="Array.isArray(item.data.producto)">
              <v-chip small label v-for="(el, i) in item.data.producto" :key="i">
                {{ el }}
              </v-chip>
              <br
            /></v-chip-group>
            <v-chip v-else-if="item.data.producto" small label>{{ item.data.producto }}</v-chip>
          </template>

          <template v-slot:item.data.racial="{ item }">
            <v-chip-group v-if="Array.isArray(item.data.racial)">
              <v-chip small label v-for="(el, i) in item.data.racial" :key="i">
                {{ el }}
              </v-chip>
            </v-chip-group>
            <v-chip v-else-if="item.data.racial" small label>{{ item.data.racial }}</v-chip>
          </template>

          <template v-slot:item.data.alimentacion="{ item }">
            <v-chip-group v-if="Array.isArray(item.data.alimentacion)">
              <v-chip small label v-for="(el, i) in item.data.alimentacion" :key="i">
                {{ el }}
              </v-chip>
            </v-chip-group>
            <v-chip v-else-if="item.data.alimentacion" small label>{{
              item.data.alimentacion
            }}</v-chip>
          </template>

          <template v-slot:item.data.presentacion="{ item }">
            <v-chip-group v-if="Array.isArray(item.data.presentacion)">
              <v-chip small label v-for="(el, i) in item.data.presentacion" :key="i">
                {{ el }}
              </v-chip>
            </v-chip-group>
            <v-chip v-else-if="item.data.presentacion" small label>{{
              item.data.presentacion
            }}</v-chip>
          </template>

          <template v-slot:item.data.fecha_alta="{ item }">
            <template
              v-if="
                typeof item.data.fecha_alta == 'array' || typeof item.data.fecha_alta == 'object'
              ">
              {{ ui.f.toMoment(item.data.fecha_alta[0], 'LL') }}
            </template>
            <template v-else>
              {{ ui.f.toMoment(item.data.fecha_alta, 'LL') }}
            </template>
          </template>

          <template v-slot:item.actions="{ item }" v-if="isAmpliacionEnabled">
            <template v-if="item.isOffline">
              <v-chip small label> Creado offline </v-chip>
            </template>
            <template v-else>
              <template v-if="isAmpliacionAvailable">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      dense
                      text
                      color="darken-3"
                      @click.stop="rowAction(item)"
                      v-bind="attrs"
                      v-on="on"
                      style="min-width: 30px">
                      <v-icon style="font-size: 20px">mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Modificar</span>
                </v-tooltip>
              </template>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    dense
                    text
                    color="darken-3"
                    @click.stop="
                      ui.confirm = true
                      ui.confirmSecondary = true
                      ui.confirmRemove = item.data
                    "
                    v-bind="attrs"
                    v-on="on"
                    style="min-width: 30px">
                    <v-icon style="font-size: 20px">mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>

        <div v-if="isOtrosEnabled">
          <div class="d-flex mt-7 mb-2">
            <h4 class="mt-2">Otras actividades</h4>
            <v-spacer></v-spacer>
            <template>
              <v-btn
                small
                text
                depressed
                color="primary"
                :disabled="disabled"
                @click="crearOtraActividad">
                Añadir otra actividad
              </v-btn>
            </template>
          </div>

          <v-data-table
            ref="datatable_"
            :headers="[
              ...table.headers,
              { text: 'Actividades', value: 'actividades', sortable: false },
              { text: '', value: 'placeholder', sortable: false },
            ]"
            :items="items_"
            :items-per-page="50"
            :hide-default-footer="true"
            sort-by="name"
            loading-text="Cargando, por favor espera"
            :class="{ disabled: ui.loading }"
            style="border: 3px solid #e0e0e0"
            :loading="ui.loading">
            <template v-slot:item.actividades="{ item }">
              <template
                v-for="(group, g) in [
                  'actividad',
                  'actividades_realizadas',
                  'actividades_maquila',
                  'actividades_alquiladas',
                  'actividades_subcontratadas',
                ]">
                <!-- <v-chip-group v-if="item.data[group]" :key="'group'+g"> -->
                <v-chip small label class="mr-2" v-for="(el, i) in item.data[group]" :key="i">
                  {{ el.replace(/_/g, ' ') }}
                </v-chip>
              </template>
              <!-- </v-chip-group> -->
            </template>

            <template v-slot:item.actions="{ item }">
              <template v-if="item.isOffline">
                <v-chip small label> Creado offline </v-chip>
              </template>
              <template v-else>
                <template v-if="isOtrosEnabled">
                  <v-tooltip bottom>
                    <!-- <template v-slot:activator='{ on, attrs }'>
                      <v-btn
                        small
                        dense
                        text
                        color='darken-3'
                        @click.stop='rowAction_(item)'
                        v-bind='attrs'
                        v-on='on'
                        style='min-width: 30px;'>
                        <v-icon style='font-size: 20px'>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Modificar</span> -->
                  </v-tooltip>
                </template>

                <template v-if="isAmpliacionEnabled && !disabled">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        dense
                        text
                        color="darken-3"
                        @click.stop="
                          ui.confirm = true
                          ui.confirmRemove = item.data
                        "
                        v-bind="attrs"
                        v-on="on"
                        style="min-width: 30px">
                        <v-icon style="font-size: 20px">mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </template>
            </template>
          </v-data-table>
        </div>

        <div style="display: none">
          <ProduccionVegetalList
            v-if="isCCLVegetal"
            ref="importGrid"
            :editable="true"
            :cultivos="{}"
            :alcance="alcance"
            @change="importSites($event)" />
        </div>
      </v-card-text>
    </v-card>

    <!-- Dynamic ampliacion component -->
    <!-- <pre>{{ ampliacion }}</pre> -->
    <component
      v-if="isAmpliacionEnabled && isAmpliacionAvailable"
      v-model="ui.dialog"
      :db="db"
      :is="ampliacion"
      :alcance="alcance"
      :cliente="cliente"
      :planificaciones="planificaciones"
      :parentSites="parentSites"
      :childrenSites="childrenSites"
      :itemSelected="itemSelected"
      :isProductosIbericos="isProductosIbericos"
      :isOperadorResposable="isOperadorResposable"
      :iteracion="auditoria.iteracion"
      @create="create"
      @update="update"
      @submit="submit"
      @add="create"
      @edit="update"
      @close="closeAmpliacion"
      @init="reload">
    </component>

    <!-- Ampliacion "Otros" -->
    <ampliacion-otros
      v-model="ui.dialog_"
      :alcance="alcance"
      :cliente="cliente"
      :planificaciones="planificaciones"
      :parentSites="parentSites"
      :iteracion="auditoria.iteracion"
      :childrenSites="childrenSites"
      :itemSelected="itemSelected"
      :isNew="ui.isNew"
      @create="create"
      @update="update"
      @submit="submit"
      @close="closeAmpliacion"
      @init="reload">
    </ampliacion-otros>

    <!-- Confirmación de eliminar -->
    <Confirmation
      v-model="ui.confirm"
      text="¿Quieres eliminar esta ampliación?"
      textButton="Eliminar de esta auditoría"
      :secondary="true"
      :conditions="true"
      secondaryTextButton="Eliminar completamente"
      @confirm="remove(false)"
      @confirm2="remove(true)">
      <p class="px-5">
        Puedes eliminar esta ampliación completamente (del expediente y las auditorías) o únicamente
        de esta auditoría.
      </p>
    </Confirmation>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    CertificadoSites.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 13th August 2021
 * Last Modified: Fri Jul 14 2023
 **/
import { uuid } from 'vue-uuid'

import Confirmation from '~/components/Confirmation'
import format from '~/util/formatHelper'

import ampliacionOtros from './ampliacionOtros'
import ampliacionCcl from '~/components/solicitudes/ccl/Marcas/AlcanceProductoDialog'
import ampliacionCclVegetal from '~/components/solicitudes/ccl/ProductionEcologica/ProduccionVegetal/ProduccionVegetalDialog'
import ampliacionCclEcologicos from '~/components/solicitudes/ccl/ProductionEcologica/ProductosEcologicos/ProductosEcologicosDialog'

import ampliacionOceglobalEcologicos from '~/components/solicitudes/oce-global/ProductosEcologicos/ProduccionVegetalDialog'

import ampliacionCerticar from '~/components/solicitudes/certicar/iberico/AlcanceProductoDialog.vue'
import ampliacionAraporcei from '~/components/solicitudes/araporcei/AlcanceProducto/AlcanceProductoDialog'
import ampliacionCerticalidad from '~/components/solicitudes/certicalidad/AlcanceProducto/AlcanceProductoDialog'
import ampliacionCerticalidadSAE from '~/components/solicitudes/certicalidad/AlcanceProducto/AlcanceProductoDialogSAE'
import ampliacionCerticalidadETG from '~/components/solicitudes/certicalidad/AlcanceProducto/AlcanceProductoDialogETG'

import ProduccionVegetalList from '~/components/solicitudes/ccl/ProductionEcologica/ProduccionVegetal/ProduccionVegetalList'
import ProductosEcologicosList from '~/components/solicitudes/ccl/ProductionEcologica/ProductosEcologicos/ProductosEcologicosList'

export default {
  name: 'CertificadoSites',
  props: {
    disabled: { type: Boolean, default: false },
    sites: { type: Array, default: () => [] },
    cliente: { type: Object, default: () => {} },
    alcance: { type: Object, default: () => {} },
    auditoria: { type: Object, default: () => {} },
    planificaciones: { type: Array, default: () => [] },
    iteracion: { type: Object, default: () => {} },
  },

  components: {
    // Ampliacion,
    Confirmation,
    ampliacionCcl,
    ampliacionCclVegetal,
    ampliacionCclEcologicos,
    ampliacionOtros,
    ampliacionCerticar,
    ampliacionAraporcei,
    ampliacionCerticalidad,
    ampliacionCerticalidadSAE,
    ampliacionCerticalidadETG,
    ampliacionOceglobalEcologicos,
    ProduccionVegetalList,
    ProductosEcologicosList,
  },

  data: function () {
    return {
      certificadoSites: [],
      itemSelected: {},

      // items que no son de alcance propio
      // Seteado en la computed items
      items_: [],
      offline: [],

      table: {
        selected: [],
        showSelect: false,
        headers: [
          { text: '', value: 'actions', sortable: false, width: 130 },
          {
            text: 'Instalación',
            value: 'instalacion',
            cellClass: 'subData',
            sortable: false,
            width: 130,
          },
          {
            text: 'RGSEAA',
            value: 'rgseaa',
            cellClass: 'subData',
            sortable: false,
            width: 130,
          },
          {
            text: 'Tipo',
            value: 'data.tipo',
            cellClass: 'subData capitalize',
            sortable: false,
            width: 130,
          },
        ],

        dinamicHeaders: [],
      },

      db: {
        propias: ['actividad', 'actividad propia', 'parcela', 'parcelas', 'producto'],
      },

      ui: {
        f: format,
        refresh: 0,

        dialog: false,
        dialog_: false,
        confirm: false,
        confirmRemove: false,
        confirmSecondary: false,
        isNew: false,
      },
    }
  },

  watch: {
    sites() {
      this.init()
    },
  },

  computed: {
    isCoplaca() {
      return this.$auth.user.empresa?.codigo == 'coplaca'
    },

    //+-------------------------------------------------
    // isAmpliacionEnabled()
    // Property used to enable the buttons based on the cliente and alcance
    // Done manually as every client has different needs with own components
    // Returns: Boolean
    // -----
    // Created on Wed Sep 08 2021
    // Created on Tue Sep 20 2022
    //+-------------------------------------------------
    isAmpliacionEnabled() {
      if (this.cliente?.empresa?.codigo == 'ccl') {
        if (this.alcance?.codigo == 'marcas') return true
        if (this.alcance?.codigo == 'produccion_ecologica') return true
      }

      if (this.cliente?.empresa?.codigo == 'coplaca') {
        if (this.alcance?.codigo == 'unegap') return true
      }

      if (this.cliente?.empresa?.codigo == 'araporcei') {
        if (this.alcance?.codigo == 'productos_ibericos') return true
      }

      if (this.cliente?.empresa?.codigo == 'certicar') {
        if (this.alcance?.codigo == 'certicar-iberico') return true
      }

      if (this.cliente?.empresa?.codigo == 'oceglobal') {
        if (this.alcance?.codigo == 'oce-produccion-ecologica') return true
      }

      if (this.cliente?.empresa?.codigo == 'certicalidad') {
        if (this.alcance?.codigo == 'certicalidad_sae') return true
        if (this.alcance?.codigo == 'certicalidad-etg') return true
        if (this.alcance?.codigo == 'certicalidad_productos_ibericos') return true
      }

      return false
    },

    //+-------------------------------------------------
    // isOtrosEnabled()
    // Returns true for a manual list of empresas
    // We set true here if the ampliacionOtros modal has been
    // updated with the list of actividades
    // -----
    // Created on Tue Feb 22 2022
    //+-------------------------------------------------
    isOtrosEnabled() {
      if (this.cliente?.empresa?.codigo == 'araporcei') {
        if (this.alcance?.codigo == 'productos_ibericos') return true
      }

      if (this.cliente?.empresa?.codigo == 'certicalidad') {
        if (this.alcance?.codigo == 'certicalidad_productos_ibericos') return true
        if (this.alcance?.codigo == 'certicalidad-etg') return true
      }

      return false
    },

    //+-------------------------------------------------
    // isAmpliacionAvailable()
    // When isAmpliacionEnabled is true, check if the component is available
    // Used to display the edit button
    // NOTE: CCL has multiple components to edit in the same alcance.
    // -----
    // Created on Tue Jan 18 2022
    // Updated on Wed Jun 01 2022
    //+-------------------------------------------------
    isAmpliacionAvailable() {
      if (!this.isAmpliacionEnabled) return false
      return this.$options.components[this.ampliacion] ? true : false
    },

    ampliacion() {
      let empresa = this.cliente?.empresa?.codigo || 'xx'
      empresa = empresa.charAt(0).toUpperCase() + empresa.slice(1)

      if (empresa == 'Ccl') {
        if (this.alcance.codigo == 'produccion_ecologica') empresa = 'CclVegetal'

        // console.warn(empresa, this.certificadoSites)
        if (
          this.certificadoSites.length &&
          this.certificadoSites[0] &&
          this.certificadoSites[0].subtipo
        ) {
          if (this.certificadoSites[0].subtipo.indexOf('PV') > -1) empresa = 'CclVegetal'
          else empresa = 'CclEcologicos'
        }
      }

      if (this.isCertSAE) empresa = 'CerticalidadSAE'
      if (this.isCertETG) empresa = 'CerticalidadETG'
      if (this.isOceECO) empresa = 'OceglobalEcologicos'

      this.ui.refresh++

      return 'ampliacion' + empresa
    },

    parentSites() {
      return this.sites.filter((e) => !e.parent_uuid)
    },

    childrenSites() {
      let parents = []
      const plan_sites = this.planificaciones.map((e) => e.site)
      const hasChildSites = this.checkIfHasChildSites()

      plan_sites.map((plansite) => {
        this.sites.map((site) => {
          if (site.parent_uuid === undefined) {
            if (hasChildSites == false && plansite.uuid == site.uuid) {
              parents.push(site)
            }
          } else {
            if (site.parent_uuid == plansite.uuid) {
              parents.push(site)
            }
          }
        })
      })
      return parents
    },

    items() {
      let items = []
      let items_ = []

      if (this.certificadoSites.length == 0) return []

      this.certificadoSites.forEach((item) => {
        if (item.tipo.indexOf('instalacion') > -1) return

        let object = {
          data: item,
          uuid: item.uuid,
          name: item.titulo, //.toLowerCase(),
          date: item.created_at || '--',
          instalacion: item.instalacion?.razon_social || '--',
          rgseaa: item.instalacion?.rgseaa || 'Sin rgseaa',
        }

        const propias = this.db.propias
        if (propias.indexOf(item.tipo) > -1) items.push(object)
        else items_.push(object)
      })

      this.offline.forEach((item) => {
        let object = {
          data: item,
          uuid: item.uuid,
          name: item.nombre, //.toLowerCase(),
          instalacion: item.instalacion?.razon_social || '--',
          isOffline: true,
          rgseaa: item.instalacion?.rgseaa || 'Sin rgseaa',
        }

        items.push(object)
      })

      this.items_ = items_
      return items
    },

    isProductosIbericos() {
      return (
        this.alcance.codigo == 'productos_ibericos' ||
        this.alcance.codigo == 'certicalidad_productos_ibericos'
      )
    },

    isCertSAE() {
      return this.alcance.codigo == 'certicalidad_sae'
    },

    isCertETG() {
      return this.alcance.codigo == 'certicalidad-etg'
    },

    isOceECO() {
      return this.alcance.codigo == 'oce-produccion-ecologica'
    },

    isCCLVegetal() {
      return this.$auth.user.empresa.nombre == 'Ccl' && this.ampliacion == 'ampliacionCclVegetal'
    },

    //+-------------------------------------------------
    // isOperadorResposable()
    // Only used in ARAPORCEI
    // always return true because can't know the alcance_proceso
    // -----
    // Created on Wed Dec 01 2021
    //+-------------------------------------------------
    isOperadorResposable() {
      return true
      return this.item.params.alcance_proceso.tipo_operador.includes('responsable')
    },
  },

  methods: {
    crearOtraActividad() {
      this.ui.dialog_ = true
      this.ui.isNew = true
    },

    rowAction(params) {
      this.ui.dialog = true
      this.ui.isNew = false

      const original = this.sites.find((e) => e.uuid == params.uuid)
      this.itemSelected = { ...original }
    },

    //+-------------------------------------------------
    // rowAction_()
    // The same as rowAction for maquila and other type of sites
    // -----
    // Created on Mon Feb 21 2022
    //+-------------------------------------------------
    rowAction_(params) {
      console.log('rowAction_', params)
      this.ui.dialog_ = true
      const original = this.sites.find((e) => e.uuid == params.uuid)
      this.itemSelected = { ...original }
    },

    closeAmpliacion() {
      this.ui.dialog = false
      this.ui.dialog_ = false
      this.ui.isNew = false
      this.itemSelected = null
    },

    mergeSiteDataWithSite() {
      let certificadoSites = []
      let sites = [...this.sites]

      sites.forEach((site, i) => {
        delete site.estado

        if (site.certificado !== true) {
          return
        }

        // comentado porque hay que habilitar el resto de empresas.
        // if (site.tipo !== 'actividad propia') {
        // return
        // }

        site.sites_data.forEach((data) => {
          if (!data.value) {
            return
          }

          if (data.value.indexOf(',') > 0) {
            data.value = data.value.split(',')
          }

          if (typeof site[data.keyname] == 'undefined') {
            site[data.keyname] = data.value
          }

          // if (typeof site[data.keyname] == 'string') {
          //   site[data.keyname] = site[data.keyname]
          // }

          /* if (typeof site[data.keyname] == 'object') {
             if (site[data.keyname].indexOf(data.value) == -1) {
               site[data.keyname].push(data.value)
             }
           } */
        })

        certificadoSites.push(site)
      })

      this.certificadoSites = certificadoSites
    },

    //+-------------------------------------------------
    // getSiteHeaders()
    // previously known as setcolumndefs
    // Gets the first site and extracts attributes
    // and adds them as columns
    // -----
    // Created on Fri Sep 10 2021
    //+-------------------------------------------------
    getSiteHeaders() {
      if (this.certificadoSites.length == 0) return

      let sample = this.certificadoSites.find((e) => this.db.propias.indexOf(e.tipo) > -1)
      // let sample = this.certificadoSites[0]

      let avoid = [
        'data',
        'site_data',
        'sites_data',
        'combinadas',
        'instalacion',
        'instalacion_nombre',
        'instalacion_codigo',
        'metadata',
        'certificado',
        'planificable',
        'manejo',
        'subtipo',
        'source_parent_uuid',
        'source_iteracion_uuid',
        'expediente_uuid',
        'uuid',
        'parent_uuid',
        'iteracion_uuid',
        'tipo',
      ]

      this.table.dinamicHeaders = [...this.table.headers]
      console.warn('sample', sample)
      for (const key in sample) {
        const value = sample[key]
        if (avoid.indexOf(key) > -1) continue

        // console.warn(key, value)
        let name = key.replace(/_/g, ' ')
        let length = name.length * 5

        // let index = this.table.headers.length - 1
        // this.table.headers.splice(index, 0, {

        this.table.dinamicHeaders.push({
          text: name,
          value: 'data.' + key,
          cellClass: 'subData',
          sortable: false,
          width: length,
        })
      }

      if (this.disabled) {
        this.table.dinamicHeaders = this.table.dinamicHeaders.filter((e) => e.value !== 'actions')
      }
    },

    checkIfHasChildSites() {
      for (const site of this.sites) {
        if (site.hasOwnProperty('parent_uuid')) {
          return true
        }
      }
      return false
    },

    sitesObject(siteData) {
      let data = []
      for (const item in siteData) {
        if (item == 'instalacionObject') continue
        data.push({
          key: item,
          value: siteData[item],
        })
      }

      return data
    },

    doImportGrid() {
      this.$refs.importGrid.doImport()
    },

    async importSites(data) {
      if (data && data.ecologicos.length) {
        data.ecologicos.forEach(async (site) => {
          await this.save(site, 'create')
        })
      }
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    submit(item) {
      if (this.itemSelected?.uuid) this.update(item)
      else this.create(item)
    },

    create(item) {
      this.save(item, 'create')
    },

    update(item) {
      this.save(item, 'update')
    },

    async save(item, method = 'create') {
      this.loading = true
      let payload = {}

      if (this.itemSelected) payload = { ...this.itemSelected }
      else payload = { ...item }

      let siteData = this.sitesObject(item)


      if (this.isOceECO) {
        let nombre = siteData.find((e) => e.key == 'nombre')
        if (nombre) {
          siteData = siteData.filter((e) => e.key !== 'nombre')
          siteData.push({ key: 'nombre', value: item.cultivo_nombre || 'Cultivo' })
          item.nombre = item.cultivo_nombre || 'Cultivo'
        }
      }

      payload.data = siteData
      payload.originalData = item

      let inst = this.cliente.instalaciones.find((e) => e.rgseaa == item.instalacion_codigo)
      payload.instalacion = inst
      // console.warn(payload)

      if (!this.itemSelected?.uuid) {
        let site = ''
        let firstSite = this.items[0]

        if (this.alcance.codigo == 'produccion_ecologica') {
          site = this.sites.find(
            (e) => e.instalacion.rgseaa == this.auditoria.instalaciones_data[0].rgseaa
          )
        } else {
          site = this.sites.find((e) =>
            e.instalacion ? e.instalacion.rgseaa == item.instalacion_codigo : false
          )
        }
        payload.uuid = uuid.v4()
        payload.nombre = site?.nombre || item.instalacion_nombre

        // Set in back
        // payload.planificable = site?.planificable || true

        if (this.isCCLVegetal) {
          if (!payload.nombre) payload.nombre = item.nombre_cultivo || 'Cultivo'
          if (!payload.tipo) payload.tipo = 'parcelas'
        }
        if (this.isOceECO) {
          if (!payload.nombre) payload.nombre = item.cultivo_nombre || 'Cultivo'
          if (!payload.tipo) payload.tipo = 'parcela'
        }

        if (this.cliente?.empresa?.codigo == 'certicalidad') {
          if (item.otras_actividades && item.tipo_act) payload.tipo = item.tipo_act
          else if (firstSite?.data?.tipo) payload.tipo = firstSite.data.tipo
          else payload.tipo = 'actividad propia'

          // SET in BACK
          // if (payload.tipo.indexOf('maquila') > -1) payload.planificable = false
        }

        // payload.tipo = item.otras_actividades ? item.tipo_act : firstSite.data.tipo // 'actividad propia' //site?.tipo
        if (!payload.instalacion) payload.instalacion = site?.instalacion || item.instalacionObject
        if (!payload.instalacion)
          payload.instalacion = { uuid: this.cliente.instalaciones[0]?.uuid }

        payload.iteracion_uuid = site?.iteracion_uuid || this.auditoria.iteracion.uuid
        if (item.iteracion_uuid) payload.iteracion_uuid = item.iteracion_uuid

        if (typeof payload.iteracion_uuid == 'object')
          payload.iteracion_uuid = payload.iteracion_uuid[0]

        if (typeof payload.iteracion == 'object') payload.iteracion = payload.iteracion[0]
      }

      console.log('save=>', payload.nombre, payload)

      try {
        if (method === 'create') {
          await this.$store.dispatch('sites/' + method, payload)
          await this.$store.dispatch('auditorias_ampliacion/create', {
            site: payload.uuid,
            auditoria: this.$route.params.uuid,
            instalacion: payload.instalacion,
          })
        } else {
          await this.$store.dispatch('auditorias_ampliacion/update', {
            site: payload.uuid,
            auditoria: this.$route.params.uuid,
            originalData: payload.originalData,
            data: payload.data,
          })
        }

        this.$store.commit('notification/show', {
          text: 'Cambios guardados correctamente',
          color: 'success',
        })

        if (this.$nuxt.isOffline) {
          this.offline.push(payload)
          this.dialog = false
          return
        }

        this.dialog = false
        this.$emit('init')
      } catch (e) {
        console.info(e)
        this.$store.commit('notification/show', {
          text: 'Ha ocurrido un error creando la ampliación',
          color: 'error',
          timeout: 3000,
        })
      } finally {
        this.loading = false
      }
    },

    async remove(permanent = false) {
      this.ui.loading = true
      this.ui.confirm = false

      const xhr = await this.$store.dispatch('auditorias_ampliacion/delete', {
        auditoria: this.auditoria.uuid,
        sites: [this.ui.confirmRemove.uuid],
        delete: permanent,
      })

      this.reload()
    },

    async preloadOffline() {
      if (!$nuxt.$store.getters['offline/caching']) {
        console.warn('offline caching disabled')
        return
      }

      await this.$store.dispatch('tramites/list')
    },

    async init() {
      this.certificadoSites = []
      this.mergeSiteDataWithSite()
      this.getSiteHeaders()
      this.preloadOffline()

      this.ui.loading = false
    },

    reload() {
      this.$emit('init')
      // this.$nuxt.$emit('auditoria:reload')
    },
  },

  created() {
    this.init()
  },
}
</script>

<style>
.v-data-table.disabled {
  opacity: 0.5;
  pointer-events: none;
}

th span {
  white-space: nowrap;
  text-transform: capitalize;
}

.subData {
  color: rgb(146, 146, 146);
  font-size: 12px !important;
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}
</style>
