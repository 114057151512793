<template>
  <v-autocomplete
    ref="cliente"
    label="Cliente"
    v-model="selected"
    :items="items"
    :item-text="text"
    item-value="uuid"
    :required="required"
    :clearable="clearable"
    :disabled="disabled"
    :readonly="readonly"
    :loading="loading"
    :dense="dense"
    :multiple="multiple"
    :rules="rules"
    :hide-details="hideDetails"
    cache-items
    return-object
    @change="emitChange()"
  >
    <template v-slot:item="data" v-if="isDOVino">
      <div style="display: flex;
        justify-content: space-between;
        width: 100%;">
        {{ text(data.item) }}
        <v-chip small label class="ml-3 float-right">
          {{ data.item.type }}
        </v-chip>
      </div>
      <!-- <div class="d-flex justify-space-between">
        <div>
          <div class="font-weight-bold">{{ text(data.item) }}</div>
          <div class="text--secondary">{{ data.item.type }}</div>
        </div>
        <div class="text--secondary">
          {{ data.item.type }}
        </div>
      </div> -->
    </template>
    <template v-slot:append-outer v-if="selected.uuid">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" style="cursor: help">
                        mdi-account-box
                      </v-icon>
                    </template>
                    #{{ selected.codigo }}
                    <strong>{{ selected.nombre_cliente || selected.nombre_completo }}</strong>
                    <template v-if="selected.localidad">
                      <br>
                      {{ selected.localidad }}, {{ selected.provincia }}
                    </template>

                    <div v-if="selected && selected.expedientes && selected.expedientes.length">
                      <br><br>
                      <em>Expedientes:</em>
                      <span>
                        {{ selected.expedientes.join(' - ') }}
                      </span>
                    </div>
                  </v-tooltip>
                </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'SelectClientesObject',
  props: {
    value: [String, Object],
    clearable: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
  },
  async mounted() {
    if (this.required) {
      this.rules = [(v) => !!v || 'Obligatorio']
    }
    this.loading = true
    const res = await this.$store.dispatch('clientes/listActive')
    if (res) this.items = res
    this.loading = false
  },
  data(ctx) {
    return {
      rules: [],
      selected: ctx.value,
      items: [],
      loading: false,
    }
  },
  watch: {
    value: function (val) {
      if (!val || val === '') this.$refs.cliente.reset()
      this.selected = val
    },
  },

  computed: {
    isDOVino() {
      return this.$auth.user.empresa.codigo == 'dovino'
    },
  },
  methods: {
    text(item) {
      if (item.contacto) {
        return `${item.contacto.nombre} ${item.contacto.primer_apellido || ''} ${
          item.contacto.segundo_apellido || ''
        }`
      }

      let nombre = item.nombre_cliente
      // if (item.primer_apellido) nombre += ' ' + item.primer_apellido
      // if (item.segundo_apellido) nombre += ' ' + item.segundo_apellido
      return nombre
    },
    emitChange() {
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
    },
  },
}
</script>

<style scoped>
</style>
