/**
 * @project: certiapp-nuxt
 * @file:    \store\signatures.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 22nd August 2022
 * Last Modified: Fri May 05 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // latest()
  // Returns latest items for the user
  // -----
  // Created on Mon Aug 22 2022
  //+-------------------------------------------------
  async latest({ rootState }, userUUID) {
    rootState.loading = true

    const xhr = await this.$axios.get(`firmas/` + userUUID)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // list()
  // Returns all items
  // -----
  // Created on Mon Sep 20 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true

    const xhr = await this.$axios.get(`shares`)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // create()
  // -----
  // Created on Mon Aug 22 2022
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    let data = new FormData()
    data.append('uuid', params.uuid || uuid.v4())
    data.append('file', params.file)
    data.append('base64', params.base64)
    data.append('created_by', params.created_by)

    data.append('modulo', params.modulo)
    data.append('modulo_ref', params.modulo_ref)
    data.append('submodulo', params.submodulo)
    data.append('submodulo_ref', params.submodulo_ref)

    const xhr = await this.$axios.post(`firmas`, data)

    rootState.loading = false
    if (xhr) return xhr.data
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Wed Sep 22 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(`shares/${params.uuid}`, {
      elemento_compartido: params.uuid,
      ...params,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  // async delete({rootState}, item) {
  //   rootState.loading = true
  //   await this.$axios.delete(`shares/${item.uuid}`)
  //   rootState.loading = false
  // },
}
