<template>
  <div>

    <v-row>
      <v-col cols="12">
        <ag-datatable :title="grid.title" :headers="grid.columnDefs" :items="grid.rowData" :height="'65'" hideOverlay
          disableClickRow @gridApi="onGridReady($event)" :sizeColumnsToFit="true" :pagination="false" :hideSearch="true">
          <!-- Añadir Fila -->
          <template slot="title">
            <v-tooltip right v-if="editable">
              <template v-slot:activator="{ on }">
                <v-btn x-small fab depressed color="success" class="white--text mx-2" v-on="on" @click="dialog = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Añadir</span>
            </v-tooltip>
          </template>
        </ag-datatable>
      </v-col>





      <v-col cols="12" v-for="(instalacion, index) in items" :key="instalacion.rgseaa">
        <v-spacer></v-spacer>
        <v-card-title>

          <v-col cols="6">
            <strong>RAZÓN SOCIAL INSTALACIÓN: {{instalacion.razon_social}}</strong>
          </v-col>
          <v-col cols="3">
            <v-text-field type="number" label="Nº LINEAS POR INSTALACION" hint="Indicar la cantidad"
              @change="UpdateInstalacionDetalles(index, $event)" min="0" max="20"
              v-model="instalacion.quantity" persistent-hint dense>
            </v-text-field>
          </v-col>

        </v-card-title>
        <v-card-text>
          <ag-datatable :headers="grid.columnDefsDetalles"
            :items="instalacion.especies" :height="'40'" hideOverlay
            disableClickRow @gridApi="onGridInstalacionesReady($event, index)" :hideSearch="true"
            :showSelect="false" :pagination="false" :sizeColumnsToFit="true">
          </ag-datatable>
          <v-spacer></v-spacer>
        </v-card-text>

      </v-col>
    </v-row>

    <!-- Dialog Alcance del Producto -->
    <instalacion-dialog v-model="dialog" :cliente="cliente" @close="dialog = false" @submit="processItem($event)" />

  </div>
</template>

<script>
  import AgDatatable from "~/components/AgDatatable"
  import InstalacionDialog from './InstalacionDialog'
  import MODELS from "~/models/certicalidad/solicitudes"

  export default {
    name: "InstalacionesList",
    props: {
      editable: {
        type: Boolean,
        default: false
      },
      cliente: {
        type: Object,
        default: null
      },
      items: {
        type: Array,
        default: () => []
      },
    },
    components: {
      AgDatatable,
      InstalacionDialog
    },
    data: () => ({
      dialog: false,
      grid: {
        title: 'Instalaciones a certificar',
        api: null,
        rowData: [],
        columnDefs: []
      }
    }),
    computed: {
      loading: {
        get() {
          return this.$store.state.loading
        },
        set(val) {
          this.$store.commit('LOADING', val)
        }
      }
    },
    methods: {
      onChange() {
        this.$emit('change', this.grid.rowData)
      },
      // Grid
      onGridReady(api, tipo) {
        this.grid.api = api
      },
      onGridInstalacionesReady(api, tipo) {
        this.items[tipo].api = api;
      },
      addRow(item) {
        this.grid.rowData.push(item)
        this.grid.api.updateRowData({
          add: [item]
        })
        this.onChange()
      },
      processItem(item) {
        this.loading = true
        item.instalacion.especies = [];
        this.items.push(item.instalacion)
        this.loading = false

        this.onChange()
      },
      UpdateInstalacionDetalles(idInstalacion, quantity){
        this.items[idInstalacion].especies.forEach(element => {
          this.items[idInstalacion].api.updateRowData({
            remove: [element]
          })
        });
        this.items[idInstalacion].especies = [];
        for (let index = 1; index <= quantity; index++) {
          var itemToAdd = {especie: 'ESPECIE '+index , especie_lineas: 'LINEAS '+index, especie_pienso:'TAMAÑO '+index};
          this.items[idInstalacion].especies.push(itemToAdd)
          this.items[idInstalacion].api.updateRowData({add: [itemToAdd]})
       }

       this.onChange()
      },
      UpdateInstalaciones(instalaciones) {
      },
      init() {
        const vm = this
        this.grid.rowData = this.items
        this.grid.columnDefs = [{
            headerName: "RGSEAA",
            field: "rgseaa",
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          },
          {
            headerName: "Razón Social",
            field: "razon_social",
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            width: 400
          },
          {
            headerName: "Dirección",
            field: "direccion",
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            width: 400
          },
          {
            headerName: "Comunidad",
            field: "comunidad.nombre",
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
          }
        ]

        if (this.editable) this.grid.columnDefs.push({
        headerName: "Acciones", sortable: false, resizable: true,
        cellRendererFramework: 'DeleteRenderer',
        cellRendererParams: {
          text: "¿Quitar la instalación seleccionada?",
          init: this.onChange.bind(this),
          confirm: true
        }})

        this.grid.columnDefsDetalles = [{
            headerName: "ESPECIE",
            field: "especie",
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            width: 450
          },
          {
            headerName: "Nº LINEAS POR ESPECIE",
            field: "especie_lineas",
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            width: 250
          },
          {
            headerName: "TM/AÑO DE PIENSO PRODUCIDO POR ESPECIE",
            field: "especie_pienso",
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            width: 450
          },
        ]
      }
    },
    created() {
      this.init()
    }
  }
</script>
