<template>
  <v-autocomplete
      ref="select"
      :label="label"
      v-model="selected"
      :items="items"
      item-text="nombre"
      item-value="uuid"
      :rules="rules"
      :required="required"
      :disabled="disabled"
      :outlined="outlined"
      :hide-details="hideDetails"
      :dense="dense"
      :filled="filled"
      :error="error"
      :error-messages="errorMessages"
      no-data-text="Listado vacío"
      return-object
      @change="emitChange()"
  >
    <template v-slot:item="data">
      <v-chip class="ma-2">{{ data.item.codigo }}</v-chip>
      {{ data.item.nombre }}
    </template>
  </v-autocomplete>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    components/forms/selectMetadata.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 9th June 2021
 * Last Modified: Mon Mar 21 2022
 **/

export default {
  name: 'selectMetadata',
  components: {},

  props: {
    value: [String, Object],
    tipo: { type: String, default: '' },
    familia: { type: String, default: '' },

    label: { type: String, default: 'Registros' },
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessages: { type: String, default: '' },
    hideDetails: { type: String, default: 'auto' },
    codigo: [Number, String],
  },

  data: (ctx) => ({
    selected: ctx.value,

    db: {
      metadata: [],
    },
  }),

  computed: {
    rules() {
      return this.required ? [(v) => !!v || 'Obligatorio'] : []
    },

    items() {
      return this.db.metadata
    },
  },

  watch: {
    value(val) {
      if (!val && this.$refs.select) this.$refs.select.resetValidation()
      this.selected = val
    },

    codigo(val) {
      if (this.codigo) this.setValueByCodigo(this.codigo)
    },
  },

  methods: {
    //+-------------------------------------------------
    // setValueByCodigo()
    // Useful method to be called by parent component
    // update value in this
    // -----
    // Created on Mon Mar 21 2022
    //+-------------------------------------------------
    setValueByCodigo(codigo) {
      const selected = this.items.find((e) => e.codigo == codigo)
      this.selected = { ...selected }
      this.$emit('input', selected)
    },

    emitChange() {
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
    },

    async getMetadata() {
      const xhr = await this.$store.dispatch('metadata/getFamilyTipo', {
        tipo: this.tipo,
        familia: this.familia,
      })
      this.db.metadata = xhr
    },

    async init() {
      await this.getMetadata()
      if (this.codigo) this.setValueByCodigo(this.codigo)
    },
  },

  created() {
    this.init()
  },
}
</script>
