import { uuid } from 'vue-uuid'

export const state = () => ({
  list: [],
  can_list: false,
  can_create: false,
  can_edit: false,
  can_delete: false,
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  SET_PERMISOS(state, permisos) {
    state.can_list = permisos.can_list ?? false
    state.can_create = permisos.can_create ?? false
    state.can_edit = permisos.can_edit ?? false
    state.can_delete = permisos.can_delete ?? false
  },
}

//dispatch actions
export const actions = {
  //+-------------------------------------------------
  // roles()
  // Get roles
  // -----
  // Created on Fri Jan 27 2023
  //+-------------------------------------------------
  async roles({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.get(`roles`)

    rootState.loading = false
    if (res) return res.data
  },

  async list({ rootState, commit }) {
    rootState.loading = true
    const res = await this.$axios.get('permisos')
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },

  // async get({ rootState }, uuid) {
  //   rootState.loading = true
  //   const res = await this.$axios.get(`permisos/${uuid}`)
  //   rootState.loading = false
  //   if (res) return res.data
  // },

  //+-------------------------------------------------
  // user()
  // Gets a single role from user uuid
  // -----
  // Created on Fri Jan 27 2023
  //+-------------------------------------------------¡
  async user({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.get(`permisos/${params.user}`)

    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, params) {
    rootState.loading = true
    if (params.submodulo && params.submodulo.length) {
      for (let i = 0; i < params.submodulo.length; i++) {
        const submodulo = params.submodulo[i]
        const body = {
          ...params,
          uuid: uuid.v4(),
        }
        body.submodulo = submodulo
        await this.$axios.post('permisos', body)
      }
    } else {
      const body = {
        ...params,
        uuid: uuid.v4(),
      }
      delete body.submodulo
      await this.$axios.post('permisos', body)
    }
    rootState.loading = false
  },

  async update({ rootState, dispatch }, params) {
    rootState.loading = true
    console.log('update -> params', params)
    const body = {
      ...params,
    }
    console.log('update -> body', body)
    await this.$axios.patch(`permisos/${params.uuid}`, body)
    rootState.loading = false
  },

  async delete({ rootState }, params) {
    rootState.loading = true
    await this.$axios.delete(`permisos/${params.uuid}`)
    rootState.loading = false
  },
}
