/**
 * @project: certiapp-nuxt
 * @file:    localidades.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 4th June 2021
 * Last Modified: Tue Sep 14 2021
 **/

import {uuid} from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Returns all families
  // -----
  // Created on Tue Jun 01 2021
  //+-------------------------------------------------
  async list({rootState}, params) {
    rootState.loading = true

    const res = await this.$axios.get(`localidades`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // payload: uuid, nombre, codigo, municipio
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  async create({rootState}, params) {
    rootState.loading = true

    await this.$axios.post(`localidades`, {
      uuid: params.uuid || uuid.v4(),
      nombre: params.nombre,
      codigo: params.codigo || '',
      municipio: params.municipio.uuid,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // customList()
  // Retorna localidades por empresa y adicionalmente
  // recibe el parametro provincia, para el filtrado
  //+-------------------------------------------------
  async customlist({rootState}, provincia) {
    rootState.loading = true

    const res = await this.$axios.put(`localidades`, {
      provincia: provincia,
    })

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Tue Sep 14 2021
  //+-------------------------------------------------
  async delete({rootState}, item) {
    rootState.loading = true
    await this.$axios.delete(`localidades/${item.uuid}`)
    rootState.loading = false
  },
}
