export const state = () => ({
  list: [],
  item: {},
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return this.list
  },
  SET_LIST(state, items) {
    this.list = items
  },
}

//dispatch actions
export const actions = {
  //+-------------------------------------------------
  // current()
  // -----
  // Created on Mon May 24 2021
  //+-------------------------------------------------
  async current({ rootState }) {
    rootState.loading = true
    const res = await this.$axios.get(`users/current`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // get()
  // Created on Mon May 24 2021
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`users/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  async update({ rootState }, user) {
    // console.log('[User-update]', cliente)
    rootState.loading = true
    let activo = false
    if (user.activo !== null) activo = user.activo
    await this.$axios.patch(`users/${user.uuid}`, {
      activo: activo,
      username: user.username,
      email: user.email,
      user: user.uuid,
    })
    rootState.loading = false
  },

  //+-------------------------------------------------
  // changePassword()
  // Changes an user password
  // (Replaces clientes/changepassword)
  // -----
  // Created on Thu May 27 2021
  //+-------------------------------------------------
  async changePassword({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(`users/${params.user}/change-password`, {
      user: params.user,
      password: params.password,
    })

    rootState.loading = false
  },

  async checkUsername({ rootState }, username) {
    rootState.loading = true
    const res = await this.$axios.put(`users/check-username`, {
      username: username,
    })
    rootState.loading = false
    if (res) return res.data
  },

  async checkEmail({ rootState }, email) {
    rootState.loading = true
    const res = await this.$axios.put(`users/check-email`, {
      email: email,
    })
    rootState.loading = false
    if (res) return res.data
  },
}
