<template>
  <div>

    <!-- HEADER -->
    <header-component noAction v-if="!expediente"/>

    <!-- LISTADO -->
    <v-row>
      <v-col cols="12">
        <ag-datatable
          title="Listado"
          :headers="headers"
          :items="items"
          :disableClickRow="!!expediente"
          :emitClickRow="true"
          :rowSelection="'multiple'"
          @click-row="clickRow"
          @gridApi="onGridReady($event)"
        >
          <comunicaciones-externas
            v-if="expediente"
            @generarTabla="generarTabla($event)"
            @onAddRow="onAddRow($event)"
            @onRemoveSelected="onRemoveSelected()"
            @save="save($event)"
          />
        </ag-datatable>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import HeaderComponent from "~/components/HeaderComponent"
import AgDatatable from "~/components/AgDatatable"
import Estado from "~/models/estados"
import moment from 'moment'
import ComunicacionesExternas from '~/components/comunicacionesExternas/ComunicacionesExternas'

export default {
  props: ['expediente', 'iteracionSelected'],
  components: {
    HeaderComponent,
    AgDatatable,
    ComunicacionesExternas
  },
  async beforeMount() {
    await this.initHeaders()
  },
  async mounted() {
    await this.init()
  },
  created() {
    if (!this.expediente) this.$store.commit("menu/setPageTitle", 'Comunicaciones externas')
  },
  data: () => ({
    gridApi: null,
    item: {},
    items: [],
    CERRADA: Estado.AUDITORIA_ESTADO.CERRADA,
    headers: [],
    defaultHeaders: [
      {headerName: "Exportado", field: "exportado", enableRowGroup: true, sortable: true, filter: true, resizable: true,
      cellRendererFramework: 'SwitchRenderer',
      cellRendererParams: {
        label: ' '
      }
      },
      {headerName: "Expediente", field: "expediente.codigo", enableRowGroup: true, sortable: true, filter: true,  resizable: true, },
      {headerName: "Cliente", field: "cliente.nombre_completo", enableRowGroup: true, sortable: true, filter: true, resizable: true, },
      {headerName: "Alcance", field: "alcance.nombre", enableRowGroup: true, sortable: true, filter: true, resizable: true, },
      {headerName: "Grupo", field: "grupo", enableRowGroup: true, sortable: true, filter: true, resizable: true, },
      {headerName: "Tipo", field: "tipo", enableRowGroup: true, sortable: true, filter: true, resizable: true, },
      {
        headerName: "Fecha de envio", field: "fecha_envio", sortable: true, filter: "agDateColumnFilter", resizable: true,
        valueGetter: (params) => {
          const item = params.data
          const value = item && item.fecha_envio
          return value ? moment(value).format('DD/MM/YYYY') : ''
        }
      },
      // {headerName: "Comentarios", field: "comentarios", enableRowGroup: true, sortable: true, filter: true},
    ]
  }),
  computed: {
    revisiones() {
      if (!this.items) return this.items
      else {
        return this.items.filter(auditoria => {
          return auditoria.estado === this.CERRADA
        });
      }
    }
  },
  methods: {
    async init () {
      const res = await this.$store.dispatch('comunicaciones_externas/list', this.expediente)
      if (res) this.items = res
    },
    initHeaders () {
      this.headers = []
      const headers = JSON.parse(JSON.stringify(this.defaultHeaders))
      headers.push({
        headerName: "Acciones", sortable: false,
        width: 300,
        cellRendererFramework: 'ComunicacionesExternasRenderer',
        cellRendererParams: {
          reload: this.init.bind(this),
        }
      })
      this.updateHeaders(headers)
    },
    onGridReady (event) {
      this.gridApi = event
      this.gridApi.addEventListener('cellClicked', this.onCellClick)
    },
    clickRow (data) {
      const empresa = this.$auth.user.empresa.codigo
      const grupo_alcance = data.grupo.toLowerCase()
      this.$store.commit('expedientes/SET_TAB', 'comunicaciones')
      this.$router.push(`/expedientes/${empresa}/${grupo_alcance}/${data.expediente.uuid}`)
    },
    // Edición de celdas al pinchar
    onCellClick(params){
      params.api.startEditingCell({ rowIndex: params.rowIndex, colKey: params.column.getId() })
    },
    async onAddRow() {
      let newItem = {}
      let headers = []
      this.headers.forEach(element => headers.push(element.field))
      for (const key of headers) {
        newItem[key] = ''
      }
      this.items.push(newItem)
      this.gridApi.updateRowData({add: [newItem]})
    },
    onRemoveSelected() {
      const selectedRows = this.gridApi.getSelectedRows()
      if (selectedRows && selectedRows.length > 0) {
        selectedRows.forEach(element => {
          let index = this.items.indexOf(element)
          this.items.splice(index, 1)
        })
      }
      this.gridApi.updateRowData({remove: this.gridApi.getSelectedRows()})
    },
    async generarTabla(tipo) {
      const vm = this
      const columnDefs = []
      const body = {
        cliente: this.expediente.cliente.uuid,
        alcance: this.expediente.alcance.uuid,
        iteracion: this.iteracionSelected.uuid
      }
      const res = await this.$store.dispatch(`comunicaciones_externas/generar${tipo}`, body)
      if (res) {
        const rows = res.rows
        const headers = res.columDef
        headers.forEach(columna => {
          columnDefs.push({
            headerName: vm.capitalizeFirstLetter(columna),
            field: columna,
            enableRowGroup: true,
            editable: true,
            resizable: true,
            filter: true
          })
        });
        this.items = rows
        this.updateHeaders(columnDefs)
        this.onAddRow()
      }
    },
    updateHeaders (headers) {
      this.headers = headers
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit()
        this.gridApi.refreshCells()
      }
    },
    capitalizeFirstLetter(string) {
      if (string) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      else return ''
    },
    async save (payload) {
      const data = []
      this.gridApi.forEachNode( function(rowNode, index) {
        data.push(rowNode.data)
      })
      const body = {
        cliente: this.expediente.cliente.uuid,
        iteracion: this.iteracionSelected.uuid,
        expediente: this.expediente.uuid,
        alcance: this.expediente.alcance.uuid,
        grupo: this.expediente.grupo_alcance.codigo,
        data: data,
        tipo: payload.tipo,
        comentarios: payload.comentarios,
        fichero: 'none',
      }
      await this.$store.dispatch('comunicaciones_externas/create', body)
      await this.initHeaders()
      await this.init()
      this.gridApi.sizeColumnsToFit()
    },
  }
};
</script>
