<template>
  <v-autocomplete
    ref="cliente"
    label="Cliente"
    v-model="selected"
    :items="items"
    :item-text="text"
    item-value="uuid"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :hide-details="hideDetails"
    :dense="dense"
    :outlined="outlined"
    :multiple="multiple"
    @change="$emit('input', selected)"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'SelectClientes',
  props: {
    value: [String, Array],
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
  },
  created() {
    this.init()
  },
  data(ctx) {
    return {
      selected: ctx.value,
      items: [],
    }
  },
  computed: {
    rules() {
      if (this.required) {
        return this.multiple
          ? [(v) => (!!v && !!v.length) || 'Obligatorio']
          : [(v) => !!v || 'Obligatorio']
      } else return []
    },
  },
  methods: {
    text(item) {
      if(item.nombre_cliente) return item.nombre_cliente
      let text = ''
      if (item.codigo) text += item.codigo + ' - '
      if (item.nombre) text += ' ' + item.nombre
      if (item.primer_apellido) text += ' ' + item.primer_apellido
      if (item.segundo_apellido) text += ' ' + item.segundo_apellido
      return text
    },
    async init() {
      const res = await this.$store.dispatch('clientes/listActive')
      if (res) this.items = res
      console.info(this.items)
    },
  },
  watch: {
    value(val) {
      if (!val || val === '') this.$refs.cliente.reset()
      this.selected = val
    },
  },
}
</script>
