<template>
  <v-select
    ref="select"
    :label="label"
    v-model="item"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :required="required"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :chips="chips"
    :small-chips="smallChips"
    :dense="dense"
    :outlined="outlined"
    :multiple="multiple"
    :clearable="clearable"
    :hide-details="hideDetails"
    @click.stop="() => {}"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  ></v-select>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\forms\SelectEstaticos.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue Dec 27 2022
 **/

export default {
  name: 'SelectEstaticos',
  props: {
    value: [String, Array],
    label: { type: String, default: 'Seleccionar opción' },
    alcance: { type: String, default: '' },
    codigo: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    estaticos: { type: Array, default: null },
    outlined: { type: Boolean, default: false },
    hideDetails: { type: String, default: 'auto' },
    itemText: { type: String, default: 'texto' },
    itemValue: { type: String, default: 'texto' },
  },
  created() {
    this.init()
  },

  data(ctx) {
    return {
      item: ctx.value,
      items: [],

      db: [],
    }
  },
  watch: {
    value(val) {
      if (!val || val === '') this.$refs.select.reset()
      // this.item = val
    },
    offlineMode(val) {
      if (val) this.init()
    },
  },

  computed: {
    rules() {
      if (this.required) {
        return this.multiple
          ? [(v) => (!!v && !!v.length) || 'Obligatorio']
          : [(v) => !!v || 'Obligatorio']
      } else return []
    },
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },

    selectedItem() {
      return this.items.find((e) => e[this.itemValue] == this.item)
    },
  },

  methods: {
    //+-------------------------------------------------
    // function()
    // Tries to figure out if the given value is a valid option
    // As sometimes back gives wrong text strings instead of select values
    // -----
    // Created on Thu Jun 30 2022
    //+-------------------------------------------------
    // IMPORTANT: Disabled because it gives wrong results on tabbed interfaces
    // setValue() {
    //   if (!this.items.length > 0) return
    //   if (!this.value || this.value == '') return

    //   const item = this.items.find((item) => {
    //     return item.texto === this.value || item.valor === this.value
    //   })
    //   console.warn('eeey', this.item, this.value)
    //   if (item) this.item = item.valor
    //   this.value = this.valor
    // },

    //+-------------------------------------------------
    // setValueByCodigo()
    // Useful method to be called by parent component
    // update value in this
    // -----
    // Created on Fri Jul 15 2022
    // Updated on Fri Jul 29 2022
    //+-------------------------------------------------
    setValueByCodigo(codigo) {
      if (typeof codigo !== 'string') return
      let field = this.itemValue
      const select = this.items.find((e) => e[field] == codigo)

      if (select) {
        this.item = select[field]
        this.$emit('input', select[field])
      }
    },

    async getEstaticos() {
      const body = {
        empresa: this.$auth.user.empresa.uuid,
        codigos: [this.codigo],
      }
      if (this.alcance) body.alcance = this.alcance
      let jxr = await this.$store.dispatch('estaticos/list', body)
      if (jxr) this.db = jxr
    },

    async init() {
      let element = null

      if (this.offlineMode) {
        const storage = JSON.parse(localStorage.getItem('estaticos'))
        element = storage && storage[this.codigo]
      } else {
        if (!this.estaticos && this.codigo) {
          await this.getEstaticos()
        }

        if (this.estaticos) {
          // console.log('estaticos', this.estaticos)
          this.db = this.estaticos && this.estaticos[0] ? this.estaticos : []
        }

        if (this.db.length > 0) {
          const estaticos = this.db.reduce((obj, element) => {
            obj[element.codigo] = element
            return obj
          }, {})
          element = estaticos[this.codigo]
        }

        //console.warn(element)

        if (element) {
          this.items = element.valor
        }

        // console.warn(this.items)
        this.items.sort((elem1, elem2) => ((elem1?.texto || '') > (elem2?.texto || '') ? 1 : -1))
        if (this.value) this.setValueByCodigo(this.value)
      }
    },
  },

  mounted() {
    this.init()
    // this.setValue()
  },
}
</script>
