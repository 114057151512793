/**
 * @project: certiapp-nuxt
 * @file:    validatorHelper.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 27th May 2021
 * Last Modified: Mon Jan 24 2022
 **/

import notifications from './notificationHelper'

export default {
  //+-------------------------------------------------
  // errorMessage()
  // Returns a message for the appropiate rule
  // -----
  // Created on Thu Jun 10 2021
  //+-------------------------------------------------
  errorMessage(rules, messages) {
    if (rules.$error == false) return ''

    for (const key in rules) {
      let rule = rules[key]
      let message = messages[key]
      console.warn('Ha ocurrido un error validando', key, rule, message)
      if (rule == false) return message ? message : 'Hay un error con este campo'
    }

    return ''
  },

  //+-------------------------------------------------
  // email()
  // Basic check for email validation
  // -----
  // Created on Wed Aug 25 2021
  //+-------------------------------------------------
  email(value) {
    if (value == '' || value == undefined) return false

    let ats = (value.match(/@/g) || []).length
    let comma = (value.match(/,/g) || []).length

    if (ats != 1) return false
    if (comma > 0) return false

    return value.indexOf('@') >= 0 && value.indexOf('.') >= 0
  },

  //+-------------------------------------------------
  // testEmail()
  // Tests the given email againts the ddbb
  // Returns true or false.
  // True is that the item already exists
  // -----
  // Created on Thu May 27 2021
  //+-------------------------------------------------
  async testEmail(value, original = null) {
    if (value == undefined || value == '') return false
    if (value == original) return false

    const res = await $nuxt.$store.dispatch('users/checkEmail', value)

    if (res && res.response === 'ko') return false
    if (res && res.response === 'ok') return true

    // if (this.originalEmail == this.user.email) this.ui.emailExists = false
  },

  //+-------------------------------------------------
  // testUsername()
  // Test the param username with the DDBB
  // Returns true or false
  // True is that the item already exists
  // -----
  // Created on Mon Oct 25 2021
  //+-------------------------------------------------
  async testUsername(value, original = null) {
    if (value == undefined || value == '') return false
    if (value == original) return false

    const res = await $nuxt.$store.dispatch('users/checkUsername', value)

    if (res && res.response === 'ko') return false
    if (res && res.response === 'ok') return true
  },

  //+-------------------------------------------------
  // validate()
  // Given a $vm instance, runs vuelidate
  // and displays an error if there is invalid fields
  // -----
  // Created on Thu May 27 2021
  //+-------------------------------------------------
  validate($vm, text) {
    $vm.$v.$touch()

    if ($vm.$v.$invalid) {
      console.warn($vm.$v)

      text =
        text !== undefined
          ? text
          : 'Debes revisar el formulario antes de poder enviarlo. ' +
            'Todos los campos son obligatorios'

      notifications.show({
        text: text,
        color: 'warning',
      })

      return false
    }

    return true
  },

  //+-------------------------------------------------
  // checkEmptyFields()
  // For data, check if any field in fields is empty
  // Returns object if there are empty fields, otherwise, return false
  // -----
  // Created on Mon Jan 24 2022
  //+-------------------------------------------------
  checkEmptyFields(data, keys, fields) {
    // console.warn('checkEmptyFields', data, fields)
    // console.warn(JSON.stringify(data))

    for (const key of keys) {
      // check each key (instalacion, actividad, etc)
      if (data[key] && data[key].length > 0) {
        // if the key has data
        for (const row of data[key]) {
          // console.warn('row', row)
          // check each row, for example, two rows in maquila
          for (const field of fields) {
            // check each field and look if it exists
            // console.warn(key, field, row[field])
            if (row[field] == '') {
              return {
                key,
                field,
              }
            }
          }
        }
      }
    }

    return false
  },
}
