<template>
  <div>
    <loading v-model="$store.getters.loading" text="Espere por favor..." />

    <floating-button-menu v-if="!isAprobada" :valid="valid" text="¿Desea crear la solicitud?" textButton="crear"
      @submit="checkValidationAndConfirm()"></floating-button-menu>

    <v-form ref="form" lazy-validation v-model="valid">
      <!-- Datos de solicitud -->
      <v-row align="center">
        <v-col cols="12">
          <v-card flat>
            <v-card-title>
              Datos de la solicitud
              <v-spacer></v-spacer>
              <!-- APROBAR -->
              <v-btn v-if="auth.canApprove && !isNew && !isAprobada" color="success" class="white--text" small depressed
                @click="confirmAprobar = true">
                Aprobar Solicitud
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row align="center">
                <v-col :cols="!isPortal ? '4' : '6'">
                  <SelectAlcancesObject v-model="item.solicitudes[0].alcance" required dense
                    :disabled="!isNew || isAprobada" @change="onChangeAlcance" hide-details="auto" />
                </v-col>

                <v-col v-if="!isPortal" :cols="!isPortal ? '4' : '6'">
                  <SelectClientesObject dense required v-model="item.cliente" hide-details="auto"
                    :disabled="!isNew || !item.solicitudes[0].alcance || isAprobada" @change="onChangeCliente" />
                </v-col>

                <v-col :cols="!isPortal ? '4' : '6'">
                  <SelectTipoSolicitud v-model="item.params.tipo_solicitud" :is-new="isNew"
                    :custom-items="tiposSolicitud" :alcance="item.solicitudes[0].alcance" :cliente="item.cliente"
                    :disabled="!isNew || Object.keys(item.cliente).length === 0 || isAprobada" @modificacion="setSites"
                    hide-details="auto" />
                </v-col>
              </v-row>

              <v-row v-if="item.solicitudes[0].alcance && item.cliente.uuid">
                <v-col cols="12" class="pb-0">
                  <v-dialog ref="dialog" v-model="ui.picker" :return-value.sync="item.fecha" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="grey--text text-caption" style="font-size: 13px !important">
                        Solicitud con fecha de
                        <strong class="pl-1 pr-3">{{
                          $moment(item.fecha).format('D [de] MMMM, YYYY')
                        }}</strong>
                        <v-btn depressed small v-bind="attrs" v-on="on">
                          Cambiar
                          <!-- <v-icon small class="pl-2">
                            mdi-comment-edit-outline
                          </v-icon> -->
                        </v-btn>
                      </div>
                    </template>
                    <v-date-picker v-model="item.fecha" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="ui.picker = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(item.fecha)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <template v-if="item.params.tipo_solicitud && item.cliente.uuid">
        <v-row align="center">
          <!-- Modificación de Alcance -->
          <v-col cols="12" v-if="isModificacion">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-select label="Tipo de modificación" v-model="item.params.tipo_modificacion"
                      :items="tiposModificacion" :rules="rules" required dense></v-select>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field label="Producto" v-model="item.params.producto" hint="Indicar que producto"
                      persistent-hint dense></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field label="Proceso/Actividad" v-model="item.params.proceso_actividad"
                      hint="Indicar actividad y la instalación" persistent-hint
                      :rules="item.params.producto ? rules : []" :required="item.params.producto" dense></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field label="Operador Subcontratado Certificado" v-model="item.params.operador_certificado"
                      hint="Indicar Actividad/Producto/EC" persistent-hint dense></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field label="Operador Subcontratado No Certificado"
                      v-model="item.params.operador_no_certificado" hint="Indicar Actividad/Producto/EC" persistent-hint
                      dense></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field label="Marca Comercial" v-model="item.params.marca_comercial" hint="Indicar productos"
                      persistent-hint dense></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field label="Otros motivos" v-model="item.params.otros_motivos"
                      hint="Describir otros motivos por la que solicita la modificación de alcance" persistent-hint
                      dense>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea label="Observaciones" v-model="item.params.observaciones" outlined
                      auto-grow></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card flat>
              <v-card-title>Datos relativos al alcance de la producción</v-card-title>
              <v-card-text>
                <template v-if="isProductosIbericos">
                  <v-checkbox v-model="item.params.certificacion_amparada" :disabled="isAprobada" hide-details="auto"
                    label="Se solicita que toda la producción este amparada por la certificación de Certicalidad"></v-checkbox>
                  <v-checkbox v-model="item.params.certificacion_excluida" :disabled="isAprobada" hide-details="auto"
                    label="Se solicita que se excluya parte de la certificación porque está bajo el control de la Entidad de Certificación del propietario del producto según lo dispuesto en el artículo 14.3 del RD 4/2014. (Solo para el caso de operadores que prestan servicio a maquila)"></v-checkbox>
                  <v-checkbox v-model="item.params.certificacion_operador_suspendido" :disabled="isAprobada"
                    hide-details="auto"
                    label="Operador afectado por una Suspensión Temporal o Retirada Definitiva de la Certificación."></v-checkbox>
                  <v-checkbox v-model="item.params.comercializar_productos_etiquetados" :disabled="isAprobada"
                    hide-details="auto" label="Va a comercializar productos etiquetados"></v-checkbox>
                  <v-text-field v-if="item.params.comercializar_productos_etiquetados"
                    label="Especifica las marcas comerciales a continuación"
                    v-model="item.params.comercializar_productos_etiquetados_desc" dense outlined class="ml-6 mt-3"
                    style="max-width: 450px" :disabled="isAprobada"></v-text-field>

                  <v-checkbox v-model="item.params.instalaciones_incluidas" :disabled="isAprobada" hide-details="auto"
                    label="Posee otras instalaciones incluidas en el alcance"></v-checkbox>
                  <v-text-field v-if="item.params.instalaciones_incluidas"
                    label="Indica el registro sanitario de las mismas"
                    v-model="item.params.instalaciones_incluidas_desc" dense outlined class="ml-6 mt-3" style="max-width: 450px"
                    :disabled="isAprobada"></v-text-field>
                </template>

                <template v-if="isSAE">
                  <v-checkbox v-model="item.params.certificacion_exterior" :disabled="isAprobada" hide-details="auto"
                    label="¿Está o ha estado previamente certificado por otra entidad de certificación?"></v-checkbox>
                  <v-text-field v-if="item.params.certificacion_exterior" label="Indique cuál"
                    v-model="item.params.certificacion_exterior_desc" dense outlined style="max-width: 450px" class="ml-6 mt-3"
                    :disabled="isAprobada"></v-text-field>

                  <v-checkbox v-model="item.params.combinar_auditorias" :disabled="isAprobada" hide-details="auto"
                    label="¿Desea el operador combinar las auditorías con otros esquemas de certificación de producto conforme a la norma EN-UNE ISO/IEC 17065?"></v-checkbox>
                  <v-text-field v-if="item.params.combinar_auditorias" label="Indique cuál"
                    v-model="item.params.combinar_auditorias_desc" dense outlined style="max-width: 450px" class="ml-6 mt-3"
                    :disabled="isAprobada"></v-text-field>

                  <v-checkbox v-model="item.params.auto_precintado" :disabled="isAprobada" hide-details="auto"
                    label="¿Va a realizar gestiones de autoprecintado?"></v-checkbox>
                  <v-text-field v-if="item.params.auto_precintado"
                    label="En caso afirmativo indique si se realizara  en su propio establecimiento o en otros (sera necesario facilitar razon socil y domicilio del establecimiento externo)"
                    v-model="item.params.establecimiento_precintado" dense outlined style="max-width: 100%" class="ml-6 mt-3"
                    :disabled="isAprobada"></v-text-field>

                  <v-checkbox v-model="item.params.instalaciones_incluidas" :disabled="isAprobada" hide-details="auto"
                    label="Posee otras instalaciones incluidas en el alcance"></v-checkbox>
                  <v-text-field v-if="item.params.instalaciones_incluidas"
                    label="Indica el registro sanitario de las mismas"
                    v-model="item.params.instalaciones_incluidas_desc" dense outlined style="max-width: 450px"
                    class="ml-6 mt-3" :disabled="isAprobada"></v-text-field>
                </template>

                <template v-if="isETG">
                  <v-checkbox v-model="item.params.curado_porciones_loncheado" hide-details="auto"
                    :disabled="isAprobada"
                    label="Se comercializará producto curado en porciones o loncheado"></v-checkbox>
                  <v-text-field v-if="item.params.curado_porciones_loncheado"
                    label="Especificar los diferentes formatos de venta"
                    v-model="item.params.curado_porciones_loncheado_desc" dense outlined class="ml-6 mt-3"
                    style="max-width: 450px" :disabled="isAprobada"></v-text-field>

                  <v-checkbox v-model="item.params.certificacion_jamon" hide-details="auto" :disabled="isAprobada"
                    label="Posee el operador alguna certificación de su sistema de calidad que incluya la elaboración de Jamón Serrano"></v-checkbox>
                  <v-text-field v-if="item.params.certificacion_jamon"
                    label="Especificar alcance de la certificación y entidad concesionaria"
                    v-model="item.params.certificacion_jamon_desc" dense outlined class="ml-6 mt-3"
                    style="max-width: 450px" :disabled="isAprobada"></v-text-field>
                  <v-checkbox v-model="item.params.comercializar_productos_etiquetados" :disabled="isAprobada"
                    hide-details="auto" label="Va a comercializar productos etiquetados"></v-checkbox>
                  <v-text-field v-if="item.params.comercializar_productos_etiquetados"
                    label="Especifica las marcas comerciales a continuación"
                    v-model="item.params.comercializar_productos_etiquetados_desc" dense outlined class="ml-6 mt-3"
                    style="max-width: 450px" :disabled="isAprobada"></v-text-field>

                  <v-checkbox v-model="item.params.instalaciones_incluidas" :disabled="isAprobada" hide-details="auto"
                    label="Posee otras instalaciones incluidas en el alcance"></v-checkbox>
                  <v-text-field v-if="item.params.instalaciones_incluidas"
                    label="Indica el registro sanitario de las mismas"
                    v-model="item.params.instalaciones_incluidas_desc" dense outlined style="max-width: 450px"
                    class="ml-6 mt-3" :disabled="isAprobada"></v-text-field>

                  <v-checkbox v-model="item.params.certificacion_previa" :disabled="isAprobada"
                    label="Está o ha estado previamente certificado por otra entidad de certificación"></v-checkbox>
                  <v-text-field v-if="item.params.certificacion_previa"
                    label="Especifica si su certificación ha sido suspendida o retirada por dicha entidad"
                    v-model="item.params.certificacion_previa_desc" dense outlined class="ml-6 mt-3"
                    style="max-width: 450px" :disabled="isAprobada"></v-text-field>
                </template>
              </v-card-text>
            </v-card>
          </v-col>



          <!-- Alcance de Proceso -->
          <v-col cols="12">
            <v-card flat>
              <v-card-title>
                Alcance de Proceso (Actividades)
                <v-spacer></v-spacer>
                <v-switch :label="item.params.alcance_proceso.operador_certificado
                  ? 'Operador Certificado'
                  : 'Operador No Certificado'
                  " v-model="item.params.alcance_proceso.operador_certificado" :disabled="isAprobada"></v-switch>
              </v-card-title>
              <v-card-text>
                <v-row class="black--text">
                  <v-col cols="3">
                    <v-select label="Tipo de operador" v-model="item.params.alcance_proceso.tipo_operador"
                      :items="tiposOperadores" :rules="rulesMultiple" multiple required dense
                      :disabled="isAprobada"></v-select>
                  </v-col>
                  <!-- Alcance del Producto -->
                  <v-col cols="12" v-if="isOperadorResposable">
                    <v-card outlined>
                      <AlcanceProductoList :solicitud="item" :cliente="item.cliente"
                        :isOperadorResposable="isOperadorResposable" :items="item.params.alcance_producto"
                        :editable="!isAprobada" :db="db" @change="updateProductoList($event)" />
                      <!--                      <pre>{{item.params.alcance_producto}}</pre>-->
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Volumen de Producción -->
          <v-col cols="12" v-if="isProductosIbericos || isETG">
            <v-card flat>
              <v-card-title>
                Volumen de Producción Anual (Último año completo)
                <v-spacer></v-spacer>
                <v-text-field label="Año" v-model="item.params.volumen_produccion.fecha" dense class="shrink pt-2"
                  :disabled="isAprobada"></v-text-field>
              </v-card-title>
              <v-card-text :key="'volumen-' + ui.refresh">
                <v-row class="black--text" v-for="produccion in tiposProduccion" :key="produccion.id">
                  <v-col cols="12" class="text-capitalize font-weight-bold">
                    Producción {{ produccion }}
                  </v-col>

                  <template v-if="isProductosIbericos">
                    <v-col cols="4">
                      <v-text-field label="Nº Animales Sacrificados"
                        v-model="item.params.volumen_produccion[produccion].animales_sacrificados" dense
                        :disabled="isAprobada" :readonly="produccion === 'propia'"></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field label="Nº Canales Despiezadas"
                        v-model="item.params.volumen_produccion[produccion].canales_despiezadas" dense
                        :disabled="isAprobada" :readonly="produccion === 'propia'"></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field label="Nº de jamones"
                        v-model="item.params.volumen_produccion[produccion].numero_jamones" persistent-hint dense
                        :disabled="isAprobada" :readonly="produccion === 'propia'"></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field label="Nº de paletas"
                        v-model="item.params.volumen_produccion[produccion].numero_paletas" persistent-hint dense
                        :disabled="isAprobada" :readonly="produccion === 'propia'"></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field label="Nº de cañas de lomo"
                        v-model="item.params.volumen_produccion[produccion].numero_canas_lomo" persistent-hint dense
                        :disabled="isAprobada" :readonly="produccion === 'propia'"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        label="Kilos de productos obtenidos del despiece de la canal que se comercializan en fresco"
                        v-model="item.params.volumen_produccion[produccion].carne_comercializada" dense
                        :disabled="isAprobada" :readonly="produccion === 'propia'"></v-text-field>
                    </v-col>
                  </template>

                  <template v-if="isETG">
                    <v-col cols="4">
                      <v-text-field label="Jamones Serranos elaborados en las instalaciones"
                        v-model="item.params.volumen_produccion[produccion].jamones_elaborados" persistent-hint dense
                        :disabled="isAprobada" :readonly="produccion === 'propia'"></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        label="Jamones Serranos adquiridos a terceros y comercializados en las instalaciones"
                        v-model="item.params.volumen_produccion[produccion].jamones_adquiridos" persistent-hint dense
                        :disabled="isAprobada" :readonly="produccion === 'propia'"></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field label="Total Jamones Serranos"
                        v-model="item.params.volumen_produccion[produccion].total_jamones" persistent-hint dense
                        :disabled="isAprobada" :readonly="produccion === 'propia'"></v-text-field>
                    </v-col>
                  </template>
                </v-row>

                <!-- Totales -->
                <v-row class="black--text">
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <template v-if="isProductosIbericos">
                    <v-col cols="4">
                      <span class="font-weight-bold">Total Animales Sacrificados:</span>
                      {{ volumenProduccionTotal.animales_sacrificados }}
                    </v-col>

                    <v-col cols="4">
                      <span class="font-weight-bold">Total Canales Despiezadas:</span>
                      {{ volumenProduccionTotal.canales_despiezadas }}
                    </v-col>

                    <v-col cols="4">
                      <span class="font-weight-bold">Total Número Jamones:</span>
                      {{ volumenProduccionTotal.numero_jamones }}
                    </v-col>

                    <v-col cols="4">
                      <span class="font-weight-bold">Total Número Paletas:</span>
                      {{ volumenProduccionTotal.numero_paletas }}
                    </v-col>

                    <v-col cols="4">
                      <span class="font-weight-bold">Total Cañas de Lomo:</span>
                      {{ volumenProduccionTotal.numero_canas_lomo }}
                    </v-col>

                    <v-col cols="4">
                      <span class="font-weight-bold">Total Kg Carne Comercializada:</span>
                      {{ volumenProduccionTotal.carne_comercializada }}
                    </v-col>
                  </template>

                  <template v-if="isETG">
                    <v-col cols="4">
                      <span class="font-weight-bold">Total Jamones Serranos elaborados en las instalaciones</span>
                      {{ volumenProduccionTotal.jamones_elaborados }}
                    </v-col>

                    <v-col cols="4">
                      <span class="font-weight-bold">Total Jamones Serranos adquiridos a terceros y comercializados en
                        las
                        instalaciones</span>
                      {{ volumenProduccionTotal.jamones_adquiridos }}
                    </v-col>

                    <v-col cols="4">
                      <span class="font-weight-bold">Total Jamones Serranos</span>
                      {{ volumenProduccionTotal.total_jamones }}
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>


          <!-- Actividades del Proceso -->
          <v-col cols="12">
            <v-card flat>
              <v-card-title>
                Actividades del Proceso (Instalaciones)
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-tabs v-model="tab">
                  <template v-for="tipo in Object.keys(grid)">
                    <v-tab v-if="grid[tipo].show" :href="`#${tipo}`">
                      <span class="text-capitalize">{{ tipo }}</span>
                    </v-tab>
                  </template>

                  <v-spacer></v-spacer>

                  <span class="pt-2" v-if="!isAprobada">
                    <!-- Añadir Fila -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn x-small fab depressed color="success" class="white--text mr-2" v-on="on"
                          @click="addRow()">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Añadir fila</span>
                    </v-tooltip>

                    <!-- Eliminar Fila -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn x-small fab depressed color="error" class="white--text" v-on="on" @click="removeRow()">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar fila</span>
                    </v-tooltip>
                  </span>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="tipo in Object.keys(grid)" :key="tipo.id" :value="tipo">
                    <v-card v-if="grid[tipo].show" outlined>
                      <ag-datatable :title="grid[tipo].title" :headers="columnDefs[tipo]"
                        :items="item.params.actividades_proceso[tipo]" :height="'65'" :rowSelection="'multiple'"
                        hideOverlay disableClickRow @gridApi="onGridReady($event, grid, tipo)"></ag-datatable>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-form>

    <!-- Confirmación Cambio de Estado -->
    <Confirmation v-model="confirmAprobar" text="¿Desea aprobar la solicitud?" textButton="Aceptar"
      @confirm="aprobarSolicitud()" />

    <!-- Confirmación Crear Solicitud -->
    <Confirmation v-model="confirm" text="¿Desea crear la solicitud?" textButton="crear" @confirm="submit()">
      <v-card-text style="height: 70vh; overflow: auto">
        <!--
          Mismas condiciones
          https://trello.com/c/lanYHRJd
        -->
        <ConditionsPI v-if="isProductosIbericos" />
        <ConditionsPI v-if="isETG" />
        <ConditionsPI v-if="isSAE" />
      </v-card-text>
    </Confirmation>
  </div>
</template>

<style>
.ag-theme-material .ag-icon-checkbox-checked {
  color: #2e3444 !important;
}
</style>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    index.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: xxx
 * Last Modified: Fri Jun 30 2023
 **/

import { uuid } from 'vue-uuid'
import validator from '~/util/validatorHelper'
import notifications from '~/util/notificationHelper'

import AgDatatable from '~/components/AgDatatableOld'
import Loading from '~/components/Loading'
import Confirmation from '~/components/Confirmation'
import FloatingButtonMenu from '~/components/FloatingButtonMenu'
import SelectGruposAlcancesObject from '~/components/forms/SelectGruposAlcancesObject'
import SelectAlcancesObject from '~/components/forms/SelectAlcancesObject'
import SelectClientesObject from '~/components/forms/SelectClientesObject'
import SelectActividades from '~/components/forms/SelectActividades'
import SelectInstalaciones from '~/components/forms/SelectInstalaciones'
import SelectTipoSolicitud from '../SelectTipoSolicitud'
import AlcanceProductoList from './AlcanceProducto/AlcanceProductoList'
import ConditionsPI from './ConditionsPI'
import ConditionsETG from './ConditionsETG'
import ESTADOS from '~/models/estados'
import MODELS from '~/models/certicalidad/solicitudes'
import SAE_CATEGORIAS from '~/models/certicalidad/categoriasProductos'

import ProductosEcologicosList from './ProductosEcologicos/ProductosEcologicosList'
import ProductoresList from './Productores/ProductoresList'
import InstalacionesList from './Instalaciones/InstalacionesList'
import RichTextEditor from '~/components/RichTextEditor'

export default {
  props: {
    uuid: { type: String, default: null },
    isPortal: { type: Boolean, default: false },
  },
  components: {
    RichTextEditor,
    SelectGruposAlcancesObject,
    SelectAlcancesObject,
    AgDatatable,
    Loading,
    Confirmation,
    FloatingButtonMenu,
    SelectClientesObject,
    SelectActividades,
    SelectInstalaciones,
    AlcanceProductoList,
    ProductosEcologicosList,
    ProductoresList,
    ConditionsPI,
    ConditionsETG,
    InstalacionesList,
    SelectTipoSolicitud,
  },
  data: () => ({
    tab: 'subcontratadas',
    fab: false,
    component: 0,
    valid: false,
    loading: false,
    dialogInstalaciones: false,
    dialogAlcanceProducto: false,
    confirm: false,
    confirmAprobar: false,
    rules: [(v) => !!v || 'Obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'Obligatorio'],
    item: {
      uuid: uuid.v4(),
      fecha: new Date().toISOString().substr(0, 10),
      grupo_alcance: {},
      solicitudes: [
        {
          alcance: {},
        },
      ],
      cliente: {},
      params: {
        certificacion_amparada: false,
        certificacion_excluida: false,
        certificacion_operador_suspendido: false,

        certificacion_exterior: false,
        combinar_auditorias: false,

        alcance_proceso: {
          tipo_operador: [],
        },

        alcance_producto: [],
        volumen_produccion: {
          propia: {
            numero_jamones: 0,
            numero_paletas: 0,
            numero_canas_lomo: 0,
            canales_despiezadas: 0,
            carne_comercializada: 0,
            animales_sacrificados: 0,

            jamones_elaborados: 0,
            jamones_adquiridos: 0,
            total_jamones: 0,
          },

          maquila: {
            numero_jamones: 0,
            numero_paletas: 0,
            numero_canas_lomo: 0,
            canales_despiezadas: 0,
            carne_comercializada: 0,
            animales_sacrificados: 0,

            jamones_elaborados: 0,
            jamones_adquiridos: 0,
            total_jamones: 0,
          },
          total_volumen: {},
        },
        actividades_proceso: {
          subcontratadas: [],
          // subcontratadas_certificadas: [],
          // subcontratadas_no_certificadas: [],
          alquiladas: [],
          maquila: [],
          proveedores: [],
        },

        // Eliminado en limpieza 25/07
        // proveedores: {
        //   productos: [],
        //   servicios: [],
        //   maquila: [],
        // },

        // Eliminado en limpieza 25/07
        // globalgap: {
        //   instalaciones: [],
        //   ganaderia: {
        //     dispone_convencional: false,
        //     ecologicos: [],
        //     convencionales: [],
        //   },
        //   custodiaGrid: {
        //     dispone_convencional: false,
        //     ecologicos: [],
        //     convencionales: [],
        //   },
        //   cultivos: [],
        // },
      },
    },
    tiposModificacion: MODELS.MODIFICACION_TIPOS,
    tiposCertificacion: MODELS.CERTIFICACION_TIPOS,
    tiposPresentacionETG: MODELS.PRESENTACION_TIPOS_ETG,
    presentacionComercialETG: [
      { text: 'Producto con etiqueta', value: 'producto_con_etiqueta' },
      { text: 'Producto sin etiqueta', value: 'producto_sin_etiqueta' },
    ],
    tiposSolicitud: [
      { text: 'Inicio de Actividad', value: 'inicial' },
      { text: 'Continuación de actividad', value: 'continuacion' },
      { text: 'Modificación de la Solicitud Inicial', value: 'modificacion' },
      { text: 'Inicial tras una retirada', value: 'retirada' },
      { text: 'Procedente de otra EC', value: 'ec' },
    ],

    // Eliminado en limpieza 25/07
    // actividades_proveedores: [
    //   'Elaboracion - Salado',
    //   'Elaboracion - Post-Salado',
    //   'Elaboracion - Secado-Maduracion',
    //   'Elaboracion - Envejecimiento-Afinamiento',
    //   'Almacenamiento',
    //   'Transformacion',
    // ],

    // Volumen Produccion Anual
    tiposProduccion: MODELS.PRODUCCION_TIPOS,

    grid: {
      subcontratadas: {
        api: null,
        title: 'Instalaciones de las Actividades Subcontratadas',
        show: true,
      },

      alquiladas: {
        api: null,
        title: 'Instalaciones Alquiladas a Incluir en el Alcance de Certificación',
        show: true,
      },

      maquila: {
        api: null,
        title: 'Prestador de servicios (a maquila)',
        show: false,
      },

      proveedores: {
        api: null,
        title: 'Instalaciones de las Actividades de proveedores ',
        show: false,
      },
    },

    auth: {},

    db: {
      actividades: {},

      SAE_categorias: SAE_CATEGORIAS.ARRAY,
      SAE_lineas: {
        'CARNE': ['Canales, carnes frescas, despojos y preparados de carne'],
        'PRODUCTOS CÁRNICOS': [
          'Productos cárnicos sin tratamiento térmico',
          'Productos cárnicos con tratamiento térmico',
        ],
        'PRODUCTOS DE LA PESCA': ['No preparados', 'Preparados'],
        'PRODUCTOS DE LA PESCA TRANSFORMADOS': [
          'Pescado, con o sin otros ingredientes, cocidos esterilizados, pasteurizados , en salazón y otros tratamientos',
        ],
        'PRODUCTOS LÁCTEOS': [
          'Leche y nata sin concentrar, y batidos y otras bebidas a base de leche',
          'Leche y nata concentradas y otros productos lácteos en polvo',
          'Leche y nata cuajadas, Yogur y demás leches, Natas fermenadas y postres lácteos',
          'Lactosuero y productos constituidos por componentes naturales de la leche',
          'Mantequilla y demás materias grasas de la leche',
          'Quesos y Requesón',
        ],
        'HUEVOS Y OVOPRODUCTOS': ['Huevo', 'Huevo líquido', 'Ovoproductos'],
        'PRODUCTOS COMPUESTOS': [
          'Productos alimenticios destinados al consumo humano que contengan productos transformados de origen animal y productos de origen vegetal',
        ],
        'OTROS PRODUCTOS DE ORIGEN ANIMAL DESTINADOS AL CONSUMO HUMANO': [
          'Miel',
          'Hemoderivados',
          'Gelatina',
          'Colágenos',
          'Otros',
        ],
      },
    },

    ui: {
      picker: false,
      refresh: 0,
    },
  }),

  computed: {
    tiposOperadores() {
      if (this.isETG) return MODELS.OPERADORES_TIPOS
      if (this.isProductosIbericos) return MODELS.OPERADORES_TIPOS
      if (this.isSAE) return MODELS.OPERADORES_TIPOS.filter((item) => item.value !== 'prestador')
    },

    actividadesProcesoFiltered() {
      return {
        certificadas: this.item.params.actividades_proceso.subcontratadas.filter(
          (actividad) => actividad.certificada === 'SI'
        ),
        no_certificada: this.item.params.actividades_proceso.subcontratadas.filter(
          (actividad) => actividad.certificada === 'NO'
        ),
      }
    },

    isNew() {
      return this.$route.params.uuid || this.uuid ? false : true
    },

    isMobile() {
      return this.$device.isTablet || this.$device.isMobile
    },

    isAprobada() {
      // Quick ugly hack to disable edit on
      if (!this.auth.canEdit) return true
      return this.item.estado === ESTADOS.APROBADA
    },

    // Eliminado en limpieza 25/07
    // isGanaderia() {
    //   return (
    //     this.item.params.globalgap.ganaderia_cattle ||
    //     this.item.params.globalgap.ganaderia_cyb ||
    //     this.item.params.globalgap.ganaderia_leche ||
    //     this.item.params.globalgap.ganaderia_pollo ||
    //     this.item.params.globalgap.ganaderia_ovino ||
    //     this.item.params.globalgap.ganaderia_cerdo
    //   )
    // },

    isModificacion() {
      if (
        this.item.params.alcance_producto.length == 0
        // &&
        // this.item.params.tipo_solicitud !== 'modificacion'
      ) {
        this.item.params.alcance_producto = []
      }
      // if (this.item.params.tipo_solicitud === 'modificacion') {
      //   this.item.params.alcance_producto = []
      //   // this.item.params.alcance_producto = ''
      // }

      return this.item.params.tipo_solicitud === 'modificacion'
    },

    isProductosIbericos() {
      return this.item.solicitudes[0].alcance.codigo == 'certicalidad_productos_ibericos'
    },

    isSAE() {
      return this.item.solicitudes[0].alcance.codigo == 'certicalidad_sae'
    },

    isETG() {
      return this.item.solicitudes[0].alcance.codigo == 'certicalidad-etg'
    },

    isOperadorResposable() {
      return this.item.params.alcance_proceso.tipo_operador.includes('responsable')
    },

    isOperadorMaquila() {
      return this.item.params.alcance_proceso.tipo_operador.includes('prestador')
    },

    //+-------------------------------------------------
    // volumenProduccionTotal()
    // -----
    // Updated on Mon Dec 19 2022
    //+-------------------------------------------------
    volumenProduccionTotal() {
      const total = {}
      let tiposProduccion = []
      const volumen_produccion = this.item.params.volumen_produccion

      tiposProduccion = this.tiposProduccion

      for (let i = 0; i < tiposProduccion.length; i++) {
        const tipo = tiposProduccion[i]
        const produccion = volumen_produccion[tipo]
        const keys = Object.keys(produccion)
        console.warn(tipo, produccion, keys)
        for (let k = 0; k < keys.length; k++) {
          const key = keys[k]
          const value = Number(produccion[key])
          if (!total[key]) total[key] = 0
          if (value) total[key] += value
        }
      }
      return total
    },

    columnDefs() {
      let headers = {
        subcontratadas: [
          {
            headerName: '#',
            sortable: true,
            filter: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            valueGetter: (params) => params.node.rowIndex + 1,
          },
          {
            headerName: 'Instalacion',
            field: 'instalacion',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            cellEditor: 'SelectCellEditor',
            cellEditorParams: {
              items: this.getInstalaciones('subcontratada'),
              item_text: this.nombreInstalacion,
              item_value: this.nombreInstalacion, // 'razon_social',
              callback: this.onSelectedInstalacion,
              callback_payload: 'subcontratadas',
            },
          },
          {
            headerName: 'RGSEAA',
            field: 'rgseaa',
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
          },
          {
            headerName: 'Actividad',
            field: 'actividad',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            cellEditor: 'SelectCellEditor',
            cellEditorParams: {
              items: this.isETG
                ? this.db.actividades.etg_subcontratadas
                : this.db.actividades.subcontratadas,
              multiple: true,
              item_text: 'nombre',
              item_value: 'nombre', //'codigo',
            },
          },
          {
            headerName: this.isETG
              ? 'Volumen de actividades (Unidades)'
              : 'Volumen de animales/canales/piezas/kg de carne',
            field: 'volumen',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
          },

          {
            headerName: 'Actividad Subcontratada Certificada',
            field: 'certificada',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            cellEditor: 'BooleanCellEditor',
            cellEditorParams: {
              text: true,
            },
          },
          {
            headerName: 'Entidad de Certificación',
            field: 'entidad',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
          },
          {
            headerName: 'Certificado de Conformidad y Anexo Técnico',
            field: 'conformidad_anexo',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
          },
        ],
        alquiladas: [
          {
            headerName: '#',
            sortable: true,
            filter: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            valueGetter: (params) => params.node.rowIndex + 1,
          },
          {
            headerName: 'Instalacion',
            field: 'instalacion',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            cellEditor: 'SelectCellEditor',
            cellEditorParams: {
              items: this.getInstalaciones('alquilada'),
              item_text: this.nombreInstalacion,
              item_value: this.nombreInstalacion, // 'razon_social',
              callback: this.onSelectedInstalacion,
              callback_payload: 'alquiladas',
            },
          },
          {
            headerName: 'RGSEAA',
            field: 'rgseaa',
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
          },

          {
            headerName: 'Actividad',
            field: 'actividad',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            cellEditor: 'SelectCellEditor',
            cellEditorParams: {
              items: this.isETG
                ? this.db.actividades.etg_subcontratadas
                : this.db.actividades.alquiladas,
              multiple: true,
              item_text: 'nombre',
              item_value: 'nombre', // 'codigo',
            },
          },
          {
            headerName: 'Volumen de animales/canales/piezas/kg de carne',
            field: 'volumen',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
          },
          {
            headerName: 'Solicita Certificación de Certicalidad',
            field: 'certificacion',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            cellEditor: 'BooleanCellEditor',
            cellEditorParams: {
              text: true,
            },
          },
        ],
        maquila: [
          {
            headerName: '#',
            sortable: true,
            filter: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            valueGetter: (params) => params.node.rowIndex + 1,
          },
          {
            headerName: 'Instalacion',
            field: 'instalacion',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            cellEditor: 'SelectCellEditor',
            cellEditorParams: {
              items: this.getInstalaciones('maquila'),
              item_text: this.nombreInstalacion,
              item_value: this.nombreInstalacion, // 'razon_social',
              callback: this.onSelectedInstalacion,
              callback_payload: 'maquila',
            },
          },
          {
            headerName: 'RGSEAA',
            field: 'rgseaa',
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
          },

          {
            headerName: 'Actividades realizadas',
            field: 'actividades_realizadas',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            cellEditor: 'SelectCellEditor',
            cellEditorParams: {
              items: this.isETG
                ? this.db.actividades.etg_subcontratadas
                : this.db.actividades.alquiladas,
              multiple: true,
              item_text: 'nombre',
              item_value: 'nombre', // 'codigo',
            },
          },
        ],
        proveedores: [
          {
            headerName: '#',
            sortable: true,
            filter: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            valueGetter: (params) => params.node.rowIndex + 1,
          },

          {
            headerName: 'Instalación',
            field: 'instalacion',
            sortable: true,
            filter: true,
            resizable: true,
            editable: !this.isAprobada,
            cellEditor: 'SelectCellEditor',
            cellEditorParams: {
              items: this.getInstalaciones('de_proveedor'),
              item_text: this.nombreInstalacion,
              item_value: this.nombreInstalacion, //'razon_social',
              callback: this.onSelectedInstalacion,
              callback_payload: 'proveedores',
            },
          },

          {
            headerName: 'RGSEAA',
            field: 'rgseaa',
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
          },
        ],
      }

      if (this.isSAE) {
        headers.proveedores.push({
          headerName: 'Categoría de productos',
          field: 'categoria_productos',
          resizable: true,
          editable: !this.isAprobada,
          cellEditor: 'SelectCellEditor',
          cellEditorParams: {
            items: this.db.SAE_categorias,
            item_text: ' ',
            item_value: ' ', //'razon_social',
          },
        })

        // lineas
        let lineas = []
        for (let i in this.db.SAE_lineas) {
          lineas.push(' + ' + i)
          this.db.SAE_lineas[i].forEach((l) => {
            lineas.push(l)
          })
        }

        headers.proveedores.push({
          headerName: 'Líneas de producción',
          field: 'lineas_produccion',
          resizable: true,
          editable: !this.isAprobada,
          cellEditor: 'SelectCellEditor',
          cellEditorParams: {
            multiple: true,
            items: lineas,
            item_text: ' ',
            item_value: ' ', //'razon_social',
          },
        })
          ;[
            // 'categoria_productos',
            // 'lineas_produccion',
            'certificados_emitidos',
            'partidas_suministradas',
            'fichas_especificacion',
            'nivel_sae',
            'pais',
            'n_ase',
            'producto_nombre_comercial',
          ].forEach((field) => {
            headers.proveedores.push({
              headerName: field.replace(/_/g, ' '),
              field: field,
              resizable: true,
              editable: !this.isAprobada,
            })
          })
      }

      return headers
    },
  },
  watch: {
    isOperadorMaquila(value) {
      this.grid.maquila.show = !!value
    },

    isSAE(value) {
      this.grid.proveedores.show = true

      this.grid.subcontratadas.show = false
      this.grid.alquiladas.show = false
    },
  },
  methods: {
    // eliminado en limpieza 25/07
    // alcanceProd() {
    //   console.log('change', this.item.params.alcance_producto.length)
    // },

    nombreInstalacion(instalacion) {
      console.warn(instalacion, instalacion.razon_social, instalacion.rgseaa)
      return instalacion.razon_social + ' (' + instalacion.rgseaa + ')'
    },

    // eliminado en limpieza 25/07
    // valueInstalacion(instalacion) {
    //   return instalacion.rgseaa // instalacion.razon_social + '--' + instalacion.rgseaa
    // },

    getInstalaciones(tipo) {
      console.warn('✨', tipo, this.item.cliente.instalaciones)
      if (!this.item.cliente.instalaciones) {
        return []
      }

      let instalaciones = []
      this.item.cliente.instalaciones.forEach((element) => {
        let arrayTipos = element.tipo_instalacions.map((a) => a.codigo)
        if (arrayTipos.includes(tipo) && element.is_active) {
          instalaciones.push(element)
        }
      })

      return instalaciones
    },

    setSites(value) {
      this.item.params.actividades_proceso.maquila = []
      this.item.params.actividades_proceso.alquiladas = []
      this.item.params.actividades_proceso.subcontratadas = []

      this.item.ultima_iteracion = value
      this.item.ultima_iteracion.sites.forEach((site) => {
        if (site.tipo === 'actividad propia' || site.tipo === 'actividad') {
          /*if (this.item.params.tipo_solicitud == "modificacion") {
            site.site_data_normalized = site.data
          }*/
          /*else {
            site.site_data_normalized = {}
            site.site_data_normalized.instalacion_codigo = site.instalacion.rgseaa
            site.site_data_normalized.instalacion_nombre = site.instalacion.razon_social
            site.site_data.forEach((site_data) => {
              site.site_data_normalized[site_data.keyname] = site_data.value
            })
          }*/
          this.item.params.alcance_producto.push({ ...site.data }) //site.site_data_normalized
        }

        if (site.tipo == 'actividad subcontratada') {
          this.item.params.actividades_proceso.subcontratadas.push({ ...site.data })
        }

        if (site.tipo == 'actividad alquilada') {
          this.item.params.actividades_proceso.alquiladas.push({ ...site.data })
        }

        if (site.tipo == 'actividad maquila') {
          this.item.params.actividades_proceso.maquila.push({ ...site.data })
        }

        // if (site.tipo === 'instalacion alquilada') {
        //   site.site_data_normalized_alquilada = {}
        //   site.site_data.forEach((site_data) => {
        //     site.site_data_normalized_alquilada[site_data.keyname] = site_data.value
        //   })
        //   this.item.params.actividades_proceso.alquiladas.push(site.site_data_normalized_alquilada)
        // }
        // if (site.tipo === 'instalacion subcontratada') {
        //   site.site_data_normalized_subcontratada = {}
        //   site.site_data.forEach((site_data) => {
        //     site.site_data_normalized_subcontratada[site_data.keyname] = site_data.value
        //   })
        //   this.item.params.actividades_proceso.subcontratadas.push(
        //     site.site_data_normalized_subcontratada
        //   )
        // }

        // Eliminado en limpieza 25/07
        // if (site.tipo === 'Proveedor de servicios') {
        //   site.site_data_normalized_servicios = {}
        //   site.site_data.forEach((site_data) => {
        //     site.site_data_normalized_servicios[site_data.keyname] = site_data.value
        //   })
        //   this.item.params.proveedores.servicios.push(site.site_data_normalized_servicios)
        // }

        // if (site.tipo === 'Proveedor de productos') {
        //   site.site_data_normalized_productos = {}
        //   site.site_data.forEach((site_data) => {
        //     site.site_data_normalized_productos[site_data.keyname] = site_data.value
        //   })
        //   this.item.params.proveedores.productos.push(site.site_data_normalized_productos)
        // }
      })
    },

    async onChangeCliente() {
      await this.loadClient()
    },

    onChangeAlcance() { },

    //+-------------------------------------------------
    // onSelectedInstalacion()
    // Callback to autocomplete fields when user select an item
    // -----
    // Created on Wed Jan 05 2022
    // Updated on Thu Jun 23 2022
    //+-------------------------------------------------
    onSelectedInstalacion(value, selected, payload, index) {
      console.info(value, selected, payload, index)
      let sel = selected[0]
      let item = this.item.params.actividades_proceso[payload][index]
      console.log(sel, item)

      if (!item) return
      item.rgseaa = sel.rgseaa || '--'
      item.instalacion = sel.razon_social || '--'

      if (item.entidad !== undefined) item.entidad = sel.entidad_certificadora || '--'
      if (item.certificada !== undefined) item.certificada = sel.certificada ? 'SI' : 'NO'
      if (item.conformidad_anexo !== undefined)
        item.conformidad_anexo = sel.numero_certificado || '--'

      this.grid[payload].api.refreshCells()
    },

    // Grid
    onGridReady(api, grid, tipo) {
      grid[tipo].api = api
      grid[tipo].api.addEventListener('cellClicked', this.onCellClick)
    },

    onCellClick(params) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column.getId(),
      })
    },

    addRow() {
      const tipo = this.tab
      const item = {}

      this.columnDefs[tipo].forEach((element) => {
        if (element.field) item[element.field] = ''
      })

      this.item.params.actividades_proceso[tipo].push(item)
      this.grid[tipo].api.updateRowData({
        add: [item],
      })
    },

    removeRow() {
      const tipo = this.tab

      const selectedRows = this.grid[tipo].api.getSelectedRows()
      if (selectedRows && selectedRows.length) {
        selectedRows.forEach((element) => {
          const index = this.item.params.actividades_proceso[tipo].indexOf(element)
          this.item.params.actividades_proceso[tipo].splice(index, 1)
        })
      }
      this.grid[tipo].api.updateRowData({
        remove: this.grid[tipo].api.getSelectedRows(),
      })
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'solicitudes',
        submodule: 'solicitudes',
      })
    },

    async init() {
      this.checkRoles()
      this.getActividades()

      if (!this.isNew) {
        const uuid = this.uuid ? this.uuid : this.$route.params.uuid
        const res = await this.$store.dispatch('grupo_solicitudes/get', uuid)

        if (res) {
          if (!res.actividades_proceso)
            res.actividades_proceso = {
              subcontratadas: [],
              alquiladas: [],
              xxx: [],
            }

          if (!res.actividades_proceso.subcontratadas) res.actividades_proceso.subcontratadas = []
          if (!res.actividades_proceso.alquiladas) res.actividades_proceso.alquiladas = []

          //update lista validacion
          this.item = res
          this.$store.commit('listas_validacion/SET_ALCANCE', this.item.solicitudes[0].alcance.uuid)

          // Formatos
          this.loadFormatosData()
        }
      }
    },

    async aprobarSolicitud() {
      this.confirmAprobar = false
      await this.$store.dispatch(`grupo_solicitudes/aprobar`, this.item)
      this.$router.push({
        name: 'grupo_solicitudes',
      })
    },

    convertToSiteData(array) {
      const newArray = []
      if (array.length) {
        const keys = Object.keys(array[0])
        for (let i = 0; i < array.length; i++) {
          const element = array[i]
          const row = []
          for (let k = 0; k < keys.length; k++) {
            const key = keys[k]
            const obj = {
              key: key,
              value: element[key],
            }
            row.push(obj)
          }
          newArray.push(row)
        }
      }
      return newArray
    },

    checkValidationAndConfirm() {
      if (!this.$refs.form.validate()) {
        let notification = {
          text: 'La solicitud no es válida. Revise los campos por favor.',
          color: 'error',
          timeout: 4000,
        }
        this.$store.commit('notification/show', notification)
      } else {
        this.confirm = true
      }
    },

    async submit() {
      this.item.params.volumen_produccion.total_volumen = {
        animales_sacrificados: this.volumenProduccionTotal.animales_sacrificados,
        canales_despiezadas: this.volumenProduccionTotal.canales_despiezadas,
        numero_jamones: this.volumenProduccionTotal.numero_jamones,
        numero_paletas: this.volumenProduccionTotal.numero_paletas,
        numero_canas_lomo: this.volumenProduccionTotal.numero_canas_lomo,
        piezas_elaboradas: this.volumenProduccionTotal.piezas_elaboradas,
        piezas_transformadas: this.volumenProduccionTotal.piezas_transformadas,
        carne_comercializada: this.volumenProduccionTotal.carne_comercializada,
      }

      // this.item.params.actividades_proceso.subcontratadas_no_certificadas =
      //   this.actividadesProcesoFiltered.no_certificada
      // this.item.params.actividades_proceso.subcontratadas_certificadas =
      //   this.actividadesProcesoFiltered.certificadas

      // Alcance Producto
      let validAlcanceProducto = true
      if (this.isOperadorResposable) validAlcanceProducto = this.item.params.alcance_producto.length
      // Alcance Actividates
      // const actividades_proceso = this.item.params.actividades_proceso
      // const validActividadesProceso = actividades_proceso.subcontratadas.length && actividades_proceso.alquiladas.length

      // Check for multiple not null fields
      // A response of false means no empty fiels (ok)
      let emptyFields = false

      emptyFields = validator.checkEmptyFields(
        { ...this.item.params.actividades_proceso },
        ['subcontratadas', 'alquiladas', 'maquila'],
        ['instalacion', 'actividad', 'actividades_realizadas', 'actividades_subcontratadas']
      )

      if (emptyFields !== false) {
        notifications.show({
          text: 'Hay filas sin completar en la tabla de proveedor de ' + emptyFields.key,
          color: 'warning',
        })
        return
      }

      // Submit
      if (this.$refs.form.validate() && validAlcanceProducto) {
        // Eliminado en limpieza 25/07
        // this.item.params.globalgap.instalaciones.forEach((element) => {
        //   element.api = null
        // })

        const body = {
          ...this.item,
        }

        body.grupo_alcance = this.isNew
          ? this.item.solicitudes[0].alcance.grupo.uuid
          : this.item.grupo_alcance.uuid
        body.alcance = this.item.solicitudes[0].alcance.uuid
        body.cliente = this.item.cliente.uuid

        // Eliminado en limpieza 25/07
        // body.params.site_data = {
        //   alcance_producto: await this.convertToSiteData(this.item.params.alcance_producto),

        //   // Eliminado en limpieza 25/07
        //   // actividades_proceso: {
        //   //   subcontratadas: await this.convertToSiteData(
        //   //     this.item.params.actividades_proceso.subcontratadas
        //   //   ),
        //   //   subcontratadas_certificadas: await this.convertToSiteData(
        //   //     this.item.params.actividades_proceso.subcontratadas_certificadas
        //   //   ),
        //   //   subcontratadas_no_certificadas: await this.convertToSiteData(
        //   //     this.item.params.actividades_proceso.subcontratadas_no_certificadas
        //   //   ),
        //   //   alquiladas: await this.convertToSiteData(
        //   //     this.item.params.actividades_proceso.alquiladas
        //   //   ),
        //   // },

        //   // Eliminado en limpieza 25/07
        //   // proveedores: {
        //   //   productos: await this.convertToSiteData(this.item.params.proveedores.productos),
        //   //   servicios: await this.convertToSiteData(this.item.params.proveedores.servicios),
        //   // },
        //   // globalgap: await this.convertToSiteData(this.item.params.globalgap),
        // }

        if (this.isNew) {
          await this.$store.dispatch('grupo_solicitudes/create', body)
        } else {
          body.grupo_solicitud = this.item.uuid
          await this.$store.dispatch('grupo_solicitudes/update', body)
        }
        await this.$router.push({
          name: 'grupo_solicitudes',
        })
      } else if (!validAlcanceProducto) {
        let notification = {
          text: 'No se ha añadido Alcance del Producto',
          color: 'error',
          timeout: 3000,
        }
        this.$store.commit('notification/show', notification)
      }
      this.confirm = false
    },

    // Eliminado en limpieza 25/07
    // capitalizeFirstLetter(string) {
    //   return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : ''
    // },

    async loadFormatosData(consecion) {
      this.$store.commit('formatos/SET_MODULO', 'solicitudes')
      this.$store.commit('formatos/SET_MODULO_REF', this.item.uuid)
      this.$store.commit('formatos/SET_ALCANCE', this.item.solicitudes[0].alcance)
      this.$store.commit('formatos/SET_CLIENTE', this.item.cliente)

      // Formato Tipos
      this.$store.commit('formatos/GET_TIPOS', this.$auth.user.empresa.codigo)

      // Formato Data
      const body = {
        ...this.item,
        cliente: cliente,
      }

      // Cliente
      const cliente = await this.$store.dispatch('clientes/get', this.item.cliente.uuid)
      if (cliente) body.cliente = cliente

      // Datos
      const datos = []
      datos.push(body)
      this.$store.commit('formatos/SET_DATOS', datos)
      console.log('loadFormatosData -> datos', datos)

      // PDF
      // let route = '/formatos?plantilla='
      // route += consecion ? 'consecion' : 'informe'
      // this.$router.push(route)
    },

    // Eliminado en limpieza 25/07
    // async nuevoMetodo() {
    //   this.loading = true
    //   // lamada 1 y cuando responde el GUID
    //   let body = {
    //     cliente: this.item.cliente.uuid,
    //     alcance: this.item.solicitudes[0].alcance.uuid,
    //   }
    //   const resExpediente = await this.$axios.put('expedientes', body)
    //   console.log(resExpediente)

    //   const lastElement = resExpediente.data.slice(-1)
    //   const uuid_iteracion = lastElement[0].ultima_iteracion.uuid
    //   const uuid_expediente = lastElement[0].uuid

    //   const resUltIteracion = await this.$axios.get(
    //     `expedientes/${uuid_expediente}/iteraciones/${uuid_iteracion}`
    //   )

    //   const listaActividad = resUltIteracion.data.sites.filter((x) => x.tipo == 'actividad')
    //   const listaInstalacion = resUltIteracion.data.sites.filter((x) => x.tipo == 'instalacion')
    //   const listaProductos = resUltIteracion.data.sites.filter(
    //     (x) => x.tipo == 'proveedor_productos'
    //   )
    //   const listaServicios = resUltIteracion.data.sites.filter(
    //     (x) => x.tipo == 'proveedor_servicios'
    //   )

    //   const listaInstalacionMapeada = this.getSiteData(listaInstalacion)
    //   const listaProductosMapeada = this.getSiteData(listaProductos)
    //   const listaServiciosMapeada = this.getSiteData(listaServicios)

    //   console.log(listaInstalacion[0].nombre)
    //   const listaActividadMapeada = []

    //   const opcionAlcanceSeleccionada = this.item.solicitudes[0].alcance.codigo

    //   if (opcionAlcanceSeleccionada === 'productos_ibericos') {
    //     listaActividadMapeada.push(
    //       this.getSiteData(
    //         listaActividad, //lista
    //         listaInstalacion[0].nombre, //nombre instalacion
    //         lastElement[0].cliente.nombre, //razon social
    //         listaInstalacionMapeada[0].rgseaa //rgseaa
    //       )
    //     )
    //   } else if (opcionAlcanceSeleccionada === 'etg') {
    //     listaActividadMapeada.push(this.getSiteData(listaActividad, listaInstalacion[0].nombre))
    //   }

    //   this.item.params.alcance_proceso.tipo_operador = []

    //   if (listaActividadMapeada.length > 0) {
    //     this.item.params.alcance_proceso.tipo_operador.push('responsable')
    //   }

    //   if (listaProductosMapeada.length > 0 || listaServiciosMapeada.length > 0) {
    //     this.item.params.alcance_proceso.tipo_operador.push('prestador')
    //   }

    //   this.item.params.alcance_producto = listaActividadMapeada[0]
    //   this.item.params.proveedores['productos'] = listaProductosMapeada
    //   this.item.params.proveedores['servicios'] = listaServiciosMapeada

    //   this.loading = false
    // },

    getSiteData(sites, instalacion, razon_social, rgseaa) {
      const keyCount = Object.keys(sites).length
      const data = []

      if (sites) {
        for (let i = 0; i < keyCount; i++) {
          const element = sites[i]
          let obj = {}
          obj.uuid = element.uuid
          obj.nombre = element.nombre
          obj.tipo = element.tipo

          if (instalacion) {
            obj.instalacion = instalacion
          }
          if (rgseaa) {
            obj.instalacion = instalacion
            obj.razon_social = razon_social
            obj.rgseaa = rgseaa
          }

          if (element.site_data.length > 0) {
            for (let k = 0; k < element.site_data.length; k++) {
              const item = element.site_data[k]
              obj[item.keyname] = item.value
            }
            data.push(obj)
          }
        }
      }
      return data
    },

    async getActividades() {
      let xhr = await this.$store.dispatch('tramites/list')
      let xhr_sub = xhr.filter((el) => ['distribucion'].indexOf(el.codigo) == -1)
      let xhr_alc = xhr
      // https://trello.com/c/NuV1Zbe0
      // let xhr_alc = xhr.filter((el) => ['comercializacion'].indexOf(el.codigo) == -1)

      this.db.actividades = {
        subcontratadas: xhr_sub,
        alquiladas: xhr_alc,
        etg_subcontratadas: MODELS.ACTIVIDADES_ETG2,
      }
    },

    updateProductoList(item) {
      this.item.params.alcance_producto = item
        ;[
          'animales_sacrificados',
          'canales_despiezadas',
          'numero_jamones',
          'numero_paletas',
          'numero_canas_lomo',
          'carne_comercializada',
          'jamones_elaborados',
          'jamones_adquiridos',
          'total_jamones',
        ].forEach((field) => {
          let value = this.item.params.volumen_produccion['propia'][field]
          let sum = this.item.params.alcance_producto.reduce((total, el) => {
            return parseInt(el[field]) + parseInt(total)
          }, 0)
          console.warn('🆗', sum, 'propia', field)
          this.item.params.volumen_produccion['propia'][field] = sum
        })
    },

    async loadClient() {
      let uuid = this.item.cliente?.uuid
      if (!uuid) return

      const xhr = await this.$store.dispatch('clientes/get', uuid)
      this.item.cliente = { ...xhr }
    },
  },

  mounted() {
    this.init()
  },
}
</script>
