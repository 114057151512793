/**
 * @project: certiapp-nuxt
 * @file:    models/modulos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th March 2021
 * Last Modified: Tue Aug 31 2021
 **/

export default [
  {
    label: 'General',
    single: 'General',
    value: 'general',
    link: '',
  },

  {
    label: 'Solicitudes',
    single: 'Solicitud',
    value: 'solicitudes',
    link: 'grupo_solicitudes/{uuid}',
  },

  {
    label: 'Grupo solicitudes',
    single: 'Solicitud',
    value: 'grupo_solicitudes',
    link: 'grupo_solicitudes/{uuid}',
  },

  {
    label: 'Expedientes',
    single: 'Expediente',
    value: 'expedientes',
    link: 'expedientes/{uuid}',
  },

  {
    label: 'Auditorías',
    single: 'Auditoría',
    value: 'auditorias',
    link: 'auditorias/{uuid}',
  },

  {
    label: 'PACS',
    single: 'PAC',
    value: 'pacs',
    link: 'auditorias/{uuid}/pacs',
  },

  {
    label: 'Revisiones',
    single: 'Revisión',
    value: 'revisiones',
    link: 'revisiones/{uuid}',
  },

  {
    label: 'Dictámenes',
    single: 'Dictámen',
    value: 'dictamenes',
    link: 'dictamenes/{uuid}',
  },

  {
    label: 'Certificados',
    single: 'Certificado',
    value: 'certificados',
    link: 'certificados/{uuid}',
  },
]
