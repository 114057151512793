var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.multiple)?_c('v-autocomplete',{ref:"emplazamientos",attrs:{"items":_vm.planificableItems,"label":"Emplazamientos","item-text":"nombre","item-value":"uuid","dense":"","multiple":"","clearable":"","small-chips":"","return-object":"","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [(item.header)?_c('v-subheader',[_vm._v(_vm._s(item.header))]):(item.divider)?_c('v-divider'):[_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){return _vm.rowSelected(item)}}},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":_vm.selected.some(function (e) { return e.uuid === item.uuid; })}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nombre)),_c('span',{staticStyle:{"color":"rgb(146, 146, 146)","font-size":"12px !important"}},[_vm._v("\n              - "+_vm._s(item.rgseaa))])])],1)],1)]]}}],null,false,2679856250),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('v-autocomplete',{ref:"emplazamientos",attrs:{"items":_vm.items,"label":"Emplazamientos","item-text":"nombre","item-value":"uuid","dense":"","clearable":"","return-object":"","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [(item.header)?_c('v-subheader',[_vm._v(_vm._s(item.header))]):(item.divider)?_c('v-divider'):[_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){return _vm.rowSelected(item)}}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nombre)),(item.rgseaa)?_c('span',{staticStyle:{"color":"rgb(146, 146, 146)","font-size":"12px !important"}},[_vm._v("\n              - "+_vm._s(item.rgseaa))]):_vm._e()])],1)],1)]]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }