import { uuid } from 'vue-uuid'

export default {
  state: () => ({
    defaultMuestras: [
      { tipo: 'INICIAL', icon: 'mdi-account-multiple-outline' },
      { tipo: 'DIRIMENTE', icon: 'mdi-text-box-check-outline' },
      { tipo: 'CONTRADICTORIO', icon: 'mdi-folder-text-outline' },
    ],
    offlineActions: [],
  }),
  // commit mutations
  mutations: {
    OFFLINE_ACTION(state, action) {
      const storage = JSON.parse(localStorage.getItem('offline_actions_muestras'))
      if (storage) state.offlineActions = storage
      state.offlineActions.push(action)
      localStorage.setItem('offline_actions_muestras', JSON.stringify(state.offlineActions))
    },
    CLEAR_OFFLINE_MODE(state) {
      state.offlineActions = []
      localStorage.removeItem('offline_actions_muestras')
      localStorage.removeItem('tomas_muestra')
      localStorage.removeItem('muestras')
    },
  },
  // dispatch actions
  actions: {
    async goOnline({ rootState, commit, dispatch }) {
      const storage = JSON.parse(localStorage.getItem('offline_actions_muestras'))
      if (storage && storage.length > 0) {
        for (const element of storage) {
          console.info(element)
          await dispatch(element.action, element.body)
        }
      }
      commit('CLEAR_OFFLINE_MODE')
    },

    //+-------------------------------------------------
    // function()
    //
    // -----
    // Updated on Wed Feb 08 2023
    //+-------------------------------------------------
    async list({ rootState, commit }, auditoria) {
      // const storage = JSON.parse(localStorage.getItem(`tomas_muestra`))
      // Check Offline
      // if (rootState.auditorias.offlineMode && storage) {
      //   return storage
      // } else {
      rootState.loading = true
      const body = {
        auditoria: auditoria,
      }
      const res = await this.$axios.put(`tomas_muestra?ref=${auditoria || '0077'}`, body)
      rootState.loading = false
      if (res) {
        // if (rootState.auditorias.offlineMode)
        //   localStorage.setItem('tomas_muestra', JSON.stringify(res.data))
        return res.data
      }
      // }
    },

    async get({ rootState, commit }, uuid) {
      // const storage = JSON.parse(localStorage.getItem('tomas_muestra'))
      // Check Offline
      // if (rootState.auditorias.offlineMode && storage) {
      //   const index = storage.findIndex((e) => e.uuid == uuid)
      //   return storage[index]
      // } else {
      rootState.loading = true
      const res = await this.$axios.get(`tomas_muestra/${uuid}`)
      rootState.loading = false
      if (res) return res.data
      // }
    },

    async create({ rootState, state, dispatch, commit }, params) {
      // Check Offline
      if (rootState.auditorias.offlineMode) {
        const action = {
          action: 'create',
          body: {
            auditoria: params.auditoria,
            codigo: params.codigo,
            responsable: params.responsable,
            site: params.site,
            uuid: params.uuid,
          },
        }
        const muestras = [uuid.v4(), uuid.v4(), uuid.v4()]
        action.body.muestras = muestras

        commit('OFFLINE_ACTION', action)

        const toma_muestra = {
          ...action.body,
          estado: 'NUEVA',
          created_at: new Date(),
        }

        const storage = JSON.parse(localStorage.getItem('tomas_muestra'))
        storage.push(toma_muestra)
        localStorage.setItem('tomas_muestra', JSON.stringify(storage))

        for (let i = 0; i < state.defaultMuestras.length; i++) {
          const element = state.defaultMuestras[i]
          const uuid = muestras[i]
          const data = {
            toma_muestra: params.uuid,
            tipo: element.tipo,
            laboratorio: '',
            uuid: uuid,
          }
          await dispatch('createMuestra', data)
        }
      } else {
        rootState.loading = true
        const body = {
          auditoria: params.auditoria.uuid,
          codigo: params.codigo,
          responsable: params.responsable,
          site: params.site.uuid,
          uuid: params.uuid,
        }
        await this.$axios.post(`tomas_muestra`, body)

        let muestras = state.defaultMuestras
        const storage = JSON.parse(localStorage.getItem('muestras'))
        if (storage && storage[params.uuid]) muestras = storage[params.uuid]

        for (let i = 0; i < muestras.length; i++) {
          const element = muestras[i]
          const data = {
            toma_muestra: body.uuid,
            tipo: element.tipo,
            laboratorio: '',
            uuid: element.uuid || uuid.v4(),
          }
          await dispatch('createMuestra', data)
        }
      }

      rootState.loading = false
    },

    async update({ rootState, commit }, params) {
      // Check Offline
      if (rootState.auditorias.offlineMode) {
        const action = {
          action: 'update',
          body: params,
        }
        commit('OFFLINE_ACTION', action)
        const storage = JSON.parse(localStorage.getItem('tomas_muestra'))
        const index = storage.findIndex((e) => e.uuid == params.uuid)
        storage[index] = params
        localStorage.setItem('tomas_muestra', JSON.stringify(storage))
      } else {
        rootState.loading = true
        const body = {
          responsable: params.responsable,
          otros_responsable: params.otros_responsable,
          codigo: params.codigo,
          personal_nombre: params.personal_nombre,
          personal_dni: params.personal_dni,
          observaciones: params.observaciones,
          tratamientos_realizados: params.tratamientos_realizados,
          site: params.site,
          auditoria: params.auditoria,
          toma_muestra: params.uuid,
        }
        body.site = params.site.uuid
        body.auditoria = params.auditoria.uuid
        await this.$axios.patch(`tomas_muestra/${params.uuid}`, body)
        rootState.loading = false
      }
    },

    // Muestras

    async listMuestra({ rootState, commit }, uuid) {
      // const storage = JSON.parse(localStorage.getItem('muestras')) || {}
      // Check Offline
      // if (rootState.auditorias.offlineMode && storage[uuid]) {
      //   return storage[uuid]
      // } else {
      rootState.loading = true
      const res = await this.$axios.get(`tomas_muestra/${uuid}/muestras`)
      rootState.loading = false
      if (res) {
        if (rootState.auditorias.offlineMode) {
          storage[uuid] = res.data
          localStorage.setItem('muestras', JSON.stringify(storage))
        }
        return res.data
      }
      // }
    },

    async createMuestra({ rootState, commit }, params) {
      // Check Offline
      if (rootState.auditorias.offlineMode) {
        // const action = {
        //   action: 'createMuestra',
        //   body: params
        // }
        // commit('OFFLINE_ACTION', action)
        const storage = JSON.parse(localStorage.getItem('muestras')) || {}
        if (!storage[params.toma_muestra]) storage[params.toma_muestra] = []
        storage[params.toma_muestra].push(params)
        localStorage.setItem('muestras', JSON.stringify(storage))
      } else {
        rootState.loading = true
        const body = {
          lote: params.lote,
          laboratorio: params.laboratorio,
          tipo: params.tipo,
          sustancias_detectadas: params.sustancias_detectadas,
          fecha_recepcion: params.fecha_recepcion,
          fecha_envio: params.fecha_envio,
          facturable: params.facturable,
          estado: params.estado,
          toma_muestra: params.toma_muestra,
          precinto: params.precinto,
          tipo_muestra: params.tipo_muestra,
          uuid: params.uuid,
        }
        await this.$axios.post(`tomas_muestra/${params.toma_muestra}/muestras`, body)
        rootState.loading = false
      }
    },

    async updateMuestra({ rootState, commit }, params) {
      // Check Offline
      if (rootState.auditorias.offlineMode) {
        const action = {
          action: 'updateMuestra',
          body: params,
        }
        commit('OFFLINE_ACTION', action)
        const storage = JSON.parse(localStorage.getItem('muestras'))
        for (let i = 0; i < storage[params.toma_muestra].length; i++) {
          const muestra_uuid = storage[params.toma_muestra][i].uuid
          if (muestra_uuid == params.uuid) {
            storage[params.toma_muestra][i] = params
            break
          }
        }
        localStorage.setItem('muestras', JSON.stringify(storage))
      } else {
        rootState.loading = true
        let body = null
        if (this.$auth.user.empresa.codigo == 'quesomanchego') {
          body = {
            lote: params.lote,
            laboratorios: params.laboratorios,
            tipos: params.tipos,
            tipo: params.tipo,
            sustancias_detectadas: params.sustancias_detectadas,
            fecha_recepcion: params.fecha_recepcion,
            fecha_envio: params.fecha_envio,
            facturable: params.facturable,
            estado: params.estado,
            toma_muestra: params.toma_muestra,
            muestra: params.uuid,
            precinto: params.precinto,
            params: params.params,
            firmas: params.firmas.map((e) => {
              return {
                fecha: e.fecha,
                auditor: e.auditor.uuid,
                cliente: e.cliente.uuid,
              }
            }),
          }
        } else {
          body = {
            lote: params.lote,
            laboratorio: params.laboratorio,
            tipo: params.tipo,
            sustancias_detectadas: params.sustancias_detectadas,
            fecha_recepcion: params.fecha_recepcion,
            fecha_envio: params.fecha_envio,
            facturable: params.facturable,
            estado: params.estado,
            toma_muestra: params.toma_muestra,
            muestra: params.uuid,
            precinto: params.precinto,
            tipo_muestra: params.tipo_muestra,
            params: params.params,
            firmas: params.firmas.map((e) => {
              return {
                fecha: e.fecha,
                auditor: e.auditor.uuid,
                cliente: e.cliente.uuid,
              }
            }),
          }
        }
        await this.$axios.patch(
          `tomas_muestra/${params.toma_muestra}/muestras/${params.uuid}`,
          body
        )
        rootState.loading = false
      }
    },

    async deleteTomaMuestra({ rootState, commit }, params) {
      rootState.loading = true
      await this.$axios.delete(`tomas_muestra/${params.uuid}`)
      rootState.loading = false
    },

    // Analisis

    async listAnalisis({ rootState, commit }, params) {
      rootState.loading = true
      const res = await this.$axios.get(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis`
      )
      rootState.loading = false
      if (res) return res.data
    },

    async createAnalisis({ rootState, commit }, params) {
      rootState.loading = true
      const body = {
        tipo_analisis: params.tipo_analisis,
        precinto: params.precinto,
        respuesta: params.respuesta,
        observaciones: params.observaciones,
        muestra: params.muestra,
        uuid: uuid.v4(),
      }
      console.log('createAnalisis -> body', body)
      await this.$axios.post(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis`,
        body
      )
      rootState.loading = false
    },

    async updateAnalisis({ rootState, commit }, params) {
      rootState.loading = true
      const body = {
        tipo_analisis: params.tipo_analisis,
        precinto: params.precinto,
        respuesta: params.respuesta,
        observaciones: params.observaciones,
        muestra: params.muestra,
        analisis: params.uuid,
      }
      await this.$axios.patch(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis/${params.uuid}`,
        body
      )
      rootState.loading = false
    },

    // Parametro

    async createParametro({ rootState, commit }, params) {
      rootState.loading = true
      const body = {
        clave: params.clave,
        valor: params.valor,
        analisis: params.analisis,
      }
      await this.$axios.post(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis/${params.analisis}/parametros`,
        body
      )
      rootState.loading = false
    },

    async updateParametro({ rootState, commit }, params) {
      rootState.loading = true
      const body = {
        clave: params.clave,
        valor: params.valor,
        analisis: params.analisis,
        parametro: params.id,
      }
      await this.$axios.patch(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis/${params.analisis}/parametros/${params.id}`,
        body
      )
      rootState.loading = false
    },

    async deleteParametro({ rootState, commit }, params) {
      rootState.loading = true
      await this.$axios.delete(
        `tomas_muestra/${params.toma_muestra}/muestras/${params.muestra}/analisis/${params.analisis}/parametros/${params.id}`
      )
      rootState.loading = false
    },
  },
}
