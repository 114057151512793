<template>
  <div>

    <v-dialog v-model="dialog" persistent width="1300px">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <span>Otras actividades</span>
          </v-card-title>

          <v-card-text style="max-height: 70vh;  overflow: auto;">
            <v-container>
              <v-row v-if="item && !isNew">
                <v-col cols="12">
                  <SelectInstalaciones
                    ref="instalaciones"
                    label="Instalación"
                    v-model="itemObjects.instalacion"
                    :cliente="cliente"
                    :tipos-instalacion="['maquila', 'alquilada', 'subcontratada']"
                    create
                    required
                    dense
                    returnObject
                    @input="updateItemObject('instalacion')"
                  />
                </v-col>

                <v-col cols="12" v-if="itemSelected">
                  <v-text-field
                    label="Tipo de instalación"
                    :value="itemSelected.tipo"
                    disabled
                    readonly
                    required
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" v-if="item.actividad !== undefined">
                  <v-select
                    label="Actividades"
                    v-model="item.actividad"
                    :items="actividadesPropias"
                    item-text="nombre"
                    item-value="nombre"
                    required
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="12" v-if="item.actividades_realizadas !== undefined">
                  <v-select
                    label="Actividades Realizadas"
                    v-model="item.actividades_realizadas"
                    :items="actividadesRealizadas"
                    item-text="nombre"
                    item-value="nombre"
                    required
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="12" v-if="item.actividades_subcontratadas !== undefined">
                  <v-select
                    label="Actividades Sub contratadas"
                    v-model="item.actividades_subcontratadas"
                    :items="actividadesSubcontratadas"
                    item-text="nombre"
                    item-value="nombre"
                    required
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <!-- <v-col cols="12">
                  <v-divider></v-divider>
                </v-col> -->
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <SelectInstalaciones
                    ref="instalaciones"
                    label="Instalación"
                    v-model="itemObjects.instalacion"
                    :cliente="cliente"
                    :tipos-instalacion="['maquila', 'alquilada', 'subcontratada', 'de_proveedor']"
                    create
                    required
                    dense
                    returnObject
                    @input="updateItemObject('instalacion')"
                  />
                </v-col>

                <v-col cols="12" v-if="itemObjects.instalacion">
                  <v-select
                    label="Tipo de instalación"
                    v-model="item.tipo"
                    :items="tipos"
                    item-text="text"
                    item-value="value"
                    required
                    dense
                    smallChips
                    nope-change="itemSelected.tipo = item.tipo"
                  />
                </v-col>

                <template v-if="isCerticalidad && headers.length">
                  <div style="width: 100%; border-radius: 3px; border: 2px solid #e0e0e0; padding: 10px; padding: 10px;">
                    <span class="pb-2 mb-2">
                    <v-icon small color="blue">
                      mdi-information-outline
                    </v-icon>
                    Haz doble click en las celdas para rellenar la información
                  </span>
                  <ag-grid-vue
                    id="ag-grid"
                    class="ag-theme-material ag-grid"
                    style="width: 100%; height: 200px"
                    :rowData="[item]"
                    :columnDefs="headers"
                    :frameworkComponents = "{
                      SwitchRenderer: 'SwitchRenderer',
                      DeleteRenderer: 'DeleteRenderer',
                      BooleanCellEditor: 'BooleanCellEditor',
                      AutocompleteCellEditor: 'AutocompleteCellEditor',
                      SelectCellEditor: 'SelectCellEditor',
                      TextCellEditor: 'TextCellEditor',
                      DateCellEditor: 'DateCellEditor',
                      AccionCorrectivaRenderer: 'AccionCorrectivaRenderer',
                    }"
                  ></ag-grid-vue>
                  </div>
                </template>

                <template v-else>

                <v-col cols="12" v-if="item.tipo == 'actividad alquilada'">
                  <v-select
                    label="Actividades"
                    v-model="item.actividad"
                    :items="actividadesPropias"
                    item-text="nombre"
                    item-value="nombre"
                    required
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="12" v-if="item.tipo == 'actividad maquila'">
                  <v-select
                    label="Actividades Realizadas"
                    v-model="item.actividades_realizadas"
                    :items="actividadesRealizadas"
                    item-text="nombre"
                    item-value="nombre"
                    required
                    dense
                    multiple
                    smallChips
                  />
                </v-col>

                <v-col cols="12" v-if="item.tipo == 'actividad subcontratada'">
                  <v-select
                    label="Actividades Sub contratadas"
                    v-model="item.actividades_subcontratadas"
                    :items="actividadesSubcontratadas"
                    item-text="nombre"
                    item-value="nombre"
                    required
                    dense
                    multiple
                    smallChips
                  />
                </v-col>


                </template>

                <!-- <v-col cols="12">
                  <v-divider></v-divider>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">Cancelar</v-btn>
            <v-btn color="primary" :disabled="loading" @click="submit()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <div style="display: none;">
      <solicitud-certicalidad
        ref="solicitud"
        v-if="solicitudUUID"
        :uuid="solicitudUUID"></solicitud-certicalidad>
    </div>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\auditorias\ampliacionOtros.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 24th August 2022
 * Last Modified: Fri Mar 03 2023
 **/

import AgDatatable from '~/components/AgDatatable'
import SelectInstalaciones from '~/components/forms/SelectInstalaciones'
import SelectActividades from '~/components/forms/SelectActividades'

import solicitudCerticalidad from '~/components/solicitudes/certicalidad'

export default {
  name: 'AlcanceMaquilaDialog',
  props: {
    value: Boolean,
    valid: { type: Boolean, default: true },
    cliente: { type: Object, default: null },
    alcance: { type: Object, default: null },
    itemSelected: { type: Object, default: { tipo: '' } },
    iteracion: { type: Object, default: null },
    isNew: { type: Boolean, default: false },
  },

  components: {
    AgDatatable,
    SelectInstalaciones,
    SelectActividades,

    solicitudCerticalidad,
  },

  data: () => ({
    dialog: false,

    sitem: {
      instalacion: '',
      rgseaa: '',
      actividad: '',
      volumen: '4324234',
      certificada: '',
      entidad: '',
      conformidad_anexo: '',
    },

    item: {
      instalacionObject: {},
    },
    itemObjects: {},

    rules: [(v) => !!v || 'El campo es obligatorio'],
    rulesMultiple: [(v) => (!!v && v.length > 0) || 'El campo es obligatorio'],

    db: {
      actividades: {
        propias: [],
        alquiladas: [],
        subcontratadas: [],
      },
    },
  }),

  watch: {
    value(val) {
      if (!val) this.resetForm()
      else {
        this.dialog = val
        this.init()
      }
    },

    dialog(val) {
      if (!val) this.close()
    },

    producto(val) {
      if (val) this.init()
      else this.resetForm()
    },

    itemSelected(val) {
      if (val) {
        this.setItem()
      }
    },
  },

  computed: {
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('LOADING', val)
      },
    },

    isCerticalidad() {
      return this.cliente?.empresa?.codigo == 'certicalidad'
    },

    tipos() {
      let tipos = []
      if (this.itemObjects.instalacion) {
        this.itemObjects.instalacion.tipo_instalacions.forEach((tipo) => {
          if (tipo.nombre == 'Alquilada') {
            tipos.push({
              text: 'Alquilada',
              value: 'actividad alquilada',
              key: 'alquiladas',
            })
          }
          if (tipo.nombre == 'Maquila') {
            tipos.push({
              text: 'Maquila',
              value: 'actividad maquila',
              key: 'maquila',
            })
          }
          if (tipo.nombre == 'Subcontratada') {
            tipos.push({
              text: 'Subcontratada',
              value: 'actividad subcontratada',
              key: 'subcontratadas',
            })
          }
          if (tipo.codigo == 'de_proveedor' || tipo.codigo == 'de_proovedor') {
            tipos.push({
              text: 'De proveedor',
              value: 'actividad proveedor',
              key: 'proveedores',
            })
          }
        })
      }

      return tipos
    },

    iteracion_() {
      return this.$parent.iteracion || {}
    },

    solicitudUUID() {
      if (!this.value) return false
      return this.iteracion_.grupo_solicitud?.uuid
    },

    actividadesPropias() {
      // For now, is subcontratadas, but maybe we
      // will have to change based on empresa codigo
      return this.db.actividades.subcontratadas
    },

    actividadesRealizadas() {
      return this.db.actividades.alquiladas
    },

    actividadesSubcontratadas() {
      return this.db.actividades.subcontratadas
    },

    headers() {
      console.warn('headers', this.$refs.solicitud, this.item.tipo)
      if (!this.$refs.solicitud || !this.item.tipo) return []

      let tipo = this.tipos.find((t) => t.value == this.item.tipo)
      console.warn('headers', this.$refs.solicitud.columnDefs, this.item.tipo, tipo)
      let headers = this.$refs.solicitud.columnDefs[tipo.key]
      // change every item.editable in headers to be true, and also remove field instalacion and rgseaa
      headers = headers.map((h) => {
        h.editable = true
        h.width = 250
        return h
      })

      headers = headers.filter(
        (h) => h.headerName != '#' && h.field != 'instalacion' // && h.field != 'rgseaa'
      )

      this.item.rgseaa = this.item.instalacionObject?.rgseaa
      if (this.item.tipo == 'actividad subcontratada') {
        this.item.rgseaa = this.item.instalacionObject?.rgseaa
        this.item.certificada = this.item.instalacionObject.certificada ? 'SI' : 'NO'
        this.item.conformidad_anexo = this.item.instalacionObject?.numero_certificado || '--'
        this.item.entidad = this.item.instalacionObject?.entidad_certificadora || '--'
      }

      return headers
    },
  },

  methods: {
    updateItemObject(object) {
      console.log('object', object)
      this.item[object + '_codigo'] = this.itemObjects[object]?.rgseaa
      this.item[object + '_nombre'] = this.itemObjects[object]?.razon_social
      this.item.instalacionObject = this.itemObjects[object]
    },

    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },

    formatDateBack(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },

    setItem() {
      if (this.itemSelected && this.itemSelected.data) this.item = { ...this.itemSelected.data }

      window.setTimeout(() => {
        if (this.$refs.instalaciones && this.item.instalacion) {
          this.$refs.instalaciones.setValueByCodigo(this.item.instalacion)
          console.warn(this.itemSelected)
        }
      }, 2000)
      this.$forceUpdate()
    },

    close() {
      this.dialog = false
      this.resetForm()
      this.$emit('close')
    },

    async submit() {
      this.$emit('validate')
      if (this.$refs.form.validate() && this.valid) {
        this.loading = true
        this.item.tipo_act = this.item.tipo
        this.item.otras_actividades = true
        this.item.iteracion_uuid = this.iteracion.uuid
        this.item.instalacion = this.item.instalacionObject?.uuid

        setTimeout(() => {
          this.$emit('submit', this.item)
          this.close()
        }, 500)
      }
    },

    resetForm() {
      this.item = {}
      if (this.$refs.form) this.$refs.form.reset()
    },

    resetValidation() {
      if (this.$refs.form) this.$refs.form.resetValidation()
    },

    async getActividadesFromTramites() {
      let xhr = await this.$store.dispatch('tramites/list')
      let xhr_sub = xhr.filter((el) => ['distribucion'].indexOf(el.codigo) == -1)
      let xhr_alc = xhr.filter(
        (el) => ['distribucion', 'comercializacion'].indexOf(el.codigo) == -1
      )

      this.db.actividades = {
        subcontratadas: xhr_sub,
        alquiladas: xhr_alc,
      }
      console.warn(this.db.actividades)
    },

    //+-------------------------------------------------
    // getActividades()
    // Determines where to get the actividades list
    // -----
    // Created on Tue Feb 22 2022
    //+-------------------------------------------------
    async getActividades() {
      if (this.cliente?.empresa?.codigo == 'araporcei') {
        if (this.alcance?.codigo == 'productos_ibericos') {
          this.getActividadesFromTramites()
        }

        return
      }

      if (this.cliente?.empresa?.codigo == 'certicalidad') {
        if (this.alcance?.codigo == 'certicalidad_productos_ibericos') {
          this.getActividadesFromTramites()
        }

        return
      }
    },

    async init() {
      this.getActividades()
    },
  },
}
</script>
