import {uuid} from 'vue-uuid'

function getParameterCaseInsensitive(object, key) {
  return object[Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())]
}

export const state = () => ({
  documentos: [],
  verGestorDocumental: false,
  adjuntarEvidencia: false,
  external_ref: null,
  documentos_custom: [],
  addDocumentosModuloExterno: false,
  actualizarEvidencias: false,
  documentos_modulo_externo: [],
})

export const mutations = {
  SET_DOCUMENTOS(state, docs) {
    state.documentos = docs
  },
  SET_DOCUMENTOS_MODULO_EXTERNO(state, docs) {
    docs.forEach((d) => {
      state.documentos_modulo_externo.push(d)
    })
  },
  ADJUNTAR_EVIDENCIA(state, flag) {
    state.adjuntarEvidencia = flag
  },
  ACTUALIZAR_EVIDENCIAS(state, flag) {
    state.actualizarEvidencias = flag
  },
  SET_EXTERNAL_REF(state, ref) {
    state.external_ref = ref
  },
  ADD_CUSTOM(state, data) {
    const body = {
      created_at: new Date(),
      file: data.documento.file,
      tipo: data.documento.file.type,
      titulo: data.documento.titulo,
      modulo: data.modulo,
      modulo_ref: data.modulo_ref,
      submodulo: data.submodulo,
      submodulo_ref: data.submodulo_ref,
      documento: {
        file: data.documento.file,
        titulo: data.documento.titulo,
      },
    }
    state.documentos_custom.push(body)
  },
  DELETE_CUSTOM(state, params_uuid) {
    let index = null
    for (let i = 0; i < state.documentos_custom.length; i++) {
      const documento = state.documentos_custom[i]
      if (documento.uuid === params_uuid) index = i
    }
    state.documentos_custom.splice(index, 1)
  },
  INIT_CUSTOM(state) {
    state.documentos_custom = []
  },
  ADD_DOCUMENTOS_MODULO_EXTERNO(state, flag) {
    state.addDocumentosModuloExterno = flag
  },
  RESET_DOCUMENTOS_MODULO_EXTERNO(state) {
    state.documentos_modulo_externo = []
  },
}

export const getters = {
  documentos(state) {
    const documentos = state.documentos
    const documentos_modulo_externo = state.documentos_modulo_externo

    return documentos.concat ? documentos.concat(documentos_modulo_externo) : []
  },
  documentos_modulo_externo(state) {
    return state.documentos_modulo_externo
  },
}

//dispatch actions
export const actions = {
  async list({state, commit}, route) {
    let params = {}

    if (route.name.indexOf('uuid') !== -1) {
      let path = route.path.split('/')
      let modulo = path[1]
      let modulo_ref = path[2]
      // TODO DESARROLLO: MEJORAR LAS RUTAS DE AUDITORIA PARA PODER MANTENER ESTO DINAMICO
      if (modulo_ref === 'tabs') {
        const modulo_uuid = route.fullPath.split('uuid=')
        modulo_ref = modulo_uuid[1]
      }
      // TODO DESARROLLO: MEJORAR LAS RUTAS DE AUDITORIA PARA PODER MANTENER ESTO DINAMICO
      let submodulo = ''
      let submodulo_ref = []
      if (path[3]) submodulo = path[3]
      if (path[4]) submodulo_ref.push(path[4])

      params = {
        modulo: modulo,
        modulo_ref: modulo_ref,
        submodulo: submodulo,
        submodulo_ref: submodulo_ref,
      }

      if (params.modulo_ref) {
        const res = await this.$axios.put(`documentos`, params)
        if (res) commit('SET_DOCUMENTOS', res.data)
      }
      state.verGestorDocumental = true
    } else state.verGestorDocumental = false
    commit('ACTUALIZAR_EVIDENCIAS', false)
    return state.documentos
  },

  /**
   * Esta función permite obtener documentos de un módulo y submódulo customizado
   * como por ejemplo, las acciones correctivas de un PAC
   */
  async listCustom({state, commit}, params) {
    commit('RESET_DOCUMENTOS_MODULO_EXTERNO')
    let documentos = []
    // for (let i=0; i < params.submodulo_ref.length; i++) {
    //   const submodulo_ref = params.submodulo_ref[i]
    //   const res = await this.$axios.put(`documentos`, {
    //     modulo: params.modulo,
    //     modulo_ref: params.modulo_ref,
    //     submodulo: params.submodulo,
    //     submodulo_ref: submodulo_ref
    //   })
    //   if (res) documentos = documentos.concat(res.data)
    // }
    let submodulo_ref = []
    if (Array.isArray(params.submodulo_ref)) {
      submodulo_ref = params.submodulo_ref
    } else if (params.submodulo_ref) {
      submodulo_ref = [params.submodulo_ref]
    }

    const res = await this.$axios.put(`documentos`, {
      modulo: params.modulo,
      modulo_ref: params.modulo_ref,
      submodulo: params.submodulo,
      submodulo_ref: submodulo_ref,
    })
    if (res) documentos = documentos.concat(res.data)
    commit('SET_DOCUMENTOS_MODULO_EXTERNO', documentos)
    return documentos
  },

  // async visualize({rootState}, params_uuid) {
  //   const res = await this.$axios.get(`documentos/getOne/${params_uuid}`)
  //   if (res) return res.data
  // },

  async download({rootState}, params_uuid) {
    const href = `documentos/${params_uuid}/download`
    this.$axios({
      method: 'GET',
      url: href,
      responseType: 'blob',
    })
      .then((response) => {
        const blob = new Blob([response.data], {type: response.data.type})
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const contentDisposition = getParameterCaseInsensitive(
          response.headers,
          'Content-Disposition'
        )
        let fileName = href.substring(href.lastIndexOf('/') + 1)
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1]
        }
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch((e) => {
        throw e
      })
      .finally(() => {})
  },

  async create({rootState, state}, data) {
    rootState.loading = true
    let path = data.path.split('/')
    let modulo = path[1]
    let modulo_ref = path[2]
    // TODO DESARROLLO: MEJORAR LAS RUTAS DE AUDITORIA PARA PODER MANTENER ESTO DINAMICO
    if (modulo_ref === 'tabs') {
      const modulo_uuid = data.fullPath.split('uuid=')
      modulo_ref = modulo_uuid[1]
    }
    // TODO DESARROLLO: MEJORAR LAS RUTAS DE AUDITORIA PARA PODER MANTENER ESTO DINAMICO
    let submodulo = ''
    let submodulo_ref = ''
    if (path[3]) submodulo = path[3]
    if (path[4]) submodulo_ref = path[4]

    let datos = new FormData()
    datos.append('uuid', uuid.v4())
    datos.append('file', data.documento.file)
    datos.append('titulo', data.documento.titulo)
    datos.append('modulo', modulo)
    datos.append('modulo_ref', modulo_ref)
    datos.append('submodulo', submodulo)
    datos.append('submodulo_ref', submodulo_ref)

    if (state.external_ref) datos.append('external_ref', state.external_ref)

    await this.$axios.post('documentos', datos)

    rootState.loading = false

    if (state.external_ref) state.external_ref = null
  },

  /**
   * Esta función permite añadir documentos de un módulo y submódulo customizado
   * como por ejemplo, las acciones correctivas de un PAC
   */

  async addCustom({rootState, state, dispatch, commit}) {
    const documentos_custom = state.documentos_custom
    if (documentos_custom && documentos_custom.length > 0) {
      for (let i = 0; i < documentos_custom.length; i++) {
        const documento = documentos_custom[i]
        await dispatch('createCustom', documento)
      }
      commit('INIT_CUSTOM')
    }
  },

  async createCustom({rootState, state}, data) {
    rootState.loading = true
    const dataUuid = data.uuid ? data.uuid : uuid.v4()
    let datos = new FormData()
    datos.append('uuid', dataUuid)
    datos.append('file', data.documento.file)
    datos.append('titulo', data.documento.titulo)
    datos.append('modulo', data.modulo)
    datos.append('modulo_ref', data.modulo_ref)
    datos.append('submodulo', data.submodulo)
    datos.append('submodulo_ref', data.submodulo_ref)
    if (data.external_ref) datos.append('external_ref', data.external_ref)
    // datos.append('tipo', data.documento.tipo.name)
    await this.$axios.post('documentos', datos)
    rootState.loading = false
  },

  async delete({rootState, commit}, params_uuid) {
    rootState.loading = true
    try {
      await this.$axios.delete(`documentos/${params_uuid}`)
      commit('ACTUALIZAR_EVIDENCIAS', true)
    } catch (e) {
      console.info(e)
    } finally {
      rootState.loading = false
    }
  },

  async downloadFichero({rootState}, params) {
    const href = `documentos/getAll`
    //download zip
    this.$axios({
      method: 'PUT',
      url: href,
      responseType: 'blob',
      data: params,
    })
      .then((response) => {
        const blob = new Blob([response.data], {type: response.data.type})
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const contentDisposition = getParameterCaseInsensitive(
          response.headers,
          'Content-Disposition'
        )
        let fileName = params.modulo + '_' + params.modulo_ref + '.zip'
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1]
        }
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch((e) => {
        throw e
      })
      .finally(() => {})
  },
}
