import { render, staticRenderFns } from "./DateCellEditor.vue?vue&type=template&id=2e1874f2&scoped=true&"
import script from "./DateCellEditor.vue?vue&type=script&lang=js&"
export * from "./DateCellEditor.vue?vue&type=script&lang=js&"
import style0 from "./DateCellEditor.vue?vue&type=style&index=0&id=2e1874f2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e1874f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDatePicker,VMenu,VTextField})
