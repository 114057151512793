/**
 * @project: certiapp-nuxt
 * @file:    territorios.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Wed Jun 02 2021
 **/

export const state = () => ({
  list: [],
  paises: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  SET_PAISES(state, items) {
    state.paises = items
  },
}
//dispatch actions
export const actions = {
  async paises({ rootState, commit }, params) {
    rootState.loading = true
    const res = await this.$axios.get('territorios/paises')
    rootState.loading = false
    if (res) {
      commit('SET_PAISES', res.data)
      return res.data
    }
  },

  //+-------------------------------------------------
  // comunidades()
  // Returns comunidades from a pais UUID
  // -----
  // Created on Wed Jun 02 2021
  //+-------------------------------------------------
  async comunidades({ rootState }, uuid) {
    rootState.loading = true

    const res = await this.$axios.get(`territorios/paises/${uuid}/comunidades`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // provincias()
  // Returns provincias from a pais UUID
  // -----
  // Created on Wed Jun 02 2021
  //+-------------------------------------------------
  async provincias({ rootState }, uuid) {
    rootState.loading = true

    const res = await this.$axios.get(`territorios/paises/${uuid}/provincias`)

    rootState.loading = false
    if (res) return res.data
  },
}
