<template>
  <div>
    <slot name="filters-top"></slot>
    <v-card :flat="flat" :tile="tile">
      <v-card-title>
        {{ title ? title : '' }}
        <slot name="title"></slot>
        <!-- <span class="title-vuely" v-else>{{`Listado ${$nuxt.$route.name}`}}</span> -->
        <v-spacer v-if="title"></v-spacer>
        <slot name="filters"></slot>
        <v-text-field
          v-if="!hideSearch"
          label="Búsqueda rápida"
          v-model="search"
          append-icon="search"
          single-line
          hide-details
          clearable
          @keyup="updateFilter"
          @input="updateFilter"></v-text-field>
        <v-btn depressed small dark color="green darken-2" @click="excelExport" class="mt-5 ml-4">
          <v-icon class="mr-1" size="20">mdi-file-excel</v-icon>
          exportar
        </v-btn>
      </v-card-title>

      <slot></slot>

      <div style="height: 100%">
        <div class="ag-container">
          <ag-grid-vue
            style="width: 100%"
            :style="{ height: height + 'vh' }"
            :suppressCellSelection="true"
            class="ag-theme-material ag-grid"
            id="ag-grid"
            :columnDefs="headers"
            @grid-ready="onGridReady"
            :rowModelType="rowModelType"
            :pagination="true"
            :sideBar="sideBar"
            :autoGroupColumnDef="autoGroupColumnDef"
            :groupSelectsChildren="groupSelectsChildren"
            :animateRows="true"
            :localeText="localeText"
            :paginationAutoPageSize="false"
            :quickFilter="search"
            @rowClicked="clickRow"
            :rowSelection="rowSelection"
            :suppressRowClickSelection="suppressRowClickSelection"
            :masterDetail="!!detailCellRenderer"
            :detailRowHeight="detailRowHeight"
            :detailCellRenderer="detailCellRenderer"
            :detailCellRendererParams="detailCellRendererParams"
            rowGroupPanelShow="never"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :gridOptions="options"
            :frameworkComponents="frameworkComponents"
            :excelStyles="excelStyles"
            @selectionChanged="onSelectionChange()"
            @firstDataRendered="autoSize"
            :paginationPageSize="paginationPageSize"
            :cacheBlockSize="cacheBlockSize"
            :serverSideSorting="true"
            @sortChanged="onSortChanged"></ag-grid-vue>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AgDatatable',
  props: {
    options: { type: Object, default: () => {} },
    detailRowHeight: { type: Number, default: null },
    detailCellRenderer: { type: String, default: null },
    detailCellRendererParams: null,
    title: { type: String, default: null },
    height: { type: String, default: '72.5' },
    tile: { type: Boolean, default: true },
    flat: { type: Boolean, default: true },
    groupSelectsChildren: { type: Boolean, default: true },
    showHover: { type: Boolean, default: true },
    hideOverlay: { type: Boolean, default: false },
    hideSearch: { type: Boolean, default: false },
    showSelect: { type: Boolean, default: false },
    rowSelection: { type: String, default: null },
    sizeColumnsToFit: { type: Boolean, default: true },
    disableClickRow: { type: Boolean, default: false },
    emitClickRow: { type: Boolean, default: false },
    suppressRowClickSelection: { type: Boolean, default: false },
    autoGroupColumnDef: {
      type: Object,
      default: () => {
        resizable: true
      },
    },
    urlData: { type: String, default: null },
    headers: { type: Array, default: () => [] },
    items: { type: Array, required: true, default: () => [] },
  },
  mounted() {
    setTimeout(() => (this.loading = false), 1500)
    if (!this.showHover) this.num_hover = 3
  },
  beforeMount() {
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Por favor, espere mientras se cargan los datos...</span>'
    this.localeText = {
      equals: 'es igual',
      notEqual: 'no es igual',
      lessThan: 'es menor',
      greaterThan: 'es mayor',
      inRange: 'esta en el rango',
      contains: 'contiene',
      noRowsToShow: 'No existen datos',
      filters: 'Filtros',
      columns: 'Columnas',
      loadingOoo: 'Cargando...',
      page: 'Página',
      of: 'de',
    }
    this.frameworkComponents = {
      SwitchRenderer: 'SwitchRenderer',
      DeleteRenderer: 'DeleteRenderer',
      BooleanCellEditor: 'BooleanCellEditor',
      AutocompleteCellEditor: 'AutocompleteCellEditor',
      SelectCellEditor: 'SelectCellEditor',
      TextCellEditor: 'TextCellEditor',
      DateCellEditor: 'DateCellEditor',
      AccionCorrectivaRenderer: 'AccionCorrectivaRenderer',
    }
    this.sideBar = {
      // toolPanels: ['filters', 'columns'],
      toolPanels: [],
    }
    this.excelStyles = [
      {
        id: 'default',
        dataType: 'string',
      },
      {
        id: 'stringType',
        dataType: 'string',
      },
      {
        id: 'string',
        dataType: 'string',
      },
    ]
  },
  data() {
    return {
      file: undefined,
      singleSelect: false,
      loading: false,
      search: '',
      page: 1,
      pageCount: 0,
      localeText: {},
      sideBar: {},
      gridApi: null,
      columnApi: true,
      frameworkComponents: null,
      overlayLoadingTemplate: null,
      num_hover: 8,
      excelStyles: null,

      rowModelType: 'serverSide',
      paginationPageSize: 10,
      cacheBlockSize: 10,

      // defaultColDef: {
      //   flex: 1,
      //   minWidth: 90,
      //   resizable: true,
      // },
    }
  },
  computed: {
    gridOptions: () => this.options,
    IS_LIST() {
      return !this.$route.params.uuid
    },
    CAN_EDIT() {
      return this.IS_LIST ? this.$store.state.permisos.can_edit : true
    },
  },
  watch: {
    items: function (value) {
      if (value && value.length > 0 && this.gridApi) {
        this.gridApi.hideOverlay()
      }
      if (value && value.length === 0 && this.gridApi) {
        this.gridApi.hideOverlay()
      }
    },
    search() {
      this.debounceUpdateDataSource()
    },
  },
  methods: {
    debounce(func, wait) {
      let timeout
      return function (...args) {
        const context = this
        clearTimeout(timeout)
        timeout = setTimeout(() => func.apply(context, args), wait)
      }
    },
    async autoSize() {
      if (this.columnApi) {
        console.log('autoSize')
        const rowGroupColumnsIds = this.columnApi
          .getRowGroupColumns()
          .map((column) => column.getColId())
        await this.columnApi.removeRowGroupColumns(rowGroupColumnsIds)
        setTimeout(() => {
          this.columnApi.autoSizeAllColumns()
          this.columnApi.addRowGroupColumns(rowGroupColumnsIds)
          setTimeout(() => {
            this.gridApi.expandAll()
            this.columnApi.autoSizeAllColumns()
          }, 800)
        }, 800)
      }
      this.$forceUpdate()
    },
    checkFile(e) {
      console.info(e)
      console.info(this.file)
    },
    excelImport() {},
    excelExport() {
      const keys = []
      const headers = this.headers
        .filter((e) => e.field || e.children)
        .map((e) => e.field || e.children)
      for (let i = 0; i < headers.length; i++) {
        const element = headers[i]
        Array.isArray(element)
          ? (keys = keys.concat(element.map((e) => e.field)))
          : keys.push(element)
      }
      const params = {
        allColumns: true,
        columnKeys: keys,
        processCellCallback: (params) => {
          const cellClass = params.column.colDef.cellClass
          const value = params.value
          return cellClass === 'date'
            ? this.$moment(params.value).format('DD-MM-YYYY')
            : params.value
        },
      }
      this.gridApi.exportDataAsExcel(params)
    },
    onGridReady(params) {
      console.log('sort onGridReady', params)
      this.gridApi = params.api
      this.columnApi = params.columnApi
      this.gridApi.sizeColumnsToFit()
      this.gridApi.expandAll()
      this.$emit('gridApi', this.gridApi)
      this.$emit('columnApi', this.columnApi)
      this.gridApi.setSortModel([{ colId: 'auditoria.desde', sort: 'desc' }])

      params.api.sizeColumnsToFit()
      this.gridColumnApi = params.columnApi

      this.gridColumnApi.autoSizeAllColumns()

      this.updateDataSource()
    },
    onSortChanged(params) {
      console.log('sort onSortChanged', params)
      this.updateDataSource()
    },
    updateDataSource() {
      if (this.gridApi) {
        const sort = this.gridApi.getSortModel().map((s) => ({ field: s.colId, order: s.sort }))
        console.log('sortModel', this.gridApi.getSortModel(), this.gridApi)
      }
      const datasource = {
        getRows: (params) => {
          const sortModel = this.gridApi.getSortModel()
          let sortData = []
          if (sortModel.length > 0) {
            sortModel[0].colId = this.getSortmodelField(sortModel[0].colId)
            sortData.push({ field: sortModel[0].colId, order: sortModel[0].sort })
          }

          const page = Math.floor(params.request.startRow / this.paginationPageSize) + 1

          let body = {
            page: page,
            limit: this.paginationPageSize,
            filter: this.search || '',
            sort: sortData[0],
          }
          this.$axios
            .put(this.urlData, body)
            .then((response) => {
              if (response.data?.items) {
                params.successCallback(response.data.items, response.data.total_count)
              } else {
                console.error('No items in response data')
              }
            })
            .catch((error) => {
              console.error('Error fetching data from API: ', error)
            })
        },
      }

      this.gridApi.setServerSideDatasource(datasource)
    },
    updateFilter() {
      this.gridApi.setQuickFilter(this.search)
    },
    debounceUpdateDataSource: function () {
      this.debounce(this.updateDataSource, 700)()
    },
    clickRow(row) {
      if (!this.disableClickRow && !this.emitClickRow) {
        if (row.data) {
          if (this.CAN_EDIT) {
            let route = this.$nuxt.$route.name.replace(/-/g, '/')
            route = `/${route}/${row.data.uuid}`
            this.$router.push(route)
          } else {
            this.$store.commit('notification/show', {
              text: 'No tiene permisos suficientes.',
              color: 'error',
              timeout: 5000,
            })
          }
        }
      } else if (!this.disableClickRow && this.emitClickRow) {
        this.$emit('click-row', row.data)
      }
    },
    onServerSortingRequested(params) {
      console.log('onServerSortingRequested', params)
    },
    onSelectionChange() {
      const selection = this.gridApi.getSelectedRows()
      this.$emit('selectionChanged', selection)
    },
    getSortmodelField(colId) {
      let field = colId
      switch (field) {
        case 'contacto.nombre':
          field = 'cliente.nombre'
          break
        case 'nombre_alcance':
        case 'grupo_alcance.solicitudes':
          field = 'alcance.nombre'
          break
        default:
          break
      }
      return field
    },
  },
}
</script>

<style>
.ag-header-cell,
.ag-header-group-cell {
  border-right: 1px solid #e2e2e2 !important;
}

.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
  background: #eeeeee;
  cursor: pointer !important;
}

.ag-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.ag-grid {
  height: 1px;
  min-height: 1px;
  flex-grow: 1;
}

.ag-cell {
  font-size: 1.1em !important;
}

.ag-header-cell-text {
  font-size: 1.3em !important;
  font-weight: bold;
}

.ag-theme-material .ag-side-bar .ag-side-buttons {
  width: 100% !important;
  padding: 0 !important;
}

.ag-theme-material .ag-side-bar .ag-side-buttons .ag-side-button button {
  min-height: 0 !important;
  padding: 0 0 16px 0 !important;
}
</style>

